import React, {useCallback, useState, useEffect} from 'react';
import {View, Text, Platform,  ActivityIndicator,} from 'react-native';
import {Spacer} from '../components/Spacer';
import { Typography } from '../components/Typography';
import { GreenSelectList } from '../components/custom/GreenSelectList';
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { Button } from '../components/Button';
import { ScrollView, StyleSheet } from 'react-native';
import { HeaderTop } from './components/HeaderTop';

import { useTrsNavigation } from '../navigations/StackParamNavi';
import { InputDropDownList, InputSelectList } from './components/InputComponent';
import { IFindInfo } from '../datas/TDataFind';
import { IDDLSelectValue } from '../datas/TrsDataDetailType';
import { IRltCode } from '../datas/TSendData';
import { DefaultMsg } from './components/TrsModal';
import { api_GetCode, default_url } from '../datas/TrsApiType';
import { ISelectCode, ISelectInfo } from '../datas/TrsDataDetailType';
import { TitlePanelDetailRow } from './components/TrsComponent';
import { COLOR } from '../utils/color';
import { Loading } from '../components/Loading';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

const fontSize= Platform.OS ==='web'? 16 : 15;

export const SelfCheckList : React.FC <{
  action:string,
  class_cd:string,
  code_cd:string,
  isSync:boolean,
  onSync:()=>void,
}> =({action,class_cd, code_cd, isSync, onSync}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [datas, setData] = useState<ISelectInfo>();

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  const state = async ()=>{
    setLoading(true);
    const url =  default_url.concat( api_GetCode );
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        action:action, 
        code_cd:code_cd,
        class_cd:class_cd,
      }),
    });
    const res = await apiResponse.json();
    setData(res);
    setLoading(false);
    setError(false);
  }

  useEffect(() => {
    if ( isSync === true){
      recordStartTime();
      state();
      onSync();
    } 
  }, [isSync]);

  if (loading) return <Loading message={'loading..'}/>
  if (error) return  <View><Text>Error Occured: </Text></View>
  if (!datas) return(
    <View style={{}}>
    </View>
  ) 

  const SelfKeyText:React.FC<{
    title:string,
    data:string,
    borderRadius?:number,
    borderWidth?:number,
    borderColor?:string,
    height?:number,
  }> =({title, data, borderRadius, borderWidth, borderColor, height})=>(
    <View style={{flex:1}}>
      <View style={{borderRadius:borderRadius??50,
                    borderWidth:borderWidth??1,
                    borderColor:borderColor??'#D9D9D9',
                    height:height??80,
                    }}>
        <Spacer space={5}/>
        <Text style={{marginLeft:10, fontSize:fontSize-2, fontWeight:'bold', color:COLOR.ICONCOLOR}}>  {title}</Text>
        <Spacer space={10}/>
        <Text style={{marginLeft:20, marginRight:20, fontSize:fontSize-2,color:'#4C4C4C'}}>  {data}</Text>
        <Spacer space={5}/>
      </View>
      <Spacer space={Platform.OS === 'web' ? 20:10}/>
    </View>
  )
  const SelfText:React.FC<{
    title:string,
    data:string,
    borderRadius?:number,
    borderWidth?:number,
    borderColor?:string,
    height?:number,
  }> =({title, data, borderRadius, borderWidth, borderColor, height})=>(
    <View style={{flex:1}}>
      <Spacer space={5}/>
      <View style={{borderRadius:borderRadius??50,
                    borderWidth:borderWidth??1,
                    borderColor:borderColor??'#D9D9D9',
                    backgroundColor:COLOR.ICONCOLOR,
                    height:height??50, alignItems:'center', justifyContent:'center'
                    }}>

        <View style={{  alignItems:'center', justifyContent:'center'}}>
          <Text style={{marginLeft:20, marginRight:20, 
                        color:'white', alignItems:'center',
                        fontSize: fontSize}}>{data}</Text>
        </View>
      </View>
      <Spacer space={5}/>
    </View>
  )

  return (
    <View style={{flex:1}}>
      {
        datas.table.map((item, i) => {
          if (item.key === 'TC51' || item.key === 'TC52'){
            return (
              <View style={{flex:1}}>
                <SelfKeyText title={item.key } data={item.value} key={item.key} borderRadius={10}/>
                <Spacer space={10}/>
              </View>
              );
          }
          return (
            <View style={{flex:1}}>
              <SelfText title={item.sub_cd2} data={item.value} key={item.key} borderRadius={10}/>
              <Spacer space={10}/>
            </View>
            );
        })
      }
    </View>
  );
}

export const SelfTestScreen:React.FC = () =>{
  const navigation = useTrsNavigation();
  const [inputs, setInputs] = useState({
    devcls:'PDA',
    model:'',
    repcls:'',
  });
  const [repclass, setRepClass] = useState("ILISTPDA");
  const [isDevSync, setIsDevSync] = useState(false);
  const [isSelfSync, setIsSelfSync] = useState(false);
  const isHorizontal = Platform.OS === "web" ? true:false;
  
  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  const onSelect = useCallback((key:IDDLSelectValue) => {
    const { name, value } = key; // 우선 e.target 에서 name 과 value 를 추출

    console.log('onSelect.........................', name, value);
    setInputs({...inputs, [name]: value});
    if ( name === 'devcls'){
      setRepClass("ILIST" + value);
    }
    if ( name === 'repcls'){
      setIsSelfSync(true);
    }
  },[inputs, repclass, isSelfSync] ); 


  return (
    <View style={{flex:1,backgroundColor:'white',}}>
      {/* 헤더 */}
      <HeaderTop
          title="자가진단" 
          isMain={false}
          onPress={onPressBack}           
          onHome={()=>{navigation.popToTop()}} 
          />

     
      <View style={{ flex: 1, marginHorizontal: 16, backgroundColor:"white"}}>
        <ScrollView showsVerticalScrollIndicator={false}>
        <Spacer space={16}/>

        <InputDropDownList title={'분류'} 
                           name='devcls' 
                           class_cd={'DEVCLS'} 
                           onSelect={onSelect}  
                           />
        <Spacer space={16}/>

        <InputDropDownList title={'장애내역을 선택해주세요'} 
                           astar='*'  
                           name='repcls' 
                           class_cd={repclass} 
                           onSelect={onSelect} />
        <Spacer space={10}/>

        <View style={styles.textalign}>
          <NoflexTypography color='#7F7F7F' fontSize={fontSize}>점검 사항</NoflexTypography>
        </View>

        <Spacer space={10}/>
        <SelfCheckList action={'selftest'} class_cd={inputs.repcls} code_cd={''} isSync={isSelfSync} onSync={()=>{setIsSelfSync(!isSelfSync)}}/>
      </ScrollView>

      </View>
           <View style={{ width:'100%',height:60, alignItems:"center",paddingHorizontal:10,justifyContent:'center'}}>
           
          <Button onPress={()=>{  navigation.navigate('ClientReg', { menuId:"SelfTest"});
                          }} width='100%'>
          <View  style={styles.button}>
              <Text style={{color:'white', fontSize:fontSize}}>서비스 신청</Text>
          </View>
          </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  textalign:{ paddingLeft:10,
    flexDirection: "row", 
    justifyContent:"flex-start"
  },
  button:{
    backgroundColor:'#A6A6A6',
    borderRadius:10,
    height: 50,
    justifyContent:'center', 
    alignItems:'center',
    width:'100%'
  }
});