import React, {useEffect, useState, useRef,useCallback} from 'react';
import { View, Text, TouchableOpacity, StyleSheet,FlatList,ListRenderItem } from "react-native";
import { api_GetCode, default_url } from '../../datas/TrsApiType';
import { SelectList } from '../../components/dropdown/CustomSelectList';
import * as ApiCmd from '../../datas/TrsApiType';
import * as DataType from '../../datas/TrsDataType';
import { IDDLSelectValue, ISelectCode, ISelectInfo } from '../../datas/TrsDataDetailType';
import { Loading } from '../../components/Loading';
import { getIndex } from '../../utils/main';


export const DataDropDownList : React.FC <{
  name:string,
  data?:string,
  placeholder?:string,
  action?:string,
  class_cd?:string,
  onSelect:(value: IDDLSelectValue)=>void,
}> =(props) => {
  const [selected, setSelected] = React.useState<IDDLSelectValue>({name:props.name, value:'', title:'', sub_cd1:'', sub_cd2:'', checked:false});

 // const [selected, setSelected] = React.useState<IDDLSelectValue>({name:props.name, value:props.data??'', title:'', sub_cd1:'', sub_cd2:'', checked:false});
  const [datas, setData] = useState<ISelectInfo>();
  const [run, setRun] = useState(ApiCmd.start);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inx, setInx] = useState(0);

  const state = async ()=>{
    if ( loading === true) return;
    setLoading(true);
    const tmp = {
      action:props.action, 
      code_cd:'',
      class_cd:props.class_cd,
    }
    console.log('DataDropDownList==============>', tmp);
    const url =  ApiCmd.default_url.concat(ApiCmd.api_GetCode);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(tmp),
    });
    const res = await apiResponse.json();
    const tmp1:ISelectInfo = res;
    const inx = await getIndex(tmp1, props.data??"");
    console.log('INDEX.................', inx);
    setData(tmp1);
    setInx(inx);

    //setData(res);
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
  }

  useEffect(() => {
    state();
  }, [props.class_cd]);

  const onSync = useCallback(() => {
    setRun(ApiCmd.start);
  },[] );

  const onSelected = useCallback(() => {
    console.log('combobox onSelected', selected);
    if (!datas) return;
    const sel =datas?.table.filter((value:ISelectCode) => value.key === selected.value)
                           .map((row)=>{ 
                            const r = { ...selected,
                                        title:row.value,
                                        sub_cd1: row.sub_cd1, 
                                        sub_cd2: row.sub_cd2, }
                            return r;
                           }) ;
    props.onSelect(sel[0]);
  },[selected] ); 

  const onSetSelected = useCallback((val:string) => {
    console.log('onSetSelected', selected);
    setSelected({...selected, value:val, });
  },[selected] ); 
  

  if (loading) return <Loading message="로딩중.." />
  if (error) return  <View><Text>Error Occured: </Text></View>
  if (!datas) return(
    <View style={{}}>
    </View>
  ) 

  return (
     <SelectList 
        placeholder={props.placeholder }
        onSelect={onSelected}
        setSelected={onSetSelected} 
        data={datas.table} 
        defaultOption={datas.table[inx]}
        save="key"
        search={false} 
        boxStyles={{borderColor : "#D9D9D9"}}
        dropdownStyles={{borderColor:"#D9D9D9",backgroundColor:'white'}}
        dropdownTextStyles={{}}
      />
  );
}



export const DataSelectList : React.FC <{
  placeholder?:string,
  action?:string,
  class_cd?:string,
  onSelect:(key:string)=>void,
}> =(props) => {

  const [selected, setSelected] = React.useState('');
  const [datas, setData] = useState<ISelectInfo>();
  const [run, setRun] = useState(ApiCmd.start);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const state = async ()=>{
    setLoading(true);
    const url =  ApiCmd.default_url.concat( ApiCmd.api_GetCode);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        action:props.action, 
        code_cd:'',
        class_cd:props.class_cd,
      }),
    });
    const res = await apiResponse.json();
    setData(res);
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
  }

  useEffect(() => {
    if ( run === ApiCmd.start) state();
  }, [run, props.class_cd]);

  const onSync = useCallback(() => {
    setRun(ApiCmd.start);
  },[] );

  const onSelected = useCallback(() => {
    props.onSelect(selected);
  },[selected] ); 

  const onSetSelected = useCallback((val:string) => {
    setSelected(val)
  },[selected] ); 
  

  if (loading) return <View><Text>로딩중..</Text></View>
  if (error) return  <View><Text>Error Occured: </Text></View>
  if (!datas) return(
    <View style={{}}>
    </View>
  ) 

  return (
     <SelectList 
        placeholder={props.placeholder }
        onSelect={onSelected}
        setSelected={onSetSelected} 
        data={datas.table} 
        defaultOption={datas.table[0]}
        save="key"
        search={false} 
        boxStyles={{borderColor : "#D9D9D9"}}
        dropdownStyles={{borderColor:"#D9D9D9",backgroundColor:'white'}}
        dropdownTextStyles={{}}
      />
  );
}

