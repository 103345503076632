export interface IFindInfo  {
  action :   string;
  site_no :  string;
  fr_dat :   string;
  to_dat :   string;
  rma_type:  string;
  loc_sts :  string;
  track_no : string;
  det_seq:   number;
  serial_no: string;
  rep_type:  string;
  rep_cls:   string;
  user_cd:   string;
  user_tp:   string;
  class_cd:  string;
  code_cd : string;
  depth:string;
  sub_cd1:string;
  sub_cd2:string;
  track_step:string;
}

export const FindDefault : IFindInfo= {
  action :    '',
  site_no :   '',
  fr_dat :    '',
  to_dat :    '',
  rma_type:   '',
  loc_sts :   '',
  track_no :  '',
  det_seq:    0,
  serial_no:  '',
  rep_type:   '',
  rep_cls:    '',
  user_cd:    '',
  user_tp:    '',
  class_cd:   '',
  code_cd :   '',
  depth :  '',
  sub_cd1: '',
  sub_cd2: '',
  track_step: '',
}

export interface ITracking{
  site_cd:string;
  site_nm:string;
  camp_cd:string;
  camp_nm:string;
  track_no:string;
  det_seq:number;
  req_type:string;
  req_tpn:string;
  track_step:string;
  track_stepn:string;
  loc_sts:string;
  loc_stsn:string;
  serial_no:string;
  prod_cls:string;
  prod_cd :string;
  rma:string;
  comp:string;
  comp_nm:string;
  tr_no : string;

  checked:boolean;
  
}

export interface ITrackings{
  result_cd:string;
  result_msg:string;
  totals : number;
  table:ITracking[];
}

export const DefaultTracking: ITracking= {
  site_cd:'',
  site_nm:'',
  camp_cd:'',
  camp_nm:'',
  track_no:'',
  det_seq:0,
  req_type:'',
  req_tpn:'',
  track_step:'',
  track_stepn:'',
  loc_sts:'',
  loc_stsn:'',
  serial_no:'',
  prod_cls:'',
  prod_cd :'',
  rma:'',
  comp:'',
  comp_nm:'',
  tr_no : '',
  checked:false,
}