import {useNavigation} from '@react-navigation/native';
import dayjs from 'dayjs';
import React, {useCallback, useState,useEffect} from 'react';
import {View, Text,Platform} from 'react-native';
import { useTrsNavigation, useTrsRoute } from '../../navigations/StackParamNavi';
import { Button } from '../../components/Button';
import { NoflexTypography } from '../../components/custom/NoflexTypography';
import { DataGrid, IGridSelect } from './../components/DataGrid';
import {Spacer} from '../../components/Spacer';
import { useLoginState } from '../../contexts/LoginContext';
import { HeaderTop, HeaderUserInfo } from './../components/HeaderTop';
import * as ApiCmd from '../../datas/TrsApiType';
import * as DataType from '../../datas/TrsDataType';
import { IFindInfo, FindDefault  } from '../../datas/TDataFind';
import { DataDropDownList } from '../components/DataSelectList';
import { IDDLSelectValue } from '../../datas/TrsDataDetailType';
import { DataSectionAdminGrid } from '../components/DataSectionGrid';
import { IMsg, TrsCalendarBox } from '../components/TrsModal';
import { IRltCode } from '../../datas/TSendData';
import AsyncStorage from "@react-native-async-storage/async-storage";

// APK 컴파일시 막고 한다. 
import { ExcelSync } from '../components/ExcelSync';

const fontSize = Platform.OS === 'web' ? 18:14

export const TrsAdminListScreen:React.FC = () =>{
  const now = new Date();
  const navigation = useTrsNavigation();
  const route = useTrsRoute<'TrsRecordList'>();
  const loginState = useLoginState();
  const [site, setSite] =  useState(loginState.site_cd);
  const [startDate, setStartDate] = useState(dayjs(now).set('month', now.getMonth()-6).format('YYYY-MM-DD').toString())
  const [endDate, setEndDate] = useState(dayjs(now).format('YYYY-MM-DD'))
  const [calendarShow, setCalendarShow] = useState(false);
  const [repCls, setRepCls] = useState('');

  const [sendData,   setSendData]     = useState<any>();
  const [command,    setCommand]       = useState('');
  const [apiname,    setApiName]       = useState('');

  const [item, setItem] = useState<IFindInfo>({
    ...FindDefault, 
    action: ApiCmd.action_admreclist,
    site_no: site,
    fr_dat : startDate,
    to_dat : endDate,
    rep_cls : repCls,
  });  
  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  
  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  const onSite = useCallback((key:string) => {
    console.log('App onSelect', key);
    setSite(key);
    setItem({...item, site_no:key});

  },[item,  site] ); 

  const onDDLSelect = useCallback( (e: IDDLSelectValue) => {
    setRepCls(e.value);
    setItem({...item, rep_cls:e.value});
    }, [item, repCls]);

  const onGridSelectData = useCallback((value:IGridSelect) => {
    console.log('action_track_no', value);
    setItem({...item, track_no:value.track_no});
    const find1 = { 
      ...FindDefault,
      action: ApiCmd.action_track_no,
      site_no : loginState.user.site_cd,
      track_no: value.track_no,
    };
    if ( value.req_tp === '1') // 세조회
      navigation.navigate('TrsClientDetail', {find:find1, menuId:'TrsAdminList'});
    else  // 일괄
      navigation.navigate('TrsListDetail', {find:find1, menuId:'TrsAdminList'});
  },[] );

  const onPopDate = useCallback(() => {
    // 차후 달력 콘트롤  팝업 
    setCalendarShow(true);
  },[calendarShow]);

  // 달력선택시 호출 
  const onDate = useCallback((start:string, end:string) => {
    // 차후 달력 콘트롤 
    console.log('Start End............', start, end);
    setStartDate(start);
    setEndDate(end);
    setItem({...item, fr_dat:start, to_dat:end});
  },[startDate, endDate]);

  useEffect(()=>{
    console.log('useEffect.............', item, startDate, endDate);
  },[site, startDate, endDate, repCls]);

  const onExcel = useCallback((find:IFindInfo)=>{
    console.log('onExcel.........', find);
    setSendData(find);
    setCommand('send');
    setApiName(ApiCmd.api_GetTrsViewExcel);
  }, [command, apiname, sendData])

  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    setApiName("");
    setCommand("");
    setSendData("");
  },[apiname, command, sendData]);


  return (
    <View style={{flex:1,backgroundColor:"white"}}>
      <HeaderTop
          title="장애분류별 이력현황" 
          isMain={false}
          onPress={onPressBack}
          onHome={()=>{navigation.popToTop()}} 
          />
          

      <View style={{flex:1, marginHorizontal: 16, backgroundColor:"white"}}>
        <Spacer space={20}/>
          <HeaderUserInfo isUser={false} onSelect={onSite} class_cd='acombo' />
        <Spacer space={20}/>
        {  /** 시작일~ 종료일 */ }
        
        <View style={{flexDirection: "row",height:50,borderWidth:1,borderColor:'#D9D9D9',borderRadius: 10}}>
          <View style={{flex:1,borderRightColor:'#D9D9D9',borderRightWidth:1, }}>
            <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
              <NoflexTypography color='#7F7F7F' fontSize={fontSize}>시작일</NoflexTypography>
            </View>   
          </View>
          <View style={{flex:2,borderRightColor:'#D9D9D9',borderRightWidth:1, }}>
              <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
                <Text style={{color:'#99C341', fontSize:fontSize,fontWeight:'bold'}}>{startDate}</Text>
              </View>   
          </View>
            <View style={{flex:1,borderRightColor:'#D9D9D9',borderRightWidth:1, }}>
              <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
                  <NoflexTypography color='#7F7F7F' fontSize={fontSize}>종료일</NoflexTypography>
              </View>   
            </View>
          <View style={{flex:2,borderRightColor:'#D9D9D9',borderRightWidth:2, }}>
              <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
                <Text style={{color:'#99C341', fontSize:fontSize,fontWeight:'bold'}}>{endDate}</Text>
              </View>   
          </View>
          <View style={{flex:1,borderRightColor:'#99C341',borderRightWidth:1, }}>
            <Button onPress={onPopDate}>
              <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
                <Text style={{color:'#99C341', fontSize:fontSize,fontWeight:'bold'}}>기간설정</Text>
              </View>   
            </Button>  
          </View>


        </View>
        <Spacer space={ Platform.OS === 'web' ? 10:5} />
        <View style={{flexDirection:'row', justifyContent:'space-around'}}>
          <View style={{flex:1,borderRightColor:'#D9D9D9',borderRightWidth:0, }}>
            <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
              <NoflexTypography color='#7F7F7F' fontSize={fontSize}>장애분류</NoflexTypography>
            </View>   
          </View>
          <View style={{flex:1}}>
            <DataDropDownList action={"acombo"} 
                              name={'repCls'} 
                              class_cd={'DLIST'} 
                              onSelect={onDDLSelect} />
          </View>
        </View>

        <Spacer space={10}/>
        <View style={{flex:1}}>
          <DataSectionAdminGrid 
            apiname={ApiCmd.api_GetTrsViewRecord} 
            title='서비스 이력 현황' 
            item={item}
            onSelectData={(find:IFindInfo)=>{
            }}
            onExcel={(find:IFindInfo)=>{
              onExcel(find);
            }}
            />
        </View>
      </View>

      <TrsCalendarBox show={calendarShow} 
                      onOK={(startDate:string, endDate:string)=>{
                               setCalendarShow(false);
                               onDate(startDate, endDate);
                           }}
                      onCancel={()=>{
                                  console.log('Cancel......................'); 
                                  setCalendarShow(false);
                                }} />
                                    
    {                                
    
        ( Platform.OS === 'web' && sendData ) && 
         <ExcelSync apiName={apiname} 
                    sendData={sendData} 
                    onData={onReceived}
                    action={"admreclist"} />
    
    }

    </View>
  )
}