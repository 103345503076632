import { ITrsList, ITrsLists } from "../datas/TrsDataType";

export function reqdataSort(tables:ITrsLists, sortKey:string, seqKey:string ){
  console.log("reqdataSort");
  
  if ( seqKey === "sort-asc"){
    tables.table.sort(function(a:ITrsList, b:ITrsList){
      if ( a.req_dat > b.req_dat){
        console.log("sort_asc 1")
        return 1;
      }
      if ( a.req_dat< b.req_dat){
        console.log("sort_asc -1")
        return -1;
      }
      return 0
    } );
  }
  else{
    tables.table.sort(function(a:ITrsList, b:ITrsList){
      if ( a.req_dat < b.req_dat){
        console.log("sort_desc 1")
        return 1;
      }
      if ( a.req_dat > b.req_dat){
        console.log("sort_desc -1")
        return -1;
      }
      return 0
    } );
  }
  return tables;
}  

export function enddataSort(tables:ITrsLists, sortKey:string, seqKey:string ){
  
  console.log("enddataSort");
  if ( seqKey === "sort-asc"){
    tables.table.sort(function(a:ITrsList, b:ITrsList){
      if ( a.end_dat > b.end_dat){
        console.log(seqKey, "1")
        return 1;
      }
      if ( a.end_dat< b.end_dat){
        console.log(seqKey, "-1")
        return -1;
      }
      return 0
    } );
  }
  else{
    tables.table.sort(function(a:ITrsList, b:ITrsList){
      if ( a.end_dat < b.end_dat){
        console.log(seqKey, "1")
        return 1;
      }
      if ( a.end_dat > b.end_dat){
        console.log(seqKey, "-1")
        return -1;
      }
      return 0
    } );
  }
  return tables;
}  
