import React, {useCallback,useState, useRef,useEffect} from 'react';
import { useTrsNavigation, useTrsRoute } from '../navigations/StackParamNavi';
import { View, Platform, Text, ActivityIndicator } from 'react-native';
import { Spacer } from '../components/Spacer';
import { HeaderTop, } from './components/HeaderTop';
import { useLoginState } from '../contexts/LoginContext';
import * as ApiCmd from '../datas/TrsApiType';
import { FindDefault, IFindInfo } from '../datas/TDataFind';
import { ITrsDetail, ITrsDetails } from '../datas/TrsDataDetailType'; 
import { COLOR } from '../utils/color';
import { ClientReg } from './ClientReg'
import { ClientDetail } from './ClientDetail';
import { TrackContent, TrackHeader, TrackLine } from './components/TrsComponent';
import { ServiceReg } from './ServiceReg';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

export const TrsClientDetailScreen:React.FC = () =>{
  const navigation = useTrsNavigation<'TrsClientDetail'>();
  const route = useTrsRoute<'TrsClientDetail'>();
  const loginState= useLoginState();
  const [run, setRun] = useState(ApiCmd.start);
  const [datas, setData] = useState<ITrsDetails>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isItem, setIsItem] = useState(false);

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };


  const onPressBack = useCallback(() => {
    console.log('onPressBack', route.params.menuId);
    navigation.goBack();
    return;
  },[route] );

  const onMove = useCallback((cmd:string, find:IFindInfo)=>{
    if ( route.params.menuId === 'ClientReg'){
        navigation.goBack();
    }
    else{
       navigation.goBack();
    }
  },[route] );


  const state = async ()=>{
    if ( loading === true) return;
    setLoading(true);
    const url =  ApiCmd.default_url.concat( ApiCmd.api_GetTrsDetail);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(route.params.find),
    });
    const res = await apiResponse.json();
    setData(res);
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
    setIsItem(true);
  }
  
  useEffect(() => {
    if ( run === ApiCmd.start  ) state();
  }, [route.params.find, run]);

  const onSync = useCallback(() => {
    state();
  },[] );


  if ( !datas ){
    return (
      <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
       <ActivityIndicator size='large' color='#ABF200'/>
          <Spacer space={10}/>  
          <Text style={{color:'#666666', fontSize:14 , fontWeight:'bold'}}> loading.. </Text>
      </View>
    )
  }

  const user_tp = loginState.user_tp;
  console.log('...............상태값 ............',user_tp, datas.table[0].track_step);
  const reg_type = ( user_tp === 'U01' && (datas.table[0].track_step === "00" ||  datas.table[0].track_step === "01" )) 
  const view_type = !( user_tp === 'U01' && (datas.table[0].track_step === "00" ||  datas.table[0].track_step === "01" )) 

  console.log('route.params.menuId    ', route.params.menuId);

  return (
    <View style={{flex:1,backgroundColor:'white'}}>
       {/* 헤더 */}
      <HeaderTop
          title="서비스 조회" 
          isMain={false}
          onPress={onPressBack}          
          onHome={()=>{navigation.popToTop()}} 
          />

      {
        //((reg_type && (user_tp == 'U01'))||( view_type && (user_tp == 'U01') )) && 
        <View>
          <TrackHeader items={datas} />
          <TrackLine />
        </View>    
      }
      {
        reg_type && (user_tp == 'U01') &&
          <ClientReg onMove={onMove} 
                      data={datas} track_step={'01'} site={loginState.site_cd} 
                      onSync={onSync}
                      onChangeId={()=>{}} 
                      isItem={isItem} onEnd={()=>{setIsItem(false)}}
                      />
      }
      {
        view_type && (user_tp == 'U01') && 
          <ClientDetail items={datas} onSync={onSync}  />
      } 
      {
        view_type && (user_tp == 'U02') &&
          <ServiceReg items={datas} onSync={onSync} isItem={isItem} onEnd={()=>{setIsItem(false)}}/>
      }
    </View>

  )
}

