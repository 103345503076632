import React, {useEffect, useState, useRef, useCallback} from 'react';
import { View, Text, TouchableOpacity, StyleSheet,FlatList,ListRenderItem } from "react-native";
import * as ApiCmd from '../../datas/TrsApiType';
import * as DataType from '../../datas/TrsDataType'
import { FindDefault, IFindInfo } from '../../datas/TDataFind';
import { GridHeader, HeaderColumn, TextColumn, ButtonColumn } from './GridHeader';
import { IGridSelect,defaultSelect } from './DataGrid';
import { ICode, ICodes } from '../../datas/TrsDataDetailType';
import { COLOR } from '../../utils/color';
import AsyncStorage from "@react-native-async-storage/async-storage";
import dayjs from 'dayjs';

// 코드 정보
export const CodeGrid : React.FC <{
  url:string,
  title:string,
  item:IFindInfo,
  onSelectData:(value:ICode)=>void,
  isSync?:boolean,
  onEnd?:()=>void,
}>=(props)=>{
  const [run, setRun] = useState(ApiCmd.start);
  const [datas, setData] = useState<ICodes>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const action = props.item.action;

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const state = async ()=>{
    setLoading(true);
    const url =  ApiCmd.default_url.concat( props.url);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(props.item),
    });
    const res = await apiResponse.json();
    setData(res);
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
    props.onEnd&& props.onEnd();
  }
  
  useEffect(() => {
    console.log('DataGrid......................', props.isSync, ']]]]]');
    if ( props.isSync === true ){
      recordStartTime();
      state();
    } 
  }, [props.isSync]);

  useEffect(() => {
    console.log('DataGrid......................', props.isSync, ']]]]]');
    //if ( props.isSync === true ){
      recordStartTime();
      state();
    //} 
  }, [props.item]);

  const onSync = useCallback(() => {
    recordStartTime();
    state();
  },[props.item] );

  const flatListRef = useRef(null);
  const onSelectData = useCallback((value:ICode) => {
    props.onSelectData(value);
  },[] );

  const ListHeaderComponent:React.FC<{}>=()=>{
    return(
      <View style={{flexDirection:"row", height:50}}>
        <HeaderColumn title='항목'/>
        <HeaderColumn title='명칭' flex={2}/>
      </View>
    )   
}

  const renderItem:ListRenderItem<ICode>=({item,index})=>{
    return(
      <View style={{ height:50,}}>
        <View style={{flexDirection:"row",}}>
        <ButtonColumn value={item.code_cd} index={index}  onData={()=>props.onSelectData(item)} />
        <TextColumn value={item.code_nm} flex={2}/>
        </View>
        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
    );
  }

  if (loading) return <View><Text>로딩중..</Text></View>
  if (error) return  <View><Text>Error Occured: </Text></View>

  if (!datas){
    return (
      <View style={{flex:1}}>
        <GridHeader title={props.title+`${0} 건`} onSync={onSync} />
      </View>

    )
  }

  return (
    <View style={{flex:1}}>
      <GridHeader title={props.title+` ( ${datas?.totals} ) 건`} onSync={onSync} />
      <View style={{flex:1}}>
        <View style={{flex:1}}>
            <FlatList 
              ref={flatListRef}
              data={datas?.table}
              renderItem={renderItem}
              ListHeaderComponent={ListHeaderComponent}
              showsVerticalScrollIndicator={false}
              style={{height:'auto'}}
            />      
        </View>
      </View>
    </View>
  );

}

