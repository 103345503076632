
export  interface ISyncInfo {
    apiname:string;
    error:boolean;
    data:string;
    message:string;
}  
export const DefautSyncInfo:ISyncInfo={
    apiname:'',
    error:false,
    data:'',
    message:'',
}

export interface ISendBatch
{
    action : string;
    site_cd : string;
    track_no : string;
    det_seq : number;
    req_dat: string;
    req_type: string;
    track_step: string;
    rep_det : string;
    rep_cdn  : string;
    serial_no: string;
    prod_cls: string;
    prod_cd : string;
    track_uid: string;
    track_nm: string;
    camp_cd: string;
    camp_req_nm : string;
    camp_nm: string;

    tr_comp : string;
    tr_no : string;
    cmt : string;
    rma_no : string;
    rec_dat : string;

    req_id : string;
    req_nm: string;
    ticket_no: string;
    loc_sts: string;
    cost_type: string;
    cost: string;

    reg_user : string;
    reg_uname : string;
    reg_ip : string;
    reg_dev : string;

}
export interface ISendBatchs{
    result_cd:string;
    result_msg:string;
    err:number;
    ok:number;
    totals : number;
    table:ISendBatch[];
  }

  

export interface ISendDetail
{
    action: string;
    site_cd: string;
    track_no: string;
    det_seq: number;
    req_dat: string;
    req_type: string;
    track_step: string;
    inner_key: number;
    serial_no: string;
    prod_cls: string;
    prod_cd: string;
    track_uid: string;
    track_nm: string;
    camp_cd: string;
    camp_req_nm: string;
    camp_nm: string;

    tr_comp: string;
    tr_no: string;
    cmt: string;
    rma_no: string;
    rec_dat: string;

    req_tr_chk: string;
    req_tr_comp: string;
    req_tr_no: string;
    res_tr_chk: string;
    res_tr_comp: string;
    res_tr_no: string;
    req_id: string;
    req_nm: string;
    ticket_no: string;
    loc_sts: string;
    cost_type: string;
    cost: string;

    reg_user: string;
    reg_uname: string;
    reg_ip: string;
    reg_dev: string;
}

export interface ISendRepair
{
    action: string;
    track_no: string;
    det_seq: number;
    req_dat: string;
    rep_type: string;
    rep_typen: string;
    rep_cls: string;
    rep_clsn: string;
    rep_cd: string;
    rep_cdn: string;
    rep_det: string;
    rma_no: string;
    cmt: string;
    checked:boolean,
    reg_user: string;
    reg_uname: string;
    reg_ip: string;
    reg_dev: string;
}

// 전송 포맷
export interface IReq
{
    trs  : ISendDetail;
    reps : ISendRepair[] 
}

// result
export interface IRltCode{
    result_cd:string;
    result_msg:string;
}

export const DefaultSendBatch={
    action     : "",
    site_cd    : "",
    track_no   : "",
    det_seq    : 0,
    req_dat    : "",
    req_type   : "",
    track_step : "",
    rep_det    : "",
    rep_cdn    : "",
    serial_no  : "",
    prod_cls :"",
    prod_cd  :"",
    track_uid :"",
    track_nm :"",
    camp_cd :"",
    camp_req_nm :"",
    camp_nm :"",

    tr_comp  :"",
    tr_no  :"",
    cmt  :"",
    rma_no  :"",
    rec_dat  :"",

    req_id  :"",
    req_nm :"",
    ticket_no :"",
    loc_sts :"",
    cost_type :"",
    cost :"",

    reg_user  :"",
    reg_uname  :"",
    reg_ip  :"",
    reg_dev  :"",

}

export const DefaultSendDetail={
    action:"",
    site_cd:"",
    track_no:"",
    det_seq: 0,
    req_dat:"",
    req_type:"",
    track_step:"",
    inner_key:0,
    serial_no:"",
    prod_cls:"",
    prod_cd:"",
    track_uid:"",
    track_nm:"",
    camp_cd:"",
    camp_req_nm:"",
    camp_nm:"",

    tr_comp:"",
    tr_no:"",
    cmt:"",
    rma_no:"",
    rec_dat:"",

    req_tr_chk:"",
    req_tr_comp:"",
    req_tr_no:"",
    res_tr_chk:"",
    res_tr_comp:"",
    res_tr_no:"",
    req_id:"",
    req_nm:"",
    ticket_no:"",
    loc_sts:"",
    cost_type:"",
    cost:"",

    reg_user:"",
    reg_uname:"",
    reg_ip:"",
    reg_dev:"",
}

export const DefaultSendRepair={
    action:"",
    track_no:"",
    det_seq: 0,
    req_dat:"",
    rep_type:"",
    rep_typen:"",
    rep_cls:"",
    rep_clsn:"",
    rep_cd:"",
    rep_cdn:"",
    rep_det:"",
    rma_no:"",
    cmt:"",
    checked:false,
    reg_user:"",
    reg_uname:"",
    reg_ip:"",
    reg_dev:"",
}
