export interface ICode{
  action   : string;
  class1   : string;
  class2   : string;
  class3   : string;
  class_cd : string;
  class_nm : string;
  code_cd  : string;
  code_nm  : string;
  depth    : string;
  sort_seq : string;
  sub_cd1  : string;
  sub_cd2  : string;
  sub_cd3  : string;
  sub_cd4  : string;
  sub_cd5  : string;
  sub_nm1  : string;
  sub_nm2  : string;
  sub_nm3  : string;
  sub_nm4  : string;
  sub_nm5  : string;
  del_flg  : string;
  reg_user : string;
  reg_uname: string;
  reg_ip   : string;
  reg_dev  : string;
}
export interface ICodes{
  result_cd:string;
  result_msg:string;
  totals : number;
  table:ICode[];

}



export interface IRepair{
  inner_key:number;
  track_no:string;
  det_seq:number;
  req_dat:string;
  rep_type:string;
  rep_typen:string;
  rep_cls:string;
  rep_clsn:string;
  rep_cd : string;
  rep_cdn :string;
  rep_det: string;
  rma_no:string;
  checked:string;
}

export interface IRepairs{
  result_cd:string;
  result_msg:string;
  totals : number;
  table:IRepair[];
}

export interface ITrsDetail{
  action:string;
  site_cd:string;
  site_nm:string;
  track_no:string;
  det_seq:number;
  req_dat:string;
  req_type:string;
  req_tpn:string;
  track_step:string;
  track_stepn:string;
  inner_key:number;
  serial_no:string;
  prod_cls:string;
  prod_cd :string;
  track_uid:string;
  track_nm:string;
  camp_cd:string;
  camp_nm:string;
  req_tr_chk:string;
  req_tr_comp:string;
  req_comp_nm:string;
  req_tr_no : string;
  res_tr_chk:string;
  res_tr_comp:string;
  res_comp_nm:string;
  res_tr_no : string;
  req_nm:string;
  ticket_no:string;
  loc_sts:string;
  cost_type:string;
  cost_typen:string;
  cost:string;
  rep_det01:string;
  rep_det02:string;
  rep_det03:string;
  rep_01:number;
  rep_02:number;
  rep_03:number;
  addr:string;
  alias:string;
  conract:string;
  validday:number;
  en_date:string;
}

export interface ITrsDetails{
  result_cd:string;
  result_msg:string;
  totals : number;
  table:ITrsDetail[];
}

export interface ITrackR{
  inner_key:number;
  track_no:string;
  det_seq:number;
  rec_seq:number;
  rec_dat:string;
  track_step:string;
  track_stepn:string;
  loc_sts:string;
  loc_stsn:string;
  rma_no:string;
}

export interface ITracksR{
  result_cd:string;
  result_msg:string;
  totals : number;
  table:ITrackR[];
}

export interface IRepClass{
  rep_cls:string; rep_clsn:string;
}
export interface IRepCode{
  rep_cd:string; rep_cdn:string;
}
export interface ISecRep{
  rep_cls:string;
  rep_clsn:string;
  reps:IRepCode[];
}

export interface ISelectCode {
  key    : string;
  value  : string;
  sub_cd1: string;
  sub_cd2: string;
  checked: boolean;
  disabled: boolean ;
}

export interface ISelectInfo{
  result_cd : string;
  result_msg : string;
  totals : number;
  table:ISelectCode[];
}

export interface IDDLSelectValue{
  name:string,
  value:string,
  title:string,
  sub_cd1:string,
  sub_cd2:string,
  checked: boolean;
}
export const DefaultDDLSelect: IDDLSelectValue={
  name:"",
  value:"",
  title:"",
  sub_cd1:"",
  sub_cd2:"",
  checked: false,
}
// TypeScript의 SectionList 에서 사용되는 형식
// data:readonly  Array[] 형식을 따라야 함 
export interface ISecRepair{
  rep_cls:string;
  rep_clsn:string;
  data:readonly IRepCode[];
}

export interface ICheckRepair{
  rep_cls:string;
  rep_clsn:string;
  selected:boolean;
  data:ISelectCode[];
}

export interface ISerial{
  serial:string;
  prod_cls:string;
  prod_cd:string;
  prod_nm:string;
  isvalid:string;
  st_date:string;
  en_date:string;
  sn_stat:string;
  valid_day:number;
  result_cd:string;
  result_msg:string;
  reg_user : string;
  reg_uname: string;
  reg_ip   : string;
  reg_dev  : string;

}



export const DefaultCheckSerial={
  isSerial:false, 
  prod_cd:"", 
  prod_cls:"", 
  validDay:0, 
  en_date:"",
  serial:"",
  sn_stat:"",
}

export interface ISerials{
  result_cd : string;
  result_msg : string;
  totals : number;
  table:ISerial[];
}


export const DefaultTrsDetail = {
  action:"",
  site_cd:"",
  site_nm:"",
  track_no:"",
  det_seq:0,
  req_dat:"",
  req_type:"",
  req_tpn:"",
  track_step:"",
  track_stepn:"",
  inner_key:0,
  serial_no:"",
  prod_cls:"",
  prod_cd :"",
  track_uid:"",
  track_nm:"",
  camp_cd:"",
  camp_nm:"",
  req_tr_chk:"",
  req_tr_comp:"",
  req_comp_nm:"",
  req_tr_no : "",
  res_tr_chk:"",
  res_tr_comp:"",
  res_comp_nm:"",
  res_tr_no : "",
  req_nm:"",
  ticket_no:"",
  loc_sts:"",
  cost_type:"",
  cost_typen:"",
  cost:"",
  rep_det01:"",
  rep_det02:"",
  rep_det03:"",
  rep_01:0,
  rep_02:0,
  rep_03:0,
  addr:"",
  alias:"",
  conract:"",
  validday:0,
  en_date:"",
}

export const DefaultCode = {
  action   : "",
  class1   : "",
  class2   : "",
  class3   : "",
  class_cd : "",
  class_nm : "",
  code_cd  : "",
  code_nm  : "",
  depth    : "",
  sort_seq : "",
  sub_cd1  : "",
  sub_cd2  : "",
  sub_cd3  : "",
  sub_cd4  : "",
  sub_cd5  : "",
  sub_nm1  : "",
  sub_nm2  : "",
  sub_nm3  : "",
  sub_nm4  : "",
  sub_nm5  : "",
  del_flg  : "",
  reg_user : "",
  reg_uname: "",
  reg_ip   : "",
  reg_dev  : "",
}

