import React, { useCallback, useEffect, useState } from "react";
import {Linking, View, Text, StyleSheet, Image, Platform, KeyboardAvoidingView, Keyboard, BackHandler, Alert , } from "react-native";
import { Typography } from "./components/Typography";
import { LoginTextInput } from "./components/custom/LoginTextInput";
import { Button } from "./components/Button";
import { useLoginStateDispatch, useLoginState } from "./contexts/LoginContext";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Spacer } from "./components/Spacer";
import { IUserInfo, IUsersInfo, TblSysUser,TblSysUsers } from './datas/TrsDataType';
import * as ApiCmd from './datas/TrsApiType';
import * as Network from 'expo-network';
import { DefaultMsg, IMsg, TrsMessageBox, TrsMessageShow } from "./screens/components/TrsModal";
import Checkbox from "expo-checkbox";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ASYNC_KEY } from "./datas/Const";
//import { BackgroundImage } from "@rneui/base";
import { TextInput } from "react-native/Libraries/Components/TextInput/TextInput";
//import crypto from "crypto";   // SHA256 Hash 를 생성하기 위한 
//import crypto from 'crypto-js'
import { AntDesign } from '@expo/vector-icons'; 
import Constants from "expo-constants";

import * as Crypto from 'expo-crypto';
import { Loading } from "./components/Loading";
//import { LinearProgress } from "@rneui/base/dist/LinearProgress/LinearProgress";
//import { Link } from "@react-navigation/native";

const space = Platform.OS ==='web'? 14 : 5;
export const SplashView : React.FC <{token?:string}>= ({token}) =>{
  const version = Constants.manifest?.version;
  const loginState = useLoginState();
  const dispatch = useLoginStateDispatch();

  const safeAreaInsets = useSafeAreaInsets();

  const [action, setAction] = useState('');
  const [userCd, setUserCd] = useState('');
  const [passWord, setPassWord] = useState('');
  const [isSync, setSync] = useState(false);
  const [status, setStatus] = useState(0);
  const [statusText, setStatusText] = useState('');
  const [data, setData] = useState<IUsersInfo>();
  const [user, setUser] = useState<IUserInfo>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [run, setRun] = useState(ApiCmd.stop);
  const [reg_ip, setRegIp] = useState('');
  const [msgInfo, setMsgInfo] = useState<IMsg>(DefaultMsg);
  const [message, setMessage] = useState<IMsg>(DefaultMsg);
  const [checked, setChecked] = useState(false);

  const canSave = true;

  const getIpAddress = async() =>{
    const ip = await Network.getIpAddressAsync();
    const nstat = await Network.getNetworkStateAsync();
    setRegIp(ip);
    console.log('network status IpAddress',ip);
    console.log('network status isConnected', nstat.isConnected);
    console.log('network status isInternetReachable', nstat.isInternetReachable);
    console.log('network status type', nstat.type);
    const user = await AsyncStorage.getItem(ASYNC_KEY.USERS);
    if ( user !== null){

      const parse = JSON.parse(user);
      if(parse){
        if ( parse.checked === true){
          setUserCd(parse.user_cd);
          setChecked(parse.checked);
        }
      }
    }
 }

  const getAPIData = async () => {
    setLoading(true);
    //const crypto = require('crypto');
    //const hash = crypto.createHash("sha256").update(passWord).digest("hex");
    const hash = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA256,
      passWord,
    );

    try {
      const url =  ApiCmd.default_url.concat( ApiCmd.api_GetLogin);
      const apiResponse = await fetch(url,{
        method:'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
          action:action, 
          user_cd:userCd,
          user_tp:action === 'client' ? 'U01':'U02',
          passwd: hash
        })
      });

      const json = await apiResponse.json();
      setData(json);
      setStatus(apiResponse.status);
      setStatusText(apiResponse.statusText);
      console.log('json', json);
      console.log('status', status);
      console.log('statusText', statusText);
      console.log('data', data);
      
      setSync(true);

    } catch (error) {
      setError(error);
    }
    setRun(ApiCmd.stop);
    setLoading(false);
  };



  const onMessage = useCallback((msgId:string)=>{
  }, []);

  const onLogin = useCallback((users:IUserInfo)=>{
    const user_tp = users.user_tp.substring(0,3);
    const ver = users.version;

    dispatch({ type: 'SET_LOGIN', user:users
    , user_tp:user_tp
    , reg_ip:reg_ip
    , reg_dev:''
    , site_cd:users.site_cd  
    , camp_cd:users.camp_cd
    , isCheck:checked
  });
   AsyncStorage.setItem(ASYNC_KEY.USERS, JSON.stringify({user_cd:users.user_cd, checked:checked}));

}, [dispatch, reg_ip, checked]);


const onOK = useCallback((msgId:string)=>{
  setMsgInfo(DefaultMsg);
  if ( msgId === 'ver' || msgId === 'download' ){
    BackHandler.exitApp(); 
    const LINK = "http://tntech.support/apk/ctn-trs.apk";
    Linking.openURL(LINK);  
  }

}, [user]);

const onCancel = useCallback((msgId:string)=>{
  setMsgInfo(DefaultMsg);
  if ( msgId === 'ver'){
    if ( user ) onLogin(user);
  }
}, [user]);

  const dataLoad=useCallback( (data:IUsersInfo) =>{
    if ( data.result_cd === ApiCmd.API_OK){
      if (data.table.length> 0){
        const users = data.table[0];
        const user_tp = users.user_tp.substring(0,3);
        const ver = users.version;

        if ( Platform.OS !== 'web' && ver.length > 0 && ver !== version ){
          setUser(users);
          setMsgInfo({...DefaultMsg, msgId:'ver', message:'변경된 버전이 있습니다. [' + ver + ']'  });
        }
        else onLogin(users);
       // setUser(users);
       // console.log('set login', user);
       // props.onFinishLoad(users);
      }
      else{
        setMessage({...DefaultMsg, msgId:'error', message:data.result_msg});
        setSync(false);
      }
    } else{
      setMessage({...DefaultMsg, msgId:'error', message:data.result_msg});
      setSync(false);
    }

  }, [dispatch, reg_ip, checked, user]);

  useEffect(() =>{
    console.log('SplashView useEffect run', run);
    if ( run === ApiCmd.start){
      getAPIData();
    }
  }, [run]);
  useEffect(() =>{
    console.log('SplashView useEffect getIpAddress');
    if ( reg_ip.length === 0){
      getIpAddress();
    }
  }, [reg_ip]);

  useEffect(() =>{
    if (isSync) {
      console.log('SplashView useEffect ISSync', isSync);
      console.log('SplashView useEffect ISData', data);
      if (data) dataLoad(data);
    }
  }, [isSync]);


  const onClientLogin=useCallback( () =>{
    setAction('client');
    setRun(ApiCmd.start);
    }, []);

  const onServerLogin=useCallback( () =>{
    console.log('splashView===URL=',ApiCmd.default_url);

    setAction('server');
    setRun(ApiCmd.start);
    }, []);
    
  const [visible,setVisible] = useState(true);
  useEffect(()=>{
      if(Platform.OS === 'web' ){
      setVisible(!visible)
      } 
  },[])

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      setVisible(!visible)
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setVisible(!visible)
    });
    
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, [visible]);

  const onLink=useCallback( () =>{
    BackHandler.exitApp(); 
    const LINK = "http://211.224.102.4:20010/apk/ctn-trs.apk";
    Linking.openURL(LINK);  
  }, []); 


  return (
    <View style={styles.backcontainer}>
      {visible &&   <View style = {{ flex: 1, width:'100%',justifyContent:"flex-start",
        alignItems:'flex-start',}}>
          <View style ={{ width:'100%', justifyContent:'flex-start',alignItems:'flex-end',paddingTop:50 }}>
            <Button onPress={()=>{console.log('press');  BackHandler.exitApp(); }}  >
              <AntDesign name="close" size={30} color="#99C341" />
            </Button>
          </View>
        </View>
      }
      <View 
        style = {{flex: Platform.OS ==='web'?  1  :2 ,
                  justifyContent:'center', 
                  alignItems:'flex-start',
                  width:'100%',
                    }}>
            
  
      <Text style={styles.logintext}>LOG IN</Text>
      <Spacer space={space}/>
      <View style={styles.subcontainer}>
        <Text style={styles.text}>USER ID</Text>
        <Spacer space={space}/>
        <LoginTextInput
          value={userCd}
          onChangeText={(item:string) =>{
            setUserCd(item); 
          }}
          placeholder="아이디를 입력하세요."
        />
        <Spacer space={space-7}/>
        <View style={styles.line}/>
      </View>
      <Spacer space={space+10}/>
      <View style={styles.subcontainer}>
        <Text style={styles.text}>PASSWORD</Text>
        <Spacer space={space}/>
        <LoginTextInput
          value={passWord}
          onChangeText={(item:string) =>{
            setPassWord(item); 
          }}
          placeholder="패스워드를 입력하세요."
          secureTextEntry={true}
        />
        <Spacer space={space-7}/>
        <View style={styles.line}/>
      </View>
      <Spacer space={space}/>
      <View style={{flexDirection:'row', alignItems:"center", justifyContent:'space-between'}}>
        <Checkbox
            style={{margin:4}}
            value={checked}
            color={ undefined}
            onValueChange={()=>{setChecked(!checked)}}
          />
        <Spacer space={space-3} horizontal/>
        <Text style={{...styles.text16}}>로그인 정보 기억</Text>
      
      </View>
      
      <Spacer space={space}/>
      <View style={styles.button}>
        <Button onPress={onClientLogin} width ='100%' >
          <View style={{
              backgroundColor:canSave ? '#99C341':'#80A634',
              borderRadius:5,
              height:50,
              width:'100%',
              justifyContent:'center', 
              alignItems:'center',
              paddingTop: 13
              }}>
              <Typography fontSize={16} color={canSave ? 'white' : 'gray'}>Client Login</Typography>
              <Spacer space={safeAreaInsets.bottom}/>
          </View>   
        </Button>
      </View>        
      <View style={styles.button}>
        <Spacer space={space}/>
        <Text style={styles.text15}> or </Text>
        <Spacer space={space}/>
        <Button onPress={onServerLogin} width={'100%'}>
          <View style={{
              backgroundColor:canSave ? '#7F7F7F':'#626262',
              borderRadius:5,
              height: 50,
              width:'100%',
              justifyContent:'center', 
              alignItems:'center',
              paddingTop: 13
              }}>
              <Typography fontSize={16} color={canSave ? 'white' : 'gray'}>Provider Login</Typography>
              <Spacer space={safeAreaInsets.bottom}/>
          </View>   
        </Button>
      
      </View>
     
      </View>
      {
        (loading)&&<Loading message={'로딩중..'}/>
      }
       

      
  { visible &&
    <View style={{
    justifyContent:"space-evenly",
    alignItems:'center',
    flex:1,
    width:'100%',
    }}
    >
    <View style={{
        flexDirection: "row",
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
        flex:1}}
        >
          <Image source={require('../assets/cupang.png')} style ={{ resizeMode:'contain',width:'30%',height:'100%'}} />
      
          <Spacer space={space} horizontal/>
          <Image source={require('../assets/tandt.png')}  style ={{ resizeMode:'contain',width:'30%',height:'100%'}}/>
     
          <Spacer space={space} horizontal/>
          <Image source={require('../assets/zebra.png')}  style ={{ resizeMode:'contain',width:'30%',height:'100%'}}/>
       
        </View>
      </View> 
    }
      {
          (Platform.OS !== 'web')&& 
          <Button onPress={()=>{setMsgInfo({...DefaultMsg, msgId:'download', message:'Apk Download 하시겠습니까?'})}} width={'100%'}>
            <View style={{width:'100%',height:20,alignItems:'flex-end'}}>
              <Text>{'Version' + version}</Text>
            </View>
          </Button>
      }
      {
        /*
          (Platform.OS === 'web')&& 
          <View style={{width:'100%',height:100,  alignItems:'flex-start'}}>
            
            <Text style={{fontSize:20,color:'#6B6B6B'}}>{'https://211.224.102.4:20007 는 보안문제로  8월 17일부터 차단 되오니 '}</Text>
            <Text style={{fontSize:20,color:'#6B6B6B'}}>{'https://tntech.support 로 접속해주시기 바랍니다.'}</Text>
          </View>
          */
      }
   
  <TrsMessageBox message={msgInfo} 
              onOK={onOK} 
              onCancel={onCancel} />

  <TrsMessageShow message={message} 
                onMessage={(msgId:string)=>{
                          setMessage(DefaultMsg)}} />
  </View> 
  )
}
const styles = StyleSheet.create({
  backcontainer:{
    flex:1,
    paddingHorizontal: Platform.OS ==='web'? 100 : 30,
    justifyContent:'center', 
    alignItems: 'center',
    backgroundColor : 'white'
  },
  container:{
    flex: Platform.OS ==='web'?  1  : 2 ,
    justifyContent:'center', 
    alignItems:'flex-start',
    width:'100%',
    
  },
  subcontainer:{
    flexGrow: 0, 
    justifyContent:'center', 
    alignItems:'flex-start',
    backgroundColor:'white',
    width:'100%'

  },
  logintext:{
    flexBasis: 80,
    flexGrow: 0,
    backgroundColor:'white',
    fontSize : 50,
    color: '#595959',
    justifyContent:'flex-start', 
    alignItems:'flex-start',
  },
  text:{
    flexGrow: 0,
    backgroundColor:'white',
    paddingBottom:0,
    fontSize :  Platform.OS == 'web' ? 20 : 17,
    color: '#7F7F7F',
    justifyContent:'flex-start', 
    alignItems:'flex-start',
  },
  text16:{
    backgroundColor:'white',
    fontSize :  Platform.OS == 'web' ? 18 : 14,
    color: '#7F7F7F',
    justifyContent:'center', 
    alignItems:'center',
  },
  text15:{
    flexGrow: 0,
    backgroundColor:'white',
    paddingBottom:0,
    fontSize : 15,
    color: '#D9D9D9',
    justifyContent:'center', 
    alignItems:'center',
  },
  button:{
    justifyContent:'center', 
    alignItems:'center',
    width:'100%',
  },
  textBox:{
      flexGrow: 1,
      width:'100%',
      borderStyle:'none',
  },
  line:{
      flexGrow:1,
      flexDirection:'row',
      backgroundColor : '#99C341',
      height : 2,
      width:'100%'
      
  },
  none:{
      flexGrow: 0,
      backgroundColor : 'white',
      width: 270,
      height : 15
  },
  imgcontainer:{
    justifyContent:"space-evenly",
    alignItems:'center',
    flexGrow:1,
    backgroundColor:'white'
  },
});
