import { useTrsNavigation, useTrsRoute } from '../navigations/StackParamNavi';
import React, { useCallback, useEffect,  useState } from 'react';
import { View, StyleSheet, Platform, ScrollView, Alert } from 'react-native';
import { HeaderTop, HeaderUserInfo } from './components/HeaderTop';
import { ReqStateContextProvider, useReqState } from '../contexts/ReqContext';
import { useLoginState, useLoginStateDispatch } from '../contexts/LoginContext';
import { FindDefault, IFindInfo } from '../datas/TDataFind';
import { ClientReg } from './ClientReg'
import { Spacer } from '../components/Spacer';
import { ClientRegBatch } from './ClientRegBatch';
import { useIsFocused } from '@react-navigation/native';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

// AS 신청등록 
export const TrsClientRegScreen:React.FC=()=>{
  const navigation = useTrsNavigation();
  const loginState = useLoginState();
  const route = useTrsRoute<'ClientReg'>();

  const dispatch = useLoginStateDispatch();
  const [selected, setSelected] =  useState(loginState.site_cd);
  const [screenId, setScreenId] = useState('reg');
  const [isInit, setIsInit] = useState(false);
  const isFocused = useIsFocused();

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const onPressBack = useCallback(() => {
    console.log('route.....', route.params.menuId);
    navigation.goBack();
  },[route] );

  useEffect(()=>{
    console.log('ClientRegScreen......isFocused......', isFocused);
    onInit();

  },[isFocused]);
  const onInit= useCallback(()=>{
    console.log('ClientRegScreen......isFocused.  onInit Call.....');
    setIsInit(true);
    
  },[isInit] );

  const onMove = useCallback((cmd:string, find:IFindInfo)=>{
    navigation.navigate('TrsClientDetail', {find:find,  menuId:"ClientReg"});
    
  },[route] );

  const onSelect = useCallback((key:string) => {
    dispatch({type: 'SET_SITE', site_cd:key});
    setSelected(key);
  },[selected] ); 
  // 일괄 배치 와 단일 건 등록 Change
  const onScreenChange=useCallback((scrId:string)=>{
    setScreenId(scrId);
  },[screenId]);

  return (
    <View style={{flex:1,backgroundColor:'white'}}>
       {/* 헤더 */}
      <HeaderTop
          title="서비스 신청" 
          isMain={false}
          onPress={onPressBack} 
          onHome={()=>{navigation.popToTop()}} />

      <View style={{marginHorizontal:16}}>
        <Spacer space={10}/>
          <HeaderUserInfo isUser={false} onSelect={onSelect} class_cd='rcombo' />
        <Spacer space={10}/>
      </View>
      {
        (screenId === 'reg') && 
        <ClientReg  onMove={onMove} 
                    track_step={'01'} 
                    site={selected} 
                    onSync={()=>{setSelected('')}}
                    onChangeId={onScreenChange}
                    isItem={false} onEnd={()=>{}}
                    isInit={isInit}
                    onClearEnd={()=>{setIsInit(false)}}
                    />
      }
      {
        (screenId ==='batch') &&
         <ClientRegBatch  track_step={'01'} 
                          site={selected} 
                          onSync={()=>{setSelected('')}}
                          onChangeId={onScreenChange}
                          />

      }
      
    </View>
  )
}

const styles = StyleSheet.create({
  textalign:{ 
    paddingLeft:10,
    flexDirection: "row", 
    justifyContent:"flex-start"
  },
  button:{
    backgroundColor:'#A6A6A6',
    borderRadius:10,
    height: 50,
    minWidth:'100%',
    maxWidth:'100%',
    justifyContent:'center', 
    alignItems:'center',
  },
  boxalign:{
    marginHorizontal: 20
  },
  boxinalign:{
    justifyContent:"center",
    alignItems:'flex-start'
  },
});