import React, {useEffect, useState, useRef, useCallback} from 'react';
import { View, Text, TouchableOpacity, StyleSheet,FlatList,ListRenderItem } from "react-native";
import * as ApiCmd from '../../datas/TrsApiType';
import * as DataType from '../../datas/TrsDataType'
import { FindDefault, IFindInfo } from '../../datas/TDataFind';
import { GridHeader, HeaderColumn, TextColumn, ButtonColumn } from './GridHeader';
import { IGridSelect,defaultSelect } from './DataGrid';
import { COLOR } from '../../utils/color';
import { Loading } from '../../components/Loading';

// 사용자 정보 
export const UserGrid : React.FC <{
  url:string,
  title:string,
  item:DataType.IUserInfo,
  onSelectData:(value:IGridSelect)=>void,
  isSync?:boolean,
  onEnd?:()=>void,
}>=(props)=>{
  const [run, setRun] = useState(ApiCmd.start);
  const [datas, setData] = useState<DataType.IUsersInfo>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const action = props.item.action;

  const state = async ()=>{
    setLoading(true);
    const url =  ApiCmd.default_url.concat( props.url);
    console.log('user find', props.item);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(props.item),
    });
    const res = await apiResponse.json();
    setData(res);
    console.log('user result', res);
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
    props.onEnd&& props.onEnd();
  }
  
  useEffect(() => {
    console.log('DataGrid......................', props.isSync, ']]]]]');
    if ( props.isSync === true ){
      state();
    } 
  }, [props.isSync]);

  const onSync = useCallback(() => {
    state();
  },[props.item] );

  const flatListRef = useRef(null);

  const ListHeaderComponent:React.FC<{}>=()=>{
      return(
        <View style={{flexDirection:"row", height:50}}>
          <HeaderColumn title='유형'/>
          <HeaderColumn title='ID'/>
          <HeaderColumn title='사용자명'/>
          <HeaderColumn title='SITE'/>
          <HeaderColumn title='사용유무'/>
        </View>
      )   
  }

  const onSelectData = useCallback((value:DataType.IUserInfo) => {
    const selectedData = {
      ...defaultSelect,
      user_tp:value.user_tp,
      user_cd: value.user_cd,
    }
    props.onSelectData(selectedData);
  },[] );

  
  const renderItem:ListRenderItem<DataType.IUserInfo>=({item,index})=>{
    return(
      <View style={{ height:50,}}>
        <View style={{flexDirection:"row",}}>
          <TextColumn value={item.user_tpn} />
          <ButtonColumn value={item.user_cd} index={index}  onData={()=>onSelectData(item)} />
          <TextColumn value={item.user_nm} />
          <TextColumn value={item.site_nm + '/' + item.camp_cd} />
          <TextColumn value={(item.del_flg === 'A') ? "사용":"사용해제"} />
        </View>
        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
    );
  }

  if (loading) return <Loading />
  if (error) return  <View><Text>Error Occured: </Text></View>

  if (!datas){
    return (
      <View style={{flex:1}}>
        <GridHeader title={props.title+`${0} 건`} onSync={onSync} />
      </View>

    )
  }

  return (
    <View style={{flex:1}}>
      <GridHeader title={props.title+` ( ${datas?.totals} ) 건`} onSync={onSync} />
      <View style={{flex:1}}>
        <View style={{flex:1}}>
            <FlatList 
              ref={flatListRef}
              data={datas?.table}
              renderItem={renderItem}
              ListHeaderComponent={ListHeaderComponent}
              showsVerticalScrollIndicator={false}
            />      
        </View>
      </View>
    </View>
  );

}

