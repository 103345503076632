import { useNavigation } from '@react-navigation/native';
import React, {useCallback} from 'react';
import {View, Platform, useWindowDimensions} from 'react-native';
import { NoflexTypography } from '../../components/custom/NoflexTypography';
import {Header} from '../../components/Header/Header';
import {Spacer} from '../../components/Spacer';
import { useLoginState } from '../../contexts/LoginContext';
import { DataSelectList } from './DataSelectList';
import * as ApiCmd from '../../datas/TrsApiType';
import { TrackLine } from './TrsComponent';
import { COLOR } from '../../utils/color';
import { Popup } from './Popup';
const fontSize= Platform.OS ==='web'? 20 : 16;
export const HeaderUserInfo:React.FC<{
  isUser:Boolean,
  onSelect:(key:string)=>void,
  class_cd?:string,
}>=(props)=>{
  const loginState = useLoginState();
  console.log('loginstate', loginState);

  //Platform.OS === 'web'
  //Platform.OS === 'android'

  const onSelect = useCallback((key:string) => {
      props.onSelect(key);
  },[] ); 

  if ( props.isUser && 
     ( loginState.user.user_tp === 'U0200' || loginState.user.user_tp  === 'U0201') ){
    return (
      <View style ={{}}>
        <View style={{   flexDirection: "row", justifyContent:"space-between", marginHorizontal: 10}}>
          <NoflexTypography color='#5A5A5A' fontSize={fontSize}>{loginState.user.user_nm}</NoflexTypography>
          <NoflexTypography color='#5A5A5A' fontSize={fontSize}>{loginState.user.user_tpn}</NoflexTypography>
        </View>
        <Spacer space={5}/>
        <TrackLine/>
      </View>
    )
  }

  if ( loginState.user.user_tp ===  ApiCmd.SITE_ADMIN )
  { // Site 관리자 또는 API
    return (
      <View style ={{ justifyContent: 'space-between',  }}>
        <View style={{   flexDirection: "row", justifyContent:"space-between", marginHorizontal: 10}}>
          <NoflexTypography color='#5A5A5A' fontSize={fontSize}>{loginState.user.user_nm}</NoflexTypography>
          <NoflexTypography color='#5A5A5A' fontSize={fontSize}>{loginState.user.site_nm}</NoflexTypography>
        </View>
        <Spacer space={5}/>
        <TrackLine/>
      </View>
    )
  }
  if ( loginState.user.user_tp ===  ApiCmd.USER )
  { // Site 관리자 또는 API
    return (
      <View style ={{ justifyContent: 'space-between',  }}>
        <View style={{   flexDirection: "row", justifyContent:"space-between", marginHorizontal: 10}}>
          <NoflexTypography color='#5A5A5A' fontSize={fontSize}>{loginState.user.user_nm}</NoflexTypography>
          <NoflexTypography color='#5A5A5A' fontSize={fontSize}>{loginState.user.site_nm + '/' + loginState.user.camp_cd}</NoflexTypography>
        </View>
        <Spacer space={5}/>
        <TrackLine/>
      </View>
    )
  }
  return(
    <View style ={{ }}>
      <View style={{ flexDirection: "row", 
                     justifyContent:"space-between", 
                     alignItems:"center", 
                     marginHorizontal: 10}}>
        <NoflexTypography color='#5A5A5A' fontSize={fontSize}>{loginState.user.user_nm}</NoflexTypography>
        <DataSelectList action={props.class_cd??"acombo"} 
                        class_cd={"SITE"} 
                        onSelect={onSelect}  />
      </View>
      <Spacer space={5}/>
      <TrackLine/>
    </View>
  )
}

export const HeaderTop:React.FC<{
  title:string,
  isMain:boolean,
  onPress:()=>void,
  onHome?:()=>void,
}> = (props) =>{
  const loginState = useLoginState();

  const onPressBack = useCallback(() => {
    props.onPress();
  },[] );

  const onLogout = useCallback(() => {
    props.onPress();
  },[] );

  if ( props.isMain === true ){
    return (
      <View style={{backgroundColor:COLOR.HEADERBACK}}>
        <Header>
          <Header.Title title={props.title}></Header.Title>
          <Header.Group>
            <Header.Title title={loginState.user.user_nm + '(' + loginState.user.user_tpn+')'}></Header.Title>
            <Spacer space={20} horizontal />
            <Header.Icon iconName='menu' onPress={onLogout} />
          </Header.Group>
        </Header>
      </View>
    );
  }
  else{
    if ( Platform.OS === 'web'){
      return (
        <View style={{backgroundColor:"#99C341"}}>
          <Header>
            <Header.Group>
              <Header.Icon iconName='arrow-back' onPress={onPressBack} />
              <Spacer space={12} horizontal />
              <Header.Title title={props.title}></Header.Title>
            </Header.Group>
            <Header.Icon iconName='home' onPress={()=>{ props.onHome && props.onHome()}} />
          </Header>
        </View>
      )
    }
    return (
      <View style={{backgroundColor:"#99C341"}}>
        <Header>
          <Header.Group>
            <Header.Icon iconName='arrow-back' onPress={onPressBack} />
            <Spacer space={12} horizontal />
            <Header.Title title={props.title}></Header.Title>
            <Header.Icon iconName='home' onPress={()=>{ props.onHome && props.onHome()}} />
          </Header.Group>
        </Header>
      </View>
    )
  }
}

