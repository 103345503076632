import React, { useState } from 'react';
import { View, TextInput } from 'react-native';
export const LoginTextInput:React.FC<{
    value:string;
    onChangeText:(text:string)=> void;
    placeholder:string;
    onSubmitEditing?:()=>void
    fontSize?:number;
    secureTextEntry?:boolean;
}> = (props)=>{
    const [focused, setFocused] = useState(false);

    return (
        <View 
            style={{
                alignSelf:'stretch', 
                paddingHorizontal:12, 
                paddingVertical:0,
                borderLeftColor : 'white',
                borderTopColor : 'white',
                borderRightColor : 'white',
                borderBottomColor : 'white',
                borderWidth:1, 
                borderColor:focused ? 'white':'white',
                paddingLeft : 1,
                paddingBottom : 0,       
                         
            }}>
            <TextInput 
                autoCorrect={false}
                autoCapitalize={'none'}
                value={props.value}
                secureTextEntry={props.secureTextEntry??false}
                onChangeText={props.onChangeText}
                placeholder={props.placeholder}
                placeholderTextColor='#D9D9D9'
                onSubmitEditing={props.onSubmitEditing}
                style={{fontSize:props.fontSize ?? 18}}
                onFocus={()=> setFocused(true)} 
                onBlur={()=> setFocused(false)}
            />
        </View>
    )
}