import React, { useEffect, useRef, useState,useCallback } from "react";
import { 
  Text, 
  View ,
  TouchableOpacity,
  Platform,
} from "react-native";
import * as ApiCmd from '../../datas/TrsApiType';
import { SimpleLineIcons,MaterialIcons } from '@expo/vector-icons'; 
import { COLOR } from '../../utils/color';
import { InputCheck } from "./InputComponent";
import { DefaultCheckData, getCheckSection, getChecksSection,  } from "../../utils/data";
import { NoflexTypography } from "../../components/custom/NoflexTypography";
import { Typography } from "../../components/Typography";
import { Spacer } from "../../components/Spacer";
import { useRepairSync} from "../../hooks/use-Input";
import { useReqState } from "../../contexts/ReqContext";
import { ICheckRepair, IRepair, IRepairs, ISelectCode } from "../../datas/TrsDataDetailType";
import { CheckColumn, TextColumn } from "./GridHeader";
type typRepair = IRepair[];
type typRepairs = IRepairs;

// 서버에서 장애 종류를 수신받아 화면에 표시 
export const DefectView:React.FC<{
  rep_type:string,
  track_no:string, 
  det_seq:number,
  width?:number,
  isSync?:boolean,
  onSync?:()=>void,
}>=({track_no, det_seq, rep_type, width, isSync, onSync})=>{
  const reqStatus = useReqState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [table, setTable] = useState<typRepair>([]);
  const [sections, setSections] = useState([{...DefaultCheckData}]);

  const sAction= 'repair';
  const fontSize = (Platform.OS === 'web')? 18: 15
  const column =  (Platform.OS === 'web')? 2: 2

  const ApiSync = async ()=>{
    if (loading === true) return;
    setLoading(true);
    const find = {
      action:sAction,
      track_no:track_no, 
      det_seq:det_seq, 
      rep_type:rep_type,
    };
    console.log('DEFECT VIEW ..........................',find);
    const apiResponse = await fetch(ApiCmd.default_url.concat(ApiCmd.api_GetTrsDetail),{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(find),
    });
    const res = await apiResponse.json();
    const conv = await getChecksSection(res); // src/utils/data.ts
    setSections(conv); 
    setLoading(false);
    onSync&&onSync();
  }
  useEffect(() => { 
    if (isSync === true) {
      console.log('DefectView 디펙트 ....', isSync, rep_type)
      ApiSync();
    }
  }, [isSync]);

  const RepCheckedItem:React.FC<{
    item:ISelectCode, 
  }>=(item)=>(
      <View style={{flex:1}}>
        <Text style={{fontSize:fontSize-1,  paddingBottom:5, paddingTop:5, color:COLOR.GRAY_4 }}>{item.item.value}</Text>
      </View>
  )

  const RepSubItemView:React.FC<{
    data:ICheckRepair,
  }> =({data})=>{
    useEffect(()=>{
    }, [reqStatus])
    
    return (
      <View style={{
        alignItems:'flex-start',justifyContent:'center' , width:'100%'
        }}>
        {
          data.data.map((item, i) => {
             return (
              <RepCheckedItem item={item} key={`${i}`}/>
             );
        })}
      </View>
    );
  }

  const RepViewItem:React.FC<{
    item:ICheckRepair
    index:string
  }>=(item)=>{
    return (

      <View style={{justifyContent:'space-between'}}>
        <View style={{ flexDirection:'row', borderBottomWidth:1,
                      borderBottomColor:COLOR.BORDER, 
                      }}>
            <View style={{ 
                           flex:1, 
                           justifyContent:'center', 
                           alignItems:'center',
                           borderRightColor:COLOR.BORDER,
                           borderRightWidth:0.5,
                        }}>
              <Text style={{color:COLOR.ICONCOLOR, fontSize:fontSize, fontWeight:'bold'}}> {item.item.rep_clsn}</Text>
            </View>
            <View style={{ 
                           flex:2,
                           borderRadius:20,
                           justifyContent:'center', 
                           alignItems:'center',
                           marginRight:10
                        }}>
                {/** Sub Item */}          
                <View style={{marginLeft:10, alignItems:'flex-start',justifyContent:'flex-start' }}>
                    <RepSubItemView data={item.item}    />
                </View>
            </View>
        </View>
      </View>
    )
  }

  // 상단의 TitleBar  
  const DefectTitle:React.FC<{title:string, isAsta?:Boolean}>=({title, isAsta})=>{
    return (
      <View style={{paddingLeft:10,
                    flexDirection: "row", 
                    justifyContent:"flex-start"   }}>
                    <NoflexTypography color='#7F7F7F' fontSize={15}>{title}</NoflexTypography>
                    { isAsta&&<Typography color='red' fontSize={14} > * </Typography>}
      </View>
     )
  }
  
  return (
    <View>
      <Spacer space={20}/>
      { (rep_type === '01')&& <DefectTitle title='장애증상' /> }
      { (rep_type === '02')&& <DefectTitle title='장애판정' /> }
      { (rep_type === '03')&& <DefectTitle title='결과' /> }
      <Spacer space={5}/>
      <View style={{ borderColor : '#D9D9D9',          
                     borderWidth : 1 ,          
                     borderRadius: 5,             }}>
        <Spacer space={5}/>
        <View style={{flex:1}}>
            <View>
              {
                sections.map((item, i) => { 
                  return ( <RepViewItem item={item} index={`${i}`+item.rep_cls} key={`${i}`+item.rep_cls} /> );
                })
              }
            </View>
        </View>
        <Spacer space={6}/>
      </View>
    </View>
  )
}