import React, {useState,useEffect} from 'react';
import {
  Alert, 
  Modal, 
  Text, 
  View,
  Platform,
  Pressable, 
  ScrollView,
  useWindowDimensions,
  KeyboardAvoidingView,
} from 'react-native';
import { MultiLineInput } from '../../components/MultiLineInput';
import { Spacer } from '../../components/Spacer';
import { minWinWidth } from '../../datas/Const';
import { COLOR } from '../../utils/color';
import { DefectList } from './DefectList';
import { DefectPopupList } from './DefectPopupList';

export const TrsDefectModalBox:React.FC<{
  isShow:boolean,
  rep_type:string,
  track_no?:string, 
  det_seq?:number,
  onOK:(rep_type:string, repDetail:string)=>void, 
  onCancel:(rep_type:string)=>void
}> = ({isShow, rep_type, track_no, det_seq, onOK, onCancel}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isSync, setSync] = useState(false);
  const [repDetail, setRepDetail] = useState('');
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
 
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=Platform.OS ==='web'? (width - (marginHorizontal * 2))/2: width - (marginHorizontal * 2)
  const marginVertical = 20;
  const innerHeight= Platform.OS ==='web'? height-marginVertical : height-marginVertical;
  const textHeight=innerHeight/2;
  const menuButtonHeight=Platform.OS ==='web'? 40:40;
  const maxWinWidth = 393 * 2;

  const defectWidth=innerWidth - 50;
  const defectHeight=innerHeight - 200;
  const repDetHeight=100;

  useEffect(() =>{
    if (isShow === true){
      console.log('DefectModel Show sync True');
      setModalVisible(true);
      setSync(true);
    } 
  }, [isShow]);

  return (
      <View style={{alignItems:'center',justifyContent:'center', marginHorizontal:marginHorizontal}}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalVisible(false);
          onCancel(rep_type);
        }}>
        <View style={{ paddingLeft:width< 1300 ? marginHorizontal : marginHorizontal,paddingRight:width< 1300 ? marginHorizontal : marginHorizontal/2,height:height,
               alignItems: 'center', justifyContent:'center',
               flexDirection:'row'}}>
          <View style={{flex:1,alignItems:'center',justifyContent:'center',height:height,backgroundColor: 'rgba(0,0,0,0.35)',paddingHorizontal:10}}>
         
              <View style={{width:'100%', flex:1 , borderRadius:10,margin:15,paddingHorizontal:20,justifyContent:'space-between',alignItems:'center',paddingTop:30,backgroundColor:'white'}}>
                  <View style={{flex:1, width:'100%', alignItems: 'center', justifyContent: 'center',marginHorizontal:20,borderWidth:1,borderRadius:5,borderColor:'#99c341'}}>
                    <ScrollView style={{width:'100%', flex:2, }}>
                      <DefectPopupList rep_type={rep_type} 
                                       track_no={track_no} 
                                       det_seq={det_seq} 
                                       isSync={isSync} 
                                       onSync={()=>{
                                           setSync(false);
                                          }}  
                      />
                    </ScrollView>
                  </View>
                  <Spacer space={10}/>
                  <KeyboardAvoidingView  behavior="padding"
                  style = {{
                    width:'100%',alignItems: 'center', justifyContent: 'center',marginHorizontal:20
                    }}>
                  <View style={{width:'100%',height:150, alignItems: 'center', justifyContent: 'center',marginHorizontal:20}}>
                    <MultiLineInput value={repDetail}
                          onChangeText={(text:string)=>{ setRepDetail(text); }} 
                          placeholder={'상세 내역을 입력해주세요'}
                          fontSize={15}
                          onSubmitEditing={()=>{}}
                          height={150 - 10}
                                />
                  </View>
                  </KeyboardAvoidingView>
                  <Spacer space={10}/>
                  
                  <View style={{width:'100%',height:50 , marginBottom:0, flexDirection:'row',paddingHorizontal:Platform.OS ==='web' ? innerWidth/10 : 15}}>
                    {/** 확인 버튼  */}
                    <View style={{flex:1, width:'100%',alignItems: 'center', justifyContent: 'center'}}>  
                      <Pressable style={{ width:'100%', flex:1 }} onPress={()=>{setModalVisible(false); onOK(rep_type, repDetail); }}>
                        <View style={{height:menuButtonHeight , width:'100%', 
                                      alignItems: 'center', justifyContent: 'center',backgroundColor:'#99C341', borderRadius:100, }} >  
                          <Text style={{color:'white',fontSize:16}}>확인</Text>
                        </View>
                      </Pressable>
                    </View>
                    <Spacer space={20} horizontal/>
                    {/** 취소 버튼  */}
                    <View style={{flex:1,width:'100%',alignItems: 'center', justifyContent: 'center'}}>  
                      <Pressable style={{ width:'100%', flex:1}} onPress={() => {setModalVisible(false); onCancel(rep_type);}}>
                        <View style={{height:menuButtonHeight , width:'100%', alignItems: 'center', justifyContent: 'center',backgroundColor:'#F2F2F2',borderRadius:100}} >  
                          <Text style={{color:'#666666', fontSize:16}}>취소</Text>
                        </View>
                      </Pressable>
                    </View>
                  </View>
                  <Spacer space={10}/>
              </View>
            </View>
          { width> 1300 ? 
            <View style={{width:500,height:height}}/>
            :<View/>
            }
            </View>
      </Modal>
      </View>
  );
};
