import React, {useCallback,useState, useRef,useEffect} from 'react';
import { DefaultCheckSerial, ISelectCode, ISerials, ITrsDetail, ITrsDetails } from "../datas/TrsDataDetailType";
import { View, Platform,Text, Alert,ScrollView,useWindowDimensions } from 'react-native';
import { useLoginState } from "../contexts/LoginContext";
import { Spacer } from '../components/Spacer';
import { TrackHeader, TrackInText, TrackLine, TrackSubContent,  } from './components/TrsComponent';
import { TrackingGrid } from './components/TrackingGrid';
import * as ApiCmd from '../datas/TrsApiType';
import { DefectList } from './components/DefectList';
import { Button } from '../components/Button';
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { DefaultMsg, TrsMessageBox, TrsMessageShow } from './components/TrsModal';
import { Sync } from './components/Sync';
import { DefaultSendDetail, DefaultSendRepair, IReq, IRltCode, ISendRepair } from '../datas/TSendData';
import { COLOR } from '../utils/color';
import { DefaultTracking, FindDefault, IFindInfo, ITracking } from '../datas/TDataFind';
import { useReqDispatch, useReqState } from '../contexts/ReqContext';
import { minWinWidth } from '../datas/Const';
import { TrsDefectModalBox } from './components/TrsDefectModal';
import { DefectView } from './components/DefectView';
import { InputSearchText } from './components/InputComponent';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

interface ICancelData{
  track_no:string;
  det_seq:number;
  reg_user : string;
  reg_uname: string;
  reg_ip   : string;
  reg_dev  : string;
}

export const ServiceReg:React.FC<{
  items:ITrsDetails,
  onSync:()=>void,
  isItem:boolean,
  onEnd:()=>void,
}>=({items, onSync, isItem, onEnd})=>{
  const loginState = useLoginState();
  const reqState = useReqState();
  const dispatch = useReqDispatch();
  const user_tp = loginState.user.user_tp.substring(0, 3);
  const [datas, setDatas] = useState<ITrsDetails>(items);
  const [input,      setInput]           = useState({serial:"",ticket:"", camp_req_name:loginState.user.user_nm, tr_no:"",  detail:"" });
  const [serialInfo, setSerialInfo] = useState(DefaultCheckSerial);
  const [sendData, setSendData] = useState<any>();
  const [command, setCommand] = useState('');
  const [apiname, setApiName] = useState('');
  const [rltCode, setRltCode] = useState<IRltCode>();
  const [msgShow, setMsgShow] = useState(DefaultMsg);
  const [msgInfo, setMsgInfo] = useState(DefaultMsg);
  const [isDefectShow, setIsDefectShow] = useState({isRep01:false, isRep02:false, isRep03:false});

  const [defectSelected, setDefectSelected] = useState({isRep01:false, isRep02:false, isRep03:false});
  const [isDefectModalShow, setIsDefectModalShow] = useState(false);
  const [selectedRepType, setSelectedRepType] = useState('');
  const [find, setFind] = useState<IFindInfo>(FindDefault);

  const fontSize = Platform.OS === 'web'? 18:15;
  const isHorizontal = Platform.OS === "web" ? true:false;

  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=width - (marginHorizontal * 2)

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  
  useEffect(()=>{
    if ( isItem === true){
      const rep_01 = (items.table[0].rep_01 > 0) 
      const rep_02 = (items.table[0].rep_02 > 0) 
      const rep_03 = (items.table[0].rep_03 > 0) 
      setDatas(items);
      setIsDefectShow({...isDefectShow
        , isRep01:( rep_01 && isDefectShow.isRep01 === false)
        , isRep02:( rep_02 && isDefectShow.isRep02 === false)
        , isRep03:( rep_03 && isDefectShow.isRep03 === false)
      });
      onEnd();  // isItem is false
    }
  },[items, datas, isDefectShow, isItem])

  const onChangeText = useCallback( (name: string,value:string) => {
    setInput({...input, [name]:value});
  }, [input, serialInfo]);

  const onSubmit = useCallback( (name:string, value:string) => {
    // 시리얼 검색
    console.log('Serial...........', input.serial, value );
    const serial = input.serial;
    console.log('Serial...........', serial );
    const search:IFindInfo = {...FindDefault, 
                              action:ApiCmd.action_serail_no, 
                              serial_no: (serial.substring(0,1) === "S")?serial.substring(1):serial }
    setInput({...input, serial:serial});
    setSendData(search);                                
    setCommand('serial_find');
    setApiName(ApiCmd.api_GetSerial);
  }, [input, command, apiname, sendData]);

  // A/S 신청 등록
  const onRepUpdate = useCallback((action:string,rep_type:string, rep_det:string)=>{
    /// Validation Check
    let repair:ISendRepair[] = [];
    console.log('여기 Repair===>', reqState.secs[0]);
    reqState.secs.map(rep => {
      rep.data.filter((value:ISelectCode) => value.checked === true)
              .map((row)=>{
                const r = {
                  ...DefaultSendRepair, 
                  action:action,
                  track_no:items.table[0].track_no,
                  det_seq:items.table[0].det_seq,
                  rep_cls:rep.rep_cls, 
                  rep_clsn:rep.rep_clsn, 
                  rep_type:rep_type,
                  rep_cd: row.key, 
                  rep_cdn:row.value,
                  rep_det:rep_det,
                  reg_user:loginState.user.user_cd,
                  reg_uname:loginState.user.user_nm,
                  reg_ip:loginState.reg_ip,
                  reg_dev:loginState.reg_dev,
                }
                repair.push(r);
              });
    });

    const track_step = datas.table[0].track_step;

    const next_track_step = (rep_type === '02' && track_step === '02')? '03': 
                            ((rep_type === '03')? '07' : track_step);  // 입고 인경우에 Next Step 으로 변경 
    const details = {...DefaultSendDetail, 
      action:action,
      track_no:items.table[0].track_no,
      det_seq:items.table[0].det_seq,
      track_step:next_track_step,
      details:'',
      reg_user:loginState.user.user_cd,
      reg_uname:loginState.user.user_nm,
      reg_ip:loginState.reg_ip,
      reg_dev:loginState.reg_dev,
    }

    if ( details && repair && repair.length > 0 ){
        let sendTmp:IReq[]=[];
        sendTmp.push({ trs:details, reps:repair})
        setSendData(sendTmp);
        setCommand(action);
        setApiName(ApiCmd.api_SetTrsRes);
        return;
    }else{
      setMsgShow({...DefaultMsg, msgId:'repair', message:'장애내역을 선택해주세요.'});
    }
  }, [datas, items, reqState, loginState,]);

  const onSerialChange = useCallback(()=>{
    if(serialInfo.isSerial === false){
      setMsgShow({...DefaultMsg, msgId:'serial', message:'변경대상을 입력해주세요.'});
      return;
    }
    const tmp:ITrsDetail = {...datas.table[0], serial_no : serialInfo.serial}
    setApiName(ApiCmd.api_SetSerialChange);
    setSendData(tmp);
    setCommand('serial_change');
  },[serialInfo, apiname, sendData, command, datas]);

  // A/S 신청 등록
  const onSend = useCallback((cmd:string,track_step:string)=>{
    const send :ITracking={
      ...DefaultTracking,
      track_no:items.table[0].track_no,
      det_seq:items.table[0].det_seq,
      track_step:track_step, 
    };
    const sendTmp:ITracking[]=[];
    sendTmp.push(send);
    setApiName(ApiCmd.api_SetTrsTracking);
    setSendData(sendTmp);
    setCommand(cmd);
  },[apiname, sendData, command]);

  const onCancel = useCallback(()=>{
    const send :ICancelData={
      track_no:items.table[0].track_no,
      det_seq:items.table[0].det_seq,
      reg_user:loginState.user.user_cd,
      reg_uname:loginState.user.user_nm,
      reg_ip:loginState.reg_ip,
      reg_dev:loginState.reg_dev,
    };
    setApiName(ApiCmd.api_CancelTrsTracking);
    setSendData(send);
    setCommand("cancel");
  },[apiname, sendData, command]);

    /// Validation Check

  // 시리얼정보검색 결과수신, A/S 신청 결과 수신
  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    setApiName(''); setSendData(null);
    if (resultCode === true) {
      if ( command ==='serial_change'){
        // 변경된 정보를 다시 수신한다.
        const tmp:IRltCode = rData;
        if ( tmp.result_cd === '1'){
          setSerialInfo(DefaultCheckSerial);
          setInput({...input, serial:''});
          setMsgShow({...DefaultMsg, msgId:'msg', message:'시리얼 정보가 변경되었습니다.'})
          onSync();
        }
        else{
          setMsgShow({...DefaultMsg, msgId:'msg', message:tmp.result_msg})
        }
      }
      else if ( command === 'serial_find')
      { // 시리얼 결과 처리
        setCommand('');
        if (rData){
          const tmp:ISerials = rData;
          if ( tmp.result_cd === '0') {
            setInput({...input, serial:""});
            setMsgShow({...DefaultMsg, msgId:'serial', message:tmp.result_msg});
            return;
          }
          if ( tmp.table[0].sn_stat === '01')
          {
            setInput({...input, serial:""});
            setMsgShow({...DefaultMsg, msgId:'serial', message:'A/S 진행중인 건입니다.'});
          }
          else if ( tmp.table[0].isvalid === '0')
          {
            setInput({...input, serial:""});
            setMsgShow({...DefaultMsg, msgId:'serial', message:"보증기간이 경과되었습니다."});
          }
          setSerialInfo({...DefaultCheckSerial
                         , isSerial:( tmp.table[0].sn_stat === '00' && tmp.table[0].isvalid === '1' )?true:false
                         , prod_cls:tmp.table[0].prod_cls
                         , prod_cd:tmp.table[0].prod_nm
                         , validDay:tmp.table[0].valid_day
                         , en_date:tmp.table[0].en_date
                         , serial:tmp.table[0].serial
                         , sn_stat:tmp.table[0].sn_stat})
          return;
        }
      }

      if ( command === 'cancel'){

        const tmp:IRltCode = rData;

        if ( tmp.result_cd === '1'){
          setMsgShow({...DefaultMsg, msgId:'msg', message:tmp.result_msg})
          onSync();
        }
        else{
          setMsgShow({...DefaultMsg, msgId:'msg', message:tmp.result_msg})
        }
      }
      else if ( command === 'item'){
        setDatas(items);
        setSendData('');
      } else {
        // 변경된 정보를 다시 수신한다.
        const tmp:IRltCode = rData;
        if ( tmp.result_cd === '1'){
          onSync();
        }
        else{
           setMsgShow({...DefaultMsg, msgId:'msg', message:tmp.result_msg})
        }
       }
    }
    else{
      setMsgShow({...DefaultMsg, msgId:'serial', message:rData});
    }

}, [command, apiname, sendData, find, items, datas, rltCode, msgShow]);

  const RepButton:React.FC<{
    title:string,
    onPress:()=>void,
    borderRadius?:number,
    borderWidth?:number,
    borderColor?:string,
    height?:number,
  }> =({title, borderRadius, borderWidth, borderColor, height, onPress})=>(
    <View>
      <Spacer space={5}/>
      <Button onPress={onPress}>
      <View style={{borderRadius:borderRadius??50,
                    borderWidth:borderWidth??2,
                    borderColor:borderColor??'#D9D9D9',
                    height:height??50,
                    }}>
          <View style={{flex:1, alignItems:'center', justifyContent:'center'}}>
              <Text style={{marginLeft:20, marginRight:20, color:COLOR.ICONCOLOR, alignItems:'center'}}>{title}</Text>
          </View>
      </View>
      </Button>
      <Spacer space={5}/>
    </View>
  )


  return (
    <View style = {{flex:1}}>
      {
     // <TrackHeader items={datas} />
     // <TrackLine />
      }
      <Spacer space={5}/>
      <View style={{flex:1,backgroundColor:'white', marginBottom:5}}>
        <ScrollView showsVerticalScrollIndicator={false} 
                    style={{marginBottom : 10, backgroundColor:'white'}}>
            <View style={{flex:1, backgroundColor:'white'}}>
              <Spacer space={5}/>
              <TrackSubContent items={datas} />
              <Spacer space={5} />
              <View style={{ marginHorizontal: 10,}}>
                  <TrackInText title='접수타입' 
                                data={datas.table[0].req_comp_nm + '  ' + datas.table[0].req_tr_no} 
                                borderRadius={10} 
                                height={60}
                                onPress={()=>{}}
                                />
              </View>
              <Spacer space={5} />
              {
                (datas.table[0].track_step ==='END' )&&
                  <View style={{ marginHorizontal: 10,}}>
                    <TrackInText title='출고타입' 
                                  data={datas.table[0].res_comp_nm + '  ' + datas.table[0].res_tr_no} 
                                  borderRadius={10} 
                                  height={60}
                                  onPress={()=>{}}
                                  />
                  </View>

              }
              <Spacer space={10} />

              <View style={{ marginHorizontal: 10,}}>
                  <TrackInText title='Serial Number' 
                                data={datas.table[0].prod_cd + '  ' + datas.table[0].serial_no} 
                                borderRadius={10} 
                                height={60}
                                onPress={()=>{}}
                                />
              </View>
            <Spacer space={10} />
            {
              (datas.table[0].track_step !=='END' && datas.table[0].track_step !=='CAN')&&
              <View style={{ marginHorizontal: 10}}>
                <InputSearchText  title={'Serial # Change'} 
                                  placeholder='변경할 시리얼 번호를 입력하세요' 
                                  name='serial' 
                                  value={input.serial} 
                                  onChange={(text:string)=>{
                                    onChangeText('serial', text); 
                                    if ( serialInfo.serial.length> 0)
                                        setSerialInfo(DefaultCheckSerial);
                                  }}
                                  onSubmit={onSubmit}  
                                  horizontal={isHorizontal}
                                  asta='*' 
                                  keyboardType="numeric"
                                  autoFocus
                                  iconName={'checkmark'}
                                  iconColor={serialInfo.isSerial ? COLOR.GREEN_1:'black'}
                                  />

              </View>
              }
              {
              ((serialInfo.prod_cd.length > 0 )&&( Platform.OS ==='web')) &&
              <View style={{width:'100%',flexDirection:'row',}}>
               <View style={{flex:1}}/>
               
               <View style={{flex:3, width:'100%',paddingHorizontal:10}}>
                <View style={{ flexDirection:"row",    
                            backgroundColor:COLOR.INPUTBORDER,
                            justifyContent:'space-between',
                            alignItems:'center',
                            height:30,
                            paddingHorizontal:15
                             }}>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER,}}>
                  <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold'}}>{serialInfo.prod_cd}</Text>
                </View>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER,}}>
                  <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold' }}>{'SN# ' + serialInfo.serial}</Text>
                </View>
              </View>
             
              <View style={{ flexDirection:"row",    
                            backgroundColor:COLOR.INPUTBORDER,
                            justifyContent:'space-between',
                            alignItems:'center',
                            height:30,
                            borderBottomLeftRadius:10,
                            borderBottomRightRadius:10,
                            paddingHorizontal:10
                                }}>
                <Spacer space={5} horizontal/>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                  <Text style={{color:COLOR.BLACK, }}>유효일자</Text>
                </View>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                  <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold'}}>{
                      (serialInfo.en_date )? serialInfo.en_date.substring(0, 4) + '-' + serialInfo.en_date.substring(4, 6) + '-' + serialInfo.en_date.substring(6, 8) :''}
                  </Text>
                </View>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                  <Text style={{color:COLOR.BLACK, justifyContent:'center'}}>잔여일</Text>
                </View>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                  <Text style={{color:(serialInfo.validDay < 0 ) ? COLOR.RED_1:COLOR.ICONCOLOR, fontWeight:'bold'}}>{serialInfo.validDay}</Text>
                </View>
                <Spacer space={5} horizontal/>
              </View>
              {
                (serialInfo.sn_stat === "01")&&
                <View style={{ flexDirection:"row",    
                                backgroundColor:COLOR.INPUTBORDER,
                                justifyContent:'space-between',
                                alignItems:'center',
                                height:30,  
                                marginHorizontal:10  }}>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                    <Text style={{color:COLOR.BLACK, justifyContent:'center'}}>AS 진행중인 시리얼 번호입니다.</Text>
                  </View>                                    
                </View>

              }
              {
                (serialInfo.validDay < 0 )&&
                <View style={{ flexDirection:"row",    
                                backgroundColor:COLOR.INPUTBORDER,
                                justifyContent:'space-between',
                                alignItems:'center',
                                height:30,  
                                marginHorizontal:10  }}>
                  <Spacer space={10} horizontal/>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                    <Text style={{color:COLOR.RED_1, justifyContent:'center'}}>보증기간이 경과되었습니다.</Text>
                  </View>                                    
                </View>
              }
                </View>
              </View>
            }
              {  
              ((serialInfo.prod_cd.length > 0 )&&( Platform.OS !=='web')) &&
              <View style={{marginHorizontal:10}}>
                <View style={{ flexDirection:"row",    
                              backgroundColor:COLOR.INPUTBORDER,
                              justifyContent:'space-between',
                              alignItems:'center',
                              paddingHorizontal:15
                               }}>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER,}}>
                    <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold'}}>{serialInfo.prod_cd}</Text>
                  </View>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER,}}>
                    <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold' }}>{'SN# ' + serialInfo.serial}</Text>
                  </View>
                </View>
                <View style={{ flexDirection:"row",    
                              backgroundColor:COLOR.INPUTBORDER,
                              justifyContent:'space-between',
                              alignItems:'center',
                              height:30,
                              borderBottomLeftRadius:10,
                              borderBottomRightRadius:10,
                              paddingHorizontal:10
                                  }}>
                  <Spacer space={5} horizontal/>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                    <Text style={{color:COLOR.BLACK, }}>유효일자</Text>
                  </View>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                    <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold'}}>{
                        (serialInfo.en_date )? serialInfo.en_date.substring(0, 4) + '-' + serialInfo.en_date.substring(4, 6) + '-' + serialInfo.en_date.substring(6, 8) :''}
                    </Text>
                  </View>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                    <Text style={{color:COLOR.BLACK, justifyContent:'center'}}>잔여일</Text>
                  </View>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                    <Text style={{color:(serialInfo.validDay < 0 ) ? COLOR.RED_1:COLOR.ICONCOLOR, fontWeight:'bold'}}>{serialInfo.validDay}</Text>
                  </View>
                  <Spacer space={5} horizontal/>
                </View>
                {
                  (serialInfo.sn_stat === "01")&&
                  <View style={{ flexDirection:"row",    
                                  backgroundColor:COLOR.INPUTBORDER,
                                  justifyContent:'space-between',
                                  alignItems:'center',
                                  height:30,  
                                  marginHorizontal:10  }}>
                    <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                      <Text style={{color:COLOR.BLACK, justifyContent:'center'}}>AS 진행중인 시리얼 번호입니다.</Text>
                    </View>                                    
                  </View>

                }
                {
                  (serialInfo.validDay < 0 )&&
                  <View style={{ flexDirection:"row",    
                                  backgroundColor:COLOR.INPUTBORDER,
                                  justifyContent:'space-between',
                                  alignItems:'center',
                                  height:30,  
                                  marginHorizontal:10  }}>
                    <Spacer space={10} horizontal/>
                    <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                      <Text style={{color:COLOR.RED_1, justifyContent:'center'}}>보증기간이 경과되었습니다.</Text>
                    </View>                                    
                  </View>
                }
              </View> 
            }
{/**장애증상 */}
            <Spacer space={5}/>
            <View style={{marginHorizontal:10}}>
              <DefectView track_no={datas.table[0].track_no}  
                              det_seq={datas.table[0].det_seq}
                              rep_type={'01'}
                              isSync={isDefectShow.isRep01}
                              onSync={()=>{
                                setIsDefectShow({...isDefectShow, isRep01:!isDefectShow.isRep01})
                              }} />      
                   
              <View style={{width:'100%', paddingHorizontal:10}}>
              <View style={{ width:'100%', backgroundColor:COLOR.INPUTBORDER, borderBottomLeftRadius:10, borderBottomRightRadius:10,padding:10,}}>
                 <Text style={{fontSize:16,paddingHorizontal:10}}>{datas.table[0].rep_det01}</Text>
                 </View>
              </View>        
              {
                  (datas.table[0].rep_02 > 0 )&&
                  <DefectView track_no={datas.table[0].track_no}  
                              det_seq={datas.table[0].det_seq}
                              rep_type={'02'} 
                              isSync={isDefectShow.isRep02}
                              onSync={()=>{setIsDefectShow({
                                          ...isDefectShow, 
                                          isRep02:!isDefectShow.isRep02})}} />      
              }
              {
                (datas.table[0].rep_02 > 0 )&&
                <View style={{width:'100%', paddingHorizontal:10}}>
                  
                  <View style={{ width:'100%', backgroundColor:COLOR.INPUTBORDER, borderBottomLeftRadius:10, borderBottomRightRadius:10,padding:10,}}>
                    <Text style={{fontSize:16,paddingHorizontal:10}}>{datas.table[0].rep_det02}</Text>
                  </View>
                </View>        
                }
                {
                  (datas.table[0].rep_03 > 0 )&&
                  <DefectView track_no={datas.table[0].track_no}  
                              det_seq={datas.table[0].det_seq}
                              rep_type={'03'}                             
                              isSync={isDefectShow.isRep03}
                              onSync={()=>{setIsDefectShow({
                                            ...isDefectShow, 
                                            isRep03:!isDefectShow.isRep03})}} />      

                }  
                {
                  (datas.table[0].rep_03 > 0 )&&
                  <View style={{width:'100%', paddingHorizontal:10,}}>
                    
                  
                    <View style={{ width:'100%', backgroundColor:COLOR.INPUTBORDER, borderBottomLeftRadius:10, borderBottomRightRadius:10,padding:10,}}>
                    <Text style={{fontSize:16,paddingHorizontal:10}}>{datas.table[0].rep_det03}</Text>
                  </View>
                              
                  </View>        
                }
                <TrackingGrid url={ApiCmd.api_GetTrsDetail} 
                              title='이동이력' 
                              item={datas} />
              </View>
            </View>
        </ScrollView>
      </View>
      {/** 버튼  */}
      {
        (datas.table[0].track_step !=='END' && datas.table[0].track_step !=='CAN')&&
        <View style={{flexDirection:'row', height: 50, alignItems:'center',justifyContent:'space-around' }}>
          <View style={{flex:1, marginHorizontal:5}}>
            <Button onPress={()=>{
              if ( serialInfo.serial.length === 0){
                setMsgShow({...DefaultMsg, msgId:'msg', message:'시리얼 번호를 입력하세요'});
                return;
              }
              setMsgInfo({...DefaultMsg, msgId:'serialChange', message:'시리얼 번호를 변경하시겠습니까?'});
              }}>
              <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                  <NoflexTypography fontSize={fontSize} color='#ffffff' background='#979b9e'>SN변경</NoflexTypography>
              </View>
            </Button>
          </View>  

          <View style={{flex:1, marginHorizontal:5}}>
            <Button onPress={()=>{ 
                if ( datas.table[0].track_step ==='01' || datas.table[0].track_step ==='00'){
                  setMsgShow({...DefaultMsg, msgId:'rep02', message:'입고되지 않은 접수건입니다.'});
                  return;
                }
                setSelectedRepType('02');
                setIsDefectModalShow(true); 
              }}>
              <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                  <NoflexTypography fontSize={fontSize} color='#ffffff' background='#979b9e'>장애판정</NoflexTypography>
              </View>
            </Button>
          </View>  

          <View style={{flex:1, marginHorizontal:5}}>
            <Button onPress={()=>{
              if ( datas.table[0].track_step ==='01' || datas.table[0].track_step ==='00'){
                setMsgShow({...DefaultMsg, msgId:'rep03', message:'입고되지 않은 접수건입니다.'});
                return;
              }
              if ( datas.table[0].track_step ==='02' ){
                setMsgShow({...DefaultMsg, msgId:'rep03', message:'장애판정 먼저 등록해주세요.'});
                return;
              }
              if ( datas.table[0].track_step ==='04' ){
                setMsgShow({...DefaultMsg, msgId:'rep03', message:'A/S 센타로 출고 신청건입니다.'});
                return;
              }
              if ( datas.table[0].track_step ==='05' ){
                setMsgShow({...DefaultMsg, msgId:'rep03', message:'A/S 센타로 출고건입니다.'});
                return;
              }
              setMsgInfo({...DefaultMsg, msgId  :'asout', 
                          message:'Zebra A/S 센타로 출고 신청하시겠습니까?'});
              }}>
              <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                  <NoflexTypography fontSize={fontSize} color='#ffffff' background='#979b9e'>A/S신청</NoflexTypography>
              </View>
            </Button>
          </View>              
          <View style={{flex:1, marginHorizontal:5}}>
            <Button onPress={()=>{ 
                if ( datas.table[0].track_step ==='01' || datas.table[0].track_step ==='00'){
                  setMsgShow({...DefaultMsg, msgId:'rep03', message:'입고되지 않은 접수건입니다.'});
                  return;
                }
                if ( datas.table[0].track_step ==='02' ){
                  setMsgShow({...DefaultMsg, msgId:'rep03', message:'장애판정 먼저 등록해주세요.'});
                  return;
                }
                if ( datas.table[0].track_step ==='04'){
                  setMsgShow({...DefaultMsg, msgId:'rep03', message:'(Zebra A/S) 출고 대기건입니다.'});
                  return;
                }
                if ( datas.table[0].track_step ==='05'){
                  setMsgShow({...DefaultMsg, msgId:'rep03', message:'(Zebra A/S) 출고건입니다.'});
                  return;
                }
                setSelectedRepType('03');
                setIsDefectModalShow(true); 
              }}>
              <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                  <NoflexTypography fontSize={fontSize} color='#ffffff' background='#979b9e'>결과등록</NoflexTypography>
              </View>
            </Button>
          </View>              

          <View style={{flex:1, marginHorizontal:5}}>
            <Button onPress={()=>{ 
                setMsgInfo({...DefaultMsg, msgId:'cancel', message:'이전 단계로 취소하시겠습니까.'});
              }}>
              <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                  <NoflexTypography fontSize={fontSize} color='#ffffff' background='#979b9e'>취소</NoflexTypography>
              </View>
            </Button>
          </View>              

        </View>
      }
      {
        (datas.table[0].track_step ==='END' || datas.table[0].track_step ==='CAN')&&
        <View style={{flexDirection:'row', height: 50, alignItems:'center',justifyContent:'space-around' }}>
          <View style={{flex:1, marginHorizontal:5}}>
            <Button onPress={()=>{ 
                setMsgInfo({...DefaultMsg, msgId:'cancel', message:'완료를 취소 하시겠습니까?.'});
              }}>
              <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                  <NoflexTypography fontSize={fontSize} color='#ffffff' background='#979b9e'>취소</NoflexTypography>
              </View>
            </Button>
          </View>              
        </View>
      }

      <TrsDefectModalBox rep_type={selectedRepType} 
                         isShow  ={isDefectModalShow} 
                         track_no={datas.table[0].track_no}
                         det_seq={datas.table[0].det_seq}
                         onOK    ={(rep_type:string, rep_det:string)=>{
                                    setIsDefectModalShow(false);
                                    onRepUpdate('set_rep', rep_type, rep_det);
                                  }}
                         onCancel={(rep_type:string)=>{
                                    setIsDefectModalShow(false)}
                                  }
                                                                
                           />
        <TrsMessageBox  message={msgInfo} 
                        onOK={(msgId:string)=>{

                          setMsgInfo({...DefaultMsg, msgId:'', message:''});
                          if (msgId === 'asout') onSend('send', '04')
                          if ( msgId === 'serialChange' ) onSerialChange();
                          if (msgId === 'cancel') onCancel();
                        }} 
                        onCancel={(msgId:string)=>{
                          setMsgInfo({...DefaultMsg, msgId:'', message:''});
                        }} />

        <TrsMessageShow message={msgShow} 
                        onMessage={ (msgId:string)=>{ setMsgShow(DefaultMsg) }} />

        {
          sendData && <Sync apiName={apiname} 
                            sendData={sendData} 
                            onData={onReceived} />
        }
    </View>

  )
}
