import {useNavigation} from '@react-navigation/native';
import React, {useCallback,useState,} from 'react';
import {View,useWindowDimensions, Platform, Text} from 'react-native';
import { Spacer } from '../../components/Spacer';
import { useLoginState } from '../../contexts/LoginContext';
import { IFindInfo } from '../../datas/TDataFind';
import { IRltCode } from '../../datas/TSendData';
import { useTrsNavigation, useTrsRoute } from '../../navigations/StackParamNavi';
import { HeaderTop, HeaderUserInfo } from '../components/HeaderTop';
import { Caption, InputButton, InputSelectList, InputText, InputView } from '../components/InputComponent';
import { Sync } from '../components/Sync';
import { DefaultMsg, TrsMessageBox, TrsMessageShow } from '../components/TrsModal';
import { MultiLineInput } from '../../components/MultiLineInput';
import { api_SetCode } from '../../datas/TrsApiType';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

/*
코드 등록

*/
export const CodeRegScreen:React.FC = () =>{
  const navigation = useTrsNavigation();
  const route = useTrsRoute<'CodeReg'>();
  const loginState = useLoginState();
  const [selected, setSelected] =  useState("SITE");
  const [input, setInput] = useState({...route.params.code});
  const [sendData, setSendData] = useState<any>();
  const [recvData, setRecvData] = useState<any>();
  const [command, setCommand] = useState('');
  const [apiname, setApiName] = useState('');
  const [rltCode, setRltCode] = useState<IRltCode>();
  const [msgShow, setMsgShow] = useState(DefaultMsg);
  const [msgInfo, setMsgInfo] = useState(DefaultMsg);
  const [find, setFind] = useState<IFindInfo>();
  const isHorizontal = Platform.OS === "web" ? true:false;
  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  const onPressReg = useCallback(() => {
    console.log('onPressReg, ', input);

    if (input.class_cd.length === 0 ){
      setMsgShow({...DefaultMsg, msgId:'code', message:'상위그룹이 선택되지 않았습니다.'});
      return;
    }

    if ( input.class1 === "장애유형" || input.class1 === "처리 결과" )
    {
      if (input.class2.length === 0){

        if (input.class1 === "장애유형" && ( input.code_cd.length !== 3 || input.code_cd.substring(0,1) !== 'D')){
          setMsgShow({...DefaultMsg, msgId:'code', message:'D 자로 시작하는 장애유형코드 3자리 입력해주세요.'});
          return;
        }
        if (input.class1 === "처리 결과" && ( input.code_cd.length !== 3 || input.code_cd.substring(0,1) !== 'A')){
          setMsgShow({...DefaultMsg, msgId:'code', message:'A 자로 시작하는 장애유형코드 3자리 입력해주세요.'});
          return;
        }

      }else{
        if ( input.class_cd !==input.code_cd.substring(0,3)){
          setMsgShow({...DefaultMsg, msgId:'code', message: input.class_cd + '로 시작하는 장애유형세부코드 6자리 입력해주세요.'});
          return;
        }
      }
    }
    if ( input.class1 === '택배사'){
      if ( input.code_cd.length !== 2 ){
        setMsgShow({...DefaultMsg, msgId:'code', message:'택배코드는 2자리로 입력해 주세요'});
        return;
      }
    }

    if ( input.code_cd.length === 0 ){
      setMsgShow({...DefaultMsg, msgId:'code', message:'코드를 입력하세요'});
      return;
    }

    if ( input.code_nm.length === 0 ){
      setMsgShow({...DefaultMsg, msgId:'code', message:'명칭를 입력하세요'});
      return;
    }

    onSend();

  },[input] );

  const onSelect = useCallback((key:string) => {
    console.log('TrsMainScreen onSelect ', key);
    setSelected(key);
    //setItem({...item, site_no:key});
  },[] ); 
  const onChangeText = useCallback( (name: string,value:string) => {
    setInput({...input, [name]:value});
  }, [input]);

  const onSubmit = useCallback( (name:string, value:string) => {
    console.log('onSubmit', name, value);
    if( name === 'code_cd'){
    }
    if ( name === 'code_nm'){
    }
  }, [input]);

  const onInit = useCallback(()=>{
    setMsgInfo(DefaultMsg)
    setInput({...route.params.code});
    setCommand('');
    setApiName('');
    setSendData(null);
    setRecvData(null);
  }, [command, apiname, sendData, recvData, rltCode, input])

  const onSend = useCallback(()=>{
    setSendData(input);
    setCommand('send');
    setApiName(api_SetCode);
  }, [command, apiname, sendData, input]);

  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    console.log('onReceived ClientReg..............', command, resultCode, cmd, rData, )
    setApiName(''); setSendData(null);
    if (resultCode === true) {
        const tmp:IRltCode = rData;
        if ( tmp.result_cd === "1"){
          setMsgShow({...DefaultMsg, msgId:cmd, message:tmp.result_msg});
        } 
        else {
          console.log('Message.. tmp.result_cd', tmp.result_cd);
          setMsgShow({...DefaultMsg, msgId:cmd, message:tmp.result_msg});
        } 
    }
    else {
      setMsgShow({...DefaultMsg, msgId:cmd, message:rData});
    }

  }, [recvData, command, apiname, sendData, find]);

  return (
    <View style={{flex:1, backgroundColor:'white' }}>
      <HeaderTop
        title="코드등록" 
        isMain={false}
        onPress={onPressBack} 
        onHome={()=>{navigation.popToTop()}} />
        
      <View style={{flex:1, marginHorizontal:Platform.OS ==='web'?25:20}}>
        <View style={{flex:1}}>
          <Spacer space={20}/>
          <HeaderUserInfo onSelect={onSelect}  isUser={true} />
          <Spacer space={30}/>
          <InputView title={'상위항목'} value={ input.class1 + 
                                              ((input.class2.length > 0)?  " > " + input.class2 : "") + 
                                              ((input.class3.length > 0)?  " > " + input.class3 : "") } 
                                              isTitle={true} />
          <Spacer space={20}/>
          <InputText title={'코드'} 
                        placeholder={'코드를 입력하세요'} 
                        name='code_cd' 
                        value={input.code_cd} 
                        onChangeText={(text:string)=>{
                          onChangeText('code_cd', text); 
                        }}
                        onSubmit={onSubmit}  
                        />
          <Spacer space={20}/>
          <Caption title={'명칭'}  />
          <Spacer space={6}/>
          <MultiLineInput value={input.code_nm}
                          onChangeText={(text:string)=>{
                            onChangeText('code_nm', text); 
                          }} 
                          placeholder={''}
                          onSubmitEditing={()=>{onSubmit('code_nm', input.code_nm)}}
                          height={150}
                                />
        <Spacer space={20}/>
       </View>

        <View style={{ alignItems:"flex-end", height:60}}>
           <InputButton title={'등록'} onPress={onPressReg} />
        </View>
      </View>
      
      <TrsMessageBox message={msgInfo} 
                       onOK={(msgId:string)=>{
                        onInit();
                       }} 
                       onCancel={(msgId:string)=>{
                        setMsgInfo({...DefaultMsg, msgId:'', message:''});
                        onSubmit('item', '');
                      }} />

        <TrsMessageShow message={msgShow} 
                      onMessage={(msgId:string)=>{ setMsgShow(DefaultMsg)}} />

        {
          sendData && <Sync apiName={apiname} 
                            sendData={sendData} 
                            onData={onReceived} />
        }
     
    </View>
  )
}