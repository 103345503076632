import React, {useCallback, useState,forwardRef, useRef, useEffect} from 'react';
import {StyleSheet, View,Platform, TouchableOpacity, TextInput, useWindowDimensions} from 'react-native';
import { useTrsNavigation, useTrsRoute } from '../navigations/StackParamNavi';
import { Button } from '../components/Button';
import { NoflexTypography } from '../components/custom/NoflexTypography';
import {Spacer} from '../components/Spacer';
import { useLoginState } from '../contexts/LoginContext';
import { HeaderTop, HeaderUserInfo } from './components/HeaderTop';
import * as ApiCmd from '../datas/TrsApiType';
import { FindDefault, ITracking, ITrackings  } from '../datas/TDataFind';
import { InputText } from './components/InputComponent';
import { IDDLSelectValue } from '../datas/TrsDataDetailType';
import { DataDropDownList } from './components/DataSelectList';
import { getSerialCheck, getSerialChecked } from '../utils/data';
import { EvilIcons } from '@expo/vector-icons'; 
import { COLOR } from './../utils/color';
import { Sync } from './components/Sync';
import { GridTrackingView } from './components/Grid';
import { minWinWidth } from '../datas/Const';
import { DefaultMsg, IMsg, TrsMessageShow } from './components/TrsModal';
import { IRltCode } from '../datas/TSendData';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

const DefaultDDLData = {
  name    : '',
  value   : '',
  title   : '',
  sub_cd1 : '',
  sub_cd2 : '',
  checked : false,
  disabled: false,
}

//----------------------------------------------------
// 이동 등록 
//----------------------------------------------------
export const TrsServiceMoveScreen:React.FC = () =>{
  const loginState = useLoginState();
  const navigation = useTrsNavigation();
  const [trackStep, setTrackStep] =  useState<IDDLSelectValue>({...DefaultDDLData});
  const [site, setSite] =  useState<IDDLSelectValue>({...DefaultDDLData});
  const [comp, setComp] =  useState<IDDLSelectValue>({...DefaultDDLData, value:'01'});
  const [input, setInput] = useState({tr_no:"", serial:"", rma:""});
  const [sendData, setSendData] = useState<any|undefined>();
  const [command, setCommand] = useState('');

  const [workSubCd, setWorkSubCd] =  useState('');
  const [workTrackStep, setWorkTrackStep] =  useState('');
  const [datas, setDatas] = useState<ITrackings>();
  const [apiname, setApiName] = useState('');
  const [refApiName, setRefApiName] = useState('');
  const [title, setTitle] = useState('이동 대상');
  const [tmpTitle, setTmpTitle] = useState('');
  const [msgShow, setMsgShow] = useState(DefaultMsg);

  const [focused, setFocused] = useState(false);
  const [serialfocused, setSerialFocused] = useState(false);
  const textHeight=(Platform.OS ==='web') ? 45:50;

  // Focus 
  const ref_input: Array<React.RefObject<TextInput>> = [];
  ref_input[0] = useRef(null); // 
  ref_input[1] = useRef(null); // serial
  
  const isHorizontal = Platform.OS === "web" ? true:false;
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=width - (marginHorizontal * 2)

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  const onDDLSelect = useCallback( (e: IDDLSelectValue) => {
    if ( e.name === 'track_step'){
      setTrackStep(e);
      setTmpTitle(e.title);
    }
    if ( e.name === 'comp'){
      setComp(e);
    }
    if (e.name ==='site') {
      setSite(e);
    }    
  }, [trackStep, site, input]);
   
  const onChangeText = useCallback( (name: string,value:string) => {
    setInput({...input, [name]:value});
  }, [input]);

  // 이동대상 리스트 조회
  const onFind = useCallback( () => {
    const site_tmp = (trackStep.value === '02') ? site.value:'';
    const find_tmp = {...FindDefault, track_step:trackStep.sub_cd2, site_no : site_tmp};
    // 전송데이타 프레임을 작성한다. 
    console.log('find_tmp', find_tmp);
    setCommand('data');
    setApiName(ApiCmd.api_GetTrsTrackingView);
    setWorkTrackStep(trackStep.value); // 작업 track_step 
    setWorkSubCd(trackStep.sub_cd2);
    setSendData(find_tmp);
    setTitle(tmpTitle  + ' 대상');

  }, [trackStep, site, sendData, ]);

   // 조회한 정보에서 입력한 시리얼 정보와 매칭되는 정보를 찾는다.
   // data: 작업 대기 정보
  const proc = async(isChecked : boolean, pSerial:string)=>{
    if ( datas){
      const serial = (isChecked === true)? pSerial: (( input.serial.substring(0,1) === 'S')? input.serial.substring(1):input.serial);
      const chk = await getSerialCheck(datas.table,serial);
      if ( chk === false )
      {
        setMsgShow({...DefaultMsg, msgId:'serial', message:'작업대상 시리얼번호가 아닙니다.'});
        return;
      }
    
      const comp_tmp= (comp)? comp.value : '' ;
      console.log('테스트 comp_tmp', comp_tmp);
      const res = await getSerialChecked(datas.table, serial, input.rma, comp_tmp, input.tr_no );
      setDatas({...datas, table:res});
      if (workTrackStep === '05'){
        setInput({...input, serial:'', rma:''});
        ref_input[0].current?.focus();
      }
      else{
        setInput({...input, serial:''});
        ref_input[1].current?.focus();
      }
    }
    else setMsgShow({...DefaultMsg, msgId:'serial', message:'이동대상을 조회하세요'});
  }

  // 시리얼 번호 Check
  const onSubmit = useCallback( (name:string, value:string) => {
    console.log('onSubmit', name, value, input.tr_no);
    if ( name === 'tr_no'){
      if ( input.tr_no.length === 0){
          setMsgShow({...DefaultMsg, msgId:'tr_no', message:'Box ID 를 입력해주세요'});
          return;
      }
      ref_input[1].current?.focus();
    }
    if ( name === 'rma'){
      ref_input[1].current?.focus();
    }
    if ( name === 'serial'){
      if (workTrackStep === '02'|| workTrackStep === '08' ){
        if ( input.tr_no.length === 0){
          setMsgShow({...DefaultMsg, msgId:'tr_no', message:'Box ID 를 입력해 주세요'});
          return;
        }
      }
      if (workTrackStep === '05' ){
        if ( input.rma.length === 0 ){
          setMsgShow({...DefaultMsg, msgId:'rma', message:'RMA 를 입력해 주세요'});
          return;
        }
      }
      if ( input.serial.length === 0 ){
        setMsgShow({...DefaultMsg, msgId:'serial', message:'Serial을 입력해 주세요'});
        return;
      }
      proc(false, "");
    }

  }, [input, command, datas, ref_input[0], ref_input[1]]);

  const onChecked=useCallback((key:string, checked:boolean)=>{
      console.log('CHECKED Event' + key, checked)
      proc(true, key);
  }, [])

  // 이동 등록
  const onSend = useCallback( () => {
    if ( datas && datas.totals > 0) {
        const save_tmp = datas.table.filter((row:ITracking) => (row.checked === true))
                          .map(row=>({...row, 
                                      track_step:workTrackStep, 
                                      reg_user  :loginState.user.user_cd, 
                                      reg_uname :loginState.user.user_nm, 
                                      reg_ip    :loginState.reg_ip}));

        if ( save_tmp && save_tmp.length > 0 ){
          setApiName(ApiCmd.api_SetTrsTracking);
          setSendData(save_tmp);
          setCommand('send');
        }
        else {
          setMsgShow({...DefaultMsg, msgId:command, message:'저장할 대상을 입력하거나 체크해주세요.'});
        }
    }
    else{
      setMsgShow({...DefaultMsg, msgId:command, message:'저장할 대상을 입력하거나 체크해주세요.'});
    }
  }, [datas, workTrackStep, command]);

  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    console.log('onReceived ..............', command, resultCode, cmd, rData, )
    setApiName(''); setSendData(null);
    if (resultCode === false) {
      setMsgShow({...DefaultMsg, msgId:'send', message:rData});
      return;
    }
    if ( command === 'data'){
      setDatas(rData);
      setCommand('');
      if (workTrackStep === '06') ref_input[1].current?.focus();
      else  ref_input[0].current?.focus();
    }
    if ( command === 'send'){
      const rltCode :IRltCode = rData;
      if (rltCode.result_cd === '1'){
        //onFind;
        const site_tmp = (trackStep.value === '02') ? site.value:'';
        const find_tmp = {...FindDefault, track_step:trackStep.sub_cd2, site_no : site_tmp};
        // 전송데이타 프레임을 작성한다. 
        setCommand('data');
        setApiName(ApiCmd.api_GetTrsTrackingView);
        setWorkTrackStep(trackStep.value); // 작업 track_step 
        setSendData(find_tmp);
        setTitle(tmpTitle  + ' 대상');
      } 
      else setMsgShow({...DefaultMsg, msgId:'send', message:rltCode.result_msg});
    }
  }, [refApiName, command, datas, sendData, workTrackStep]);

  return (
    <View style={{flex:1,backgroundColor:"white"}}>
      <HeaderTop
          title="이동등록" 
          isMain={false}
          onPress={onPressBack} 
          onHome={()=>{navigation.popToTop()}} 
          />
      <View style={{flex:1, marginHorizontal: 16, backgroundColor:"white"}}>
        <Spacer space={10} />
        <View style={{flexDirection:'row', justifyContent:'space-around'}}>
          <View style={{flex:1}}>
            <DataDropDownList action={"trstep"} 
                              name={'track_step'} 
                              class_cd={'MOVETYPE'} 
                              onSelect={onDDLSelect}  
                              />
          </View>
          <Spacer space={10} horizontal/>
          {
                  (trackStep && (trackStep.sub_cd2 === '01' || trackStep.sub_cd2 === '01,T') )&&
                  <View style={{flex:1}}>
                        <DataDropDownList action={"rcombo"} 
                                          name={'site'} 
                                          class_cd={'SITE'} 
                                          onSelect={onDDLSelect}  
                                          />
                  </View>
          }
          <View style={{backgroundColor:COLOR.ICONCOLOR,
                        width:40,height:40, borderRadius:10,
                        justifyContent:'center', 
                        marginRight:5}}>
            <TouchableOpacity

              style={{justifyContent:'center', alignItems:'center'}}
              activeOpacity={0.5}
              onPress={onFind}>
              <EvilIcons name="search" size={24} color='white' />
            </TouchableOpacity>
          </View>
        </View>
        <Spacer space={10} />
        {
          /** 01, 입고*/
          (trackStep.value === '02' ) &&
            <View style={{...styles.inputStyle, 
                          height:textHeight,
                          borderColor:focused ?COLOR.GREEN_1: '#D9D9D9',
                         }}>
              <TextInput 
                    autoCorrect={false}
                    autoCapitalize={'none'}
                    ref={ref_input[0]}
                    returnKeyType={"next"}
                    value={input.tr_no}
                    onChangeText={(text:string)=>{
                      onChangeText('tr_no', text); 
                      }}
                    placeholder={'박스 입력하세요' }
                    autoFocus
                    style={{fontSize:16, color:COLOR.GREEN_1,flex:1,width:'100%'}}
                    onFocus={()=> setFocused(true)} 
                    onBlur={()=> setFocused(false)}
                    blurOnSubmit={false}
                    onSubmitEditing={()=>{onSubmit('tr_no', input.tr_no);}}
                    keyboardType={'numeric'}
                    />
            </View>
        }
        {
          /** 출고*/
         (trackStep.value === '08' ) &&
          <View style={{flexDirection:'row', justifyContent:'space-around'}}>
          <View style={{flex:1}}>
              <DataDropDownList action={"rcombo"} 
                                name={'comp'} 
                                class_cd={'TLIST'} 
                                onSelect={onDDLSelect}  
                                />
          </View>
          <Spacer space={10} horizontal/>
          <View style={{flex:2}}>
            <View style={{...styles.inputStyle, 
                          height:textHeight,
                          borderColor:focused ?COLOR.GREEN_1: '#D9D9D9',
                         }}>
              <TextInput 
                    autoCorrect={false}
                    autoCapitalize={'none'}
                    ref={ref_input[0]}
                    returnKeyType={"next"}
                    value={input.tr_no}
                    onChangeText={(text:string)=>{
                      onChangeText('tr_no', text); 
                      }}
                    placeholder={'박스 입력하세요' }
                    autoFocus
                    style={{fontSize:16, color:COLOR.GREEN_1,flex:1,width:'100%'}}
                    onFocus={()=> setFocused(true)} 
                    onBlur={()=> setFocused(false)}
                    blurOnSubmit={false}
                    onSubmitEditing={()=>{onSubmit('tr_no', input.tr_no);}}
                    keyboardType={'numeric'}
                    />
            </View>
          </View>
        </View>
        }
        { 
            /** 05, 06  A/S 수리 출고, A/S수리입고*/
            (trackStep.value === '05' ) &&
            <View style={{...styles.inputStyle, 
              height:textHeight,
              borderColor:focused ?COLOR.GREEN_1: '#D9D9D9',
             }}>
              <TextInput 
                    autoCorrect={false}
                    autoCapitalize={'none'}
                    ref={ref_input[0]}
                    returnKeyType={"next"}
                    value={input.rma}
                    onChangeText={(text:string)=>{
                      onChangeText('rma', text); 
                      }}
                    placeholder={'RMA # 입력하세요' }
                    autoFocus
                    style={{fontSize:16, color:COLOR.GREEN_1,flex:1,width:'100%'}}
                    onFocus={()=> setFocused(true)} 
                    onBlur={()=> setFocused(false)}
                    blurOnSubmit={false}
                    onSubmitEditing={()=>{onSubmit('rma', input.rma);}}
                    keyboardType={'default'}
                    />
            </View>

        }
        <Spacer space={10} />
        <View style={{...styles.inputStyle, 
                      height:textHeight,
                      borderColor:serialfocused ?COLOR.GREEN_1: '#D9D9D9',
                    }}>
            <TextInput 
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  ref={ref_input[1]}
                  returnKeyType={"next"}
                  value={input.serial}
                  onChangeText={(text:string)=>{
                    onChangeText('serial', text); 
                    }}
                  placeholder={'Serial # 입력하세요' }
                  autoFocus
                  style={{fontSize:16, color:COLOR.GREEN_1,flex:1,width:'100%'}}
                  onFocus={()=> setSerialFocused(true)} 
                  onBlur={()=> setSerialFocused(false)}
                  blurOnSubmit={false}
                  onSubmitEditing={()=>{onSubmit('serial', input.serial);}}
                  keyboardType={'numeric'}
                  />
        </View>
        <Spacer space={10} />
        <View style={{flex:1}} >
          <GridTrackingView
              tableTitle={title}
              tableData={datas}
              onRefresh={()=>{}}
              onChecked={onChecked}
          />
        </View>
        <View style={{width:'100%',height: height/13, alignItems:'center',justifyContent:'center' }}>
        <Button onPress={onSend} width={'100%'}>
          <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: (height/13)-10,width:'100%'}}>
              <NoflexTypography fontSize={20} color='#ffffff' background='#979b9e'>적용</NoflexTypography>
          </View>
        </Button>
      </View>
      </View>
      

      <TrsMessageShow message={msgShow} 
                      onMessage={(msgId:string)=>{
                          switch(msgId){
                            case 'serial':
                              setInput({...input, serial:''});
                              ref_input[1].current?.focus();
                              break;
                            default:
                          }
                          setMsgShow(DefaultMsg);
                         }}/>
    {
      sendData && <Sync apiName={apiname} 
                        sendData={sendData} 
                        onData={onReceived} />
    }
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer:{
    flex:3, justifyContent:'flex-end'
  },
  inputStyle:{
    flexDirection:'row',
    alignSelf:'stretch', 
    alignItems:'center',
    paddingHorizontal:20, 
    paddingVertical:12, 
    borderRadius: 10,
    borderWidth:1, 
    borderColor:'#D9D9D9',
    justifyContent:'space-between'
  },

});
