import React, { useCallback, useEffect, useState, forwardRef, useRef} from 'react';
import { 
  View, 
  Text,
  TextInput,
  Platform, 
  ScrollView, 
  useWindowDimensions, 
  TouchableOpacity} from 'react-native';
import * as XLSX from "xlsx";
import { NoflexTypography } from '../../components/custom/NoflexTypography';
import { Spacer } from '../../components/Spacer';
import { COLOR } from '../../utils/color';
import { useLoginState } from '../../contexts/LoginContext';
import { Sync } from './../components/Sync';
import { Button } from '../../components/Button';
import { DefaultMsg, TrsMessageBox, TrsMessageConfirm, TrsMessageShow, Waiting } from './../components/TrsModal';
import { useTrsNavigation } from '../../navigations/StackParamNavi';
import { useSize } from '../../hooks/use-Size';
import { useReqState } from '../../contexts/ReqContext';
import { IRltCode} from '../../datas/TSendData';
import {  api_SetSerialBatch } from '../../datas/TrsApiType';
import { ISerial,ISerials } from '../../datas/TrsDataDetailType';
import {  GridSerialExcelLoad } from './../components/Grid';
import dayjs from 'dayjs';
import { HeaderTop } from '../components/HeaderTop';
import AsyncStorage from "@react-native-async-storage/async-storage";

const fontSize= Platform.OS ==='web'? 16 : 12;

export const SerialListScreen:React.FC=()=>{
  const loginState                  = useLoginState();
  const navigation                  = useTrsNavigation();
  const reqState                    = useReqState();
  const [
    winWidth, winHeight, 
    isHorizontal, menuFontSize
  ] = useSize();
  const [sendData, setSendData]     = useState<any>();
  const [command, setCommand]       = useState('');
  const [apiname, setApiName]       = useState('');
  const [msgShow, setMsgShow]       = useState(DefaultMsg);
  const [msgInfo, setMsgInfo]       = useState(DefaultMsg);
  const [msgOk, setMsgOk]           = useState(DefaultMsg);

  ///////////////=======Excel Loading 정리
  const [currentFile, setCurrentFile] = useState<File>();
  const [status, setStatus] = useState<Number>(0);
  const [statusText, setStatusText] = useState<String>('');
  const [error, setError] = useState<any>();
  const [data, setData] = useState<ISerial[]>();
  
  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files  = event.target.files;
    const selectedFiles = files as FileList;
    setCurrentFile(selectedFiles?.[0]);

    console.log('Excel load........', selectedFiles?.[0]);

    readFile(selectedFiles?.[0])
  };


  function readFileAsync (file : File) {
    console.log('readFile Async..........................', file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = () => {
        const wb = XLSX.read(reader.result, {
          type: rABS ? 'binary' : 'array',
          bookVBA: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        let col = ws['!ref'];
        console.log('col', col);
        let datas = [];
         // 행의갯수만큼 반복 , 열의갯수만큼 알파벳추가
        for(let i = 2; i <= 10000; i++){
          if ( ws["A" + i] === undefined || ws["B" + i] === undefined ){
            console.log('Excel WorkSheet END........................', i);
            break;
          }
          //dayjs.toString()
          let tmp :ISerial = {
             serial: ws["B" + i].w,
             st_date: ws["C" + i].w,
             en_date: ws["D" + i].w,
             prod_cls:(ws["E" + i] === undefined) ? "PDA":ws["E" + i].w,
             prod_cd:"",
             prod_nm: ws["A" + i].w,
             isvalid:"",
             valid_day:0,
             sn_stat:"",
             result_cd:"",
             result_msg:"",
             reg_user:loginState.user.user_cd,
             reg_uname:loginState.user.user_nm,
             reg_ip:loginState.reg_ip,
             reg_dev:loginState.reg_dev,

           }
           datas.push(tmp);
        }         
        setData(datas);
        resolve(datas);
      };
      reader.onerror = reject;
      if (file){
        if (rABS){
          reader.readAsBinaryString(file);
        }
        else{
          reader.readAsArrayBuffer(file);
        }
      }
    
    })
  }

  async function readFile(file:File)
  {
    try {
      let contentBuffer = await readFileAsync(file);
      console.log('json',JSON.stringify(contentBuffer, null, 2));
    } catch(err) {
        console.log(err);
        setMsgOk({...DefaultMsg, msgId:'excel', message:'Excel 파일 로드 Failed'})
    }
    finally{
      setMsgOk({...DefaultMsg, msgId:'excel', message:'Excel 파일 로드하였습니다.'})
    }
  }

  const onPressFileSelect = () =>{
    if ( !data || data.length === 0){
      setMsgShow({...DefaultMsg, msgId:'msg', message:'Excel 파일을 로딩하세요.'});
      return;
    }
    setMsgInfo({...DefaultMsg, msgId:'msg', message:'등록하시겠습니까?'});
  }
  const onInit = useCallback(()=>{
  },[])

  const onSend = useCallback(()=>{
    setSendData(data);
    setCommand('send');
    setApiName(api_SetSerialBatch);
  }, [data, command, apiname])

  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    const rcvData:ISerials = rData;
    setApiName('');
    setCommand('');
    setData(rData.table);
    setMsgOk({...DefaultMsg, msgId:'send', message:rcvData.result_msg});

  },[data, apiname, command, sendData, msgOk]);

  return ( 
    <View style = {{flex:1, backgroundColor:COLOR.WHITE}}>
      <HeaderTop
        title="Serial # 등록" 
        isMain={false}
        onPress={onPressBack} 
        onHome={()=>{navigation.popToTop()}} 
        />

      <Spacer space={5}/>
      <View style={{flex:1, marginBottom:5, marginHorizontal:16, }}>
        <Spacer space={10} />
        <View style={{borderRadius:10, alignItems:'center', justifyContent:'space-between', height: 40}}>
             <Text style={{fontSize:14, justifyContent:'flex-start'}}>Excel file 작성시 추가건만 등록해서 작성해 주세요( Max 1000 row )</Text>
        </View>

        <Spacer space={20} />

        <label className="btn btn-default p-0">
          <input type="file" onChange={selectFile} />
        </label>
        
        <View style={{height:10}}/>
        {
          data&&
            <GridSerialExcelLoad  
                          tableTitle="대상정보"
                          tableData={data}
                          onRefresh={()=>{}}
                          onSelectData={(value:ISerial)=>{}}    />

        }
       
      </View>
      <View style={{flexDirection:'row', height: 50, alignItems:'center',justifyContent:'space-around' }}>
        <View style={{flex:1, marginHorizontal:10}}>
          <Button onPress={()=>{ onPressFileSelect() }}>
            <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                <NoflexTypography fontSize={fontSize+8} color='#ffffff' background='#979b9e'>전송</NoflexTypography>
            </View>
          </Button>
        </View>              
      </View>

        <TrsMessageBox  message={msgInfo} 
                        onOK={(msgId:string)=>{
                          console.log('TrsMessageBox...... msgId', msgId);
                          onSend();
                        }} 
                        onCancel={(msgId:string)=>{
                          setMsgInfo({...DefaultMsg, msgId:'', message:''});
                        }} />

        <TrsMessageConfirm  message={msgOk} 
                        onOK={(msgId:string)=>{
                          setMsgOk({...DefaultMsg, msgId:'', message:''})
                          console.log('TrsMessageBox...... msgId', msgId);
                        }} />



        <TrsMessageShow message={msgShow} 
                        onMessage={ (msgId:string)=>{ setMsgShow(DefaultMsg) }} />

    {
      sendData && <Sync apiName={apiname} 
                        sendData={sendData} 
                        onData={onReceived} />
    }

    </View>

  )
}
