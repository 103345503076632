import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import dayjs from 'dayjs';

interface IExcelData {
  //  site_cd:string,
    site_nm:string,
    camp_cd:string,
    st_dat:string,
    en_dat:string,
    prod_cls:string,
    prod_cd:string,
    qty:number,
  //  rep_cls:string,
    rep_clsn:string
  }
  
  export interface IExcelDatas{
    result_cd:string;
    result_msg:string;
    totals : number;
    table:IExcelData[];
  }
  
  interface IExcelData_Rec {
    track_no:string,
    det_seq:number,
    site_cd:string,
    camp_cd:string,
    camp_req_nm:string,
    req_dat:string,
    inp_dat:string,
    aso_dat:string,
    asi_dat:string,
    end_dat:string,
    prod_cls:string,
    prod_cd:string,
    serial_no:string,
    req_tpn:string,
    track_stepn:string,
    rma:string,
    rep_01:string,
    rep_01_det:string,
    rep_02:string,
    rep_02_det:string,
    rep_03:string,
    rep_03_det:string,
    req_tr_comp:string,
    req_comp_nm:string,
    req_tr_no:string,
    res_tr_comp:string,
    res_comp_nm:string,
    res_tr_no:string,
  
  //  addr:string,
  //  alias:string,
  //  contract:string,
  }
  export interface IExcelDatas_Rec{
    result_cd:string;
    result_msg:string;
    totals : number;
    table:IExcelData_Rec[];
  }
  

  export function XlsxData(exelData:IExcelDatas)
  {
      var wscols=[
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:50},
      ];
      var ws = XLSX.utils.json_to_sheet(exelData.table);
      ws.A1.v = "Site";
      ws.B1.v = "Camp";
      ws.C1.v = "요청일";
      ws.D1.v = "완료일";
      ws.E1.v = "Device";
      ws.F1.v = "Model";
      ws.G1.v = "수량";
      ws.H1.v = "장애";
      ws['!cols'] = wscols;
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const tdata = new Blob([wbout], { type: fileType });
      const now = new Date();
      const sheetname = dayjs(now).format('YYYYMMDD').toString()
      saveAs(tdata, sheetname + `${fileExtension}`);
    }

    export function XlsxData_Rec(exelData:IExcelDatas_Rec)
    {
      var wscols=[
        {wch:20},
        {wch:8},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:20},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:20},
        {wch:50},
        {wch:50},
        {wch:50},
        {wch:50},
        {wch:50},
        {wch:50},
        {wch:10},
        {wch:10},
        {wch:20},
        {wch:10},
        {wch:10},
        {wch:20},
      ];

      var ws = XLSX.utils.json_to_sheet(exelData.table);
      ws.A1.v = "접수번호";
      ws.B1.v = "순번(일괄)";
      ws.C1.v = "Site";
      ws.D1.v = "Camp";
      ws.E1.v = "요청일";
      ws.F1.v = "입고일";
      ws.G1.v = "AS출고";
      ws.H1.v = "AS입고";
      ws.I1.v = "완료일";
      ws.J1.v = "Serial";
      ws.K1.v = "Device";
      ws.L1.v = "Model";
      ws.M1.v = "신청자";
      ws.N1.v = "타입";
      ws.O1.v = "단계";
      ws.P1.v = "RMA";
      ws.Q1.v = "장애증상";
      ws.R1.v = "장애증상상세";
      ws.S1.v = "장애판정";
      ws.T1.v = "장애판정상세";
      ws.U1.v = "처리결과";
      ws.V1.v = "처리결과상세";
      ws.W1.v = "입고타입";
      ws.X1.v = "입고타입";
      ws.Y1.v = "박스&송장";
      ws.Z1.v = "출고타입";
      ws['!cols'] = wscols;
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const tdata = new Blob([wbout], { type: fileType });
      const now = new Date();
      const sheetname = dayjs(now).format('YYYYMMDD').toString()
      saveAs(tdata, sheetname + `${fileExtension}`);
  }
