import React, {useState} from 'react'
import { View, SafeAreaView ,StyleSheet,Text, Platform, useWindowDimensions} from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import { minWinWidth } from '../../datas/Const';
import { Moment } from 'moment';

export const TrsCalendar:React.FC<{
  calendarSize?: number
  width?:number
  onDateChange:(date : Moment , type: 'START_DATE' | 'END_DATE')=>void 
}> = (props) => {
  //date

  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
 
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=width - (marginHorizontal * 2)
  const innerHeight= Platform.OS ==='web'? height/1.5 : height/1.5;
  const textHeight=innerHeight/2;
  const menuButtonHeight=innerHeight/4;
  const maxWinWidth = 393 * 2;


  const [selectedStartDate, setSelectedStartDate] =useState(''); 
  const [selectedEndDate, setSelectedEndDate] = useState(''); 
 
  const onDateChange = (date : Moment , type: 'START_DATE' | 'END_DATE') => {
    //function to handle the date change
    //const asdf = date.toString()
    if ( date === null) return;
    const sel_date = date.format('YYYY-MM-DD').toString();
    if (type === 'END_DATE') {
        setSelectedEndDate(sel_date);
    } else {
        setSelectedStartDate(sel_date);
    }
    props.onDateChange(date, type);
  };
  
  return (
      <SafeAreaView style={styles.container}>
      <View style={styles.container}>
        <CalendarPicker
          startFromMonday={true}
          allowRangeSelection={true}
          minDate={new Date(2018, 1, 1)}
          maxDate={new Date(2050, 6, 3)}
          weekdays={
            [
              '월', 
              '화', 
              '수', 
              '목', 
              '금', 
              '토', 
              '일'
            ]}
          months={[
            '1월',
            '2월',
            '3월',
            '4월',
            '5월',
            '6월',
            '7월',
            '8월',
            '9월',
            '10월',
            '11월',
            '12월',
          ]}
          previousTitle="<"
          nextTitle=">"
          todayBackgroundColor="#BCE55C"
          selectedDayColor="#99C341"
          selectedDayTextColor="white"
          scaleFactor={props.calendarSize}
          textStyle={{
            color: '#000000',
          }}
          onDateChange={onDateChange}
          width={props.width}
        />
        <View style={{}}>
          <Text style={styles.textStyle}>
            Selected Start Date :
          </Text>
          <Text style={styles.textStyle}>
            {selectedStartDate ? selectedStartDate.toString() : ''}
          </Text>
          <Text style={styles.textStyle}>
            Selected End Date :
          </Text>
          <Text style={styles.textStyle}>
            {selectedEndDate ? selectedEndDate.toString() : ''}
          </Text>
        </View>
      </View>
     </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
  },
  textStyle: {
    color:'white'
  },
  titleStyle: {
    textAlign: 'center',
    fontSize: 20,
    margin: 20,
  },
});