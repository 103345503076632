import { Platform } from "react-native";
export const default_url = 'https://tntech.support/api/'
//export const default_url = 'https://localhost:44307/'

export const api_GetTrsViewTotal    = 'api/Rma/GetTrsViewTotal';
export const api_GetTrsViewDetail   = 'api/Rma/GetTrsViewDetail';
export const api_GetTrsViewDetail2  = 'api/Rma/GetTrsViewDetail2';
export const api_GetTrsViewExcel    = 'api/Rma/GetTrsViewExcel';
export const api_GetTrsViewRecord   = 'api/Rma/GetTrsViewRec';
export const api_GetTrsViewRecord2  = 'api/Rma/GetTrsViewRec2';
export const api_GetTrsDetail       = 'api/Rma/GetTrsDetail';
export const api_GetTrsTrackingView = 'api/Rma/GetTrsTrackingView';
export const api_SetTrsReqBatch     = 'api/Rma/SetTrsReqBatch';  // 신청 등록, 수정
export const api_SetTrsReq          = 'api/Rma/SetTrsReq';  // 신청 등록, 수정
export const api_SetTrsRes          = 'api/Rma/SetTrsRes';  // 장애 판정 및 A/S 처리 결과 저장 
export const api_SetTrsTracking     = 'api/Rma/SetTrsTracking'; // 이동 등록 처리
export const api_CancelTrsTracking  = 'api/Rma/CancelTrsTracking'; // 단계 취소 처리
export const api_SetSerialChange    = 'api/Rma/SetSerialChange';
export const api_GetLogin           = 'api/Cmn/GetLogin';

export const api_GetUser            = 'api/Sys/GetUser';
export const api_SetUser            = 'api/Sys/SetUser';

export const api_GetCode            = 'api/Cmn/GetCode';
export const api_SetCode            = 'api/Cmn/SetCode';
export const api_GetSerial          = 'api/Cmn/GetSerial';
export const api_SetSerial          = 'api/Cmn/SetSerial';
export const api_SetSerialBatch     = 'api/Cmn/SetSerialBatch';

export const action_trstotal        = 'trstotal';  // 접수현황 
export const action_trsalist        = 'trsalist';  // 접수현황 
export const action_trslist         = 'trslist';  // 접수현황 
export const action_trsblist        = 'trsblist'; // 일괄 접수 시리얼 정보
export const action_reclist         = 'reclist';  // 접수현황 

export const action_admreclist      = 'admreclist';
export const action_track_no        = 'track_no';
export const action_serail_no       = 'serial_no';
export const action_track_rec       = 'track_rec';
export const action_repair          = 'repair';
export const action_list            = 'list';
export const action_select          = 'select';

export const rep_type_client        = '01'  // 고객사 장애항목
export const rep_type_service       = '02'  // 장애판정
export const rep_type_result        = '03'  // 수리결과 

export const start                  = "start";
export const stop                   = "stop";

export const API_OK                 = "1";
export const API_MSG                = "2";
export const API_FAIL               = "0";

export const ADMIN                  = "U0100";
export const SITE_ADMIN             = "U0101";
export const USER                   = "U0102";

export const SERVICE_ADMIN          = "U0200";
export const SERVICE_USER           = "U0201";