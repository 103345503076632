import React, { useCallback, useEffect, useState, forwardRef, useRef} from 'react';
import { 
  Linking,
  View, 
  Text,
  TextInput,
  Platform, 
  ScrollView, 
  useWindowDimensions, 
  TouchableOpacity
} from 'react-native';
import * as XLSX from "xlsx";
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { Spacer } from '../components/Spacer';
import { COLOR } from '../utils/color';
import { useLoginState } from '../contexts/LoginContext';
import { Sync } from './components/Sync';
import { Button } from '../components/Button';
import { DefaultMsg, TrsMessageBox, TrsMessageConfirm, TrsMessageShow, Waiting } from './components/TrsModal';
import { useTrsNavigation } from '../navigations/StackParamNavi';
import { useSize } from '../hooks/use-Size';
import { useReqState } from '../contexts/ReqContext';
import { DefaultSendBatch, DefaultSendDetail, IRltCode, ISendBatch, ISendBatchs, ISendDetail } from '../datas/TSendData';
import { api_SetTrsReqBatch, default_url } from '../datas/TrsApiType';
import { GridExcelLoad } from './components/Grid';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

const fontSize= Platform.OS ==='web'? 16 : 12;
export const ClientRegBatch:React.FC<{
  onSync:()=>void,
  onChangeId:(scrId:string)=>void
  track_step:string,
  site:string,
}>=({track_step,site, onSync, onChangeId})=>{
  const loginState                  = useLoginState();
  const navigation                  = useTrsNavigation();
  const reqState                    = useReqState();
  const [
    winWidth, winHeight, 
    isHorizontal, menuFontSize
  ] = useSize();
  const [sendData, setSendData]     = useState<any>();
  const [recvData, setRecvData]     = useState<any>();
  const [command, setCommand]       = useState('');
  const [apiname, setApiName]       = useState('');
  const [sendFlg, setSendFlag]      = useState(false);
  const [rltCode, setRltCode]       = useState<IRltCode>();
  const [msgShow, setMsgShow]       = useState(DefaultMsg);
  const [msgInfo, setMsgInfo]       = useState(DefaultMsg);
  const [msgOk, setMsgOk]           = useState(DefaultMsg);

  ///////////////=======Excel Loading 정리
  const [currentFile, setCurrentFile] = useState<File>();
  const [status, setStatus] = useState<Number>(0);
  const [statusText, setStatusText] = useState<String>('');
  const [error, setError] = useState<any>();
  const [data, setData] = useState<ISendBatch[]>();
  const [rcvData, setRcvData] = useState<ISendBatchs>();

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };


  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files  = event.target.files;
    const selectedFiles = files as FileList;
    setCurrentFile(selectedFiles?.[0]);
    console.log('Excel load........', selectedFiles?.[0]);
    readFile(selectedFiles?.[0])
  };

  function readFileAsync (file : File) {
    console.log('readFile Async..........................', file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = () => {
        const wb = XLSX.read(reader.result, {
          type: rABS ? 'binary' : 'array',
          bookVBA: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        let col = ws['!ref'];
        console.log('col', col);
        let datas = [];
         // 행의갯수만큼 반복 , 열의갯수만큼 알파벳추가
        for(let i = 2; i <= 1000; i++){
          if ( ws["D" + i] === undefined || 
               ws["F" + i] === undefined ){
            console.log('Excel WorkSheet END........................', i);
            break;
          }
          let tmp :ISendBatch = {
             ...DefaultSendBatch,
             action : 'NEW',
             req_type: '2', 
             track_step: '01',
             serial_no: ws["D" + i].w,
             ticket_no: (ws["E" + i] === undefined )? '':ws["E" + i].w,
             rep_cdn: ws["F" + i].w,
             rep_det: (ws["G" + i] === undefined )? '':ws["G" + i].w,
             site_cd:loginState.user.site_cd,
             camp_cd: (ws["B" + i] === undefined )?loginState.user.camp_cd: ws["B" + i].w,
             camp_req_nm: (ws["C" + i] === undefined )?loginState.user.user_nm: ws["C" + i].w,
             tr_comp:  (ws["A" + i] === undefined )?'01': ws["A" + i].w,
             tr_no: (ws["H" + i] === undefined )?'': ws["H" + i].w,
             reg_user:loginState.user.user_cd,
             reg_uname:loginState.user.user_nm,
             reg_ip:loginState.reg_ip,
             reg_dev:loginState.reg_dev,
             prod_cls:'',
             prod_cd: '',
           }
           datas.push(tmp);
        }         

        setData(datas);
        resolve(datas);
      };
      reader.onerror = reject;
      if (file){
        if (rABS){
          reader.readAsBinaryString(file);
        }
        else{
          reader.readAsArrayBuffer(file);
        }
      }
      // if (currentFile){
      //   if (rABS){
      //     reader.readAsBinaryString(currentFile);
      //   }
      //   else{
      //     reader.readAsArrayBuffer(currentFile);
      //   }
      // }
    })
  }

  async function readFile(file:File)
  {
    try {
      let contentBuffer = await readFileAsync(file);
      console.log('json',JSON.stringify(contentBuffer, null, 2));
    } catch(err) {
        console.log(err);
        setMsgOk({...DefaultMsg, msgId:'excel', message:'Excel 파일 로드 Failed'})
    }
    finally{
      setSendFlag(false);
      setMsgOk({...DefaultMsg, msgId:'excel', message:'Excel 파일 로드하였습니다.'})
    }
  }

  const onPressFileSelect = useCallback(() =>{
    recordStartTime();

    if ( !data || data.length === 0){
      setMsgShow({...DefaultMsg, msgId:'msg', message:'Excel 파일을 로딩하세요.'});
      return;
    }
    if ( sendFlg === true){
      setMsgShow({...DefaultMsg, msgId:'msg', message:'이미 수행된 Excel 정보입니다.'});
      return;
    }
    setMsgInfo({...DefaultMsg, msgId:'msg', message:'등록하시겠습니까?'});
  },[sendFlg, data]);

  const onInit= useCallback(()=>{
  //  setData(null);


  }, [data])


  const onSend = useCallback(()=>{
   

    setSendData(data);
    setCommand('send');
    setApiName(api_SetTrsReqBatch);
    //processFile(data);

    recordStartTime();
    
  }, [data, command, apiname])

  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    const rcvData:ISendBatchs = rData;
    setData(rcvData.table);
    setRcvData(rcvData);
    setApiName('');
    setCommand('');
    setSendFlag(true);
    if ( rcvData.ok > 0 ){
      setMsgOk({...DefaultMsg, 
                msgId:'send', 
                message:'PDA 본체 케이스, 배터리 제거 후 발송해 주세요.',
                message2: rcvData.result_msg + ' 접수되었습니다.'});
    }
    else{
      setMsgOk({...DefaultMsg, 
                msgId:'send', 
                message:'서비스 등록건이 존재하지 않습니다.'});
    }
  },[data, rcvData, sendFlg, apiname, command, sendData, msgOk]);


  const onSampleDown=useCallback( () =>{
    const LINK = "https://tntech.support/images/Batch_Sample.xlsx";
    Linking.openURL(LINK);  
  }, []); 

  return ( 
    <View style = {{flex:1}}>
      <Spacer space={5}/>
      <View style={{flex:1,backgroundColor:'white', marginBottom:5, marginHorizontal:16}}>
        <View style={{flexDirection:'row', width:'100%', justifyContent:'space-between'}}>
          <View>
            <label className="btn btn-default p-0">
              <input type="file" onChange={selectFile} />
            </label>
          </View>
          <View>
            <Button onPress={onSampleDown} width={'100%'}>
              <View style={{width:'100%',height:20,alignItems:'flex-end'}}>
                <Text>{'기본양식 down'}</Text>
              </View>
            </Button>

          </View>

        </View>
        <Spacer space={10}/>
        <View style={{flexDirection:'row', width:'100%', justifyContent:'space-between'}}>
          <Text>{`엑셀 민감도를 설정하여 업로드해주시기 바랍니다.`}</Text>
        </View>
      
        <Spacer space={10}/>

        {
          sendFlg&&
          <View style={{width:'100%',height:20,alignItems:'flex-end'}}>
            <Text>{`전체 ${rcvData?.totals}, 수행완료${rcvData?.ok}, 수행실패${rcvData?.err}`}</Text>
          </View>
        }
        <Spacer space={10}/>
        {
          data&&
            <GridExcelLoad  tableTitle="대상정보"
                            tableData={data}
                            onRefresh={()=>{}}
                            onSelectData={(value:ISendBatch)=>{}}
                            />

        }
       
      </View>
      <View style={{flexDirection:'row', height: 50, alignItems:'center',justifyContent:'space-around' }}>
        <View style={{flex:1, marginHorizontal:10 }}>
              <Button onPress={()=>{ onChangeId('reg')}}>
                <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                    <NoflexTypography fontSize={fontSize+8} color='#ffffff' background='#979b9e'>단건등록</NoflexTypography>
                </View>
              </Button>
        </View>     
        {         
          /*
          <View style={{flex:1, marginHorizontal:10}}>
                <Button onPress={onInit}>
                  <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                      <NoflexTypography fontSize={fontSize+8} color='#ffffff' background='#979b9e'>초기화</NoflexTypography>
                  </View>
                </Button>
          </View>              
          */
        }
        <View style={{flex:1, marginHorizontal:10}}>
          <Button onPress={()=>{ onPressFileSelect() }}>
            <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                <NoflexTypography fontSize={fontSize+8} color='#ffffff' background='#979b9e'>전송</NoflexTypography>
            </View>
          </Button>
        </View>              
      </View>

        <TrsMessageBox  message={msgInfo} 
                        onOK={(msgId:string)=>{
                          console.log('TrsMessageBox...... msgId', msgId);
                          onSend();
                        }} 
                        onCancel={(msgId:string)=>{
                          setMsgInfo({...DefaultMsg, });
                        }} />

        <TrsMessageConfirm  message={msgOk} 
                        onOK={(msgId:string)=>{
                          setMsgOk({...DefaultMsg, })
                          console.log('TrsMessageBox...... msgId', msgId);
                        }} />



        <TrsMessageShow message={msgShow} 
                        onMessage={ (msgId:string)=>{ setMsgShow(DefaultMsg) }} />

    {
      sendData && <Sync apiName={apiname} 
                        sendData={sendData} 
                        onData={onReceived} />
    }

    </View>

  )
}
