import React, {useCallback, useState, useEffect} from 'react';
import { 
  View, 
  Text, 
  StyleSheet, 
  Image, 
  BackHandler, 
  Platform } 
from "react-native";
import { useLoginState, useLoginStateDispatch } from '../../contexts/LoginContext';
import * as ApiCmd from '../../datas/TrsApiType';
import * as DataType from '../../datas/TrsDataType';
import { IFindInfo, FindDefault  } from '../../datas/TDataFind';
import { useTrsNavigation, useTrsRoute } from '../../navigations/StackParamNavi';
import { HeaderTop, HeaderUserInfo } from '../components/HeaderTop';
import { Spacer } from '../../components/Spacer';
import { IGridSelect } from '../components/DataGrid';
import { UserGrid } from '../components/UserGrid';
import { InputButton, InputDropDownList, InputSearchText, InputText } from '../components/InputComponent';
import { IDDLSelectValue } from '../../datas/TrsDataDetailType';
import { useSize } from '../../hooks/use-Size';
import { useIsFocused } from '@react-navigation/native';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

// 사용자 정보 조회
// Sys/GetUser - pr_get_user 
// param : action : 'list', site_no : 센타 코드, user_tp 사용자 유형
export const UserListScreen : React.FC<{}> = () =>{
  const navigation = useTrsNavigation();
  const loginState = useLoginState();
  const [winWidth, winHeight, isHorizontal] = useSize();
  const [selected, setSelected] =  useState("");
  const [item, setItem] = useState<DataType.IUserInfo>({
    ...DataType.DefaultUser, 
    action:ApiCmd.action_list,
    site_cd:loginState.user.site_cd,
    user_tp:loginState.user.user_tp,
    user_cd:''
  });
  const [isSync, setSync] = useState(true);
  const [searchText, setSearchText] = useState('');
  const isFocused = useIsFocused();
  
  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  useEffect(() =>{
    onSync();
  }, [isFocused]);

  const onSync=useCallback(()=>{
    setSync(true);
  }, [isSync])

  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

   const onGridSelectData = useCallback((value:IGridSelect) => {
    // 사용자 정보 를 클릭시 사용자 조회 화면으로 이동
    console.log('trsList', value);

    //setItem({...item, user_tp:value.user_tp, user_cd:value.user_cd});

    const find1 = { 
      ...FindDefault,
      action: ApiCmd.action_select,
      site_no : loginState.user.site_cd,
      user_tp: value.user_tp,  
      user_cd: value.user_cd,
    };
    //navigation.reset({routes: [{name: "UserDetail", params: {find:find1}}]})
    navigation.navigate('UserDetail', {find:find1, menuId:'UserList'});
  },[] );

  const onSelect = useCallback((key:string) => {
    // Site 정보 선택 
    setSelected(key);
    setItem({...item, site_cd:key});
  },[selected] ); 

  const onChangeText = useCallback( (name: string,value:string) => {
    setSearchText(value);
  }, [item]);

  const onUserTypeSelect = useCallback((select:IDDLSelectValue) => {
    // 사용자 유형선택
    console.log('UserList onSelect ', select);
    if ( select.value === undefined || select.value === null || select.value === '' ) 
    {
      // 전체 선택시 로그인 사용자 유형의 3자리로 검색
      setSelected ( loginState.user.user_tp.substring(0, 3));
      setItem({...item, user_tp:loginState.user.user_tp.substring(0, 3)});
    }
    else{
      setSelected(select.value);
      setItem({...item, user_tp:select.value});
    } 
  },[selected] ); 

  const onSubmit = useCallback((name:string, text:string)=>{
     setItem({...item, user_cd:searchText});
  }, [item, searchText]);

  return (
    <View style={{flex:1,  backgroundColor:'white'}}>
      <HeaderTop title="사용자 관리" 
                 isMain={false} 
                 onPress={onPressBack} 
                 onHome={()=>{navigation.popToTop()}} />
    
      <View style={{flex:1, marginHorizontal: 16, backgroundColor:"white"}}>
        <Spacer space={20}/>
          <HeaderUserInfo onSelect={onSelect}  isUser={true} />
        
        <Spacer space={20}/>
        {/** 사용자 유형
         * class_cd : 'U01' Client User Type, 'U02'  Service User Type 
         */}
        <InputSearchText title={''} 
                      placeholder={'사용자 또는 사용자 명 검색'} 
                      name='user_cd' 
                      value={searchText} 
                      onChange={(text:string)=>{
                        onChangeText('user_cd', text); 
                      }}

                      onSubmit={onSubmit}  
                      horizontal={isHorizontal} 
                      />
        <View style={{flex:1, marginBottom:50}}>
          {/** 사용자 유형 선택 값에 따라서 사용자 정보 조회
           * 전체 선택시 loginstate.user.user_tp 의 3자리로 조회 */}
          <UserGrid
              url={ApiCmd.api_GetUser} 
              title='사용자 정보' 
              item={item}
              isSync={isSync}
              onEnd={()=>{setSync(false)}}
              onSelectData={onGridSelectData}
            />
        </View>
      </View>
      <View style={{height:50, alignItems:"flex-end", marginBottom:10, marginHorizontal:16}}>
            <InputButton title={'등록'} onPress={()=>{
                                                  //navigation.reset({routes: [{name: "UserReg" }]})
                                                  // navigation.navigate('UserReg')
                                                  navigation.navigate('UserReg');
                                                }} />
      </View>
    </View>
  )
}


