import React, { useEffect, useRef, useState,useCallback } from "react";
import { 
  Text, 
  View ,
  TouchableOpacity,
  Platform,
  ScrollView,
  ActivityIndicator,
  SectionList,
  SectionListData,
  SectionListRenderItem ,
} from "react-native";
import * as ApiCmd from '../../datas/TrsApiType';
import { SimpleLineIcons,MaterialIcons } from '@expo/vector-icons'; 
import { COLOR } from '../../utils/color';
import { InputCheck } from "./InputComponent";
import { DefaultCheckData, getCheckSection, getChecksSection, setRepairChecked,  } from "../../utils/data";
import { NoflexTypography } from "../../components/custom/NoflexTypography";
import { Typography } from "../../components/Typography";
import { Spacer } from "../../components/Spacer";
import { useRepairSync} from "../../hooks/use-Input";
import { useReqDispatch, useReqState } from "../../contexts/ReqContext";
import { ICheckRepair, IRepair, IRepairs, ISelectCode } from "../../datas/TrsDataDetailType";
import { CheckColumn, TextColumn } from "./GridHeader";
import { FindDefault } from "../../datas/TDataFind";
import { TrackLine } from "./TrsComponent";
import { Loading } from "../../components/Loading";
import Checkbox from "expo-checkbox";

type typRepair = IRepair[];
type typRepairs = IRepairs;
type SectionHeader = (info: { section: SectionListData<ISelectCode, ICheckRepair> }) => React.ReactElement ;

// 서버에서 장애 종류를 수신받아 화면에 표시 
export const DefectPopupList:React.FC<{
  rep_type:string,
  track_no?:string, 
  det_seq?:number,
  isSync?:boolean,
  onSync?:()=>void,
  NewAction?:string,
}>=({track_no, det_seq, rep_type,  isSync, onSync, NewAction})=>{
  const reqStatus = useReqState();
  const dispatch = useReqDispatch(); // Req Context Dispatch

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [table, setTable] = useState<typRepair>([]);
  const [sections, setSections] = useState([{...DefaultCheckData}]);

  const sAction=NewAction??'repair_res';
  const fontSize = (Platform.OS === 'web')? 18: 15
  const column =  (Platform.OS === 'web')? 2: 2
  const [find, setFind] = useState({
    ...FindDefault, action:sAction,
    track_no:track_no??"", 
    det_seq:det_seq??0, 
    rep_type:rep_type,}) ;

    const ApiSync = async ()=>{
    setLoading(true);
    console.log('DefectList  DataSend.....', find);

    const apiResponse = await fetch(ApiCmd.default_url.concat(ApiCmd.api_GetTrsDetail),{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(find),
    });
    const res = await apiResponse.json();
    const conv = await getChecksSection(res); // src/utils/data.ts
    setSections(conv); 
    setLoading(false);
    onSync&&onSync();
    console.log('DefectList//////////수신')
    dispatch({ type: 'SET_SEC', secs:conv, });
  }

  useEffect(() => { 
    if (isSync === true){
      ApiSync();
    } 
  }, [isSync]);
  useEffect(()=>{
    console.log('체크 체인지 상태값 확인', reqStatus.secs[0])
  }, [reqStatus])

 
  if ( loading ) return <Loading message={'loading...'} />
   
   const ListHeaderComponent:React.FC<{}>=()=>{
    const title =  (rep_type ==='01')?'장애유형을 선택해주세요' :
                   (rep_type ==='02')?'장애판정':'결과'
    return(
      <View style={{flexDirection:"row", 
                    alignItems:'center',
                    justifyContent:'center', 
                    height:50}    }>
          <NoflexTypography color='#7F7F7F' fontSize={fontSize}>{title}</NoflexTypography>
      </View>
    );
  }
  const renderSectionHeader:SectionHeader = ({section}) => {
    return <View style={{ flexDirection:"row", 
                          alignItems:'center',
                          justifyContent:'center', 
                          height:40,
                          borderTopColor:COLOR.GRAY_3,
                          borderTopWidth:0.5,
                          } }>
        <Text style={{fontSize:fontSize,color:'#6B6B6B'}}> {section.rep_clsn}</Text>
    </View>
  };

  const renderItem:SectionListRenderItem<ISelectCode, ICheckRepair>=({item,index})=>{
    const [checked, setChecked] = useState(item.checked);

    const onChecked=useCallback((value:boolean)=>{
      const state = async (psec:ICheckRepair[])=>{
        const newSec = await setRepairChecked(psec, item);
        console.log('Defect onItemChecked changed', newSec[0] );
        dispatch({ type: 'SET_SEC', secs:newSec, });
      }
      setChecked(value);
      const sec = reqStatus.secs;
      state(sec);
    },[reqStatus]);

    return(
      <View style={{flexDirection:"row", 
                    alignItems:'center', 
                    justifyContent:'center', 
                    height:40,}}>
        <View style={{flex:1,justifyContent:'center', alignItems:'center'}}>
            <Checkbox
                style={{margin: 8,}}
                value={checked}
                key={item.key}
                onValueChange={onChecked}
                color={checked ? COLOR.SELECT : undefined}
                />
        </View>
        <View style={{flex:4, width:'100%',justifyContent:'flex-start',alignItems:'center' }}>
            <Text style={{ fontSize: fontSize,
                          justifyContent:'flex-start',
                          alignItems:'center',
                          color:'#595959'}}  key={ item.key}>{item.value}</Text>
        </View>
      </View>
    );
  }
  const ItemSeparatorComponent = () => (
    <View style={{ width: "100%", height: 1, borderBottomWidth: 1, borderBottomColor : '#eeeeee' }} />
  );
  const ListFooterComponent = () => (
    <View style={{height:10, borderTopColor:COLOR.GRAY_2, borderTopWidth:0.5  }} />
  )

  return (
    <View style={{flex:1}}>
      <View
       style={{ borderColor : '#D9D9D9',          
                borderWidth : 1 ,          
                borderRadius: 5,  
                }}
      >
        <Spacer space={5}/>
        <View style={{flex:1}}>
        <SectionList
            style={{ flex: 1, width: "100%" }}
            sections={sections}
            ListHeaderComponent={ListHeaderComponent}
            renderSectionHeader={renderSectionHeader}
            renderItem={renderItem}
            keyExtractor={item=>item.key }
            ItemSeparatorComponent={ItemSeparatorComponent}
            ListFooterComponent={ListFooterComponent}
         />

        </View>
        <Spacer space={15}/>
      </View>
      
    </View>
  )
}