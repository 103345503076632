import React, {useCallback, useState, useEffect} from 'react';
import {useIsFocused, useNavigation} from '@react-navigation/native';
import { useTrsNavigation, useTrsRoute } from '../navigations/StackParamNavi'; 
import {View, useWindowDimensions} from 'react-native';
import { Button } from '../components/Button';
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { DataSelectList } from './components/DataSelectList';
import { DataGrid, IGridSelect } from './components/DataGrid';
import {Spacer} from '../components/Spacer';
import { useLoginState } from '../contexts/LoginContext';
import { HeaderTop, HeaderUserInfo } from './components/HeaderTop';
import * as ApiCmd from '../datas/TrsApiType';
import * as DataType from '../datas/TrsDataType';
import { IFindInfo, FindDefault } from '../datas/TDataFind';
import { TitlePanelRow } from './components/TrsComponent';
import { Sync } from './components/Sync';
import { GridBatchView } from './components/Grid';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

export const TrsListDetailScreen:React.FC = () =>{
  const route = useTrsRoute<'TrsListDetail'>();
  const navigation = useTrsNavigation<'TrsListDetail'>();
  const loginState = useLoginState();
  const compName = 'TrsListDetailScreen';
  console.log(compName.concat('route'), route.params.find );
  const [command, setCommand] = useState('');
  const [apiname, setApiName] = useState('');
  const [refApiName, setRefApiName] = useState('');
  const default_item = route.params.find;
  const [item, setItem] = useState<IFindInfo>(default_item);
  const [sendData, setSendData] = useState<any|undefined>();
  const [recvData, setRecvData] = useState<any>();
  const [datas, setDatas] = useState<DataType.ITrsBLists>();
  const [data, setData] = useState<DataType.ITrsBList>();

  const isFocused = useIsFocused();

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };


  useEffect(() =>{
    console.log('TrsList isFocused', isFocused);
    onSend();
   }, [isFocused]);


  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  const onGridSelectData = useCallback((value:DataType.ITrsBList) => {
    console.log('trsList', value);
    setItem({...item, track_no:value.track_no});
    const find1 = { 
      ...FindDefault,
      action  : ApiCmd.action_track_no,
      site_no : loginState.user.site_cd,
      track_no: value.track_no,
      det_seq : value.det_seq,
    };
    navigation.navigate('TrsClientDetail', {find:find1, menuId:'TrsListDetail'});
  },[] );

  const onData = useCallback(( resultCode:boolean, cmd:string, recvData:any)=>{ 
    setApiName('');
    setDatas(recvData);
  }, [apiname, datas, data] );

  const onSend = useCallback(()=>{
    setCommand('send');
    setApiName(ApiCmd.api_GetTrsViewDetail);
    setSendData(item);
  },[item, sendData, command, apiname])

  return (
    <View style={{flex:1,backgroundColor:"white"}}>
      <HeaderTop
          title="서비스일괄 신청현황" 
          isMain={false}
          onPress={onPressBack}
          onHome={()=>{navigation.popToTop()}} />

      <View style={{flex:1, marginHorizontal: 16, backgroundColor:"white"}}>
        <View style={{}}>
          <Spacer space={20}/>  
          <View style={{backgroundColor : '#D9D9D9', height:1,borderRadius: 3}}></View>
          <View style={{flexDirection:'row'}}>
            <TitlePanelRow title='센터' data={(datas && datas.totals > 0 )? datas?.table[0].site_nm:''} />
            <TitlePanelRow title='위치' data={(datas && datas.totals > 0 )? datas?.table[0].loc_stsn:''}/>
          </View>
          <View style={{backgroundColor : '#D9D9D9', height:1,borderRadius: 3}}></View>
          <View style={{flexDirection:'row'}}>
            <TitlePanelRow title='접수번호' data={(datas && datas.totals > 0 )? datas?.table[0].track_no:''} />
            <TitlePanelRow title='접수일' data={(datas && datas.totals > 0 )? datas?.table[0].req_dat:''} />
          </View>
          <View style={{backgroundColor : '#D9D9D9', height:1,borderRadius: 3}}></View>
        </View>
        <Spacer space={10}/>
        <View style={{flex:1}}>
          
          <GridBatchView
              tableTitle='접수현황(일괄)' 
              tableData={datas}
              onRefresh={()=>{}}
              onSelectData={onGridSelectData}
              />
        </View>
      </View>
      {
      sendData&& <Sync apiName={apiname} 
            sendData={sendData} 
            onData={( resultCode:boolean, cmd:string, recvData:any)=>{ 
                      setApiName('');
                      onData(resultCode, cmd, recvData);
                    }} />
    }
 
    </View>
  )
}