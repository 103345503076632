import React, { useCallback, useEffect, useState, forwardRef, useRef,} from 'react';
import { 
  View, 
  Text,
  TextInput,
  Platform, 
  ScrollView, 
  useWindowDimensions } from 'react-native';
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { Spacer } from '../components/Spacer';
import { Typography } from '../components/Typography';
import { InputDropDownList, InputSearchText, InputText, InputView } from './components/InputComponent';
import { COLOR } from '../utils/color';
import { useLoginState } from '../contexts/LoginContext';
import { api_GetSerial, api_SetTrsReq, action_serail_no, api_GetTrsDetail, action_track_no, api_SetTrsTracking} from '../datas/TrsApiType';
import { DefectList } from './components/DefectList';
import { DefaultSendDetail, DefaultSendRepair, IReq, IRltCode, ISendRepair } from '../datas/TSendData';
import { DefaultTracking, FindDefault, IFindInfo, ITracking } from '../datas/TDataFind';
import { DefaultCheckSerial, DefaultDDLSelect, DefaultTrsDetail, IDDLSelectValue, ISelectCode, ISerials, ITrsDetail, ITrsDetails } from '../datas/TrsDataDetailType';
import { Sync } from './components/Sync';
import { Button } from '../components/Button';
import { MultiLineInput } from '../components/MultiLineInput';
import { DefaultMsg, TrsMessageBox, TrsMessageConfirm, TrsMessageShow, Waiting } from './components/TrsModal';
import { useReqDispatch, useReqState } from '../contexts/ReqContext';
import { useTrsNavigation, useTrsRoute } from '../navigations/StackParamNavi';
import { minWinWidth } from '../datas/Const';
import dayjs from 'dayjs';
import { DefectView } from './components/DefectView';
import { useSize } from '../hooks/use-Size';
import { useIsFocused } from '@react-navigation/native';
import AsyncStorage from "@react-native-async-storage/async-storage";

const fontSize= Platform.OS ==='web'? 16 : 12;
const line_space=10;

export const ClientReg:React.FC<{
  onSync:()=>void,
  onMove:(cmd:string, find:IFindInfo)=>void
  onChangeId:(scrId:string)=>void
  track_step:string,
  site:string,
  data?:ITrsDetails|undefined,
  isItem:boolean,
  onEnd:()=>void,
  isInit?:boolean,
  onClearEnd?:()=>void,
}>=({track_step,site, data, onMove, onSync, onChangeId, isItem, onEnd, isInit, onClearEnd})=>{
  const loginState                  = useLoginState();
  const navigation                  = useTrsNavigation();
  const reqState                    = useReqState();
  const [
    winWidth, winHeight, 
    isHorizontal, menuFontSize
  ] = useSize();
  const [serialInfo, setSerialInfo] = useState(DefaultCheckSerial);
  const [sendData,   setSendData]     = useState<any>();
  const [recvData,   setRecvData]     = useState<any>();
  const [command,    setCommand]      = useState('');
  const [apiname,    setApiName]      = useState('');
  const [rltCode,    setRltCode]      = useState<IRltCode>();
  const [msgShow,    setMsgShow]      = useState(DefaultMsg);
  const [msgInfo,    setMsgInfo]      = useState(DefaultMsg);
  const [msgOk,      setMsgOk]        = useState(DefaultMsg);
  
  const [items, setItems]           = useState<ITrsDetails|undefined>(data)
  const [find, setFind]             = useState<IFindInfo>(FindDefault);
  const [isSync, setSync]           = useState(true);
  const [isDefectShow, setIsDefectShow] = useState(true);
  const [input,      setInput]          = useState({serial:"",ticket:"", camp_req_name:loginState.user.user_nm, tr_no:"",  detail:"" });

  const action=(items&&items.totals > 0 ) ?'UPD':'NEW';
  const track_no = (items&&items.totals > 0 ) ?items.table[0].track_no:'';
  const det_seq = (items&&items.totals > 0 ) ?items.table[0].det_seq:0;
  const req_type = (items&&items.totals > 0 ) ?items.table[0].req_type:'';
  const camp_cd = (items&&items.totals > 0 )  ?items.table[0].camp_cd:loginState.user.camp_cd;
  const tr_comp = (items&&items.totals > 0 )  ?items.table[0].req_tr_comp:'01';

  const [comp, setComp]             = useState<IDDLSelectValue>({...DefaultDDLSelect, value:tr_comp});
  const [camp, setCamp]             = useState<IDDLSelectValue>({...DefaultDDLSelect, value:camp_cd});
  const isFocused = useIsFocused();

   // Focus 
  const ref_input: Array<React.RefObject<TextInput>> = [];
   //ref_input = forwardRef(ref_input)
   ref_input[0] = useRef(null); // serial
   ref_input[1] = useRef(null); // ticket
   ref_input[2] = useRef(null); // detail
   ref_input[3] = useRef(null); // camp_req_nm
   ref_input[4] = useRef(null); // songjang

   const onFocusNext = (index: number) => {
     if (ref_input[index + 1] && index < ref_input.length - 1) {
       ref_input[index + 1].current?.focus();
     }
     if (ref_input[index + 1] && index == ref_input.length - 1) {
       ref_input[index].current?.blur();
     }
   };

   const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
   useEffect(() =>{
    recordStartTime();
  }, [isFocused]);

  useEffect(()=>{
    if ( isItem === true){
      setItems(data);

      setIsDefectShow(true);
      setSync(true);
      onEnd();  // isItem is false
    }
  },[data, items, isItem, isDefectShow, isSync])

  useEffect(()=>{
    if ( isInit === true){
      onClear();
    }

  }, [isInit])

  useEffect(()=>{
    onItemToInput()
  },[items])
  
  // 조회 한 항목을 입력 필드로 Set
  const onItemToInput=useCallback(()=>{
    if ( !items || items.totals === 0) return;

   
    const item:ITrsDetail = items.table[0];

    console.log('onItemToInput...Trcomp', item.req_tr_comp);


    setInput({...input
              , serial:item.serial_no
              , ticket:item.ticket_no
              , detail:item.rep_det01
              , tr_no:item.req_tr_no
              , camp_req_name:item.req_nm
            });
    setComp({...comp, value:item.req_tr_comp})        
    setCamp({...camp, value:item.camp_cd})  
        
    setSerialInfo({...DefaultCheckSerial, 
                   isSerial:true, 
                   prod_cd:item.prod_cd, 
                   prod_cls:item.prod_cls, 
                   serial:item.serial_no, 
                   en_date:item.en_date,
                   validDay:item.validday})        
  }, [input,  comp, camp]);


  // 초기화 
  const onClear = useCallback(()=>{
    console.log('ClientReg ====>?onClear isSync ' , isSync);
    setSync(true);
    setMsgInfo(DefaultMsg)
    setInput({
              serial:"",
              ticket:"", 
              camp_req_name:loginState.user.user_nm, 
              tr_no:"",  
              detail:"" 
            });
    setSerialInfo(DefaultCheckSerial)
    setCommand('');
    setApiName('');
    setSendData(null);
    setRecvData(null);
    setItems({result_cd:'', result_msg:'', totals:0, table:[{...DefaultTrsDetail}]});

    ref_input[0].current?.focus();
    onClearEnd && onClearEnd();
    
  }, [isSync, command, apiname, sendData, recvData, rltCode, input, serialInfo, ref_input])

  const onInit = useCallback(()=>{
    if ( action === 'UPD'){
      setMsgInfo({...msgInfo, msgId:'CAN', message:'접수번호' + track_no + ' 취소하시겠습니까?'})
      return;
    }
    else{
      onClear();
      /*
      setSync(true);
      setMsgInfo(DefaultMsg)
      setInput({
                serial:"",
                ticket:"", 
                camp_req_name:loginState.user.user_nm, 
                tr_no:"",  
                detail:"" 
              });
      setSerialInfo(DefaultCheckSerial)
      setCommand('');
      setApiName('');
      setSendData(null);
      setRecvData(null);
      setItems({result_cd:'', result_msg:'', totals:0, table:[{...DefaultTrsDetail}]});
  
      ref_input[0].current?.focus();
      */
    }
  }, [msgInfo, action])

  const onDDLSelect = useCallback( (e: IDDLSelectValue) => {
    if ( e.name === 'comp'){
      setComp(e);
    }
    if ( e.name === 'camp'){
      setCamp(e);
    }
  }, [comp, camp]);
   
  const onChangeText = useCallback( (name: string,value:string) => {
    if ( name === 'camp_req_name'){
      console.log(camp)
    }
    setInput({...input, [name]:value});
  }, [input, serialInfo]);

  const onSubmit = useCallback( (name:string, value:string) => {
    console.log('onSubmit', name, value);
    if ( name === 'serial'){
      // 시리얼 검색
      console.log('Serial...........', input.serial, value );
      const serial = input.serial;
      console.log('Serial...........', serial );
      const search:IFindInfo = {...FindDefault, 
                                action:action_serail_no, 
                                serial_no: (serial.substring(0,1) === "S")?serial.substring(1):serial }
      setInput({...input, serial:serial});
      setSendData(search);                                
      setCommand('serial_find');
      setApiName(api_GetSerial);
    }
    if( name === 'ticket'){
      onFocusNext(2);
    }
    if ( name === 'detail'){
      onFocusNext(3);
    }
    if ( name === 'camp_req_name'){
      onFocusNext(4);
    }
    if ( name === 'tr_no'){

    }
    if (name === 'item'){
      setSendData(find);           
      setCommand('item');
      setApiName(api_GetTrsDetail);
    }
  }, [input, command, apiname, sendData]);



  const onItemSync = useCallback(()=>{
    onSync();
  },[])

  const onCancel = useCallback(()=>{
    const send :ITracking={
      ...DefaultTracking,
      track_no:track_no,
      det_seq:det_seq,
      track_step:'CAN', 
    };
    const sendTmp:ITracking[]=[];
    sendTmp.push(send);
    setApiName(api_SetTrsTracking);
    setSendData(sendTmp);
    setCommand('CAN');
},[])
  // A/S 신청 등록
  const onSend = useCallback(()=>{
    /// Validation Check
    console.log('clientReg ........camp_value=[', camp.value, '] camp_value_length=[', camp.value.length  , ']' );
    if (!camp ||  camp.value.length === 0 ){
      setMsgShow({...DefaultMsg, msgId:'camp', message:'SITE(MDM 버전 선택) 선택해 주세요.'});
      return;
    }
    if ( serialInfo.isSerial === false){
      setMsgShow({...DefaultMsg, msgId:'serial', message:'시리얼정보를 확인해주세요'});
      return;
    }

    console.log('clientReg ........action=[', action, '] track_no=[', track_no , ']' );

    let repair:ISendRepair[] = [];
    let rep_type = '01';
    reqState.secs.map(rep => {
      rep.data.filter((value:ISelectCode) => value.checked === true)
              .map((row)=>{
                const r = {
                  ...DefaultSendRepair,
                  action:action,
                  track_no:track_no,
                  det_seq:det_seq,
                  rep_cls:rep.rep_cls, 
                  rep_clsn:rep.rep_clsn, 
                  rep_type:rep_type,
                  rep_det:input.detail,
                  rep_cd: row.key, 
                  rep_cdn:row.value,
                  reg_user:loginState.user.user_cd,
                  reg_uname:loginState.user.user_nm,
                  reg_ip:loginState.reg_ip,
                  reg_dev:loginState.reg_dev,
                }
                repair.push(r);
              });
    });

    const comp_value = (!comp || comp.value.length === 0 )? '01': comp.value;
    const camp_value = (!camp || camp.value.length === 0 )? loginState.camp_cd: camp.value;
    const details = {
      ...DefaultSendDetail, 
      action:action,
      track_no:track_no,
      det_seq:det_seq,
      site_cd:loginState.site_cd,
      req_type:'1',
      track_step:'01',
      serial_no:serialInfo.serial, // input.serial,
      prod_cls:serialInfo.prod_cls,
      prod_cd:serialInfo.prod_cls,
      req_id:loginState.user.user_cd,
      req_nm:loginState.user.user_nm,
      camp_cd:camp_value,
      camp_req_nm:input.camp_req_name,
      tr_comp:comp_value,
      tr_no:input.tr_no,
      ticket_no:input.ticket,
      rep_det:input.detail,
      cmt:input.detail,
      reg_user:loginState.user.user_cd,
      reg_uname:loginState.user.user_nm,
      reg_ip:loginState.reg_ip,
      reg_dev:loginState.reg_dev,
    }
    if ( details && repair && repair.length > 0 ){
      
      let sendTmp:IReq[]=[];
        sendTmp.push({ trs:details, reps:repair})
        setSendData(sendTmp);
        setCommand('send');
        setApiName(api_SetTrsReq);
        return;
    }else{
      setMsgShow({...DefaultMsg,msgId:'repair', message:'장애내역을 선택해주세요.'});
    }
  }, [input, serialInfo, reqState, comp,camp, loginState, command, sendData, apiname, msgShow]);

  // 시리얼정보검색 결과수신, A/S 신청 결과 수신
  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    console.log('onReceived ClientReg..............', command, resultCode, cmd, rData, )
    setApiName(''); setSendData(null);
    if (resultCode === false) {
      setMsgShow({...DefaultMsg,msgId:'serial', message:rData});
      return;
    }

    if ( command === 'serial_find')
    { // 시리얼 결과 처리
      setCommand('');
      if (rData){
        const tmp:ISerials = rData;
        setInput({...input, serial:""});
        if ( tmp.result_cd === '0') {
          //setInput({...input, serial:""});
          setMsgShow({...DefaultMsg,msgId:'serial', message:tmp.result_msg});
          return;
        }
        if ( tmp.table[0].sn_stat === '01')
        {
          //setInput({...input, serial:""});
          setMsgShow({...DefaultMsg,msgId:'serial', message:'A/S 진행중인 건입니다.'});
        }
        else if ( tmp.table[0].isvalid === '0')
        {
          //setInput({...input, serial:""});
          setMsgShow({...DefaultMsg,msgId:'serial', message:"보증기간이 경과되었습니다."});
        }
        setSerialInfo({...DefaultCheckSerial
                       , isSerial:( tmp.table[0].sn_stat === '00' && tmp.table[0].isvalid === '1' )?true:false
                       , prod_cls:tmp.table[0].prod_cls
                       , prod_cd:tmp.table[0].prod_nm
                       , validDay:tmp.table[0].valid_day
                       , en_date:tmp.table[0].en_date
                       , serial:tmp.table[0].serial
                       , sn_stat:tmp.table[0].sn_stat})
        return;
      }
    }
    if ( command === 'send')
    { 
      // 전송 결과 처리 
      setCommand('');
      const tmp:IRltCode = rData;

      if ( tmp.result_cd === '1'){
        setFind({...FindDefault,  action:action_track_no,  track_no:tmp.result_msg, det_seq:0})
        if (items&&items.totals > 0 ){
          setMsgOk({...DefaultMsg, 
                    msgId:'send', 
                    message:'PDA 본체 케이스, 배터리 제거 후 발송해 주세요. ',
                    message2: tmp.result_msg + ' 수정되었습니다'});
        }
        else{
          setMsgOk({...DefaultMsg, 
                    msgId:'send', 
                    message:'PDA 본체 케이스, 배터리 제거 후 발송해 주세요. ',
                    message2:'[' + tmp.result_msg + ']로 접수 번호가 생성되었습니다. '});
        }
      }
      else{
        setMsgShow({...DefaultMsg, msgId:'send', message:tmp.result_msg});
      }
    }
    else if ( command === 'CAN'){
      const tmp:IRltCode = rData;
      if ( tmp.result_cd === '1'){
        setMsgOk({...DefaultMsg, msgId:'CAN', 
            message:'접수번호' + track_no + ' 취소되었습니다.'});
      }
      else{
        setMsgShow({...DefaultMsg, msgId:'CAN', message:tmp.result_msg});
      }
    }
    else if ( command === 'item'){
      console.log('item Received', rData)
      setCommand('');  setApiName('')
      const tmp:ITrsDetails = rData;
      if ( tmp.result_cd === '1'){
        setItems(tmp);  // 수신 받은 데이타 를 테이블에 위치
        onItemToInput();
      }
      else{
        setMsgShow({...DefaultMsg, msgId:'item', message:tmp.result_msg});
        return;
      }
    }
  }, [command, apiname, sendData, find, items, isSync, serialInfo]);
  
  return ( 
    <View style = {{flex:1}}>
      <View style={{flex:1,backgroundColor:'white', marginBottom:5}}>
        <View style={{flexDirection:"row",  
                      height:15, 
                      alignItems:'center',
                      justifyContent: 'flex-end', 
                      marginRight:12,
                      }}>
          <NoflexTypography color='#404040' fontSize={fontSize}>{items? `수정항목`: `필수입력항목`}</NoflexTypography>     
          <NoflexTypography color='red'     fontSize={fontSize} >{items? ``: `*`}</NoflexTypography>
          <Spacer space={10} horizontal />
        </View>
        <ScrollView showsVerticalScrollIndicator={false} >
        <Spacer space={5}/>
          {/* 제품선택, 모델선택, 시리얼넘버, 티켓넘버 */}
          <View style={{marginHorizontal:16, 
                        backgroundColor:"white"  }}>
            {/* 신청자 */}
            {/* 버튼 : 서비스신청 */}
            <Spacer space={line_space-10}/>
            <InputDropDownList title={'접수방식'} 
                              name='comp' astar='*' 
                              class_cd={'TLIST'} 
                              data={comp.value}
                              action={'rcombo'} 
                              onSelect={onDDLSelect}  
                              horizontal={isHorizontal}
                              onSync={()=>{}}
                              />
            <Spacer space={line_space}/>
            {
              (loginState.user.user_tp === 'U0102')&&
              <InputView          title={'SITE'} 
                                  value={camp_cd}
                                  isTitle={true}  
                                  horizontal={isHorizontal}
                                  />
            }
            {
              (loginState.user.user_tp !== 'U0102')&&
              <InputDropDownList title={'SITE(MDM 버전 선택)'} 
                                  name='camp' astar='*' 
                                  data={camp_cd}
                                  class_cd={site} 
                                  onSelect={onDDLSelect}  
                                  horizontal={isHorizontal}
                                  action={'scombo'} 
                                  onSync={onSync}
                                  />
            }

            <Spacer space={line_space}/>
            {
              (loginState.user.user_tp === 'U0102')&&
              <InputView          title={'신청자'} 
                                  value={input.camp_req_name}
                                  isTitle={true}  
                                  horizontal={isHorizontal}
                                  />
            }
            {
              (loginState.user.user_tp !== 'U0102')&&
              <InputText          title={'신청자'} 
                                  placeholder='신청자를 입력하세요' 
                                  name='camp_req_name' 
                                  value={input.camp_req_name}
                                  ref={ref_input[3]}
                                  keyboardType={'default'}
                                  onChangeText={(text:string)=>{
                                    onChangeText('camp_req_name', text); 
                                  }} 
                                  horizontal={isHorizontal} 
                                  onSubmit={(onSubmit)}  
                                  />
            }

            <Spacer space={10}/>
            <InputSearchText title={'Serial #'} 
                            placeholder='시리얼 번호를 입력하세요' 
                            name='serial' 
                            value={input.serial} 
                            onChange={(text:string)=>{
                              onChangeText('serial', text); 
                            }}
                            onSubmit={onSubmit}  
                            horizontal={isHorizontal}
                            asta='*' 
                            keyboardType="numeric"
                            ref={ref_input[0]}
                            autoFocus
                            iconName={'checkmark'}
                            iconColor={serialInfo.isSerial ? COLOR.GREEN_1:'black'}
                              />
              {
              ((serialInfo.prod_cd.length > 0 )&&( Platform.OS ==='web')) &&
              <View style={{width:'100%',flexDirection:'row',}}>
               <View style={{flex:1}}/>
               
               <View style={{flex:3, width:'100%',paddingHorizontal:10}}>
                <View style={{ flexDirection:"row",    
                            backgroundColor:COLOR.INPUTBORDER,
                            justifyContent:'space-between',
                            alignItems:'center',
                            height:30,
                            paddingHorizontal:15
                             }}>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER,}}>
                  <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold'}}>{serialInfo.prod_cd}</Text>
                </View>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER,}}>
                  <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold' }}>{'SN# ' + serialInfo.serial}</Text>
                </View>
              </View>
             
              <View style={{ flexDirection:"row",    
                            backgroundColor:COLOR.INPUTBORDER,
                            justifyContent:'space-between',
                            alignItems:'center',
                            height:30,
                            borderBottomLeftRadius:10,
                            borderBottomRightRadius:10,
                            paddingHorizontal:10
                                }}>
                <Spacer space={5} horizontal/>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                  <Text style={{color:COLOR.BLACK, }}>유효일자</Text>
                </View>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                  <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold'}}>{
                      (serialInfo.en_date )? serialInfo.en_date.substring(0, 4) + '-' + serialInfo.en_date.substring(4, 6) + '-' + serialInfo.en_date.substring(6, 8) :''}
                  </Text>
                </View>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                  <Text style={{color:COLOR.BLACK, justifyContent:'center'}}>잔여일</Text>
                </View>
                <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                  <Text style={{color:(serialInfo.validDay < 0 ) ? COLOR.RED_1:COLOR.ICONCOLOR, fontWeight:'bold'}}>{serialInfo.validDay}</Text>
                </View>
                <Spacer space={5} horizontal/>
              </View>
              {
                (serialInfo.sn_stat === "01")&&
                <View style={{ flexDirection:"row",    
                                backgroundColor:COLOR.INPUTBORDER,
                                justifyContent:'space-between',
                                alignItems:'center',
                                height:30,  
                                marginHorizontal:10  }}>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                    <Text style={{color:COLOR.BLACK, justifyContent:'center'}}>AS 진행중인 시리얼 번호입니다.</Text>
                  </View>                                    
                </View>

              }
              {
                (serialInfo.validDay < 0 )&&
                <View style={{ flexDirection:"row",    
                                backgroundColor:COLOR.INPUTBORDER,
                                justifyContent:'space-between',
                                alignItems:'center',
                                height:30,  
                                marginHorizontal:10  }}>
                  <Spacer space={10} horizontal/>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                    <Text style={{color:COLOR.RED_1, justifyContent:'center'}}>보증기간이 경과되었습니다.</Text>
                  </View>                                    
                </View>
              }
                </View>
              </View>
            }
              {  
              ((serialInfo.prod_cd.length > 0 )&&( Platform.OS !=='web')) &&
              <View style={{marginHorizontal:10}}>
                <View style={{ flexDirection:"row",    
                              backgroundColor:COLOR.INPUTBORDER,
                              justifyContent:'space-between',
                              alignItems:'center',
                              paddingHorizontal:15
                               }}>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER,}}>
                    <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold'}}>{serialInfo.prod_cd}</Text>
                  </View>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER,}}>
                    <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold' }}>{'SN# ' + serialInfo.serial}</Text>
                  </View>
                </View>
                <View style={{ flexDirection:"row",    
                              backgroundColor:COLOR.INPUTBORDER,
                              justifyContent:'space-between',
                              alignItems:'center',
                              height:30,
                              borderBottomLeftRadius:10,
                              borderBottomRightRadius:10,
                              paddingHorizontal:10
                                  }}>
                  <Spacer space={5} horizontal/>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                    <Text style={{color:COLOR.BLACK, }}>유효일자</Text>
                  </View>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                    <Text style={{color:COLOR.ICONCOLOR, fontWeight:'bold'}}>{
                        (serialInfo.en_date )? serialInfo.en_date.substring(0, 4) + '-' + serialInfo.en_date.substring(4, 6) + '-' + serialInfo.en_date.substring(6, 8) :''}
                    </Text>
                  </View>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                    <Text style={{color:COLOR.BLACK, justifyContent:'center'}}>잔여일</Text>
                  </View>
                  <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER}}>
                    <Text style={{color:(serialInfo.validDay < 0 ) ? COLOR.RED_1:COLOR.ICONCOLOR, fontWeight:'bold'}}>{serialInfo.validDay}</Text>
                  </View>
                  <Spacer space={5} horizontal/>
                </View>
                {
                  (serialInfo.sn_stat === "01")&&
                  <View style={{ flexDirection:"row",    
                                  backgroundColor:COLOR.INPUTBORDER,
                                  justifyContent:'space-between',
                                  alignItems:'center',
                                  height:30,  
                                  marginHorizontal:10  }}>
                    <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                      <Text style={{color:COLOR.BLACK, justifyContent:'center'}}>AS 진행중인 시리얼 번호입니다.</Text>
                    </View>                                    
                  </View>

                }
                {
                  (serialInfo.validDay < 0 )&&
                  <View style={{ flexDirection:"row",    
                                  backgroundColor:COLOR.INPUTBORDER,
                                  justifyContent:'space-between',
                                  alignItems:'center',
                                  height:30,  
                                  marginHorizontal:10  }}>
                    <Spacer space={10} horizontal/>
                    <View style={{flex:1, backgroundColor:COLOR.INPUTBORDER, justifyContent:'center'}}>
                      <Text style={{color:COLOR.RED_1, justifyContent:'center'}}>보증기간이 경과되었습니다.</Text>
                    </View>                                    
                  </View>
                }
              </View> 
            }
            <Spacer space={line_space}/>
            <InputText title={'Ticket #'} 
                      placeholder='Ticket Number를 입력하세요' 
                      name='ticket' 
                      ref={ref_input[1]}
                      value={input.ticket} 
                      onChangeText={(text:string)=>{
                        onChangeText('ticket', text); 
                      }}
                      onSubmit={onSubmit}  
                      horizontal={isHorizontal}  />

            <Spacer space={line_space}/>
            {/* 상세 증상 Check */}
            <View style={{}}>
              {
                items&& <DefectView track_no={items.table[0].track_no}  
                                    det_seq={items.table[0].det_seq}
                                    rep_type={'01'} 
                                    isSync={isDefectShow}
                                    onSync={()=>{
                                      setIsDefectShow(!isDefectShow)}} />      
              }
              {
                items&& <DefectList track_no={items.table[0].track_no}  
                                    det_seq={items.table[0].det_seq}
                                    rep_type={'01'} 
                                    isSync={isSync}
                                    onSync={()=>{
                                      console.log('Client Defect onSync Call');
                                      setSync(false);}} 
                                    newAction={(action === 'NEW')?"repair_req_new":"repair_req"}  
                                      />      
              }
              {
              !items&&<DefectList rep_type='01' 
                                  isSync={isSync}
                                  onSync={()=>{setSync(false); }} 
                                  newAction={(action === 'NEW')?"repair_req_new":"repair_req"}  
                                  /> 
              }

            </View>
            {/* 상세 증상 */}
            <Spacer space={line_space}/>
            <View style={{ paddingLeft:10, 
                          flexDirection: "row", 
                          alignItems:'center', 
                          justifyContent:"flex-start"}}>
              <NoflexTypography color='#7F7F7F' fontSize={fontSize+4}> 상세 증상 </NoflexTypography>
              <Typography color={COLOR.GRAY_4} fontSize={fontSize} >(장애증상을 상세히 입력부탁드립니다.)</Typography>
            </View>
            <Spacer space={line_space}/>
            <View style={{height: 150 , borderRadius: 4, backgroundColor: "#F2F2F2" }}>

              <MultiLineInput value={input.detail}
                              onChangeText={(text:string)=>{
                                onChangeText('detail', text); 
                              }} 
                              ref={ref_input[2]}                           
                              placeholder={'장애 세부 내역을 입력해주세요'}
                              fontSize={fontSize+3}
                              onSubmitEditing={()=>onSubmit('detail', input.detail)}
                              height={150}
                                    />

            </View>
            <Spacer space={10} />
            <InputText title={(comp.value === '01') ? '박스번호':'송장번호'} 
                      placeholder={(comp.value === '01') ? '박스번호  입력하세요':'송장번호를 입력하세요'} 
                      name='tr_no' 
                      value={input.tr_no} 
                      ref={ref_input[4]}
                      onChangeText={(text:string)=>{
                        onChangeText('tr_no', text); 
                      }}
                      onSubmit={onSubmit}  
                      horizontal={isHorizontal} 
                      />
            </View>
          <View style={{height:50}} />
        </ScrollView>
      </View>
      <View style={{flexDirection:'row', height: 50, alignItems:'center',justifyContent:'space-around' }}>
        {
                (Platform.OS === 'web' && action === 'NEW')&&
                <View style={{flex:1, marginHorizontal:10 }}>
                      <Button onPress={()=>{ onChangeId('batch')}}>
                        <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                            <NoflexTypography fontSize={fontSize+8} color='#ffffff' background='#979b9e'>일괄등록</NoflexTypography>
                        </View>
                      </Button>
                </View>              
        }
        <View style={{flex:1, marginHorizontal:10}}>
          <Button onPress={onInit}>
            <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                <NoflexTypography fontSize={fontSize+8} color='#ffffff' background='#979b9e'>{(!items || items.totals === 0 )?'초기화':'취소'}</NoflexTypography>
            </View>
          </Button>
        </View>              
        <View style={{flex:1, marginHorizontal:10}}>
          <Button onPress={()=>{
            setMsgInfo({...msgInfo, msgId  :'SENDASK', 
                        message:(action === 'NEW')?'서비스 신청하시겠습니까?':'등록된 내용을 수정하시겠습니까?'});
            }}>
            <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                <NoflexTypography fontSize={fontSize+8} color='#ffffff' background='#979b9e'>{(!items || items.totals === 0 )?`신청` : `수정`}</NoflexTypography>
            </View>
          </Button>
        </View>              
      </View>
        <TrsMessageBox  message={msgInfo} 
                        onOK={(msgId:string)=>{
                          setMsgInfo(DefaultMsg);
                          switch(msgId)
                          {
                            case 'SENDASK': onSend(); break;
                            case 'NEW':     onInit(); break;
                            case 'CAN':     onCancel(); break;
                            default:        onMove("", find);
                          }
                        }} 
                        onCancel={(msgId:string)=>{
                          setMsgInfo(DefaultMsg);
                          console.log('onCancel=================', action)
                          if ( action === 'NEW') {
                            onMove(action, find);
                          }
                          if ( action === 'UPD'){
                            onSync();
                          }
                        }} />

        <TrsMessageConfirm  message={msgOk} 
                            onOK={(msgId:string)=>{
                              setMsgOk(DefaultMsg);
                              if ( action === 'UPD'){
                                onSync();
                              }else{
                                onMove(action,find);
                              }
                            }} />


        <TrsMessageShow message={msgShow} 
                        onMessage={ (msgId:string)=>{ setMsgShow(DefaultMsg) }} />

    {
      sendData && <Sync apiName={apiname} 
                        sendData={sendData} 
                        onData={onReceived} />
    }

    </View>

  )
}
