import React, {useCallback,useState, useRef,useEffect} from 'react';
import { useTrsNavigation, useTrsRoute } from '../navigations/StackParamNavi';
import {View,Text,TouchableOpacity, FlatList} from 'react-native';
import {Spacer} from '../components/Spacer';
import { HeaderTop,  } from './components/HeaderTop';
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { ScrollView } from 'react-native';
import { useLoginState } from '../contexts/LoginContext';
import * as ApiCmd from '../datas/TrsApiType';
import * as DataType from '../datas/TrsDataType'
import { FindDefault, IFindInfo } from '../datas/TDataFind';
import { ITrsDetail, ITrsDetails } from '../datas/TrsDataDetailType'; 
import { NotFound } from './components/NotFound';
import { TrackContent, TrackHeader, TrackingResult, TrackLine } from './components/TrsComponent';
import { TrackingGrid } from './components/TrackingGrid';
import { RepairGrid } from './components/RepairGrid';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

// 서비스신청내역 처리
export const TrsServiceRegScreen:React.FC = () =>{
  const route = useTrsRoute<'TrsClientDetail'>();
  const navigation = useTrsNavigation<'TrsClientDetail'>();
  const loginState = useLoginState();
  const [selected, setSelected] =  useState("");
  const [item, setItem] = useState<IFindInfo>(route.params.find);

  const [run, setRun] = useState(ApiCmd.start);
  const [data, setData] = useState<ITrsDetail>();
  const [datas, setDatas] = useState<ITrsDetails>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const action = item.action;
  const sync = ApiCmd.start;

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const state = async ()=>{

    setLoading(true);
    const url =  ApiCmd.default_url.concat( ApiCmd.api_GetTrsDetail);
    console.log('state start', url);
    console.log('state start item', item);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(item),
    });
    const res = await apiResponse.json();
    console.log('state res', res);
    setDatas(res)
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
  }

   useEffect(() => {
    console.log('client useEffect2', run);
    if ( run === ApiCmd.start) state();
  }, [item, run]);

  const onSync = useCallback(() => {
    setRun(ApiCmd.start);
  },[] );
 

  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  if (loading) return <View><Text>로딩중..</Text></View>
  if (error) return  <View><Text>Error Occured: </Text></View>
  if (!datas){
    return <NotFound 
      title="서비스 신청내역" 
      message={"데이타 수신 실패"} 
      onRetry={onSync} 
      onPressBack={onPressBack} 
      />
  }
 
  return (
    <View style={{flex:1, backgroundColor:'white'}}>
      {/* 헤더 */}
      <HeaderTop  title="서비스 신청내역" 
                  isMain={false} 
                  onPress={onPressBack} 
                  onHome={()=>{navigation.popToTop()}} />

       <View style={{flex:1, marginHorizontal : 16, backgroundColor:'white'}}>
        <ScrollView showsVerticalScrollIndicator={false}>
        <Spacer space={20}/>
        {/**접수일 구분... */}
        <TrackHeader items={datas} />
        <TrackLine />
        <Spacer space={10}/>
        <TrackContent items={datas}/>
        <Spacer space={20}/>
        <TrackingGrid 
              url={ApiCmd.api_GetTrsDetail} 
              title='이동이력' 
              item={datas}
              />
        {/** A/S 결과  */}
        <TrackingResult items={datas}  />
        {/** <SubMenu />   */}
        </ScrollView>
      </View>
    </View>
  )
}

