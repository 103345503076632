import React,{useEffect,useState,useCallback} from 'react';
import { View, Text, Platform ,TouchableOpacity} from "react-native";
import { NoflexTypography } from '../../components/custom/NoflexTypography';
import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import { ITrsDetail,ITrsDetails } from "../../datas/TrsDataDetailType";
import { RepairGrid } from './RepairGrid';
import * as ApiCmd from '../../datas/TrsApiType';
import { DataSelectList } from './DataSelectList';
import { DefectList } from './DefectList';
import { useLoginState } from '../../contexts/LoginContext';
import { COLOR } from '../../utils/color';
import { IconName } from '../../components/Icons';
import { MaterialIcons } from '@expo/vector-icons'; 
import { DefectView } from './DefectView';
import { FontAwesome } from '@expo/vector-icons'; 

const fontSize= Platform.OS ==='web'? 16 : 15;
export const TitlePanelRow:React.FC <{
  title : string,
  data : string,
  panelflex?: number,
  titleflex?: number,
  dataflex?: number,
  height?:number,
}> =(props)=>{
  return (

  <View style={{flex : props.panelflex ?? 1}}>
    <View style={{ flexDirection:'row'}}>
      <View style={{flex:props.titleflex ??1, height:props.height ?? 40, alignItems:'center', justifyContent:'center'}}>
        <NoflexTypography fontSize={fontSize}  color='#5A5A5A'>{props.title}</NoflexTypography>  
      </View>
      <View style={{flex:props.dataflex ?? 1,alignItems:'center',height:40, justifyContent:'center', }}>
        <NoflexTypography fontSize={fontSize-1} color='#99C341'>{props.data}</NoflexTypography>
      </View>
    </View>
  </View>
) 
}

export const TitlePanel:React.FC <{
  title : string,
  data  : string,
  flex? :number,
}> =(props)=>{
  
return (
  <View style={{flex:props.flex??1}}>
  <View style={{ height:40, alignItems:'center', justifyContent:'center'}}>
    <NoflexTypography fontSize={fontSize} color='#5A5A5A'>{props.title}</NoflexTypography>  
 
  </View>
  <TrackLine color='#99C341'/>
  <View style={{alignItems:'center',height:40, justifyContent:'center'}}>
    <NoflexTypography fontSize={fontSize} color='#5A5A5A'>{props.data}</NoflexTypography>
  </View>
  </View>
) 
}
export const TitlePanelList:React.FC <{
data : string,
}> =(props)=>{
  
return (
<View style={{flex:1}}>
<View style={{alignItems:'center',height:40, justifyContent:'center'}}>
  <NoflexTypography fontSize={fontSize} color='#5A5A5A'>{props.data}</NoflexTypography>
</View>
</View>
) 
}


export const TitlePanelDetailRow:React.FC <{
  title : string,
  data : string,
  panelflex?: number,
  titleflex?: number,
  dataflex?: number,
  height?:number,
}> =(props)=>{

return (
  <View style={{flex : props.panelflex ?? 1}}>
    <View style={{ flexDirection:'row'}}>
      <View style={{flex:props.titleflex ??1, height:props.height ?? 40, alignItems:'center', justifyContent:'center'}}>
        <NoflexTypography fontSize={fontSize-1} background='white' color={COLOR.ICONCOLOR}>{props.title}</NoflexTypography>  
      </View>
      <View style={{flex:props.dataflex ?? 1,alignItems:'center',height:40, justifyContent:'center', }}>
        <NoflexTypography fontSize={fontSize} color='#5A5A5A'>{props.data}</NoflexTypography>
      </View>
    </View>
  </View>
) 
}

export const TitlePanelSelectList:React.FC <{
  title : string,
  class_cd: string,
  data : string,
  panelflex?: number,
  titleflex?: number,
  dataflex?: number,
  height?:number,
  onSelect:(key:string)=>void,
}> =(props)=>{

  const onSelect = useCallback((key:string) => {
    props.onSelect(key);
},[] ); 
return (
  <View style={{flex : props.panelflex ?? 1}}>
    <View style={{ flexDirection:'row'}}>
      <View style={{flex:props.titleflex ??1,backgroundColor:'#a9a9a9', height:props.height ?? 40, alignItems:'center', justifyContent:'center'}}>
        <NoflexTypography fontSize={fontSize} background='#a9a9a9' color='white'>{props.title}</NoflexTypography>  
      
      </View>
      <View style={{flex:props.dataflex ?? 1,alignItems:'center',height:40, justifyContent:'center', }}>
        <DataSelectList action={"rcombo"} class_cd={props.class_cd} onSelect={onSelect}  />
      </View>
    </View>
  </View>
) 
}


// 서비스 신청 내역조회 
// 상단 접수일/구분/접수번호/진행 표시
export const TrackHeader:React.FC<{
  items:ITrsDetails
}> = ({items}) =>{
  const [item, setItem] = useState<ITrsDetail>(items.table[0]);
  useEffect(()=>{
    console.log('TrackHeader');
    setItem(items.table[0])
  },[items])

  return (
    <View style={{flexDirection:'row',paddingHorizontal:5}}>
      <View style={{flex:1,flexDirection:'row',borderColor:'#5A5A5A'}}>
        <TitlePanel title='접수일' data={item.req_dat.substring(0, 4) + '-' + item.req_dat.substring(4, 6) + '-' + item.req_dat.substring(6, 8)} flex={2}/>
        <TitlePanel title='구분' data={item.req_tpn}/>
        <TitlePanel title='접수번호' data={item.track_no} flex={2}/>
        <TitlePanel title='진행' data={item.track_stepn}/>
      </View>
    
    </View>
  )
}

export const TrackLine:React.FC<{
  height?:number
  color?:string
}> =(props)=>{
  if ( props.height )
    return <View style={{backgroundColor : props.color ?? '#E2E2E2', height:props.height, borderRadius: 3}}></View>
  return <View style={{backgroundColor : props.color ?? '#E2E2E2', height:1, borderRadius: 3}}></View>
}

export const TrackText:React.FC<{
  title:string,
  data:string,
  borderRadius?:number,
  borderWidth?:number,
  borderColor?:string,
}> =({title, data, borderRadius, borderWidth, borderColor})=>(
  <View style={{borderRadius:borderRadius??50,borderWidth:borderWidth??1,borderColor:borderColor??'#D9D9D9'}}>
    <TitlePanelDetailRow title={title} data={data} titleflex={2} dataflex= {4} panelflex={0}/>
  </View>
)

export const TrackInText:React.FC<{
  title:string,
  data:string,
  height?:number,
  borderRadius?:number,
  borderWidth?:number,
  borderColor?:string,
  onPress?:()=>void,
  iconName?:any,
}> =({title, data, borderRadius, borderWidth, borderColor, onPress, iconName})=>(
  
  <View style={{ flex:1, flexDirection:'row', 
                 justifyContent:'space-between', alignItems:'center',
                 borderRadius:borderRadius??50,borderWidth:borderWidth??1,borderColor:borderColor??'#D9D9D9'
                }}>
    <View style={{flex:1}}>
      <TitlePanelDetailRow title={title} data={data} titleflex={2} dataflex= {4} panelflex={0}/>
    </View>
    {
      /*
    <View style={{ width:30,height:30,borderRadius:20
                  ,justifyContent:'center', alignItems:'center',marginRight:5}}>
      <TouchableOpacity style={{justifyContent:'center', alignItems:'center'}}
                        activeOpacity={0.5}
                        onPress={onPress}>
          <FontAwesome name={iconName??"exchange"} size={24} color={COLOR.ICONCOLOR} />
      </TouchableOpacity>
    </View>
      */
  }
  </View>
)


export const TrackCombo:React.FC<{
  title:string,
  data:string,
  class_cd:string,
  onSelect:(key:string)=>void,
}> =({title, data, class_cd, onSelect})=>(
  <View>
    <TrackLine />
    <TitlePanelSelectList
        title={title} 
        data={data} 
        class_cd={class_cd} 
        onSelect={onSelect}
        titleflex={1} 
        dataflex= {3} 
        panelflex={0}
        />
  </View>
)

export const TrackSubContent:React.FC<{
  items:ITrsDetails,
}> = ({items}) =>{
  const loginState = useLoginState();
  const [item, setItem] = useState<ITrsDetail>(items.table[0]);
  const user_tp = loginState.user_tp;
  useEffect(()=>{
    console.log('TrackHeader');
    setItem(items.table[0])
  },[items])

  return (
      <View style={{margin:10,
                    borderColor:'#D9D9D9', borderWidth:0.5, borderRadius:10}}>
        <View style={{flexDirection:'row', 
                        borderBottomColor:COLOR.GRAY_2, borderBottomWidth:0.5
                      }}>
            <TitlePanelDetailRow title='Site' data={item.site_nm}/>
            <TitlePanelDetailRow title='Alias' data={item.alias}/>
        </View>
        <View  style={{flexDirection:'row',
                        borderBottomColor:COLOR.GRAY_2, borderBottomWidth:0.5
                      }}>
            <TitlePanelDetailRow title='Camp' data={item.camp_cd}/>
            <TitlePanelDetailRow title='신청자' data={item.camp_nm}/>
        </View>
        <View style={{flex:1,paddingBottom:2 }}>
          <Spacer space={5} />
          <TitlePanelDetailRow title='Addr' data={item.addr} panelflex={1} dataflex={3}/>
          <Spacer space={5} />
        </View>
      </View>
  )
}

// 센타 
export const TrackContent:React.FC<{
  items:ITrsDetails,
}> = ({items}) =>{
  const loginState = useLoginState();
  const [item, setItem] = useState<ITrsDetail>(items.table[0]);
  const user_tp = loginState.user_tp;
  useEffect(()=>{
    console.log('TrackContent');
    setItem(items.table[0])
  },[items])

  return (
    <View style={{flex:1,}}>
      <TrackSubContent items={items} />
      <View style={{ marginHorizontal: 10,}}>
          <TrackInText title='접수방식' 
                        data={item.req_comp_nm + '  ' + item.req_tr_no} 
                        borderRadius={10} 
                        height={60}
                        onPress={()=>{}}
                        />
      </View>
      <Spacer space={10} />
      {
        (item.track_step ==='END' )&&
          <View style={{ marginHorizontal: 10,}}>
            <TrackInText title='출고방식' 
                          data={item.res_comp_nm + '  ' + item.res_tr_no} 
                          borderRadius={10} 
                          height={60}
                          onPress={()=>{}}
                          />
            <Spacer space={10} />
          </View>
      }

      <View style={{ marginHorizontal: 10,}}>
          <TrackText title='Serial Number' data={item.prod_cd + '  ' + item.serial_no} borderRadius={10}  />
      </View>
      <Spacer space={10} />
      {
        (user_tp === 'U01') &&
        <View style={{marginHorizontal:10,}}>
          <TrackText title='Ticket Number' data={item.ticket_no} borderRadius={10} />
          <Spacer space={10} />
        </View>
      }
      {
        (item.track_step !== '00' && item.track_step!== '01')&&
        <View style={{flexDirection:'row'}}>
          <TitlePanelDetailRow title='A/S담당' data={item.track_nm}/>
          <TitlePanelDetailRow title='유/무상' data={item.cost_typen + ' ' +  item.cost}/>
          <TrackLine />
        </View>
      }
    </View>
  )
}

  // 상단의 TitleBar  
export const TrackingDTitle:React.FC<{title:string, isAsta?:Boolean}>=({title, isAsta})=>{
    return (
      <View style={{borderColor:COLOR.GRAY_2, borderWidth:0.7, borderRadius:10, 
                    height:40, 
                    alignItems:'center',
                    backgroundColor:COLOR.ICONCOLOR,
                    }}>
        <View style={{paddingLeft:10,
                      flexDirection: "row", 
                      alignItems:'center',
                      justifyContent:"flex-start",
                      flex:1}}>
          <Text style={{color:'white',fontFamily:'bold', fontSize:15}}>{title}</Text>
        </View>
      </View>
      )
  }

// Tracking
export const TrackingResult:React.FC<{
  items:ITrsDetails
}> = ({items}) =>{
  const loginState = useLoginState();
  if ( items.totals > 0 ){
    const [item, setItem] = useState<ITrsDetail>(items.table[0]);
    const [isDefectShow, setIsDefectShow] = useState({isRep01:true, isRep02:true, isRep03:true});
    useEffect(()=>{
      console.log('TrackingResult');
      setItem(items.table[0])
    },[items])
  
    return (
        <View style={{marginHorizontal:10}}>
          
          {/** 고객사 기준 입력 장애 */}
          {
            !(items.table[0].rep_01 === 0 )&&
            <DefectView track_no={items.table[0].track_no}  
                        det_seq={items.table[0].det_seq}
                        rep_type={'01'} 
                        isSync={isDefectShow.isRep01}
                        onSync={()=>{
                          setIsDefectShow({...isDefectShow, isRep01:!isDefectShow.isRep01})}} />      
          }
          {
            (items.table[0].rep_01 === 0 )&&<TrackingDTitle title={'장애증상'} />
          }
          {
            (items.table[0].rep_01 > 0 )&&
            <View style={{width:'100%', paddingHorizontal:10,}}>
              
            
              <View style={{ width:'100%', backgroundColor:COLOR.INPUTBORDER, borderBottomLeftRadius:10, borderBottomRightRadius:10,padding:10,}}>
              <Text style={{fontSize:16,paddingHorizontal:10}}>{items.table[0].rep_det01}</Text>
            </View>
                        
            </View>        
          }

          <Spacer space={10} />
          {
            !(items.table[0].rep_02 === 0 )&&
              <DefectView track_no={items.table[0].track_no}  
                det_seq={items.table[0].det_seq}
                rep_type={'02'} 
                isSync={isDefectShow.isRep02}
                onSync={()=>{
                        setIsDefectShow({...isDefectShow, isRep02:!isDefectShow.isRep02})}} />      
          }
          {
            (items.table[0].rep_02 > 0 )&&
            <View style={{width:'100%', paddingHorizontal:10,}}>
              
            
              <View style={{ width:'100%', backgroundColor:COLOR.INPUTBORDER, borderBottomLeftRadius:10, borderBottomRightRadius:10,padding:10,}}>
              <Text style={{fontSize:16,paddingHorizontal:10}}>{items.table[0].rep_det02}</Text>
            </View>
                        
            </View>        
          }


          {
            (items.table[0].rep_02 === 0 ) && 
            (loginState.user_tp === 'U02') &&
            <TrackingDTitle title={'장애판정 대기중입니다...'} />
          }


          <Spacer space={10} />
          {
            !(items.table[0].rep_03 === 0 )&&
            (loginState.user_tp === 'U02') &&
            <DefectView track_no={items.table[0].track_no}  
                        det_seq={items.table[0].det_seq}
                        rep_type={'03'} 
                        isSync={isDefectShow.isRep03}
                        onSync={()=>{
                          setIsDefectShow({...isDefectShow, isRep03:!isDefectShow.isRep03})}} />      
          }
          {
            (items.table[0].rep_03 > 0 )&&
            (loginState.user_tp === 'U02') &&
            <View style={{width:'100%', paddingHorizontal:10,}}>
              <View style={{ width:'100%', backgroundColor:COLOR.INPUTBORDER, borderBottomLeftRadius:10, borderBottomRightRadius:10,padding:10,}}>
              <Text style={{fontSize:16,paddingHorizontal:10}}>{items.table[0].rep_det03}</Text>
            </View>
                        
            </View>        
          }

          {
            (items.table[0].rep_03 === 0 )&&
            (loginState.user_tp === 'U02') &&
            <TrackingDTitle title={'서비스 결과 대기중입니다...'} />
          }
        </View>
      )
  }
  else{
    console.log("TrackResult2.................................");
    return (
      <View> <Text>정보를 불러오지 못했습니다</Text></View>
    )

  }
}

