import React, {useEffect, useState, useRef, useCallback} from 'react';
import { 
  View, Text, TouchableOpacity, StyleSheet,
  FlatList,ListRenderItem, Platform, 
} from "react-native";
import * as ApiCmd from '../../datas/TrsApiType';
import * as DataType from '../../datas/TrsDataType'
import { FindDefault, IFindInfo } from '../../datas/TDataFind';
import { 
  GridHeader, HeaderColumn, TextColumn, 
  ButtonColumn, GridExcelHeader, HeaderColumn2, HeaderButtonColumn 
} from './GridHeader';
import { COLOR } from '../../utils/color';
import { Loading } from '../../components/Loading';
import { useLoginState } from '../../contexts/LoginContext';
import {  enddataSort, reqdataSort } from '../../utils/datasort';
import AsyncStorage from "@react-native-async-storage/async-storage";
import dayjs from 'dayjs';

export interface IGridSelect  {
   inner_key:number;
   track_no: string;
   track_step: string;
   loc_sts:string;
   det_seq : number;
   req_tp:string;
   serial:string;
   user_tp:string;
   user_cd:string;
   class_cd:string;
   code_cd: string;
   value: string;
   action:string;
}

export const defaultSelect = {
  inner_key:0,
  track_no:'',
  track_step:'',
  loc_sts:'',
  det_seq :0,
  req_tp:'',
  serial:'',
  user_tp:'',
  user_cd:'',
  class_cd:'',
  code_cd: '',
  value:'',
  action:'',
}

export const DataGrid : React.FC <{
  url:string,
  title:string,
  item:IFindInfo,
  onSelectData:(value:IGridSelect)=>void,
  isSync?:boolean,
  onEnd?:()=>void,
  onExcel:(find:IFindInfo)=>void,
}>=(props)=>{
  const loginState = useLoginState();

  const [run, setRun] = useState(ApiCmd.start);
  const [datas, setData] = useState<DataType.ITrsLists>();
  const [databs, setDataB] = useState<DataType.ITrsBLists>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [endSortOrder, setEndSortOrder] = useState("sort-up");
  const [reqSortOrder, setReqSortOrder] = useState("sort-asc");
  const action = props.item.action;
  const BORDER = COLOR.BORDER 

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const state = async ()=>{
    setLoading(true);
    const url =  ApiCmd.default_url.concat( props.url);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(props.item),
    });
    const res = await apiResponse.json();
    switch( action){
      case ApiCmd.action_trsalist:
      case ApiCmd.action_trslist:
      case ApiCmd.action_reclist:
          setData(res);
        break;
      case ApiCmd.action_trsblist:
        setDataB(res);
        break;
    }
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
    props.onEnd&& props.onEnd();
  }

  const sort = async (sortKey:string)=>{
    console.log('grid........22222..', sortKey);
    if (!datas) return;
    setLoading(true);
    if ( sortKey === "req_dat") {
      console.log('grid..........1111', reqSortOrder);
      const tmp = await reqdataSort(datas, sortKey, reqSortOrder);
      setData(tmp);
      setReqSortOrder(reqSortOrder === "sort-asc" ? "sort-desc" : "sort-asc" )
    }
    else{
      console.log('grid..........222', endSortOrder);
      const tmp = await enddataSort(datas, sortKey, endSortOrder);
      setData(tmp);
      setEndSortOrder(endSortOrder === "sort-asc" ? "sort-desc" : "sort-asc" )
    }
    setLoading(false);
  }
  
  useEffect(() => {
    if ( props.isSync === true ){
      recordStartTime();
      state();
    } 
  }, [props.isSync]);

  const onSync = useCallback(() => {
    recordStartTime();
    state();
  },[props.item] );

  const onSort= useCallback((sortType:string) => {
    recordStartTime();
    console.log('onSort Click');
    if ( loading === false) sort(sortType);
  },[reqSortOrder, endSortOrder, loading] );

  const flatListRef = useRef(null);

  const ListHeaderComponent:React.FC<{}>=()=>{
      switch( action ) {
        case ApiCmd.action_trsalist:
        case ApiCmd.action_trslist:
            return(
            <View style={{flexDirection:"row", height:50}}>
              <HeaderColumn title='Site'  />
              <HeaderButtonColumn title='접수일' 
                                  flex={1.5} 
                                  onData={() => onSort("req_dat")} 
                                  iconName={ reqSortOrder ==="sort-asc"?"caretup":"caretdown"}
                                  />
              {
                Platform.OS === 'web' &&
                <View style={{flex:4, flexDirection:'row'}}>
                  <HeaderColumn title='접수번호' flex={1}/>
                  <HeaderColumn title='시리얼' flex={1}/>
                </View>
              }                                  
              {
                Platform.OS !== 'web'&&
                <HeaderColumn title='접수번호 / Serial' flex={3}/>
              }                                  
              <HeaderColumn title='타입' />
              <HeaderColumn title='상태' />
            </View>
          )
        case ApiCmd.action_reclist:
          return(
            <View style={{flexDirection:"row", height:70}}>
              <HeaderColumn title='Site'  />
              <HeaderButtonColumn title='접수일' 
                                  flex={1.5} 
                                  onData={() => onSort("req_dat")} 
                                  iconName={ reqSortOrder ==="sort-asc"?"caretup":"caretdown"}
                                  />
              <HeaderButtonColumn title='완료일' 
                                  flex={1.5} 
                                  onData={() => onSort("end_dat")} 
                                  iconName={ endSortOrder ==="sort-asc"?"caretup":"caretdown"}
                                  />
              {
                Platform.OS === 'web' &&
                <View style={{flex:4, flexDirection:'row'}}>
                  <HeaderColumn title='접수번호' flex={1}/>
                  <HeaderColumn title='시리얼' flex={1}/>
                </View>
              }                                  
              {
                Platform.OS !== 'web'&&
                <HeaderColumn title='접수번호 / Serial' flex={3}/>
              }                                  
              <HeaderColumn title='타입' />
              <HeaderColumn title='상태' />
            </View>
          )
        case ApiCmd.action_trsblist:
          return(
            <View style={{flexDirection:"row", height:50}}>
              <HeaderColumn title='번호'/>
              <HeaderColumn title='제품'/>
              <HeaderColumn title='시리얼'/>
              <HeaderColumn title='타입'/>
              <HeaderColumn title='진행상태'/>
            </View>
          )   
      }
      return (<View></View>)
  }

  const onSelectData = useCallback((value:DataType.ITrsList, paction:string) => {
    const selectedData = {
      ...defaultSelect,
      track_no:value.track_no,
      track_step:value.track_step,
      serial_no:value.serial_no,
      loc_sts:props.item.loc_sts,
      req_tp : value.req_type,
      det_seq: value.det_seq,
      action: paction,
    }

    props.onSelectData(selectedData);
  },[props.item] );

  const onSelectBData = useCallback((value:DataType.ITrsBList) => {
    const selectedData = {
      ...defaultSelect,
      track_no:value.track_no,
      det_seq: value.det_seq,
      req_tp : value.req_type,
    }
    props.onSelectData(selectedData)
  },[] );


  const renderItem:ListRenderItem<DataType.ITrsList>=({item,index})=>{
    const yy=item.req_dat.substring(2, 4)
    const mm=item.req_dat.substring(4, 6)
    const dd=item.req_dat.substring(6, 8)
    const eyy=(item.end_dat&&item.end_dat.length === 8) ? item.end_dat.substring(2, 4):'';
    const emm=(item.end_dat&&item.end_dat.length === 8) ? item.end_dat.substring(4, 6):'';
    const edd=(item.end_dat&&item.end_dat.length === 8) ? item.end_dat.substring(6, 8):'';
    const fontSize = 13;
    if (action === ApiCmd.action_reclist)
        return(
          <View style={{ height:50,}}>
            <View style={{flexDirection:"row",}}>
              <TextColumn value={(item.camp_cd === undefined)?item.site_cd  :item.site_cd+ '/' + item.camp_cd} pfontSize={14}/>
              <TextColumn value={yy + `-` + mm + `-` + dd}  flex={1.5}  pfontSize={14}/>
              <TextColumn value={eyy + `-` + emm + `-` + edd}  flex={1.5}  pfontSize={14}/>
              {
                Platform.OS === 'web' &&
                <View style={{flex:Platform.OS === 'web'?4:3, flexDirection:'row'}}>
                  <ButtonColumn value={item.track_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'track')} 
                                />

                  <ButtonColumn value={item.serial_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'serial')} 
                                color={COLOR.GREEN_1}
                                />                            
                </View>
              }     
              {
                Platform.OS !== 'web' &&
                <View style={{flex:3}}>
                  <ButtonColumn value={item.track_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'track')} 
                                />

                  <ButtonColumn value={item.serial_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'serial')} 
                                color={COLOR.GREEN_1}
                                />                            
                </View>
              }     
              <TextColumn value={item.req_tpn} pfontSize={14} />
              <TextColumn value={item.track_stepn}  pfontSize={14}/>
            </View>
            <View style={{backgroundColor: BORDER, height:1}}></View>
          </View>
        );

    if (loginState.user_tp === 'U02' &&  item.rma && item.rma.length > 0 &&
        (item.loc_sts === '01' ||  item.loc_sts === '02' ) 
       ) {        
      return(
        <View style={{ height:Platform.OS === 'web'?60:80,}}>
          <View style={{flexDirection:"row",}}>
            <TextColumn value={(item.camp_cd === undefined)?item.site_cd  :item.site_cd+ '/' + item.camp_cd}/>
            <TextColumn value={yy + `-` + mm + `-` + dd}  flex={1.5}/>
            {
                Platform.OS === 'web' &&
                <View style={{flex:Platform.OS === 'web'?4:3, flexDirection:'row'}}>
                  <View style={{flex:1}}>
                  <ButtonColumn value={item.track_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'track')} 
                                />
                  <TextColumn value={item.rma} />
                  </View>

                  <ButtonColumn value={item.serial_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'serial')} 
                                color={COLOR.GREEN_1}
                                />         
                </View>
              }     
              {
                Platform.OS !== 'web' &&
                <View style={{flex:3}}>            
                  <ButtonColumn value={item.track_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'track')} 
                                />
                  <ButtonColumn value={item.serial_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'serial')} 
                                color={COLOR.GREEN_1}
                                />
                  <TextColumn value={item.rma} />
                </View>
             }
            <TextColumn value={item.req_tpn} />
            <TextColumn value={item.track_stepn} />
          </View>
          <View style={{backgroundColor: BORDER, height:1}}></View>
        </View>
      );
    }

    return(
      <View style={{ height:50,}}>
        <View style={{flexDirection:"row",}}>
          <TextColumn value={(item.camp_cd === undefined)?item.site_cd  :item.site_cd+ '/' + item.camp_cd}/>
          <TextColumn value={yy + `-` + mm + `-` + dd}  flex={1.5}/>
          {
                Platform.OS === 'web' &&
                <View style={{flex:Platform.OS === 'web'?4:3, flexDirection:'row'}}>
                  <ButtonColumn value={item.track_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'track')} 
                                />

                  <ButtonColumn value={item.serial_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'serial')} 
                                color={COLOR.GREEN_1}
                                />                            
                </View>
              }     
              {
                Platform.OS !== 'web' &&
                <View style={{flex:3}}>
                  <ButtonColumn value={item.track_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'track')} 
                                />

                  <ButtonColumn value={item.serial_no} 
                                index={index}  
                                onData={()=>onSelectData(item, 'serial')} 
                                color={COLOR.GREEN_1}
                                />                            
                </View>
              }     

             
          <TextColumn value={item.req_tpn} />
          <TextColumn value={item.track_stepn} />
        </View>
        <View style={{backgroundColor: BORDER, height:1}}></View>
      </View>
    );
  }
  const renderBItem:ListRenderItem<DataType.ITrsBList>=({item,index})=>{
    console.log('item.....loc_sts', item.loc_sts);
    if ( item.loc_sts === '02'){
      return(
        <View style={{ height:80,}}>
          <View style={{flexDirection:"row",}}>
            <TextColumn value={item.camp_cd} />
            <TextColumn value={item.prod_cd} />
            <View style={{flex:1}}>
              <ButtonColumn value={item.serial_no}  index={index} onData={()=>onSelectBData(item)} />
              <TextColumn value={item.rma}  />
            </View>
            <TextColumn value={item.req_tpn} />
            <TextColumn value={item.track_stepn} />
          </View>
          <View style={{backgroundColor: BORDER, height:1}}></View>
        </View> 
      );
  
    }
    return(
      <View style={{ height:50,}}>
        <View style={{flexDirection:"row",}}>
          <TextColumn value={item.camp_cd} />
          <TextColumn value={item.prod_cd} />
          <ButtonColumn value={item.serial_no}  index={index} onData={()=>onSelectBData(item)} />
          <TextColumn value={item.req_tpn} />
          <TextColumn value={item.track_stepn} />
        </View>
        <View style={{backgroundColor: BORDER, height:1}}></View>
      </View> 
    );
  }

  if (loading) return <Loading message={'로딩중..'}/>
  if (error) return  <View><Text>Error Occured: </Text></View>

  switch( action ){
    case ApiCmd.action_trsalist:
    case ApiCmd.action_trslist:
        if (!datas){
        return (
          <View style={{flex:1}}>
            <GridHeader title={props.title+`${0} 건`} onSync={onSync} />
          </View>

        )
      }
      return (
        <View style={{flex:1}}>
          {
            (Platform.OS === 'web') &&
            <GridExcelHeader title={props.title+` ( ${datas?.totals} ) 건`} onSync={onSync} onExcel={()=>{props.onExcel(props.item)} } />
          }      
          {
            (Platform.OS !== 'web') &&
            <GridHeader title={props.title+` ( ${datas?.totals} ) 건`} onSync={onSync}  />
          }

          <View style={{flex:1}}>
            <View style={{flex:1}}>
                <FlatList 
                  ref={flatListRef}
                  data={datas?.table}
                  renderItem={renderItem}
                  ListHeaderComponent={ListHeaderComponent}
                  showsVerticalScrollIndicator={false}
                />      
            </View>
          </View>
       </View>
      );
    case ApiCmd.action_reclist:
      if (!datas){
        return (
          <View style={{flex:1}}>
            <GridHeader title={props.title+`${0} 건`} onSync={onSync} />
          </View>

        )
      }

      return (
        <View style={{flex:1}}>
          {
            (Platform.OS === 'web') &&
            <GridExcelHeader 
                 title={props.title+` ( ${datas?.totals} ) 건`} 
                 onSync={onSync} 
                 onExcel={()=>{props.onExcel(props.item)} } />
          }      
          {
            (Platform.OS !== 'web') &&
            <GridHeader title={props.title+` ( ${datas?.totals} ) 건`} onSync={onSync}  />
          }


          <View style={{flex:1}}>
            <View style={{flex:1}}>
                <FlatList 
                  ref={flatListRef}
                  data={datas?.table}
                  renderItem={renderItem}
                  ListHeaderComponent={ListHeaderComponent}
                  showsVerticalScrollIndicator={false}
                />      
            </View>
          </View>
       </View>
      );

    case ApiCmd.action_trsblist:
      if (!databs)
        <GridHeader title={props.title+`${0} 건`} onSync={onSync} />
      return (
        <View style={{flex:1}}>
          {
            //(Platform.OS === 'web') &&
            //<GridExcelHeader title={props.title+` ( ${databs?.totals} ) 건`} onSync={onSync} onExcel={()=>{props.onExcel(props.item)} } />
          }      
          {
            //(Platform.OS !== 'web') &&
            <GridHeader title={props.title+` ( ${databs?.totals} ) 건`} onSync={onSync}  />
          }

          <View style={{flex:1}}>
              <FlatList 
                ref={flatListRef}
                data={databs?.table}
                renderItem={renderBItem}
                ListHeaderComponent={ListHeaderComponent}
                showsVerticalScrollIndicator={false}
              />      
          </View>
        </View>
      );
  }
  return <View><Text>action type error!</Text></View>
}

