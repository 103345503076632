import React, { useEffect, useRef, useState,useCallback } from "react";
import { 
  Text, 
  View ,
  TouchableOpacity,
  Platform,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import * as ApiCmd from '../../datas/TrsApiType';
import { SimpleLineIcons,MaterialIcons } from '@expo/vector-icons'; 
import { COLOR } from '../../utils/color';
import { InputCheck } from "./InputComponent";
import { DefaultCheckData, getCheckSection, getChecksSection,  } from "../../utils/data";
import { NoflexTypography } from "../../components/custom/NoflexTypography";
import { Typography } from "../../components/Typography";
import { Spacer } from "../../components/Spacer";
import { useRepairSync} from "../../hooks/use-Input";
import { useReqDispatch, useReqState } from "../../contexts/ReqContext";
import { ICheckRepair, IRepair, IRepairs, ISelectCode } from "../../datas/TrsDataDetailType";
import { CheckColumn, TextColumn } from "./GridHeader";
import { FindDefault } from "../../datas/TDataFind";
import { TrackLine } from "./TrsComponent";

type typRepair = IRepair[];
type typRepairs = IRepairs;

// 서버에서 장애 종류를 수신받아 화면에 표시 
export const DefectList:React.FC<{
  rep_type:string,
  track_no?:string, 
  det_seq?:number,
  view_type?:string,
  width?:number,
  height?:number,
  isSync?:boolean,
  onSync?:()=>void,
  newAction?:string,
}>=({track_no, det_seq, rep_type, view_type,width, isSync, onSync,height, newAction})=>{
  const reqStatus = useReqState();
  const dispatch = useReqDispatch(); // Req Context Dispatch

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [table, setTable] = useState<typRepair>([]);
  const [sections, setSections] = useState([{...DefaultCheckData}]);

  const sAction=newAction?? 'repair_req';
  const fontSize = (Platform.OS === 'web')? 18: 15
  const column =  (Platform.OS === 'web')? 2: 2
  const [find, setFind] = useState({
    ...FindDefault, action:sAction,
    track_no:track_no??"", 
    det_seq:det_seq??0, 
    rep_type:rep_type,}) ;

  

  const ApiSync = async ()=>{
    setLoading(true);
    console.log('DefectList  DataSend.....', find);

    const apiResponse = await fetch(ApiCmd.default_url.concat(ApiCmd.api_GetTrsDetail),{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(find),
    });
    const res = await apiResponse.json();
    const conv = await getChecksSection(res); // src/utils/data.ts
    setSections(conv); 
    setLoading(false);
    onSync&&onSync();
    dispatch({ type: 'SET_SEC', secs:conv, });
  }
  useEffect(() => { 
    if (isSync === true){
      ApiSync();
    } 
  }, [isSync, ]);

  

  const onItemChecked=(e:ISelectCode, keymap:string, rep_type:string )=>{
    //const keymap = e.key.substring(0, 3);
    const newSection = sections.map(rep => {
      if (rep.rep_cls !== keymap) return rep;
      return{
        ...rep,
        selected:true,
        data:rep.data.map(item => {
          if ( item.key !== e.key) return item;
          console.log('item checked', e.checked)
          return {
            ...item,
            checked:!e.checked
          }
       })
      } 
    });
    setSections(newSection);
    dispatch({ type: 'SET_SEC', secs:newSection });
  }
  if ( loading ) return (
      <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
       <ActivityIndicator size='large' color='#ABF200'/>
          <Spacer space={10}/>  
          <Text style={{color:'#666666', fontSize:14 , fontWeight:'bold'}}> loading.. </Text>
      </View>
    );
  
  const RepCheckedItem:React.FC<{
    item:ISelectCode, 
  }>=(item)=>{
    const [checked, setChecked] = useState(item.item.checked);
    const onChecked=useCallback((key:string, checked:boolean)=>{
      setChecked(!checked);
      onItemChecked(item.item, item.item.sub_cd1, rep_type);
    },[reqStatus, checked, item]);

    return(
      <View style={{flexDirection:"row",justifyContent:'flex-start', alignItems:'center' ,width:'100%',height:40}}>
        <View style={{flex:1,justifyContent:'center', alignItems:'center'}}>
        <CheckColumn value={checked} 
                      key={item.item.key} 
                      onChecked={onChecked} />
        </View>
        <View style={{flex:4, width:'100%',justifyContent:'flex-start',alignItems:'center' }}>
            <Text style={{ fontSize: fontSize,
                          justifyContent:'flex-start',
                          alignItems:'center',
                          color:'#595959'}}  key={ item.item.key}>{item.item.value}</Text>
        </View>
      </View>
    )
  }

  const RepSubItemView:React.FC<{
    data:ICheckRepair,
  }> =({data})=>{
    useEffect(()=>{
    }, [reqStatus])
    return (
      <View style={{
        alignItems:'flex-start',justifyContent:'center' , width:'100%'
        }}>
        {
          data.data.map((item, i) => {
             return ( 
              <RepCheckedItem item={item} key={`${i}` + item.key}/>
             );
        })}
      </View>
    );
  }

  const RepSelectCombo:React.FC<{
    item:ICheckRepair
    index:string
  }>=(item)=>{
    const [selected, setSelected] = useState(item.item.selected);
    const [visible, setVisible] = useState(true);
    const onPress=useCallback(()=>{
      setSelected(!selected),
      setVisible(!visible)
    },[selected,visible]);
    
    return (
      <View>
        <View style={{ justifyContent:'space-between'}}>
          <View style={{ flexDirection:'row', borderBottomWidth:1,
                        borderBottomColor:COLOR.BORDER,
                        }}>
              <View style={{ 
                            flex:1, 
                            justifyContent:'center', 
                            alignItems:'flex-start',
                            marginLeft:30
                          }}>
                <Text style={{fontSize:fontSize,color:'#6B6B6B'}}> {item.item.rep_clsn}</Text>
              </View>
              <View style={{ 
                            width:40,height:40,borderRadius:20,
                            justifyContent:'center', 
                            alignItems:'center',
                            marginRight:10
                          }}>

                <TouchableOpacity
                  style={{justifyContent:'center', alignItems:'center'}}
                  activeOpacity={0.5}
                  onPress={onPress}>
                  <MaterialIcons name={selected? "keyboard-arrow-up":"keyboard-arrow-down"} 
                                size={18} color='#6B6B6B' />
                </TouchableOpacity>
              </View>
          </View>
        </View>
      {
        /* Sub Check List Show Hide*/
        selected && <View style={{marginLeft:10, alignItems:'flex-start',justifyContent:'flex-start' }}>
            <RepSubItemView data={item.item}    />
         </View>
      }
    </View>
    )
  }

  /** view_type === 'VIEW' 일 경우  */
  const RepItemView:React.FC<{
    sections:ICheckRepair[],
    }>=({sections})=>{
      
    return (
      <View style={{flex:1}}>
        {
          sections.map((item, i) => {
            return (
              <RepSelectCombo item={item} index={`${i}`+item.rep_cls} key={`${i}`+item.rep_cls}/>
            );
          })}
      </View>
    );
  }

  // 상단의 TitleBar  
  const DefectTitle:React.FC<{title:string, isAsta?:Boolean}>=({title, isAsta})=>{
    return (
      <View style={{paddingLeft:10,
                    flexDirection: "row", 
                    justifyContent:"flex-start"   }}>
                    <NoflexTypography color='#7F7F7F' fontSize={fontSize}>{title}</NoflexTypography>
                    { isAsta&&<Typography color='red' fontSize={fontSize} > * </Typography>}
      </View>
     )
  }

  console.log('width, height', width, height)
  
  return (
    <View style={{ width:width, height:height,}}>
      <Spacer space={5}/>
      { (rep_type ==='01')&& <DefectTitle title='장애유형을 선택해주세요' isAsta/> }
      { (rep_type ==='02')&& <DefectTitle title='장애판정' /> }
      { (rep_type ==='03')&& <DefectTitle title='결과' /> }
      <Spacer space={5}/>
      <View
       style={{ borderColor : '#D9D9D9',          
                borderWidth : 1 ,          
                borderRadius: 5,  
                }}
      >
        <Spacer space={5}/>
        <View style={{flex:1}}>
           <RepItemView sections={sections} />
        </View>
        <Spacer space={15}/>
      </View>
      
    </View>
  )
}