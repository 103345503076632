import React, {useCallback, useState,useEffect,} from 'react';
import {View,Text, ScrollView, KeyboardAvoidingView, Modal, useWindowDimensions, Platform, SafeAreaView} from 'react-native';
import { useLoginState } from '../../contexts/LoginContext';
import { IFindInfo } from '../../datas/TDataFind';
import { DefaultUser, IUserInfo, IUsersInfo } from '../../datas/TrsDataType';
import { useTrsNavigation, useTrsRoute } from '../../navigations/StackParamNavi';
import { HeaderTop } from '../components/HeaderTop';
import * as ApiCmd from '../../datas/TrsApiType';
import { useSize } from '../../hooks/use-Size';
import { Sync } from '../components/Sync';
import { IRltCode } from '../../datas/TSendData';
import { DefaultMsg, TrsMessageBox, TrsMessageShow } from '../components/TrsModal';
import { InputCheck, InputText, InputView } from '../components/InputComponent';
import { Spacer } from '../../components/Spacer';
import { Button } from '../../components/Button';
import { NoflexTypography } from '../../components/custom/NoflexTypography';
import { COLOR } from '../../utils/color';
import { minWinWidth } from '../../datas/Const';
import Checkbox from 'expo-checkbox';
//import crypto from "crypto";   // SHA256 Hash 를 생성하기 위한 
//import crypto from 'crypto-js'
import * as Crypto from 'expo-crypto';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

export const UserDetailScreen:React.FC = () =>{
  const route = useTrsRoute<'UserDetail'>();
  const navigation = useTrsNavigation<'UserDetail'>();
  const loginState = useLoginState();
  const [winWidth, winHeight, isHorizontal, menuFontSize] = useSize();
  const [input, setInput] = useState(DefaultUser);
  const [item, setItem] = useState<IFindInfo>(route.params.find);
  const [run, setRun] = useState(ApiCmd.start);
  const [sendData, setSendData] = useState<any>();
  const [command, setCommand] = useState('');
  const [apiname, setApiName] = useState('');
  const [msgInfo, setMsgInfo] = useState(DefaultMsg);
  const [msgShow, setMsgShow] = useState(DefaultMsg);
  const [isShow, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  const onPressBack = useCallback(() => {
    //if ( route.params.menuId === "TrsMain")
       navigation.goBack();
    //else navigation.reset({routes: [{name: "UserList"}]})
  },[] );

  const onPressReg = useCallback(() => {
    setMsgInfo({...DefaultMsg, msgId:'save', message:'변경된 사용자 정보를 저장하시겠습니까?'})
  },[msgInfo]); 

  const onPressDelete = useCallback(() => {
    setMsgInfo({...DefaultMsg, msgId:'delete', message:'사용자 계정을 삭제하시겠습니까?'})
  },[msgInfo]); 

  const onSave = useCallback(() => {
    const del_flg = checked? 'A':'D';
    console.log('onSave,,,,,,,', del_flg);
    setCommand('save');
    setApiName(ApiCmd.api_SetUser);
    setSendData({...input, action:'update', del_flg:del_flg});
  },[command, apiname, sendData, input, checked]); 

  const onDelete = useCallback(() => {
    console.log('onDelete,,,,,,,');
    setCommand('delete');
    setApiName(ApiCmd.api_SetUser);
    setSendData({...input, action:'delete'});
  },[command, apiname, sendData, input, checked]); 

  const onPasswd = useCallback(() => {
    console.log('onPasswd');
    setShow(true);
    // modal box Open 

  },[false] );

  const onChangePasswd = useCallback((text:string)=>{
    // SHA 256 Hash 생성
    //  const crypto = require('crypto');
    //  const hash = crypto.createHash("sha256").update(text).digest("hex");
    (async () => {
      const digest = await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256,
        text,
      );
      console.log('Digest: ', digest);
      /* Some crypto operation... */
      console.log('create hash[', digest, ']' )
      setCommand('passwd');
      setApiName(ApiCmd.api_SetUser);
      setSendData({...input, action:'passwd', passwd:digest});

    })();

  },[command, apiname, sendData, input]); 

  const onChangeText = useCallback((name:string, value:string)=>{
     if ( name === 'user_nm') setInput({...input, user_nm:value});
     if ( name === 'email') setInput({...input, email:value});
     if ( name === 'phone') setInput({...input, phone:value});
     if ( name === 'cmt') setInput({...input, cmt:value});
  }, [input])
 
  const onSubmit = useCallback((name:string, value:string)=>{
    if ( name === 'user_nm') {}
    if ( name === 'email') {}
    if ( name === 'phone') {}

  }, [input]) 

 
  // 시리얼정보검색 결과수신, A/S 신청 결과 수신
  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    console.log('onReceived ClientReg..............', command, resultCode, cmd, rData, )
    setApiName(''); setSendData(null);
    if (resultCode === false) {
      setMsgShow({...DefaultMsg, msgId:cmd, message:rData});
      return;
    }
    if ( command === 'item'){
      const tmp : IUsersInfo = rData;
      if ( tmp.totals > 0 ) {
        setInput({...input, 
                  del_flg :tmp.table[0].del_flg,
                  site_cd :tmp.table[0].site_cd,
                  site_nm :tmp.table[0].site_nm,
                  camp_cd :tmp.table[0].camp_cd,
                  user_tpn:tmp.table[0].user_tpn,
                  user_cd :tmp.table[0].user_cd, 
                  user_nm :tmp.table[0].user_nm,
                  email   :tmp.table[0].email,
                  phone   :tmp.table[0].phone,
                  cmt     :tmp.table[0].cmt,
                  reg_user:loginState.user.user_cd,
                  reg_uname:loginState.user.user_nm,
                  reg_ip:loginState.reg_ip,
                  reg_dev:loginState.reg_dev,
                });
         if ( tmp.table[0].del_flg === 'A' ) setChecked( true);
         else setChecked( false );
      }
    }
    else if (command === 'passwd'){
      const tmp:IRltCode = rData;
      
      if ( tmp.result_cd === "1"){
        setMsgShow({...DefaultMsg, msgId:command, message:'패스워드 변경이 완료되었습니다.'});
      }
      else {
        console.log('Message.. tmp.result_cd', tmp.result_cd);
        setMsgShow({...DefaultMsg, msgId:cmd, message:tmp.result_msg});
      } 
    }

    else if (command === 'delete'){
      const tmp:IRltCode = rData;
      
      if ( tmp.result_cd === "1"){
        setMsgShow({...DefaultMsg, msgId:'end', message:tmp.result_msg});
      }
      else {
        console.log('Message.. tmp.result_cd', tmp.result_cd);

        setMsgShow({...DefaultMsg, msgId:cmd, message:tmp.result_msg});
      } 

    }
    else {
      const tmp:IRltCode = rData;
      
      if ( tmp.result_cd === "1"){
        console.log('onReceive.. tmp.result_cd', tmp.result_cd);
        onSend('item');
      } 
      else {
        console.log('Message.. tmp.result_cd', tmp.result_cd);

        setMsgShow({...DefaultMsg, msgId:cmd, message:tmp.result_msg});
      } 
    }
  }, [msgShow, apiname, sendData, input, checked]);

  const onSend=useCallback((cmd:string)=>{
    
    setCommand(cmd);
    setApiName(ApiCmd.api_GetUser);
    setSendData(item);

  },[command, apiname, sendData]);

  const onValueChange=useCallback((value:boolean)=>{
    console.log('onValueChange', value);
    setChecked(value);

  },[input, checked]);

  useEffect(()=>{
    if ( run === ApiCmd.start ){
      setRun ( ApiCmd.stop );
      onSend('item');
    }
  },[run])

  // Password Modal
  const PasswordModal:React.FC=()=>{
   // const [modalVisible, setModalVisible] = useState(false);
    const [passwd, setPasswd] = useState('');
    const [passwdConfirm, setPasswdConfirm] = useState('');
    const [msg, setMsg] = useState('');
   // useEffect(()=>{
   //   if(isShow === true) setModalVisible(true);

   // }, [isShow])
   const width = useWindowDimensions().width;
   const height = useWindowDimensions().height;
 
   const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
   const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
   const innerWidth=width - (marginHorizontal * 2)
   const innerHeight= Platform.OS ==='web'? height/1.5 : height/1.5;
   const onPasswd=useCallback(()=>{

    var pattern1 = /[0-9]/; //숫자
    var pattern2 = /[a-zA-Z]/; //영어
    var pattern3 = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
    var pattern4 = /[~!@#\#$%<>^&*]/; //특수문자

    var num = pattern1.test(passwd);
    var alph = pattern2.test(passwd);
    var spec = pattern4.test(passwd);
    if ( !num){
      setMsgShow({...DefaultMsg, msgId:'user', 
                  message:'숫자 미포함', 
                  message2: '영숫자,특수문자로 조합 8자리 또는 이상 입력하세요' });
      return;
    }
    if ( !alph){
      setMsgShow({...DefaultMsg, msgId:'user', 
                  message:'영문자 미포함', 
                  message2: '영숫자,특수문자로 조합 8자리 또는 이상 입력하세요' });
      return;
    }
    if ( !spec){
      setMsgShow({...DefaultMsg, msgId:'user', 
                  message:'특수기호 미포함', 
                  message2: '영숫자,특수문자로 조합 8자리 또는 이상 입력하세요' });
      return;
    }

    if ( passwd.length < 8 ){
      setMsgShow({...DefaultMsg, msgId:'user', 
                  message:'8자리 미만입니다.', 
                  message2: '영숫자,특수문자로 조합 8자리 또는 이상 입력하세요' });
      return;
    }


    if ( passwd.length < 6){
         setMsgShow({...DefaultMsg, msgId:'pass', message:'패스워드는 5자리 이상이어야 합니다.'})
         return;
    }
    if ( passwd !== passwdConfirm){
      setMsgShow({...DefaultMsg, msgId:'pass', message:'패스워드 확인과 동일하지 않습니다. '})
      return;
    }
    setShow(false);
    onChangePasswd(passwd);
    },[passwd, passwdConfirm, msgShow, isShow]);

    return (
          
          <Modal
            animationType="fade"
            transparent={true}
            visible={isShow}
            onRequestClose={() => {
              setShow(!isShow);
            }}
            onShow={()=>{
            }}
            >
             
             <View style={{ paddingLeft:width< 1300 ? marginHorizontal : marginHorizontal,paddingRight:width< 1300 ? marginHorizontal : marginHorizontal/2,height:height,
               alignItems: 'center', justifyContent:'center',
               flexDirection:'row',}}>
            
                <View style={{flex:1,alignItems:'center',justifyContent:'flex-end',backgroundColor: 'rgba(0,0,0,0.35)',height:height}}>
                <KeyboardAvoidingView 
                  behavior="padding"
                  style = {{
                    width:'100%',
                    }}>
                <View style={{
                              width:'100%',
                              height:winHeight/2.5,
                              backgroundColor:'white',
                              borderTopLeftRadius:20,
                              borderTopRightRadius:20,
                              borderBottomColor:'#dddde2',borderBottomWidth:4
                              }}>
                    <View style={{flex:1, marginHorizontal:20}}>
                      <Spacer space={16} />      
                      <View style={{borderRadius:10, alignItems:'center', justifyContent:'center', height: 30}}>
                      <NoflexTypography fontSize={14} color={COLOR.RED_1} background={COLOR.WHITE}>영숫자,특수문자로 조합 8자리 또는 이상 입력하세요</NoflexTypography>
                      </View>
                      <Spacer space={16} />      

                      <InputText  title={'패스워드'} 
                            name={'passwd'}
                            value={passwd} 
                            keyboardType={'default'}
                            onChangeText={(text:string)=>setPasswd(text)}
                            onSubmit={(name:string, value:string)=>{}}
                            width={'100%'}
                            secureTextEntry={true}
                            />
                      <Spacer space={16} />      
                      <InputText  title={'패스워드 확인'} 
                            name={'passwdConfirm'}
                            value={passwdConfirm} 
                            onChangeText={(text:string)=>setPasswdConfirm(text)} 
                            onSubmit={(name:string, value:string)=>{}}
                            width={'100%'}
                            keyboardType={'default'}
                            secureTextEntry={true}
                            />

                    </View>
                  <View style={{flexDirection:'row', height: 60, alignItems:'flex-end',justifyContent:'space-around', marginBottom:30, marginHorizontal:20 }}>
                  <View style={{flex:1, marginHorizontal:10}}>
                        <Button onPress={onPasswd}>
                          <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                              <NoflexTypography fontSize={menuFontSize} color='#ffffff' background='#979b9e'>변경</NoflexTypography>
                          </View>
                        </Button>
                  </View>              

                  <View style={{flex:1, marginHorizontal:10}}>
                      <Button onPress={()=>{setShow(false)}}>
                          <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                              <NoflexTypography fontSize={menuFontSize} color='#ffffff' background='#979b9e'>취소</NoflexTypography>
                          </View>
                        </Button>
                  </View>              
                </View>
              </View>
              </KeyboardAvoidingView>
            </View>
            
            { width> 1300 ? 
            <View style={{width:500,height:height}}/>
            :<View/>
            }
            </View>
          </Modal>
    );
  }

  return (
    <View style={{flex:1, backgroundColor:'white' }}>
      <HeaderTop title="사용자 정보" 
                 isMain={false}
                 onPress={onPressBack} 
                 onHome={()=>{navigation.popToTop()}} 
                 />

      <ScrollView style={{marginHorizontal:26, }}>
        <Spacer space={30}/>
        <InputView title={'사용자 유형'} value={input.user_tpn} isTitle={true} />
        {
          (loginState.user.user_tp !== 'U0100' && loginState.user.user_tp !== 'U0200' )&&
          <View>
            <Spacer space={16}/>
            <InputView title={'CENTER'} value={input.site_nm} isTitle={true} />
            <Spacer space={16}/>
            <InputView title={'SITE'} value={input.camp_cd} isTitle={true} />
          </View>
        }
        <Spacer space={16}/>
        <InputView title={'사용자 ID'} value={input.user_cd}  isTitle={true} />
        <Spacer space={16}/>
        <InputText title={'사용자 명'} 
                    placeholder={'명칭를 입력하세요'} 
                    name='user_nm' 
                    value={input.user_nm} 
                    onChangeText={(text:string)=>{
                      onChangeText('user_nm', text); 
                    }}
                    onSubmit={onSubmit}  
                    keyboardType={'default'}
                    />
        <Spacer space={16}/>
        {
          /*
        <InputText  title={'이메일'} 
                    name={'email'}
                    value={input.email} 
                    onChangeText={
                      (value:string) =>{
                        setInput({...input, email:value}); 
                        console.log(input.email);
                      }} 
                    keyboardType={'email-address'}
                    />
        <Spacer space={16}/>
        <InputText  title={'연락처'} 
                    name={'phone'}
                    value={input.phone} 
                    onChangeText={
                      (value:string) =>{
                        setInput({...input, phone:value}); 
                        console.log(input.phone);
                    }} 
                    keyboardType={'phone-pad'}
                    />
 
        <Spacer space={16}/>
        <InputText  title={'주소'} 
                    name={'cmt'}
                    value={input.cmt} 
                    onChangeText={
                      (value:string) =>{
                        setInput({...input, cmt:value}); 
                        console.log(input.cmt);
                    }} 
                    keyboardType={'default'}
                    />
             */
                  }
        <Spacer space={16}/>                    
        {
          (loginState.user.user_tp !== 'U0100' && loginState.user.user_tp !== 'U0200' )&&
          <View style={{flexDirection:'row' , justifyContent:'flex-start', alignItems:'center'}}>
            <Checkbox
              style={{margin: 8,}}
              value={checked || false}
              onValueChange={onValueChange}
              color={checked ? COLOR.SELECT : undefined}
            />
            <Text>사용</Text>
          </View>
         }
        <Spacer space={16}/>

      </ScrollView>
      <View style={{flexDirection:'row', height: 60, alignItems:'flex-end',justifyContent:'space-around', marginBottom:16, marginHorizontal:20 }}>
        <View style={{flex:1, marginHorizontal:10}}>
              <Button onPress={onPasswd}>
                <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                    <NoflexTypography fontSize={menuFontSize} color='#ffffff' background='#979b9e'>패스워드 변경</NoflexTypography>
                </View>
              </Button>
        </View>              

        <View style={{flex:1, marginHorizontal:10}}>
              <Button onPress={onPressReg}>
                <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                    <NoflexTypography fontSize={menuFontSize} color='#ffffff' background='#979b9e'>저장</NoflexTypography>
                </View>
              </Button>
        </View>              
        <View style={{flex:1, marginHorizontal:10}}>
              <Button onPress={onPressDelete}>
                <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                    <NoflexTypography fontSize={menuFontSize} color='#ffffff' background='#979b9e'>계정삭제</NoflexTypography>
                </View>
              </Button>
        </View>              

      </View>
      <PasswordModal />
      <TrsMessageBox  message={msgInfo} 
                      onOK={(msgId:string)=>{
                        setMsgInfo(DefaultMsg);
                        if ( msgId === 'delete') onDelete();
                        else
                            onSave();
                      }}
                      onCancel={(msgId:string)=>{
                        setMsgInfo(DefaultMsg);
                      }}
                      />

     <TrsMessageShow message={msgShow} 
                        onMessage={ (msgId:string)=>{ 
                          setMsgShow(DefaultMsg) 
                          if ( msgId === 'end') navigation.goBack();
                          }} />

      {
         sendData && <Sync apiName={apiname} 
                           sendData={sendData} 
                          onData={onReceived} />
      }
    </View>
  )
}