import { ISelectCode, ISelectInfo } from "../datas/TrsDataDetailType";
import { IAdminDataGridInfo, IDataGridInfo } from "../datas/TrsDataType";

export interface ISiteClass{
  site_cd:string;
  site_nm:string;
  c_qty:string;
  t_qty:string;
  z_qty:string;
  tot_qty:string;
}

export interface IProdSum{
  skey:number;
  camp_cd:string;
  site_cd:string;
  prod_cd:string;
  c_qty:number;
  t_qty:number;
  z_qty:number;
  tot_qty:number;
}

export interface ISecGridMain{
  site_cd:string;
  site_nm:string;
  c_qty:number;
  t_qty:number;
  z_qty:number;
  tot_qty:number;
  data:readonly IProdSum[];
}

export interface ISecMain{
  site_cd:string;
  site_nm:string;
  c_qty:number;
  t_qty:number;
  z_qty:number;
  tot_qty:number;
  data: readonly IProdSum[];
}
export interface ISecAdminMain{
  site_cd:string;
  site_nm:string;
  st_end_dat:string;
  qty:number;
  data: readonly IAdminDataGridInfo[];
}

// 배열의 중복값 제거 
function getSection(table:IDataGridInfo[] ):IDataGridInfo[]{
  return table.filter((value:IDataGridInfo) => value.skey === 1);
}

export function getMainSection(table:IDataGridInfo[]){
  const sections: ISecMain[] = [];
  getSection(table).forEach ( item =>{
    const reps = table.filter((value:IDataGridInfo) => ((value.site_cd === item.site_cd) && (value.skey === 2)))
                        .map((row)=>{ 
                          const r = { skey:row.skey,
                                      site_cd:row.site_cd,
                                      camp_cd:row.camp_cd,
                                      prod_cd:row.prod_cd, 
                                      c_qty  :row.c_qty, 
                                      t_qty  :row.t_qty,
                                      z_qty  :row.z_qty, 
                                      tot_qty:row.tot_qty,  }
                          return r;
                        }) 
                        
    sections.push ({ site_cd:item.site_cd,
                     site_nm:item.site_nm,
                     c_qty:item.c_qty,
                     t_qty:item.t_qty,
                     z_qty:item.z_qty,
                     tot_qty:item.tot_qty,
                     data:reps  }) 
  } )
  return sections;
}  

// 배열의 중복값 제거 
function getAdminSection(table:IAdminDataGridInfo[] ):IAdminDataGridInfo[]{
  return table.filter((value:IAdminDataGridInfo) => value.skey === 1);
}

export function getAdminMainSection(table:IAdminDataGridInfo[]){
  const sections: ISecAdminMain[] = [];
  getAdminSection(table).forEach ( item =>{
    const reps = table.filter((value:IAdminDataGridInfo) => 
                               ((value.site_cd === item.site_cd) 
                               && (value.st_end_dat === item.st_end_dat)
                               && (value.skey === 2)
                                 ))
                        .map((row)=>{ 
                          const r = { skey       : row.skey,
                                      site_cd    : row.site_cd,
                                      site_nm    : row.site_nm,
                                      st_end_dat : row.st_end_dat,
                                      prod_cls   : row.prod_cls, 
                                      prod_cd    : row.prod_cd, 
                                      rep_cls    : row.rep_cls, 
                                      rep_clsn   : row.rep_clsn, 
                                      qty        : row.qty, 
                                    }
                          return r;
                        }) 
                        
    sections.push ({ site_cd   : item.site_cd,
                     site_nm   : item.site_nm,
                     st_end_dat: item.st_end_dat,
                     qty       : item.qty, 
                     data      : reps  }) 
  } )
  return sections;
}  


export function getIndex(table:ISelectInfo, key:string):number{
  for(let i = 0; i < table.totals; i++){
    if ( table.table[i].key === key){
      return i;
    }
  }
  return 0;
}

