import React, { useEffect, useState,useCallback } from "react";
import { 
  View, 
  Text, 
  Modal, 
  Pressable,
  StyleSheet,
  Platform,
  useWindowDimensions, 
  ActivityIndicator,
} from "react-native";
import { minWinWidth } from "../../datas/Const";
import { api_GetTrsViewRecord, default_url } from "../../datas/TrsApiType";
import { Spacer } from "../../components/Spacer";
import { XlsxData, XlsxData_Rec } from "../../utils/excel";

// 서버와 송수신 기능 
export const ExcelSync:React.FC<{
  apiName:string,
  sendData:any|undefined,
  onData:(resultCode:boolean, cmd:string, recvData:any)=>void,
  action?:string,
}>=({apiName, sendData, onData, action})=>{
  // get Window Size 
  const [modalVisible, setModalVisible] = useState(false);
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=width - (marginHorizontal * 2)
  const innerHeight=height/5;
  

  const state = async ()=>{
    if ( modalVisible === true) return;
    setModalVisible(true);
    const url = default_url.concat(apiName);
    const send = JSON.stringify(sendData);
    console.log('Sync.......................', url, send);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:send,
    });
    console.log('Sync apiResponse', apiResponse.ok, apiResponse.status, apiResponse.statusText)
    if( !apiResponse.ok){
      const message = `An error has occured: ${apiResponse.statusText}`;
      
      setModalVisible(false);
  
      //onReceive(false, message);
      onData(false, apiName, message);
      return;
    }
    const res = await apiResponse.json();
    if (Platform.OS === 'web'){
      if ( action && action === 'admreclist')
        await XlsxData(res);
      else
        await XlsxData_Rec(res);
    }
    
    console.log('Sync.END......................', modalVisible);
    onData(true, apiName, res);
    setModalVisible(false);
  }

  useEffect(() => {
    console.log('useEffect======= apiName, modalVisible')
    if ( sendData === undefined){
      if ( modalVisible === true ) setModalVisible(false);
      return;
    } 
    if ( apiName.length > 0 && sendData && modalVisible === false){
      state();
    }
  }, [apiName, sendData, modalVisible])
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => { setModalVisible(false); }}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Pressable style={{}} onPress={() => {setModalVisible(false); }}>
          <ActivityIndicator size='large' color='#ABF200'/>
          <Spacer space={10}/>  
          <Text style={{color:'#666666', fontSize:14 , fontWeight:'bold'}}> {sendData.message} </Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  )

  return(
    <View>
          <Text style={{color:'#666666', fontSize:14 , fontWeight:'bold'}}> {sendData.message} </Text>
    </View>
  )

}


const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    margin: 20,
    borderRadius: 20,
    padding: 15,
    alignItems: 'center',
  },

  button: {
    borderRadius: 20,
    padding: 10,
  },
  buttonOpen: {
    backgroundColor: '#99C341',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    textAlign: 'center',
    color:'white'
  },
});


