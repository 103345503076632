import { ITracking } from "../datas/TDataFind";
import { IRepClass,IRepairs, ISecRepair, IRepair, ICheckRepair, ITracksR, ITrsDetail, ISelectCode } from "../datas/TrsDataDetailType";

export const repDefault:IRepair = {
  inner_key:0,
  track_no:"",
  det_seq:0,
  req_dat:"",
  rep_type:"",
  rep_typen:"",
  rep_cls:"",
  rep_clsn:"",
  rep_cd : "",
  rep_cdn :"",
  rep_det: "",
  rma_no:"",
  checked:"",

};
export const reqDefault:ITrsDetail = {
  action:"",
  site_cd:"",
  site_nm:"",
  track_no:"",
  det_seq:0,
  req_dat:"",
  req_type:"",
  req_tpn:"",
  track_step:"",
  track_stepn:"",
  inner_key:0,
  serial_no:"",
  prod_cls:"",
  prod_cd :"",
  track_uid:"",
  track_nm:"",
  camp_cd:"",
  camp_nm:"",
  req_tr_chk:"",
  req_tr_comp:"",
  req_tr_no : "",
  res_tr_chk:"",
  res_tr_comp:"",
  res_tr_no : "",
  req_nm:"",
  ticket_no:"",
  loc_sts:"",
  cost_type:"",
  cost_typen:"",
  cost:"",
  rep_det01:"",
  rep_det02:"",
  rep_det03:"",
  rep_01:0,
  rep_02:0,
  rep_03:0,
  addr:"",
  alias:"",
  conract:"",
  validday:0,
  en_date:"",
}

export const DefaultCheckData={
  rep_cls:'',
  rep_clsn:'',
  selected:false,
  data:[{
    key : '',
    value : '',
    sub_cd1: '',
    sub_cd2: '',
    checked: false,
    disabled: false,
  
  }]
}


// 장애정보 를 처리 하기 위한 데이타 
export const repairData :ICheckRepair[] = [];
export const reqData:ITrsDetail[] =[] ;

function getArray(table:IRepair[]):IRepClass[]{
  const arr : IRepClass[] = [];
  table.forEach ( item =>{
    arr.push({rep_cls:item.rep_cls, rep_clsn:item.rep_clsn});
  });
  return arr;
}

// 배열의 중복값 제거 
function getSection(table:IRepair[] ):IRepClass[]{
  const arr : IRepClass[] = getArray(table);
  const result1 = arr.filter((arr, index, callback) => index === callback.findIndex(t => t.rep_cls === arr.rep_cls));
  return result1;
}

export function getCheckSection(table:IRepair[]){
  const sections: ICheckRepair[] = [];
  const repcls:IRepClass[]=getSection(table);

  repcls.forEach ( item =>{
  const reps = table.filter((value:IRepair) => value.rep_cls === item.rep_cls)
                      .map((row)=>{
                        const r = {key: row.rep_cd
                                , value:row.rep_cdn
                                , sub_cd1:item.rep_cls
                                , sub_cd2:item.rep_clsn
                                , checked:(row.checked === '1')? true:false
                                , disabled:false}
                        return r;
                      }) 
    sections.push ({ rep_cls:item.rep_cls,
                     rep_clsn:item.rep_clsn,
                     selected:false,
                     data:reps  
                  }) 
  } )
  return sections;
}  

export function getChecksSection(tables:IRepairs){
  const sections: ICheckRepair[] = [];
  const repcls:IRepClass[]=getSection(tables.table);

  repcls.forEach ( item =>{
  const reps = tables.table.filter((value:IRepair) => value.rep_cls === item.rep_cls)
                      .map((row)=>{
                        const tchecked = (row.checked === '1')? true:false;
                        const r = {key: row.rep_cd
                                , value:row.rep_cdn
                                , sub_cd1:item.rep_cls
                                , sub_cd2:item.rep_clsn
                                , checked:tchecked
                                , disabled:false}
                        return r;
                      }) 
    sections.push ({ rep_cls:item.rep_cls,
                     rep_clsn:item.rep_clsn,
                     selected:false,
                     data:reps  
                  }) 
  })

  return sections;
}  


export function getSerialCheck(table:ITracking[], serial_no : string)
{
  const r1 = table.filter((row:ITracking) => (row.serial_no === serial_no))
  if ( r1.length > 0 ) return true;
  return false;
}

export function getSerialChecked(table:ITracking[],  serial_no : string, rma:string, tr_icomp:string, tr_no:string)
{
  const r = table.map((row:ITracking)=>{
                if ( row.serial_no === serial_no ) {
                    if ( row.track_step === '01' && row.comp === '01')
                      return { ...row, checked : true, rma : rma, tr_no: tr_no }
                    if ( row.track_step === '01' && row.comp !== '01')
                      return { ...row, checked : true, rma : rma }
                    if ( row.track_step === '04' )  // A/S 출고시
                      return { ...row, checked : true, rma : rma }
                    if ( row.track_step === '05' )  // A/S 입고시
                      return { ...row, checked : true }
                    return { ...row, checked : true, rma : rma, comp:tr_icomp, tr_comp:tr_icomp, tr_no: tr_no }  // 출고시 
                }
                else return row;
            });
  const r1 = r.filter ((row:ITracking) => (row.checked === true)) 
  const r2 = r.filter ((row:ITracking) => (row.checked !== true))   
  r1.push( ...r2);
  return r1;
}

export function setRepairChecked(tables:ICheckRepair[], pitem:ISelectCode){
  const newSection = tables.map(rep => {
    if (rep.rep_cls !== pitem.sub_cd1) return rep;
    return{
      ...rep,
      selected:true,
      data:rep.data.map(item => {
        if ( item.key !== pitem.key) return item;
        else{
          return {
            ...item,
            checked:!item.checked
          }
        }
     })
    } 
  });
  return newSection;
}  


