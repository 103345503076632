import {View, Text, Pressable, ActivityIndicator} from 'react-native';
import { Spacer } from './Spacer';

// 화면 로딩시 전체적으로 보이는 화면 
export const Loading:React.FC<{message?:string}>=({message})=>(
  <View style={{flex: 1,justifyContent: 'center', alignItems: 'center',}}>
    <ActivityIndicator size='large' color='#ABF200'/>
    <Spacer space={10}/>  
    <Text style={{color:'#666666', fontSize:14 , fontWeight:'bold'}}> {message??"loading"} </Text>
  </View>
)