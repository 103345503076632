import { 
  View, 
  Text, 
  TouchableOpacity, 
  StyleSheet, 
  Platform,
  SectionList,   
  SectionListData,
  SectionListRenderItem ,
 } from "react-native";
 import React, { useEffect, useState, useRef, useCallback} from 'react';
 import { IDataGridInfo,  IDatasGridInfo } from '../../datas/TrsDataType';
import { IFindInfo } from '../../datas/TDataFind';
import * as ApiCmd from '../../datas/TrsApiType';
import * as DataType from '../../datas/TrsDataType';
import { GridExcelHeader, GridHeader, HeaderColumn, SectionHeaderColumn, TextColumn } from './GridHeader';
import { COLOR } from '../../utils/color';
import { getAdminMainSection, getMainSection, IProdSum, ISecAdminMain, ISecMain, ISiteClass } from '../../utils/main';
import { Spacer } from "../../components/Spacer";
import { Loading } from "../../components/Loading";
import { useLoginState } from "../../contexts/LoginContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import dayjs from 'dayjs';

type SectionHeader = (info: { section: SectionListData<IProdSum, ISecMain> }) => React.ReactElement ;
type SectionAdminHeader = (info: { section: SectionListData<DataType.IAdminDataGridInfo, ISecAdminMain> }) => React.ReactElement ;

export const DataSectionGrid : React.FC <{
  apiname:string,
  title:string,
  item:IFindInfo,
  isSync:boolean,
  onEnd:()=>void,
  onSelectData:(find:IFindInfo)=>void,
  
}> =( {apiname, title,item, onSelectData, isSync, onEnd}) => {
  const BORDER = COLOR.BORDER 
  const loginState = useLoginState();
  
  const [datas, setData] = useState<IDatasGridInfo>();
  const [run, setRun] = useState(ApiCmd.start);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sections, setSections] = useState<ISecMain[]>([]);

  const action = item.action;

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const state = async ()=>{
    if ( loading === true) return;
    setLoading(true);
    const url =  ApiCmd.default_url.concat( apiname);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(item),
    });
    const res = await apiResponse.json();
    setData(res);
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
    onEnd();
  }

  useEffect(() => {
    if ( isSync === true ) {
      recordStartTime();
      state();
    }
  }, [isSync]);

  useEffect(()=>{
    const conv = async ()=>{
      if (!datas) return;
      let section = await getMainSection(datas.table);
      setSections(section);
    }
    conv();
  },[datas])


  const onSync = useCallback(() => {
    recordStartTime();
    state();
  },[item] );

  const renderSectionHeader:SectionHeader = ({section}) => {
    return <View style={{ flexDirection:"row", 
                          alignItems:'center',
                          justifyContent:'center', 
                          height:40,
                          borderTopColor:COLOR.GRAY_3,
                          borderTopWidth:0.5,
                          } }>
        <SectionHeaderColumn title={section.site_nm}/>
        <SectionHeaderColumn title={''}/>
        <SectionHeaderColumn title={`${section.c_qty}`}/>
        {
          (loginState.user_tp === 'U01')&&
          <SectionHeaderColumn title={`${section.t_qty + section.z_qty}`}/>
        }
        {
          (loginState.user_tp === 'U02')&&
          <SectionHeaderColumn title={`${section.t_qty}`}/>
        }
        {
          (loginState.user_tp === 'U02')&&
          <SectionHeaderColumn title={`${section.z_qty}`}/>
        }
        <SectionHeaderColumn title={`${section.tot_qty}`}/>
    </View>
  };


  const ListHeaderComponent:React.FC<{}>=()=>{
    return(
      <View style={{flexDirection:"row", 
                    alignItems:'center',
                    justifyContent:'center', 
                    height:50}    }>
        <HeaderColumn title='SITE'/>
        <HeaderColumn title='제품'/>
        <HeaderColumn title='대기'/>
        {
          (loginState.user_tp === 'U01')&&
          <HeaderColumn title='A/S 진행'/>
        }
        {
          (loginState.user_tp === 'U02')&&
          <HeaderColumn title='티엔테크'/>
        }
        {
          (loginState.user_tp === 'U02')&&
          <HeaderColumn title='Zebra'/>
        }
        <HeaderColumn title='Total'/>
      </View>
    );
  }

  const renderItem:SectionListRenderItem<IProdSum, ISecMain>=({item,index})=>{
    return(
      <View style={{flexDirection:"row", 
                    alignItems:'center', 
                    justifyContent:'center', 
                    height:40,  }}>
          <TextColumn value={item.camp_cd}/>
          <TextColumn value={item.prod_cd}/>
          <TextColumn value={`${item.c_qty}`}/>
          {
          (loginState.user_tp === 'U01')&&
          <TextColumn value={`${item.t_qty + item.z_qty}`}/>
          }
          {
            (loginState.user_tp === 'U02')&&
            <TextColumn value={`${item.t_qty}`}/>
          }
          {
            (loginState.user_tp === 'U02')&&
            <TextColumn value={`${item.z_qty}`}/>
          }
          <TextColumn value={`${item.tot_qty}`}/>
      </View>
    );
  }
  const ItemSeparatorComponent = () => (
    <View style={{ width: "100%", height: 1, borderBottomWidth: 1, borderBottomColor : '#eeeeee' }} />
  );
  const ListFooterComponent = () => (
    <View style={{height:10, borderTopColor:COLOR.GRAY_2, borderTopWidth:0.5  }} />
  )
 
  if (loading) return <Loading message={'Loading...'} />
  if (error) return  <View><Text>Error Occured: </Text></View>
  if (!sections){
    return (
      <View style={{flex:1}}>
        <GridHeader title={title+`${0} 건`} onSync={onSync} />
      </View>
    )
  }


  return (
    <View style={{flex:1}}>
    <GridHeader title={title} onSync={onSync} />
    <View style={{flex:1}}>
      <SectionList
            style={{ flex: 1, width: "100%" }}
            sections={sections}
            ListHeaderComponent={ListHeaderComponent}
            renderSectionHeader={renderSectionHeader}
            renderItem={renderItem}
            keyExtractor={item=>item.prod_cd + item.camp_cd + item.site_cd}
            ItemSeparatorComponent={ItemSeparatorComponent}
            ListFooterComponent={ListFooterComponent}
         />
    </View>
 </View>
  );
}


export const DataSectionAdminGrid : React.FC <{
  apiname:string,
  title:string,
  item:IFindInfo,
  onSelectData:(find:IFindInfo)=>void,
  onExcel:(find:IFindInfo)=>void,
}> =( {apiname, title,item, onSelectData, onExcel}) => {
  const BORDER = COLOR.BORDER 
  
  const [datas, setData] = useState<DataType.IAdminDataGridInfos>();
  const [run, setRun] = useState(ApiCmd.start);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sections, setSections] = useState<ISecAdminMain[]>([]);

  const action = item.action;

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const state = async ()=>{
    if ( loading === true) return;
    setLoading(true);
    const url =  ApiCmd.default_url.concat( apiname);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(item),
    });
    const res = await apiResponse.json();
    console.log('Admin Section receive....................', res)
    setData(res);
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
  }
  
  useEffect(() => {
    console.log('DataAdminSectionGrid.................', item)
    recordStartTime();
    state();
  }, [item]);

  useEffect(()=>{
    console.log('Admin Section Conv....................')
    
    const conv = async ()=>{
      if (!datas) return;
      let section = await getAdminMainSection(datas.table);
      console.log('Admin Section Conv....................', section)
      setSections(section);
    }
    conv();

  },[datas, ])


  const onSync = useCallback(() => {
    console.log('Sync.................')
    recordStartTime();
    state();
  },[item] );

  const renderSectionHeader:SectionAdminHeader = ({section}) => {
    return <View style={{ flexDirection:"row", 
                          alignItems:'center',
                          justifyContent:'center', 
                          height:40,
                          borderTopColor:COLOR.GRAY_3,
                          borderTopWidth:0.5,
                          } }>
        <SectionHeaderColumn title={section.site_nm}/>
        <SectionHeaderColumn title={section.st_end_dat} flex={2}/>
        <SectionHeaderColumn title={`${section.qty}`}/>
    </View>
  };


  const ListHeaderComponent:React.FC<{}>=()=>{
    return(
      <View style={{flexDirection:"row", 
                    alignItems:'center',
                    justifyContent:'center', 
                    height:50}    }>
        <HeaderColumn title='제품'/>
        <HeaderColumn title='모델'/>
        <HeaderColumn title='장애분류'/>
        <HeaderColumn title='건수'/>
      </View>
    );
  }

  const renderItem:SectionListRenderItem<DataType.IAdminDataGridInfo, ISecAdminMain>=({item,index})=>{
    return(
      <View style={{flexDirection:"row", 
                    alignItems:'center', 
                    justifyContent:'center', 
                    height:40,  }}>
          <TextColumn value={item.prod_cls}/>
          <TextColumn value={item.prod_cd}/>
          <TextColumn value={item.rep_clsn}/>
          <TextColumn value={`${item.qty}`}/>
      </View>
    );
  }
  const ItemSeparatorComponent = () => (
    <View style={{ width: "100%", height: 1, borderBottomWidth: 1, borderBottomColor : '#eeeeee' }} />
  );
  const ListFooterComponent = () => (
    <View style={{height:10, borderTopColor:COLOR.GRAY_2, borderTopWidth:0.5  }} />
  )
 
  if (loading) return <Loading message="loading..." />
  if (error) return  <View><Text>Error Occured: </Text></View>
  if (!sections){
    return (
      <View style={{flex:1}}>
        <GridHeader title={title+`${0} 건`} onSync={onSync} />
      </View>
    )
  }


  return (
    <View style={{flex:1}}>
    {
      (Platform.OS === 'web') &&
      <GridExcelHeader title={title} onSync={onSync} onExcel={()=>{onExcel(item)}}/>
    }      
    {
      (Platform.OS !== 'web') &&
      <GridHeader title={title} onSync={onSync} />
    }

    <View style={{flex:1}}>
      <SectionList
            style={{ flex: 1, width: "100%" }}
            sections={sections}
            ListHeaderComponent={ListHeaderComponent}
            renderSectionHeader={renderSectionHeader}
            renderItem={renderItem}
            keyExtractor={item=>item.prod_cd + item.rep_cls}
            ItemSeparatorComponent={ItemSeparatorComponent}
            ListFooterComponent={ListFooterComponent}
         />
    </View>
 </View>
  );
}

