import { 
  Platform, 
  useWindowDimensions,
} 
from "react-native";
import { minWinWidth } from "../datas/Const";

type SizeProps = [
  number, 
  number, 
  boolean,
  number,
]
// 전역 공통 Size 
export const useSize = (): SizeProps => {
  const isHorizontal = Platform.OS === "web" ? true:false;
  const winWidth = useWindowDimensions().width;
  const winHeight = useWindowDimensions().height;
  const tmpMargin = ( winWidth < minWinWidth)? 0 :( (winWidth - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=winWidth - (marginHorizontal * 2)
  const menuFontSize=Platform.OS ==='web'? 18:15;

  return [winWidth, winHeight, isHorizontal, menuFontSize];
}