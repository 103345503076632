import React, {useEffect, useState, useRef, useCallback} from 'react';

import {View, Text, TouchableOpacity, StyleSheet,Platform} from 'react-native';
import { IconName } from '../../components/Icons';
import { Entypo } from '@expo/vector-icons'; 
import { EvilIcons } from '@expo/vector-icons'; 
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { COLOR } from '../../utils/color';
import Checkbox from 'expo-checkbox';
import { Octicons } from '@expo/vector-icons';

export type FontAwesomeName = keyof typeof FontAwesome.glyphMap;

const fontSize= Platform.OS ==='web'? 16 : 15;
const BORDER = COLOR.BORDER
const PRIMARY = COLOR.PRIMARY

export const SubjectHeader : React.FC <{
  title:string,
  iconName?:IconName,
  onSync:()=>void,
}> =({title, iconName, onSync}) => {

  
  return (
      <View style={{
        flexDirection:'row', 
        justifyContent:'space-between', 
        alignItems:'center',
        height:50
        }}>
        <View style={{flex:1, justifyContent:'center', alignItems:'flex-start'}}>
          <Text style={{color:'#6B6B6B',fontSize:fontSize}}> {title}</Text>
        </View>
        <View style={{width:30,height:30,borderRadius:20,justifyContent:'center', alignItems:'center',marginRight:5}}>
          <TouchableOpacity
            style={{justifyContent:'center', alignItems:'center'}}
            activeOpacity={0.5}
            onPress={onSync}>
            <Entypo name="popup" size={36} color='black' />
          </TouchableOpacity>
        </View>
      </View>
  );
}

export const GridExcelHeader : React.FC <{
  title:string,
  iconName?:IconName,
  onSync:()=>void,
  onExcel:()=>void,
}> =({title, iconName, onSync,onExcel}) => {

  return (
      <View style={{
        flexDirection:'row', 
        justifyContent:'space-between', 
        alignItems:'center',
        height:50
        }}>
        <View style={{flex:1, justifyContent:'center', alignItems:'flex-start'}}>
          <Text style={{color:'#6B6B6B',fontSize:fontSize}}> {title}</Text>
        </View>
        <View style={{width:30,height:30,borderRadius:20,justifyContent:'center', alignItems:'center',marginRight:5}}>
        <TouchableOpacity
          style={{justifyContent:'center', alignItems:'center'}}
          activeOpacity={0.5}
          onPress={onExcel}>
          <FontAwesome name="file-excel-o" size={24} color="black" />
        </TouchableOpacity>
        </View>
        <View style={{width:30,height:30,borderRadius:20,justifyContent:'center', alignItems:'center',marginRight:5}}>
          <TouchableOpacity
            style={{justifyContent:'center', alignItems:'center'}}
            activeOpacity={0.5}
            onPress={onSync}>
             <Octicons name="sync" size={24} color="black" />
          </TouchableOpacity>
        </View>
      </View>
  );
}


export const GridHeader : React.FC <{
  title:string,
  iconName?:IconName,
  onSync:()=>void,
}> =({title, iconName, onSync}) => {

  return (
      <View style={{
        flexDirection:'row', 
        justifyContent:'space-between', 
        alignItems:'center',
        height:50
        }}>
        <View style={{flex:1, justifyContent:'center', alignItems:'flex-start'}}>
          <Text style={{color:'#6B6B6B',fontSize:fontSize}}> {title}</Text>
        </View>
        
        <View style={{width:30,height:30,borderRadius:20,justifyContent:'center', alignItems:'center',marginRight:5}}>
          <TouchableOpacity
            style={{justifyContent:'center', alignItems:'center'}}
            activeOpacity={0.5}
            onPress={onSync}>
              <Octicons name="sync" size={24} color="black" />
          </TouchableOpacity>
        </View>
      </View>
  );
}
export const GridHeader2 : React.FC <{
  title:string,
  iconName?:IconName,
  onSync:()=>void,
}> =({title, iconName, onSync}) => {

  
  return (
      <View style={{
        flexDirection:'row', 
        justifyContent:'space-between', 
        alignItems:'center',
        height:50
        }}>
        <View style={{flex:1, justifyContent:'center', alignItems:'flex-start'}}>
          <Text style={{color:'#6B6B6B',fontSize:fontSize}}> {title}</Text>
        </View>
      </View>
  );
}


export const SectionHeaderColumn:React.FC<{
  title:string
  flex?:number,
  height?:number,
}>=({title, flex, height})=>(
  <View style={{flex:flex??1, height:height??40}}>
  <View style={{flex:1, backgroundColor:'white', justifyContent:'center',alignItems:'center' }}>
    <Text style={styles.sectionHeaderTextStyle}>{title}</Text>
  </View>
   <View style={{backgroundColor: '#D9D9D9', height:2}}/>
  </View>
);
export const HeaderColumn:React.FC<{
  title:string,
  flex?:number,
  height?:number,
  pfontSize?:number,
}>=({title, flex, height, pfontSize})=>(
  <View style={{flex:flex??1, height:height??50,}}>
    <View style={{flex:1, backgroundColor:'white', justifyContent:'center',alignItems:'center' }}>
      <Text style={{  backgroundColor: 'white',
                      color:'#797979',
                      fontSize: pfontSize??fontSize,
                      alignItems:'center',
                      justifyContent:'center',
                      fontWeight:'bold'
                      }}>{title}</Text>
    </View>
     <View style={{backgroundColor: '#D9D9D9', height:2}}/>
  </View>
);

export const HeaderColumn2:React.FC<{
  title:string,
  flex?:number,
  height?:number,
  pfontSize?:number,
  
}>=({title, flex, height, pfontSize})=>(
  <View style={{flex:flex??1, height:height??50,}}>
    <View style={{flex:1, backgroundColor:'white', justifyContent:'center',alignItems:'center' }}>
      <Text style={{  backgroundColor: 'white',
                      color:'#797979',
                      fontSize: pfontSize??fontSize,
                      alignItems:'center',
                      justifyContent:'center',
                      fontWeight:'bold'
                      }}>{title}</Text>
    </View>
  </View>
);

export const HeaderButtonColumn:React.FC<{
  title:string,
  flex?:number,
  height?:number,
  pfontSize?:number,
  onData:()=>void,
  iconName?:any,

}>=({title, flex, height, pfontSize, onData, iconName})=>(
  <View style={{flex:flex??1, height:height??50,}}>
    <View style={{flex:1, backgroundColor:'white', justifyContent:'center',alignItems:'center' }}>
    <TouchableOpacity
        style={styles.textStyle}
        activeOpacity={0.5}
        onPress={ onData }>
          <View style={{flexDirection:'row', justifyContent:'space-between'}}>
            <Text style={{backgroundColor: 'white',
                        color:COLOR.ICONCOLOR,
                        fontSize: pfontSize??fontSize,
                        alignItems:'center',
                        justifyContent:'center',
                        fontWeight:'bold'}}>{title}</Text>
            {
              iconName&&
              <View style={{height:"100%", alignItems:'center'}}>
                 <AntDesign name={iconName} size={10} color="green" />           
              </View>

            }
          </View>
      </TouchableOpacity>
    </View>
     <View style={{backgroundColor: '#D9D9D9', height:2}}/>
  </View>
);


export const TextColumn:React.FC<{
  value:string
  flex?:number,
  height?:number,
  color?:string,
  pfontSize?:number,
}>=({value, flex, height, color, pfontSize})=>{
  const ufontSize=(Platform.OS !== 'web' && value && value.length > 5)? fontSize-1 : fontSize;
  return ( 
    <View style={{flex:flex??1, justifyContent:'center',alignItems:'center' ,height:height??50,}}>
        <Text style={{fontSize: pfontSize??ufontSize,
                      alignItems:'center',
                      justifyContent:'center',
                      color:color??'#595959'
                    }}>{value}</Text>
        <View style={{backgroundColor: BORDER, height:1}}/>
    </View>
  );
};

export const CheckColumn:React.FC<{
  value:boolean,
  key:string,
  flex?:number,
  height?:number,
  onChecked:(key:string, checked:boolean)=>void,
}>=({value, key, flex, height, onChecked})=>{
  const [checked, setChecked] = useState(false); 
  const [item, setItem] = useState(value); 

  const onValueChange = useCallback(() => {
    setChecked(!checked);
    onChecked(key, checked);
  },[checked] );

  return (
    <View style={{flex:flex??1, justifyContent:'center',alignItems:'center' ,height:height??50,}}>
    <Checkbox
        style={{margin: 8,}}
        value={value || false}
        key={key}
        onValueChange={onValueChange}
        color={checked ? COLOR.SELECT : undefined}
        />
    <View style={{backgroundColor: BORDER, height:1}}/>
    </View>
  )
}

export const ButtonColumn:React.FC<{
  value:string,
  onData:()=>void,
  index:number,
  flex?:number,
  color?:string,
}>=({value, onData, index, flex, color})=>{
  const ufontSize=(Platform.OS !== 'web' && value.length > 5)? fontSize-1 : fontSize;

  return (
    <View style={{
      flex:flex??1, 
      justifyContent:'center',
      alignItems:'center',
      marginVertical:3,borderRadius:5}}>
      <TouchableOpacity
        style={styles.textStyle}
        activeOpacity={0.5}
        onPress={ onData }>
        <Text style={{color: color??'#99C341',
                      fontSize: fontSize,
                      alignItems: 'center',
                      justifyContent:'center'}}>{value}</Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  buttonStyle: {
    backgroundColor: '#307ecc',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#307ecc',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 15,
  },
  buttonGridStyle: {
    backgroundColor: '#307ecc',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#307ecc',
    height: 40,
    alignItems: 'center',
    borderRadius: 5,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 15,
  },
  buttonTextStyle: {
    color: '#99C341',
    fontSize: fontSize,
    alignItems: 'center',
    justifyContent:'center'

  },

  textStyle: {
    fontSize: fontSize,
    alignItems:'center',
    justifyContent:'center',
    color:'#595959'
  },
  headerTextStyle: {
    backgroundColor: 'white',
    color:'#797979',
    fontSize: fontSize,
    alignItems:'center',
    justifyContent:'center',
    fontWeight:'bold'
  },
  sectionHeaderTextStyle: {
    backgroundColor: 'white',
    color:'#99C341',
    fontSize: fontSize-1,
    alignItems:'center',
    justifyContent:'center',
    fontWeight:'bold'
  },
  item: {
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: fontSize*1.5,
  },
});
