import {useNavigation} from '@react-navigation/native';
import React, {useCallback,useState,} from 'react';
import {View,useWindowDimensions, Platform, Text, ScrollView} from 'react-native';
import { Spacer } from '../../components/Spacer';
import { useLoginState } from '../../contexts/LoginContext';
import { IFindInfo } from '../../datas/TDataFind';
import { IRltCode } from '../../datas/TSendData';
import { useTrsNavigation, useTrsRoute } from '../../navigations/StackParamNavi';
import { HeaderTop, HeaderUserInfo } from '../components/HeaderTop';
import { Caption, InputButton, InputSelectList, InputText, InputView } from '../components/InputComponent';
import { Sync } from '../components/Sync';
import { DefaultMsg, TrsMessageBox, TrsMessageShow } from '../components/TrsModal';
import { MultiLineInput } from '../../components/MultiLineInput';
import Checkbox from 'expo-checkbox';
import { COLOR } from '../../utils/color';
import { api_SetCode } from '../../datas/TrsApiType';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

export const CodeDetailScreen:React.FC = () =>{
  const navigation = useTrsNavigation();
  const route = useTrsRoute<'CodeReg'>();
  const loginState = useLoginState();
  const [selected, setSelected] =  useState("SITE");
  const [input, setInput] = useState({...route.params.code});
  const [sendData, setSendData] = useState<any>();
  const [recvData, setRecvData] = useState<any>();
  const [command, setCommand] = useState('');
  const [apiname, setApiName] = useState('');
  const [rltCode, setRltCode] = useState<IRltCode>();
  const [msgShow, setMsgShow] = useState(DefaultMsg);
  const [msgInfo, setMsgInfo] = useState(DefaultMsg);
  const [find, setFind] = useState<IFindInfo>();
  const [checked, setChecked] = useState((route.params.code.del_flg === 'A' ) ? true: false);


  const isHorizontal = Platform.OS === "web" ? true:false;
    
  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );


  const onPressReg = useCallback(() => {
    console.log('onPressReg');
    setCommand('save');
    setApiName(api_SetCode);
    setSendData({...input,
                  action:'update',
                  del_flg:checked == true ? 'A':'D',
                  reg_user:loginState.user.user_cd,
                  reg_uname:loginState.user.user_nm,
                  reg_ip:loginState.reg_ip,
                  reg_dev:loginState.reg_dev,
                });
  },[input, checked, command, apiname, sendData]);

  const onSelect = useCallback((key:string) => {
    console.log('TrsMainScreen onSelect ', key);
    setSelected(key);
    //setItem({...item, site_no:key});
  },[] ); 
  const onChangeText = useCallback( (name: string,value:string) => {
    console.log('onchange==', name,',,,,', value)
    setInput({...input, [name]:value});
  }, [input]);

  const onSubmit = useCallback( (name:string, value:string) => {
    console.log('onSubmit', name, value);
    if( name === 'sort_seq'){
    }
    if ( name === 'code_nm'){
    }
  }, [input]);

  const onInit = useCallback(()=>{
    setMsgInfo(DefaultMsg)
    setInput({...route.params.code});
    setCommand('');
    setApiName('');
    setSendData(null);
    setRecvData(null);
  }, [command, apiname, sendData, recvData, rltCode, input])

  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    setApiName('');
    setSendData('');
    setCommand('');
    if (resultCode === false){
      setMsgShow({...DefaultMsg, msgId:'msg', message:rData});
    }else{
      const tmp:IRltCode = rData;
      setMsgShow({...DefaultMsg, msgId:'msg', message:tmp.result_msg});
    }
  }, [recvData, command, apiname, sendData, find]);

  const onValueChange=useCallback((value:boolean)=>{
    console.log('onValueChange', value);
    setChecked(value);

  },[input, checked]);

  return (
    <View style={{flex:1, backgroundColor:'white' }}>
      <HeaderTop
        title="코드상세 조회 및 수정" 
        isMain={false}
        onPress={onPressBack} 
        onHome={()=>{navigation.popToTop()}} 
        />
        <View style={{flex:1, marginHorizontal:20}}>
        <ScrollView  showsVerticalScrollIndicator={false}>
          <Spacer space={20}/>
          <HeaderUserInfo onSelect={onSelect}  isUser={true} />
          <Spacer space={30}/>
          <InputView title={'상위항목'} value={input.class1 + 
                                          ((input.class2.length > 0)?  " > " + input.class2 : "") + 
                                          ((input.class3.length > 0)?  " > " + input.class3 : "") } 
                                          isTitle={true} />
          <Spacer space={20}/>
          <InputView title={'항목'} value={input.code_cd } isTitle={true} />
          <Spacer space={20}/>
          <InputText    title={'정렬순서'} 
                        placeholder='정렬순서를 입력하세요. default 00 로 입력됩니다. ' 
                        name='sort_seq' 
                        value={input.sort_seq}
                        keyboardType={'numeric'}
                        onChangeText={(text:string)=>{
                          onChangeText('sort_seq', text); 
                        }} 
                        onSubmit={(onSubmit)}  
                        />

          <Spacer space={20}/>
          <Caption title={'명칭'}  />
          <Spacer space={6}/>
          <MultiLineInput value={input.code_nm}
                          onChangeText={(text:string)=>{
                            onChangeText('code_nm', text); 
                          }} 
                          placeholder={''}
                          onSubmitEditing={()=>{onSubmit('code_nm', input.code_nm)}}
                          height={150}
                                />
          <Spacer space={40}/>

          <View style={{flexDirection:'row' , justifyContent:'flex-start', alignItems:'center'}}>
              <Checkbox
                style={{margin: 8,}}
                value={checked || false}
                onValueChange={onValueChange}
                color={checked ? COLOR.SELECT : undefined}
              />
              <Text>사용</Text>
          </View>
        </ScrollView>

        <View style={{ alignItems:"flex-end", height:60}}>
           <InputButton title={'저장'} onPress={onPressReg} />
        </View>

      </View>
      
      <TrsMessageBox message={msgInfo} 
                       onOK={(msgId:string)=>{
                        onInit();
                       }} 
                       onCancel={(msgId:string)=>{
                        setMsgInfo({...DefaultMsg, msgId:'', message:''});
                        onSubmit('item', '');
                      }} />

        <TrsMessageShow message={msgShow} 
                      onMessage={(msgId:string)=>{ setMsgShow(DefaultMsg)}} />

        {
          sendData && <Sync apiName={apiname} 
                            sendData={sendData} 
                            onData={onReceived} />
        }
     
    </View>
  )
}