import { Text, View, Platform,useWindowDimensions,Image } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { RootApp } from './src/RootApp';
import { LoginStateContextProvider } from './src/contexts/LoginContext';
import { useDebounce } from 'react-use';
import { COLOR } from './src/utils/color';
import { minWinWidth } from './src/datas/Const';
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Spacer } from './src/components/Spacer';
//import * as Device from 'expo-device';
//import * as Notifications from 'expo-notifications';
//import Constants from 'expo-constants';
//import {registerForPushNotificationsAsync} from './src/utils/notify';
//import AsyncStorage from '@react-native-async-storage/async-storage';

//import { Colors } from 'react-native/Libraries/NewAppScreen';
//import { useFonts,  } from '@expo-google-fonts/inter';
//import * as SplashScreen from 'expo-splash-screen';

// Notifications.setNotificationHandler({
//   handleNotification: async () => ({
//     shouldShowAlert: true,
//     shouldPlaySound: false,
//     shouldSetBadge: false,
//   }),
// });


export default function App() {
  const [expoPushToken, setExpoPushToken] = useState("");
 // const [notification, setNotification] = useState<Notifications.Notification>();
  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();

  const width=useWindowDimensions().width;
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const web = Platform.OS ==='web' ? true : false
  const panelwidth = width > 1300 ? true : false

  if ( web ){
    // Web 환경 하에서 
    const preventClose = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = ""; //Chrome에서 동작하도록; deprecated
    };
    useEffect(() => {
      (() => {
        window.addEventListener("beforeunload", preventClose);
      })();
     
      return () => {
        window.removeEventListener("beforeunload", preventClose);
      };
    }, []);

    return (
      <View style={{
        flex:1,
        backgroundColor:COLOR.GRAY_1, 
      }} >
      <SafeAreaProvider>
          <LoginStateContextProvider >
            <View style={{flex:1, flexDirection:'row'}} >
              <View style={{flex:1, 
                            marginLeft:marginHorizontal, 
                            alignContent:'center',  
                            marginVertical:0,
                            borderLeftWidth:0.5,
                            borderRightWidth:0.5,
                            borderLeftColor:COLOR.GRAY_2,
                            borderRightColor:COLOR.GRAY_2,
                            }}>
                <RootApp/>
              </View>
             {panelwidth &&
              <View style={{ width : 500,
                marginRight:marginHorizontal/2,
                justifyContent:'center',
                alignItems:'center'}}>
                <View style={{flex:2, width:300, justifyContent : 'center'}}>
                  <Text style={{fontSize:20, color:'#4C4C4C', fontWeight:'500'}}>Web URL</Text>
                  <Spacer space={10} />
                  <Image source={{uri:"https://tntech.support/images/web.png"}} style ={{ width:150, height:150,}}/>
                  <Spacer space={50} />
                  <Text style={{fontSize:20, color:'#4C4C4C',  fontWeight:'500'}}>APK Download</Text>
                  <Spacer space={10} />
                  <Image source={{uri:"https://tntech.support/images/apk.png"}} style ={{ width:150, height:150}}/>
                </View>
                <View style={{flex:1,width:300, alignItems:'flex-start'}}>
                  <Image source={{uri : "https://tntech.support/images/coupang.png"}} style ={{width:150, height:50}}/>
                  <Image source={{uri : "https://tntech.support/images/tandt.png"}} style ={{width:150, height:50}}/>
                  <Image source={{uri : "https://tntech.support/images/zebra.png"}} style ={{width:150, height:50}}/>
                </View>
              </View>
             }
             </View>
          </LoginStateContextProvider>
        </SafeAreaProvider>
      </View>
    );
  }
/*
  useEffect(() => {
    registerForPushNotificationsAsync().then(token => setExpoPushToken(token??''));

    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log(response);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  useEffect(() => {
    console.log('pushToken......', expoPushToken);

  },[expoPushToken]);
*/
  return (
    <SafeAreaProvider>
        <LoginStateContextProvider >
              <RootApp token={expoPushToken}/>
        </LoginStateContextProvider>
    </SafeAreaProvider>
  );
}

