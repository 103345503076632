import React, {useCallback,useState, useRef,useEffect} from 'react';
import { DefaultTrsDetail, ITrsDetail, ITrsDetails } from "../datas/TrsDataDetailType";
import { View, Platform, Alert,ScrollView } from 'react-native';
import { useLoginState } from "../contexts/LoginContext";
import { Spacer } from '../components/Spacer';
import { TrackContent, TrackHeader, TrackingResult, TrackInText, TrackLine,  } from './components/TrsComponent';
import { TrackingGrid } from './components/TrackingGrid';
import * as ApiCmd from '../datas/TrsApiType';
import { Button } from '../components/Button';
import { DefaultMsg, TrsMessageBox, TrsMessageConfirm, TrsMessageShow } from './components/TrsModal';
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { COLOR } from '../utils/color';
import { Sync } from './components/Sync';
import { DefaultTracking, ITracking } from '../datas/TDataFind';
import { IRltCode } from '../datas/TSendData';
import { useTrsNavigation } from '../navigations/StackParamNavi';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

const fontSize= Platform.OS ==='web'? 16 : 12;
const line_space=10;

export const ClientDetail:React.FC<{
  items:ITrsDetails,
  onSync:()=>void,
}>=({items})=>{

  const loginState = useLoginState();
  const navigation = useTrsNavigation<'TrsClientDetail'>();

  const [sendData, setSendData] = useState<any>();
  const [recvData, setRecvData] = useState<any>();
  const [command, setCommand] = useState('');
  const [apiname, setApiName] = useState('');
  const [rltCode, setRltCode] = useState<IRltCode>();
  const [msgShow, setMsgShow] = useState(DefaultMsg);
  const [msgInfo, setMsgInfo] = useState(DefaultMsg);
  const [msgOk, setMsgOk] = useState(DefaultMsg);

  const isHorizontal = Platform.OS === "web" ? true:false;
  const user_tp = loginState.user.user_tp.substring(0, 3);
  const [datas, setDatas] = useState<ITrsDetails>(items);

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  // A/S 신청 등록
  const onSend = useCallback(()=>{
    const send :ITracking={
      ...DefaultTracking,
      track_no:items.table[0].track_no,
      det_seq:items.table[0].det_seq,
      track_step:"END", 
    };
    const sendTmp:ITracking[]=[];
    sendTmp.push(send);
    setApiName(ApiCmd.api_SetTrsTracking);
    setSendData(sendTmp);
    setCommand('send');
  },[]);

  // 시리얼정보검색 결과수신, A/S 신청 결과 수신
  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    console.log('onReceived ClientReg..............', command, resultCode, cmd, rData, )
    if (resultCode === false) {
      setMsgShow({...msgShow, msgId:'serial', message:rData});
      return;
    }
    setApiName(''); setSendData(null);
    setCommand('');
    const tmp:IRltCode = rData;

    if ( tmp.result_cd === '1'){
      setMsgOk({...msgOk, msgId:'send', message:tmp.result_msg + ' 완료되었습니다.'});
    }
    else{
      setMsgShow({...msgShow, msgId:'send', message:tmp.result_msg});
    }
}, [command, apiname, sendData, msgShow, msgOk]);

  return (
    <View style={{flex:1}}>

    <ScrollView showsVerticalScrollIndicator={false} 
                style={{marginBottom : 10, backgroundColor:'white'}} >
        <View style={{flex:1, backgroundColor:'white'}}>
          <Spacer space={5}/>
          <TrackContent items={datas}/>
          <Spacer space={5}/>
          <TrackingResult items={datas}/>
          <View style={{marginHorizontal:10}}>
            <TrackingGrid url={ApiCmd.api_GetTrsDetail} 
                          title='이동이력' 
                          item={datas} />
          </View>
        </View>
    </ScrollView>
    {
      /*
    <View style={{flexDirection:'row', height: 50, alignItems:'center',justifyContent:'space-around' }}>
        <View style={{flex:1, marginHorizontal:10}}>
        </View>              
        <View style={{flex:1, marginHorizontal:10}}>
          <Button onPress={()=>{
            setMsgInfo({msgId  :'sendAsk', 
                        message:'완료하시겠습니까?'});
            }}>
            <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                <NoflexTypography fontSize={fontSize+8} color='#ffffff' background='#979b9e'>완료</NoflexTypography>
            </View>
          </Button>
        </View>              
      </View>
      */      
    }

      <TrsMessageBox  message={msgInfo} 
                      onOK={(msgId:string)=>{
                          console.log('TrsMessageBox...... msgId', msgId);
                          onSend();
                      }} 
                      onCancel={(msgId:string)=>{
                          setMsgInfo(DefaultMsg);
                      }} />

        <TrsMessageConfirm  message={msgOk} 
                            onOK={(msgId:string)=>{
                              setMsgOk(DefaultMsg)
                              console.log('TrsMessageBox...... msgId', msgId);
                              navigation.goBack();
                            }} />

        <TrsMessageShow message={msgShow} 
                        onMessage={ (msgId:string)=>{ setMsgShow(DefaultMsg) }} />

    {
      sendData && <Sync apiName={apiname} 
                        sendData={sendData} 
                        onData={onReceived} />
    }
    </View>
  
  )
}
