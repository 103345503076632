export interface IUserInfo {
  action   : string ;
  user_cd  : string ;
  user_nm  : string ;
  user_tp  : string ;
  user_tpn : string ;
  site_cd  : string ;
  site_nm  : string ;
  camp_cd  : string ;
  camp_nm  : string ;
  del_flg  : string ;
  log_stat : string ;
  passwd   : string ;
  email    : string ;
  phone    : string ;
  version  : string ;
  cmt      : string ;
  reg_user : string;
  reg_uname: string;
  reg_ip   : string;
  reg_dev  : string;
}

export const DefaultUser = {
  action:'',
  user_cd : '',
  user_tp : '',
  user_tpn: '',
  user_nm : '',
  user_lv : '',
  del_flg : '',
  log_stat: '',
  passwd  : '',
  email   : '',
  phone   : '',
  site_cd : '',
  site_nm : '',
  camp_cd : '',
  camp_nm : '',
  version : '',
  cmt     : '' ,
  reg_user: '',
  reg_uname: '',
  reg_ip: '',
  reg_dev: '',
}
export interface IUsersInfo{
  result_cd : string;
  result_msg : string;
  totals : number;
  table:IUserInfo[];
}

export type ITrsList = {
  site_cd:string;
  track_no:string;
  det_seq: number,
  req_dat:string;
  req_type:string;
  req_tpn:string;
  track_step:string;
  track_stepn:string;
  camp_cd:string;
  serial_no:string;
  end_dat:string;
  loc_sts:string;
  loc_stsn:string;
  rma:string;
}

export type ITrsLists ={
  result_cd:string;
  result_msg:string;
  totals : number;
  table:ITrsList[];
}

export type ITrsBList = {
  site_cd:string;
  site_nm:string;
  camp_cd:string;
  track_no:string;
  det_seq:number;
  req_dat:string;
  req_type:string;
  req_tpn:string;
  loc_sts:string;
  loc_stsn:string;
  prod_cd :string;
  track_step:string;
  track_stepn:string;
  serial_no:string;
  req_tr_comp:string;
  req_tr_no : string;
  res_tr_comp:string;
  res_tr_no : string;
  rma:string;
}

export type ITrsBLists ={
  result_cd:string;
  result_msg:string;
  totals : number;
  table:ITrsBList[];
}


export interface IDataGridInfo{
  site_cd:string;
  skey:number;
  camp_cd:string;
  inx_key:string;
  site_nm:string;
  prod_cd:string;
  c_qty:number;
  t_qty:number;
  z_qty:number;
  tot_qty:number;
}

export interface IDatasGridInfo{
  result_cd:string;
  result_msg:string;
  totals : number;
  table:IDataGridInfo[];
}

export interface IAdminDataGridInfo{
  skey:number,
  site_cd:string;
  site_nm:string;
  st_end_dat:string;
  prod_cls:string;
  prod_cd:string;
  rep_cls:string;
  rep_clsn:string;
  qty:number;
}
export interface IAdminDataGridInfos{
  result_cd:string;
  result_msg:string;
  totals : number;
  table:IAdminDataGridInfo[];
}

export interface IGridColumnInfo{
  column_id:string;
  title:string,
}
export interface IGridColumnsInfo{
  columns:IGridColumnInfo[];
}

export interface TblSysUser {
  user_cd : string ;
  user_nm : string ;
  user_tp : string ;
  user_tpn: string ;
  site_cd : string ;
  site_nm : string ;
  camp_cd : string ;
  camp_nm : string ;
  del_flg : string ;
  log_stat: string ;
  passwd  : string ;
  email   : string ;
  phone   : string ;
  cmt    : string ;
  reg_user: string;
  reg_uname: string;
  reg_ip: string;
  reg_dev: string;
}
export type TblSysUsers = TblSysUser[];


