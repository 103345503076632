import { View, Text, TouchableOpacity} from 'react-native'
import { HeaderTop } from './HeaderTop'

export const NotFound:React.FC<{
  title?:string,
  message?:string,
  onRetry?:()=>void,
  onPressBack?:()=>void,
}> = ({title, message, onRetry, onPressBack}) =>{
  
    return (
      <View style={{flex:1}}>
      {/* 헤더 */}
      { onPressBack && title &&
        <HeaderTop
          title={title} 
          isMain={false}
          onPress={onPressBack} />
      }
      <View style={{justifyContent:'center', alignItems:'center'}}>
        <Text>{message}</Text>
        {
          onRetry&&
            <TouchableOpacity
              style={{justifyContent:'center', alignItems:'center'}}
              activeOpacity={0.5}
              onPress={onRetry}>
              <Text>재 시도</Text>
            </TouchableOpacity>
        }
      </View>
    </View>

  ) 
 }