import React, {createContext, useContext, Dispatch, useReducer} from 'react';
import { FindDefault, IFindInfo } from '../datas/TDataFind';
import { DefaultUser, IUserInfo } from '../datas/TrsDataType';


export type LoginInfos = {
  find     : IFindInfo;
  user     : IUserInfo;
  isCheck  : boolean;
  isLogin  : boolean;
  site_cd  : string;
  camp_cd  : string;
  user_tp  : string;
  reg_ip   : string;
  reg_dev  : string;
}


type LoginInfoState = LoginInfos;
const LoginStateContext = createContext<LoginInfos | undefined>(undefined);

type Action = 
| { type: 'SET_FIND';  find     : IFindInfo; }
| { type: 'SET_USER';  user     : IUserInfo; }
| { type: 'SET_LOGIN'; user     : IUserInfo;
                       user_tp  : string; 
                       reg_ip   : string; 
                       reg_dev  : string; 
                       site_cd  : string; 
                       camp_cd  : string; 
                       isCheck  : boolean;   }
| { type: 'SET_LOGOUT';  }
| { type: 'SET_SITE';  site_cd  : string; }


type LoginStateDispatch = Dispatch<Action>;
const LoginStateDispatchContext = createContext<LoginStateDispatch | undefined>(
  undefined
);

function LoginStateReducer(loginInfo: LoginInfos, action: Action): LoginInfos {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...loginInfo,
        user : action.user
      };
    case 'SET_LOGIN'          :
      //console.log('LoginContext SET_LOGin', action.user);
      
      
      return {
        ...loginInfo,
        user    : action.user,
        isLogin : true,
        site_cd : action.site_cd,
        camp_cd : action.camp_cd,
        user_tp : action.user_tp,
        reg_ip  : action.reg_ip,
        reg_dev : action.reg_dev,
    }
    case 'SET_LOGOUT'          :
      //  console.log('LoginContext SET_LOGOUT')
        return {
          ...loginInfo,
          user    : DefaultUser,
          isLogin : false,
          site_cd : '',
          camp_cd : '',
          user_tp : '',
          reg_ip  : '',
          reg_dev : '',
          find:FindDefault,
        }
      case 'SET_FIND'          :
        return {
          ...loginInfo,
          find:action.find,
        }
      case 'SET_SITE'          :
          return {
            ...loginInfo,
            site_cd:action.site_cd,
      }
    default:
      throw new Error('Unhandled action');
  }
}

export function LoginStateContextProvider({ children }: { children: React.ReactNode }) {
  const [userInfos, dispatch] = useReducer(LoginStateReducer, 
    { 
      user: DefaultUser,
      isLogin : false,
      isCheck : false,
      site_cd:'',
      camp_cd:'',
      user_tp:'',
      reg_ip:'',
      reg_dev:'',
      find : FindDefault,
    },
  );

  return (
    <LoginStateDispatchContext.Provider value={dispatch}>
      <LoginStateContext.Provider value={userInfos}>
        {children}
      </LoginStateContext.Provider>
    </LoginStateDispatchContext.Provider>
  );
}

export function useLoginState(){
  const state = useContext(LoginStateContext);
  if(!state) throw new Error('LoginStateProvider not found');
  return state;
}

export function useLoginStateDispatch(){
  const dispatch = useContext(LoginStateDispatchContext);
  if(!dispatch) throw new Error('LoginStateProvider not found');
  return dispatch;
}

