import { createNativeStackNavigator, NativeStackNavigationProp } from "@react-navigation/native-stack"
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { IFindInfo, FindDefault } from "../datas/TDataFind";
import { IUserInfo } from "../datas/TrsDataType";
import { ICode } from "../datas/TrsDataDetailType";
//const Stack = createNativeStackNavigator();
// 화면으로 넘어가는 Props type Defined
export type  StackParamList = {
  TrsMain: undefined,
  TRLogin: undefined,
  MoveReg: undefined,
  SelfTest: undefined,
  ClientReg: {
    menuId:string,
  },
  TrsList:{
    find:IFindInfo
    menuId:string,
  },
  TrsListDetail:{
    find:IFindInfo
    menuId:string,
  },
  TrsClientDetail:{
    find:IFindInfo
    menuId:string,
  },
  TrsServiceMove:{
    find:IFindInfo
    menuId:string,
  },
  TrsServiceReg:{
    find:IFindInfo
    menuId:string,
  },
  TrsRecordList:{
    find:IFindInfo
    menuId:string,
  },
  TrsAdminList:undefined,
  UserList:undefined,
  UserReg:undefined,
  UserDetail:{
    find:IFindInfo,
    menuId:string,
  },
  CodeList:undefined,
  CodeReg:{
    code:ICode
    menuId:string,
  },
  CodeDetail:{
    code:ICode
    menuId:string,
  },
  TrsRecord:undefined,
  SerialList:undefined,
  StackSelfTest:undefined,
  StackClientReg:undefined,
  StackTrsList:undefined,
  StackCode:undefined,
  StackUser:undefined,
  StackAdminList:undefined,
}
export const useTrsNavigation = <RouteName extends keyof StackParamList>()=>{
  return useNavigation<NativeStackNavigationProp<StackParamList, RouteName>>();
}

export const useTrsRoute = <RouteName extends keyof StackParamList>()=>{
  return useRoute<RouteProp<StackParamList,RouteName>>();
}

