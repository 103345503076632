import React, {createContext, useContext, Dispatch, useReducer} from 'react';
import { default_url } from '../datas/TrsApiType';
import { ICheckRepair, IRepair, ISelectCode, ITrsDetail } from '../datas/TrsDataDetailType';
import { DefaultSendDetail, DefaultSendRepair, ISendDetail, ISendRepair } from '../datas/TSendData';
import { DefaultCheckData, repDefault, reqDefault } from '../utils/data';

export type ReqInfos = {
  trs:ISendDetail,
  reps:ISendRepair[],
  secs:ICheckRepair[],
  secs1:ICheckRepair[],
  secs2:ICheckRepair[],
  apiname:string,
  send:string,
  url:string,
}

type reqState = ReqInfos;
const ReqStateContext = createContext<ReqInfos | undefined>(undefined);

type Action = 
| { type: 'SET_CREATE'; trs: ISendDetail; rep:ISendRepair[]}
| { type: 'SET_TRS';    trs: ISendDetail}
| { type: 'SET_REP';    reps: ISendRepair[]}
| { type: 'SET_SEC';    secs: ICheckRepair[]}
| { type: 'SET_SEC1';   secs1: ICheckRepair[]}
| { type: 'SET_SEC2';   secs2: ICheckRepair[]}
| { type: 'SET_SEND';   send: string; apiname:string}
| { type: 'SET_URL';    url: string;}
| { type: 'SET_SEND_INIT'; };

type ReqStateDispatch = Dispatch<Action>;
const ReqStateDispatchContext = createContext<ReqStateDispatch | undefined>(
  undefined
);

function ReqStateReducer(reqInfo: ReqInfos, action: Action): ReqInfos {
  switch (action.type) {
    case 'SET_CREATE':
      return {
        ...reqInfo,
        trs:action.trs,
        reps:action.rep
      };
    case 'SET_TRS'          :
      return {
        ...reqInfo,
        trs: action.trs,
    };
    case 'SET_REP'          :
      return {
        ...reqInfo,
        reps:action.reps,
    };
    case 'SET_SEC'          :
      return {
        ...reqInfo,
        secs:action.secs,
    };
    case 'SET_SEC1'          :
      return {
        ...reqInfo,
        secs:action.secs1,
    };
    case 'SET_SEC2'          :
      return {
        ...reqInfo,
        secs:action.secs2,
    };
    case 'SET_SEND'         :
      return {
        ...reqInfo,
        send:action.send,
        apiname:action.apiname,
      };
    case 'SET_SEND_INIT'         :
      return {
        ...reqInfo,
        send:'',
        apiname:'',
      };
    case 'SET_URL'         :
      return {
        ...reqInfo,
        url: default_url.concat(action.url),
      };
    default:
      throw new Error('Unhandled action');
  }
}

export function ReqStateContextProvider({ children }: { children: React.ReactNode }) {
  const [reqInfos, dispatch] = useReducer(ReqStateReducer, 
    { 
      trs:DefaultSendDetail,
      reps:[{...DefaultSendRepair}], 
      secs:[{...DefaultCheckData}], 
      secs1:[{...DefaultCheckData}], 
      secs2:[{...DefaultCheckData}], 
      send:'',
      url:'',
      apiname:'',
    },
  );

  return (
    <ReqStateDispatchContext.Provider value={dispatch}>
      <ReqStateContext.Provider value={reqInfos}>
        {children}
      </ReqStateContext.Provider>
    </ReqStateDispatchContext.Provider>
  );
}

export function useReqState(){
  const state = useContext(ReqStateContext);
  if(!state) throw new Error('ReqStateProvider not found');
  return state;
}

export function useReqDispatch(){
  const dispatch = useContext(ReqStateDispatchContext);
  if(!dispatch) throw new Error('ReqStateProvider not found');
  return dispatch;
}

