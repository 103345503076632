import { createNativeStackNavigator, NativeStackNavigationProp } from "@react-navigation/native-stack"
import React from 'react';

import { TrsMainScreen } from '../screens/TrsMainScreen';
import { StackTrsListNavi } from "./StackTrsListNavi";
import { StackSelfTestNavi } from "./StackSelfTestNavi";
import { StackClientRegNavi } from "./StackClientRegNavi";
import { StackTrsRecordListNavi } from "./StackTrsRecordNavi";
import { StackCodeNavi } from "./StackCodeNavi";
import { StackUserNavi } from "./StackUserNavi";
import { SerialListScreen } from "../screens/admins/SerialListScreen";
import { TrsServiceMoveScreen } from "../screens/TrsServiceMoveScreen";
import { TrsClientRegScreen } from "../screens/TrsClientRegScreen";
import { StackAdminListNavi } from "./StackAdminListNavi";
import { UserDetailScreen } from "../screens/admins/UserDetailScreen";
import { SelfTestScreen } from "../screens/SeftTestScreen";
import { TrsListScreen } from "../screens/TrsListScreen";
import { TrsListDetailScreen } from "../screens/TrsListDetailScreen";
import { TrsRecordListScreen } from "../screens/TrsRecordListScreen";
import { TrsAdminListScreen } from "../screens/admins/TrsAdminListScreen";
import { CodeListScreen } from "../screens/admins/CodeListScreen";
import { CodeRegScreen } from "../screens/admins/CodeRegScreen";
import { CodeDetailScreen } from "../screens/admins/CodeDetailScreen";
import { UserListScreen } from "../screens/admins/UserListScreen";
import { UserRegScreen } from "../screens/admins/UserRegScreen";
import { TrsClientDetailScreen } from "../screens/TrsClientDetailScreen";
import { TrsServiceRegScreen } from "../screens/TrsServiceReg";

const Stack = createNativeStackNavigator();

export const StackMainNavi:React.FC = ()=>{

  return (
    <Stack.Navigator
        initialRouteName='TrsMain'
        
        screenOptions={{
          headerShown:false,
      }}>
      <Stack.Screen name='TrsMain' component={TrsMainScreen}/>
      <Stack.Screen name='SelfTest' component={SelfTestScreen}/>
      <Stack.Screen name='TrsList' component={TrsListScreen}/>
      <Stack.Screen name='TrsListDetail' component={TrsListDetailScreen}/>
      <Stack.Screen name='TrsRecord' component={TrsRecordListScreen}/>
      <Stack.Screen name='AdminList' component={TrsAdminListScreen}/>
      <Stack.Screen name='CodeList' component={CodeListScreen}/>
      <Stack.Screen name='CodeReg' component={CodeRegScreen}/>
      <Stack.Screen name='CodeDetail' component={CodeDetailScreen}/>
      <Stack.Screen name='MoveReg' component={TrsServiceMoveScreen}/>
      <Stack.Screen name='ClientReg' component={TrsClientRegScreen}/>
      <Stack.Screen name='UserList' component={UserListScreen}/>
      <Stack.Screen name='UserReg' component={UserRegScreen}/>
      <Stack.Screen name='UserDetail' component={UserDetailScreen}/>
      <Stack.Screen name='SerialList' component={SerialListScreen}/>
      <Stack.Screen name='TrsAdminList' component={TrsAdminListScreen}/>
      <Stack.Screen name='TrsClientDetail' component={TrsClientDetailScreen}/>
      <Stack.Screen name='TrsServiceReg' component={TrsServiceRegScreen}/>

      
    </Stack.Navigator>   
)
}
