import React, {useEffect, useState, useRef, useCallback} from 'react';
import { View, Text, FlatList,ListRenderItem } from "react-native";
import { IFindInfo, FindDefault } from '../../datas/TDataFind';
import * as ApiCmd from '../../datas/TrsApiType';
import * as DataType from '../../datas/TrsDataType';
import { GridHeader, HeaderColumn, TextColumn } from './GridHeader';
import { ITrackR, ITracksR, ITrsDetail, ITrsDetails } from '../../datas/TrsDataDetailType';
import { DefectList } from './DefectList';
import { COLOR } from '../../utils/color';
import { Loading } from '../../components/Loading';
import { useLoginState } from '../../contexts/LoginContext';

export const TrackingGrid : React.FC <{
  url:string,
  title:string,
  item:ITrsDetails,
}> =(props) => {
  const loginState = useLoginState();

  const table = props.item.table[0];
  const [datas, setData] = useState<ITracksR>();
  const [run, setRun] = useState(ApiCmd.start);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState<IFindInfo>({
    ...FindDefault, 
    action:ApiCmd.action_track_rec,
    site_no:table.site_cd,
    track_no:table.track_no,
    det_seq:table.det_seq,
  });  


  const state = async ()=>{
    if ( loading === true) return;
    setLoading(true);
    const url =  ApiCmd.default_url.concat( props.url );
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(item),
    });
    const res = await apiResponse.json();
    setData(res);
    setRun(ApiCmd.stop);
    setLoading(false);
    setError(false);
  }

  useEffect(()=>{
    console.log('TrackingGrid.............', run);
    state();
  },[props.item])


 // useEffect(() => {
 //   if ( run === ApiCmd.start) state();
 // }, [run]);

  const onSync = useCallback(() => {
    state();
  },[] );

  const flatListRef = useRef(null);
  const ListHeaderComponent:React.FC<{}>=()=>{
    return(
      <View style={{flexDirection:"row", height:50}}>
        <HeaderColumn title='일자'/>
        <HeaderColumn title='위치'/>
        <HeaderColumn title='상태'/>
        {
          (loginState.user_tp === 'U02')&&
          <HeaderColumn title='RMA'/>
        }
      </View>
    );
  }

  const renderItem:ListRenderItem<ITrackR>=({item,index})=>{

    return(
      <View style={{height:50, borderBottomColor:COLOR.BORDER, borderBottomWidth:0.5}}>
        <View style={{flexDirection:"row"}}>
         <TextColumn value={item.rec_dat}/>
         <TextColumn value={item.loc_stsn}/>
         <TextColumn value={item.track_stepn}/>
         {
            (loginState.user_tp === 'U02')&&
             <TextColumn value={item.rma_no}/>
         }
         </View>
      </View>    
    );
  }

  if (loading) return <Loading message={'Loading...'}/>
  if (error) return  <View><Text>Error Occured: </Text></View>
  if (!datas){
    return (
      <View style={{flex:1}}>
        <GridHeader title={props.title+`${0} 건`} onSync={onSync} />
      </View>
    )
  }

  return (
    <View style={{flex:1}}>
    <GridHeader title={props.title+` ( ${datas?.totals} ) 건`} onSync={onSync} />
    <View style={{flex:1}}>
      <View>
          <FlatList 
            ref={flatListRef}
            data={datas?.table}
            renderItem={renderItem}
            keyExtractor={item=>item.track_no +'-' + `${item.det_seq}`+'-' + `${item.rec_seq}`}
            ListHeaderComponent={ListHeaderComponent}
            showsVerticalScrollIndicator={false}
          />      
      </View>
    </View>
 </View>
  );
}

