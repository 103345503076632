import {useNavigation} from '@react-navigation/native';
import dayjs from 'dayjs';
import React, {useCallback, useState,useEffect} from 'react';
import {View, Text, Platform} from 'react-native';
import { useTrsNavigation, useTrsRoute } from '../navigations/StackParamNavi';
import { Button } from '../components/Button';
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { DataGrid, IGridSelect } from './components/DataGrid';
import {Spacer} from '../components/Spacer';
import { useLoginState } from '../contexts/LoginContext';
import { HeaderTop, HeaderUserInfo } from './components/HeaderTop';
import * as ApiCmd from '../datas/TrsApiType';
import * as DataType from '../datas/TrsDataType';
import { IFindInfo, FindDefault  } from '../datas/TDataFind';
import { TrsCalendarBox } from './components/TrsModal';
import { InputSearchText } from './components/InputComponent';
import { useSize } from '../hooks/use-Size';
import { COLOR } from '../utils/color';
import { ExcelSync } from './components/ExcelSync';
import AsyncStorage from "@react-native-async-storage/async-storage";


const fontSize = Platform.OS === 'web' ? 16:14
export const TrsRecordListScreen:React.FC = () =>{
  const now = new Date();
  const navigation = useTrsNavigation();
  const route = useTrsRoute<'TrsRecordList'>();
  const loginState = useLoginState();
  const [winWidth, winHeight, isHorizontal] = useSize();
  const [searchText, setSearchText] = useState('');

  const [isSync, setSync] = useState(true);

  const [site, setSite] =  useState(loginState.site_cd);
  const [startDate, setStartDate] = useState(dayjs(now).set('month', now.getMonth()-1).format('YYYY-MM-DD').toString())
  const [endDate, setEndDate] = useState(dayjs(now).format('YYYY-MM-DD'))
  const [calendarShow, setCalendarShow] = useState(false);
  const [selected, setSelected] =  useState('');
  const [track_item, setTrackItem] = useState("");
  const user_cd = (loginState.user.user_tp === 'U0102')? loginState.user.user_cd: '';

  const [sendData,   setSendData]     = useState<any>();
  const [command,    setCommand]       = useState('');
  const [apiname,    setApiName]       = useState('');

  const [item, setItem] = useState<IFindInfo>({
    ...FindDefault, 
    action:ApiCmd.action_reclist,
    site_no: site,
    fr_dat : startDate,
    to_dat : endDate,
    user_cd : (loginState.user.user_tp === 'U0102' ) ? loginState.user.user_cd:"",
    user_tp: loginState.user.user_tp,
  });  

  console.log('TrsRecordList........', loginState.user.user_tp, loginState.user)  

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  const onSite = useCallback((key:string) => {
    console.log('App onSelect', key);
    setSite(key);
    setItem({...item, fr_dat:startDate, to_dat:endDate, site_no:key});
    setSync(true);
  },[item,  site] ); 

  
  const onGridSelectData = useCallback((value:IGridSelect) => {
    console.log('trsList', value);
    setItem({...item, track_no:value.track_no});
    if ( value.req_tp === '1'){
      const find1 = { 
        ...FindDefault,
        action: ApiCmd.action_track_no,
        site_no : loginState.user.site_cd,
        track_no: value.track_no,
        det_seq : value.det_seq,
      };
        navigation.navigate('TrsClientDetail', {find:find1, menuId:"TrsRecordList"});

    } // 세조회
    else if ( value.action === 'serial'){
      const find1 = { 
        ...FindDefault,
        action: ApiCmd.action_track_no,
        site_no : loginState.user.site_cd,
        track_no: value.track_no,
        det_seq : value.det_seq,
      };
        navigation.navigate('TrsClientDetail', {find:find1, menuId:"TrsRecordList"});

    } // 세조회
    else{
      const find1 = { 
        ...FindDefault,
        action: ApiCmd.action_trsblist,
        loc_sts:value.loc_sts,
        track_step:value.track_step,
        site_no : loginState.user.site_cd,
        track_no: value.track_no,
      };
  
      navigation.navigate('TrsListDetail', {find:find1,  menuId:"TrsRecordList"});
    }  // 일괄
  },[item] );

  const onPopDate = useCallback(() => {
    // 차후 달력 콘트롤  팝업 
    setCalendarShow(true);
  },[calendarShow]);

  // 달력선택시 호출 
  const onDate = useCallback((start:string, end:string) => {
    // 차후 달력 콘트롤 
    console.log('Start End............', start, end);
    if ( start && start.length > 0 )
    setStartDate(start);

    if ( end && end.length > 0 )
    setEndDate(end);

    setItem({...item, fr_dat:start, to_dat:end});
    setSync(true);

  },[startDate, endDate]);

  const onChangeText = useCallback( (name: string,value:string) => {
    setSearchText(value);
  }, [item]);

  const onSubmit = useCallback((name:string, text:string)=>{
    setItem({...item, 
             fr_dat:startDate, 
             to_dat:endDate, 
             serial_no:searchText});
    setSync(true);

 }, [item, searchText]);


  useEffect(()=>{
    console.log('useEffect.............', item);
  },[site, startDate, endDate]);

  const onExcel = useCallback((find:IFindInfo)=>{
    setSendData(find);
    setCommand('send');
    setApiName(ApiCmd.api_GetTrsViewExcel);
  }, [command, apiname])

  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    setApiName("");
    setCommand("");
    setSendData("");
  },[apiname]);


  return (
    <View style={{flex:1,backgroundColor:"white"}}>
      <HeaderTop
          title="서비스 이력현황" 
          isMain={false}
          onPress={onPressBack} 
          onHome={()=>{navigation.popToTop()}} />
      <View style={{flex:1, marginHorizontal: 16, backgroundColor:"white"}}>
        <Spacer space={20}/>
          <HeaderUserInfo isUser={false} onSelect={onSite} class_cd='acombo' />
        <Spacer space={10}/>
        <View style={{flexDirection: "row",height:30, alignItems:'center'}}>
          <Text style={{fontSize:12, color:COLOR.GREEN_1}}>서비스 완료만 조회합니다.</Text>
        </View>
        <Spacer space={5}/>

        <View style={{flexDirection: "row",height:50,borderWidth:1,borderColor:'#D9D9D9',borderRadius: 10}}>
          <View style={{flex:1,borderRightColor:'#D9D9D9',borderRightWidth:1, }}>
            <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
              <NoflexTypography color='#7F7F7F' fontSize={fontSize}>시작일</NoflexTypography>
            </View>   
          </View>
          <View style={{flex:2,borderRightColor:'#D9D9D9',borderRightWidth:1, }}>
              <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
                <Text style={{color:'#99C341', fontSize:fontSize,fontWeight:'bold'}}>{item.fr_dat}</Text>
              </View>   
          </View>
          <View style={{flex:1,borderRightColor:'#D9D9D9',borderRightWidth:1, }}>
              <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
                  <NoflexTypography color='#7F7F7F' fontSize={fontSize}>종료일</NoflexTypography>
              </View>   
            </View>
          <View style={{flex:2,borderRightColor:'#D9D9D9',borderRightWidth:2, }}>
              <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
                <Text style={{color:'#99C341', fontSize:fontSize,fontWeight:'bold'}}>{item.to_dat}</Text>
              </View>   
          </View>
          <View style={{flex:1,borderRightColor:'#99C341',borderRightWidth:1, }}>
            <Button onPress={onPopDate}>
              <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
                <Text style={{color:'#99C341', fontSize:fontSize,fontWeight:'bold'}}>기간설정</Text>
              </View>   
            </Button>  
          </View>
          
        </View>
        <Spacer space={5}/>
        <InputSearchText title={''} 
                      placeholder={'Serial # '} 
                      name='user_cd' 
                      value={searchText} 
                      onChange={(text:string)=>{
                        onChangeText('user_cd', text); 
                      }}

                      onSubmit={onSubmit}  
                      horizontal={isHorizontal} 
                      />
        <Spacer space={10}/>
        <View style={{flex:1}}>
        <DataGrid 
          url={ApiCmd.api_GetTrsViewRecord2} 
          title='서비스 이력 현황' 
          item={item}
          onSelectData={onGridSelectData}
          isSync={isSync}
          onEnd={()=>{setSync(false)}}
          onExcel={onExcel}
          />
        </View>
      </View>
      <TrsCalendarBox show={calendarShow} 
                      onOK={(startDate:string, endDate:string)=>{
                               setCalendarShow(false);
                               onDate(startDate, endDate);
                           }}
                      onCancel={()=>{
                                  console.log('Cancel......................'); 
                                  setCalendarShow(false);
                                }} />
     
     {   
     
      ( Platform.OS === 'web' && sendData ) && 
         <ExcelSync apiName={apiname} 
                    sendData={sendData} 
                     onData={onReceived} />
     
    }
    </View>
  )
}