import React, {useEffect, useState, useRef, useCallback} from 'react';
import { View, Platform,Text, TouchableOpacity, StyleSheet,FlatList,ListRenderItem } from "react-native";
import * as ApiCmd from '../../datas/TrsApiType';
import * as DataType from '../../datas/TrsDataType'
import { FindDefault, IFindInfo, ITrackings, ITracking } from '../../datas/TDataFind';
import { GridHeader, HeaderColumn, TextColumn, ButtonColumn, CheckColumn, HeaderColumn2, GridHeader2 } from './GridHeader';
import { COLOR } from '../../utils/color';
import { IGridSelect } from './DataGrid';
import { ISendBatch } from '../../datas/TSendData';
import { ISerial } from '../../datas/TrsDataDetailType';
import { useLoginState } from '../../contexts/LoginContext';

// 이동등록 사용
export const GridTrackingView : React.FC <{
  tableTitle:string,
  tableData?:ITrackings,
  onRefresh:()=>void,
  onChecked:(key:string, value:boolean)=>void,
}>=({tableTitle, tableData, onRefresh, onChecked})=>{
  const flatListRef = useRef(null);

  useEffect(()=>{
    console.log('tableData.......', tableData);
  },[tableData]);
  
  const ListHeaderComponent:React.FC<{}>=()=>{
    return(
      <View style={{flexDirection:"row", height:50}}>
        <HeaderColumn title='확인'/>
        <HeaderColumn title='상태'/>
        <HeaderColumn title='접수번호' flex={2}/>
        <HeaderColumn title='시리얼' flex={3} />
        {
          (Platform.OS === 'web')&&<HeaderColumn title='모델' flex={1}/>
        }
       <HeaderColumn title='SITE' flex={1}/>
      </View>
    )
  }

  const renderItem:ListRenderItem<ITracking>=({item,index})=>{

    if ( item.track_step === '01' && item.comp != '01'){
      // 입고이면서 택배접수인 경우 
      return(
        <View style={{ height: 50,}}>
        <View style={{flexDirection:"row",}}>
          <CheckColumn value={item.checked} 
                       key={item.serial_no} 
                       onChecked={(key:string, checked:boolean)=>{
                        onChecked(item.serial_no, checked);
                       }} />
          <TextColumn value={item.track_stepn}  />
          <TextColumn value={item.track_no} flex={2} />
          <View style={{flex:3}}>
            <TextColumn value={item.serial_no}  />
            <TextColumn value={item.comp_nm + '/' + item.tr_no}  />
          </View>
          {
          (Platform.OS === 'web')&&<TextColumn value={item.prod_cd} flex={1}/>
          }
          <TextColumn value={item.site_nm + '/' + item.camp_cd}  />
        </View>
        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
      );
    }

    if ( item.track_step === '05' || item.track_step === '04'){
      return(
        <View style={{ height: 50,}}>
        <View style={{flexDirection:"row",}}>
          <CheckColumn value={item.checked} 
                       key={item.serial_no} 
                       onChecked={(key:string, checked:boolean)=>{
                        onChecked(item.serial_no, checked);
                       }} />
          <TextColumn value={item.track_stepn}  />
          <TextColumn value={item.track_no} flex={2} />
          <View style={{flex:3}}>
            <TextColumn value={item.serial_no}  />
            {
              (item.rma && item.rma.length > 0) &&
              <TextColumn value={item.rma}  />

            }
          </View>
          {
          (Platform.OS === 'web')&&<TextColumn value={item.prod_cd} flex={1}/>
          }
          <TextColumn value={item.site_nm + '/' + item.camp_cd}  />
        </View>
        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
      );
    }
    return(
      <View style={{ height:50,}}>
        <View style={{flexDirection:"row",}}>
          <CheckColumn value={item.checked} 
                       key={item.serial_no} 
                       onChecked={(key:string, checked:boolean)=>{

                        onChecked(item.serial_no, checked);
                       }} />
          <TextColumn value={item.track_stepn}  />

          <TextColumn value={item.track_no} flex={2} />
          <TextColumn value={item.serial_no}  flex={3}  />
          {
          (Platform.OS === 'web')&&<TextColumn value={item.prod_cd} flex={1}/>
          }
          <TextColumn value={item.site_nm + '/' + item.camp_cd}  />
        </View>
        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
    );
  }
  return (
    <View style={{flex:1}}>
      <GridHeader2 title={tableTitle + ` ( ${tableData && tableData?.totals | 0} ) 건`} onSync={onRefresh} />
      <View style={{flex:1}}>
        <View style={{flex:1}}>
          { 

          tableData&&
            <FlatList 
            ref={flatListRef}
            data={tableData.table}
            renderItem={renderItem}
            ListHeaderComponent={ListHeaderComponent}
            showsVerticalScrollIndicator={false}
          />      
          }

          {
            !tableData&&
            <ListHeaderComponent />
          }

        </View>
      </View>
    </View>
  )
}

// A/S 진행 관리
export const GridView : React.FC <{
  tableTitle:string,
  tableData:ITrackings,
  onRefresh:()=>void,
}>=({tableTitle, tableData, onRefresh})=>{
  const flatListRef = useRef(null);
  console.log('GridView...........', tableData);
  const ListHeaderComponent:React.FC<{}>=()=>{
    return(
      <View style={{flexDirection:"row", height:50}}>
        <HeaderColumn title='센터'/>
        <HeaderColumn title='접수일'/>
        <HeaderColumn title='구분'/>
        <HeaderColumn title='접수번호' flex={2}/>
        <HeaderColumn title='진행상태' flex={1} />
      </View>
    )
  }
  const renderItem:ListRenderItem<ITracking>=({item,index})=>{
    return(
      <View style={{ height:50,}}>
        <View style={{flexDirection:"row",}}>
          <TextColumn value={item.track_no} flex={2} />
          <TextColumn value={item.serial_no}  flex={3}  />
          <TextColumn value={item.track_stepn}  />
        </View>
        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
    );
  }
  return (
    <View style={{flex:1}}>
      <GridHeader title={tableTitle + ` ( ${tableData?.totals | 0} ) 건`} onSync={onRefresh} />
      <View style={{flex:1}}>
        <View>
          { 

          tableData&&
            <FlatList 
            ref={flatListRef}
            data={tableData.table}
            renderItem={renderItem}
            ListHeaderComponent={ListHeaderComponent}
            showsVerticalScrollIndicator={false}
          />      
          }
          {
            !tableData&&
            <ListHeaderComponent />
          }
        </View>
      </View>
    </View>
  )
}

// 
export const GridBatchView : React.FC <{
  tableTitle:string,
  tableData?:DataType.ITrsBLists,
  onRefresh:()=>void,
  onSelectData:(value:DataType.ITrsBList)=>void,
}>=({tableTitle, tableData, onRefresh, onSelectData})=>{
  const loginState = useLoginState();
  const flatListRef = useRef(null);
  console.log('GridBatchView...........', tableData);

  const ListHeaderComponent:React.FC<{}>=()=>{
    return(
      <View style={{flexDirection:"row", height:50}}>
      <HeaderColumn title='Site'  />
      <HeaderColumn title='모델'/>
      <HeaderColumn title='시리얼번호' flex={3} />
      <HeaderColumn title='진행'/>
      <HeaderColumn title='위치'/>
    </View>
   )
  }

  const renderItem:ListRenderItem<DataType.ITrsBList>=({item,index})=>{

    if (loginState.user_tp === 'U02' &&  item.loc_sts === '02'){
      return(
        <View style={{ height:50,}}>
        <View style={{flexDirection:"row",}}>
          <TextColumn value={item.camp_cd}  />
          <TextColumn value={item.prod_cd} />
          <View style={{flex:3}}>
            <ButtonColumn value={item.serial_no} 
                          index={index} 
                          onData={()=>{onSelectData(item)}} />
            <TextColumn value={item.rma}  pfontSize={13}/>
          </View>
          <TextColumn value={item.track_stepn}  pfontSize={13}/>
          <TextColumn value={item.loc_stsn} pfontSize={13} />
        </View>
        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
      );
  
    }
    return(
      <View style={{ height:50,}}>
        <View style={{flexDirection:"row",}}>
          <TextColumn value={item.camp_cd}  />
          <TextColumn value={item.prod_cd} />
          <ButtonColumn value={item.serial_no} 
                        flex={3} 
                        index={index} 
                        onData={()=>{onSelectData(item)}} />
          <TextColumn value={item.track_stepn}  pfontSize={13}/>
          <TextColumn value={item.loc_stsn} pfontSize={13} />
        </View>
        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
    );
  }
  return (
    <View style={{flex:1}}>
      <GridHeader title={tableTitle + ` ( ${tableData && tableData?.totals | 0} ) 건`} onSync={onRefresh} />
      <View style={{flex:1}}>
          { 
          tableData&&
            <FlatList 
            ref={flatListRef}
            data={tableData.table}
            renderItem={renderItem}
            ListHeaderComponent={ListHeaderComponent}
            showsVerticalScrollIndicator={false}
          />      
          }
          {
            !tableData&&
            <ListHeaderComponent />
          }
      </View>
    </View>
  )

}



//-- Excel Load 된 상태 
export const GridExcelLoad : React.FC <{
  tableTitle:string,
  tableData?:ISendBatch[],
  onRefresh:()=>void,
  onSelectData:(value:ISendBatch)=>void,
}>=({tableTitle, tableData, onRefresh, onSelectData})=>{
  const flatListRef = useRef(null);
  console.log('GridExcelLoad...........', tableData);

  const ListHeaderComponent:React.FC<{}>=()=>{
    return(
      <View>
        <View style={{flexDirection:"row", height:40, borderTopColor:COLOR.GRAY_2, borderTopWidth:0.7}}>
        <HeaderColumn title='시리얼'   flex={2} height={40} />
        <HeaderColumn title='장애'     height={40} />
        <HeaderColumn title='장애상세' flex={3} height={40} />
        <HeaderColumn title='Site'     height={40} />
        <HeaderColumn title='신청자'   height={40} />
        <HeaderColumn title='접수방식' height={40} />
        <HeaderColumn title='송장번호' height={40} />
        </View>
      </View>
   )
  }

  const renderItem:ListRenderItem<ISendBatch>=({item,index})=>{
    return(
      <View style={{ height:(item.prod_cls.length> 0)?80:40,}}>
        <View style={{flexDirection:"row", height:40, alignItems:'center'}}>
          <TextColumn value={item.serial_no} flex={2} />
          <TextColumn value={item.rep_cdn} />
          <TextColumn value={item.rep_det} flex={3} />
          <TextColumn value={item.camp_cd} />
          <TextColumn value={item.camp_req_nm} />
          <TextColumn value={item.tr_comp} />
          <TextColumn value={item.tr_no} />
        </View>
        {
        (item.prod_cls.length > 0) &&
        <View style={{flexDirection:"row",height:40, alignItems:'center'}}>
          <TextColumn value={item.prod_cd} flex={1} color={(item.prod_cls ==='0')?'red':COLOR.ICONCOLOR}/>
        </View>
        }

        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
    );
  }
  return (
    <View style={{flex:1}}>
      <GridHeader2 title={tableTitle + ` ( ${tableData && tableData.length | 0} ) 건`} onSync={onRefresh} />
      <View style={{flex:1}}>
          { 
          tableData&&
            <FlatList 
            ref={flatListRef}
            data={tableData}
            renderItem={renderItem}
            ListHeaderComponent={ListHeaderComponent}
            showsVerticalScrollIndicator={false}
          />      
          }
          {
            !tableData&&
            <ListHeaderComponent />
          }
      </View>
    </View>
  )


}


//-- Excel Load 된 상태 
export const GridSerialExcelLoad : React.FC <{
  tableTitle:string,
  tableData?:ISerial[],
  onRefresh:()=>void,
  onSelectData:(value:ISerial)=>void,
}>=({tableTitle, tableData, onRefresh, onSelectData})=>{
  const flatListRef = useRef(null);
  console.log('GridExcelLoad...........', tableData);

  const ListHeaderComponent:React.FC<{}>=()=>{
    return(
      <View>
        <View style={{flexDirection:"row", height:40, borderTopColor:COLOR.GRAY_2, borderTopWidth:0.7}}>
        <HeaderColumn title='Serial#' flex={2} height={40} />
        <HeaderColumn title='Product Name'     height={40} />
        <HeaderColumn title='Start Date'       height={40} />
        <HeaderColumn title='End Date'         height={40} />
        <HeaderColumn title='Device Type'         height={40} />
        </View>
      </View>
   )
  }

  const renderItem:ListRenderItem<ISerial>=({item,index})=>{
    return(
      <View style={{ height:(item.result_cd.length > 0)?80:40,}}>
        <View style={{flexDirection:"row", height:40, alignItems:'center'}}>
          <TextColumn value={item.serial} flex={2} />
          <TextColumn value={item.prod_nm} />
          <TextColumn value={item.st_date} />
          <TextColumn value={item.en_date} />
          <TextColumn value={item.prod_cls} />
        </View>
        {
        (item.result_cd.length > 0) &&
        <View style={{flexDirection:"row",height:40, alignItems:'center'}}>
          <TextColumn value={item.result_msg} flex={1} color={(item.result_cd ==='0')?'red':COLOR.ICONCOLOR}/>
        </View>
        }
        <View style={{backgroundColor: COLOR.BORDER, height:1}}></View>
      </View>
    );
  }
  return (
    <View style={{flex:1}}>
      <GridHeader2 title={tableTitle + ` ( ${tableData && tableData.length | 0} ) 건`} onSync={onRefresh} />
      <View style={{flex:1}}>
          { 
          tableData&&
            <FlatList 
            ref={flatListRef}
            data={tableData}
            renderItem={renderItem}
            ListHeaderComponent={ListHeaderComponent}
            showsVerticalScrollIndicator={false}
          />      
          }
          {
            !tableData&&
            <ListHeaderComponent />
          }
      </View>
    </View>
  )
}



