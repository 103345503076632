import React from 'react';
import { Text as RNText } from 'react-native';
import { COLOR } from '../../utils/color';
// export class Typography extends React.Component{
//     render(){
//         return (
//             <RNText 
//                 style={{
//                     color:this.props.color, 
//                     fontSize:this.props.fontSize
//                 }}>
//                 {this.props.children}
//             </RNText>
//         )
//     }
// } 
export const NoflexTypography:React.FC<{
    color?:string,
    fontSize?:number,
    fontWeight?:string,
    numberOfLines?:number,
    background?:string,
    children:React.ReactElement | string | React.ReactElement[]
}> = (props)=>{
    return (
        <RNText 
            style={{ 
                color: props.color ?? 'black', 
                fontSize: props.fontSize ?? 10,
                backgroundColor:props.background ?? 'white',
            }}
            numberOfLines={props.numberOfLines}>
            {props.children}
        </RNText>
    )
}

// Typography.propTypes = {
//     color: PropTypes.string,
//     fontSize: PropTypes.number.isRequired,
//     children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired
// }