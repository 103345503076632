import React, {useCallback, useState,useEffect,useRef} from 'react';
import {Platform, View,Text, StyleSheet, ScrollView, TextInput, KeyboardAvoidingView, Modal} from 'react-native';
import { useLoginState } from '../../contexts/LoginContext';
import { FindDefault, IFindInfo } from '../../datas/TDataFind';
import { DefaultUser,  IUsersInfo } from '../../datas/TrsDataType';
import { useTrsNavigation, useTrsRoute } from '../../navigations/StackParamNavi';
import { HeaderTop } from '../components/HeaderTop';
import * as ApiCmd from '../../datas/TrsApiType';
import { useSize } from '../../hooks/use-Size';
import { Sync } from '../components/Sync';
import { IRltCode } from '../../datas/TSendData';
import { DefaultMsg, TrsMessageShow } from '../components/TrsModal';
import { Caption, InputDropDownList, InputSearchText, InputText,  } from '../components/InputComponent';
import { Spacer } from '../../components/Spacer';
import { Button } from '../../components/Button';
import { NoflexTypography } from '../../components/custom/NoflexTypography';
import { COLOR } from '../../utils/color';
import { IDDLSelectValue } from '../../datas/TrsDataDetailType';
import * as Crypto from 'expo-crypto';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

interface IFocused{
  snFocused:Boolean;
  nmFocused:Boolean;
  pwFocused:Boolean;
  maFocused:Boolean;
  phFocused:Boolean;
  adFocused:Boolean;
}

export const UserRegScreen:React.FC = () =>{
  const route = useTrsRoute<'UserReg'>();
  const navigation = useTrsNavigation<'UserReg'>();
  const loginState = useLoginState();
  const [winWidth, winHeight, isHorizontal, menuFontSize] = useSize();
  const [input, setInput] = useState(DefaultUser);
  const [isUser, setIsUser] = useState(false);
  const [sendData, setSendData] = useState<any>();
  const [command, setCommand] = useState('');
  const [apiname, setApiName] = useState('');
  const [msgShow, setMsgShow] = useState(DefaultMsg);
  const [focused, setFocused] = useState<IFocused>({
    snFocused:false,
    nmFocused:false,
    pwFocused:false,
    maFocused:false,
    phFocused:false,
    adFocused:false,
  });

  const textHeight=(Platform.OS ==='web') ? 45:50;

  const ref_input: Array<React.RefObject<TextInput>> = [];
  //ref_input = forwardRef(ref_input)
  ref_input[0] = useRef(null); // user_cd
  ref_input[1] = useRef(null); // user_nm
  ref_input[2] = useRef(null); // passwd
  ref_input[3] = useRef(null); // email
  ref_input[4] = useRef(null); // phone
  ref_input[5] = useRef(null); // Address

  const onFocusNext = (index: number) => {
    if (ref_input[index + 1] && index < ref_input.length - 1) {
      ref_input[index + 1].current?.focus();
    }
    if (ref_input[index + 1] && index == ref_input.length - 1) {
      ref_input[index].current?.blur();
    }
  };
  
  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  const onPressBack = useCallback(() => {
  //  navigation.reset({routes: [{name: "UserList"}]})
     navigation.goBack();
  },[] );

  const onPressInit= useCallback(()=>{
    setInput ({...DefaultUser});
    setIsUser(false);
    onFocusNext(0);

  }, [input,isUser])

  const onPressReg = useCallback(() => {
    console.log('onPressReg');


    if ( loginState.user.user_tp === 'U0100' && input.site_cd.length === 0){
      setMsgShow({...DefaultMsg, msgId:'user', message:'센타 선택해 주세요'});
      return;
    }
    if (  loginState.user.user_tp === 'U0101' && input.camp_cd.length === 0){
      setMsgShow({...DefaultMsg, msgId:'user', message:'SITE 선택해 주세요'});
      return;
    }

    // validation check
    if ( isUser === false){
      setMsgShow({...DefaultMsg, msgId:'user', message:'사용자 ID 를 체크하세요'});
      return;
    }
    if (input.user_nm.length === 0 ){
      setMsgShow({...DefaultMsg, msgId:'user', message:'사용자 명을 입력하세요'});
      return;
    }

    var pattern1 = /[0-9]/; //숫자
    var pattern2 = /[a-zA-Z]/; //영어
    var pattern3 = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
    var pattern4 = /[~!@#\#$%<>^&*]/; //특수문자

    var num = pattern1.test(input.passwd);
    var alph = pattern2.test(input.passwd);
    var spec = pattern4.test(input.passwd);
    if ( !num){
      setMsgShow({...DefaultMsg, msgId:'user', 
                  message:'숫자 미포함', 
                  message2: '영숫자,특수문자로 조합 8자리 또는 이상 입력하세요' });
      return;
    }
    if ( !alph){
      setMsgShow({...DefaultMsg, msgId:'user', 
                  message:'영문자 미포함', 
                  message2: '영숫자,특수문자로 조합 8자리 또는 이상 입력하세요' });
      return;
    }
    if ( !spec){
      setMsgShow({...DefaultMsg, msgId:'user', 
                  message:'특수기호 미포함', 
                  message2: '영숫자,특수문자로 조합 8자리 또는 이상 입력하세요' });
      return;
    }

    if ( input.passwd.length < 8 ){
      setMsgShow({...DefaultMsg, msgId:'user', 
                  message:'8자리 미만입니다.', 
                  message2: '영숫자,특수문자로 조합 8자리 또는 이상 입력하세요' });
      return;
    }

    /*
    if ( input.email.length < 6 ){
      setMsgShow({...DefaultMsg, msgId:'user', message:'Email 정보를 입력하세요.'});
      return;
    }
    if ( input.phone.length < 8 ){
      setMsgShow({...DefaultMsg, msgId:'user', message:'연락처를 입력하세요.'});
      return;
    }
    */


    (async () => {
      const digest = await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256,
        input.passwd,
      );
      console.log('Digest: ', digest);
      /* Some crypto operation... */
      console.log('create hash[', digest, ']' )

      const tmp_user_tp = (loginState.user.user_tp === 'U0101') ? 'U0102'
      : (loginState.user.user_tp === 'U0100')? 'U0101'
      : 'U0201';
      if ( loginState.user.user_tp === 'U0100' )
        setSendData({...input, 
                    action:'insert', 
                    passwd:digest,
                    user_tp:tmp_user_tp});
      else if ( loginState.user.user_tp === 'U0101' )
        setSendData({...input, 
                    action:'insert', 
                    passwd:digest,
                    site_cd:loginState.user.site_cd,
                    user_tp:tmp_user_tp});
      else                                 
        setSendData({...input, 
          action:'insert', 
          passwd:digest,
          user_tp:tmp_user_tp});

      setCommand('save');
      setApiName(ApiCmd.api_SetUser);
    })();

  },[command, apiname, sendData, input, loginState]); 


  const onChangeText = useCallback((name:string, value:string)=>{
     if ( name === 'user_cd'){
      setInput({...input, user_cd:value});
      setIsUser(false);
     }
     if ( name === 'user_nm') setInput({...input, user_nm:value});
     if ( name === 'passwd') setInput({...input, passwd:value});
  }, [input])

  const onSelect = useCallback((key:IDDLSelectValue) => {
    const { name, value } = key; // 우선 e.target 에서 name 과 value 를 추출

    console.log('onSelect.........................', name, value);

    setInput({...input, [name]:value});

  },[input, isUser] ); 

  

  const onSubmit = useCallback((name:string, value:string)=>{
    console.log('onSubmit');
    if ( name === 'user_cd'){
      const tmp = {...FindDefault, action:'select', user_cd:value}
      console.log('onSubmit...............',tmp)
      setInput({...input, user_cd:value});
      setSendData(tmp);
      setCommand('user_cd');
      setApiName(ApiCmd.api_GetUser);
      return;
    }

    if ( name === 'user_nm') {
      setInput({...input, user_nm:value});
      onFocusNext(1);
    }
    if ( name === 'passwd') {

      setInput({...input, passwd:value});
      onFocusNext(2);
    }
  }, [input, command, apiname, sendData]) 

 
  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    console.log('onReceived ClientReg..............', command, resultCode, cmd, rData, )
    setApiName(''); setSendData(null);
    if (resultCode === true) {
      if ( command === 'user_cd'){
        // 사용자 ID Check 
        const tmpUser:IUsersInfo = rData;
        if ( tmpUser.totals === 0 ){
          setIsUser(true);
          onFocusNext(0);

        }
        else{
          setMsgShow({...DefaultMsg, msgId:cmd, message:'존재하는 ID 입니다.'});
        }
        
      }
      else{
        const tmp:IRltCode = rData;
      
        if ( tmp.result_cd === "1"){
          setMsgShow({...DefaultMsg, msgId:cmd, message:tmp.result_msg});
        } 
        else {
          console.log('Message.. tmp.result_cd', tmp.result_cd);
          setMsgShow({...DefaultMsg, msgId:cmd, message:tmp.result_msg});
        } 
      }
    }
    else {
      setMsgShow({...DefaultMsg, msgId:cmd, message:rData});
    }

  }, [msgShow, apiname, sendData, input]);

 

  return (
    <View style={{flex:1, backgroundColor:'white' }}>
      <HeaderTop title="사용자 등록" 
                 isMain={false}
                 onPress={onPressBack} 
                 onHome={()=>{navigation.popToTop()}} 
                 />

      <ScrollView style={{marginHorizontal:26, }} showsVerticalScrollIndicator={false}>
        <Spacer space={16}/>
        {
          (loginState.user.user_tp === 'U0100') &&
          <View>
            <InputDropDownList title={'센타'} 
                                astar="*"
                                name='site_cd' 
                                action='scombo'
                                class_cd={'SITE'} 
                                onSelect={onSelect}  
                                horizontal={isHorizontal}/>
            <Spacer space={16}/>
          </View>
        }
        {
          (loginState.user.user_tp === 'U0101') &&
          <View>
            <InputDropDownList title={'SITE'} 
                                name='camp_cd' 
                                action='scombo'
                                class_cd={loginState.user.site_cd} 
                                onSelect={onSelect}  
                                horizontal={isHorizontal}/>
            <Spacer space={16}/>
          </View>  
        }
        <Spacer space={16}/>
        <InputSearchText  title={'사용자 ID'} 
                          asta="*"
                          placeholder={'사용자 ID 를 입력해 주세요.'} 
                          name='user_cd' 
                          value={input.user_cd} 
                          ref={ref_input[0]}
                          onChange={(text:string)=>{
                            onChangeText('user_cd', text); 
                          }}
                          onSubmit={onSubmit}  
                          keyboardType={'default'}
                          iconName={'checkmark'}
                          iconColor={isUser ? COLOR.GREEN_1:'black'}
                          />
        <Spacer space={16}/>
        <View>
          <Caption flex={0} title={'사용자 명'} astar={'*'} horizontal={false} />
          <Spacer space={5}/>
          <View style={{...styles.inputStyle, 
                            height:textHeight,
                            borderColor:focused.maFocused ? COLOR.GREEN_1: '#D9D9D9',
                          }}>
            <TextInput 
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  ref={ref_input[1]}
                  returnKeyType={"next"}
                  value={input.user_nm}
                  onChangeText={(text:string)=>{
                    onChangeText('user_nm', text); 
                    }}
                  placeholder={'사용자 명을 입력하세요' }
                  style={{fontSize:16, color:COLOR.GREEN_1,flex:1,width:'100%'}}
                  onFocus={()=> setFocused({...focused, nmFocused:true})} 
                  onBlur={()=> setFocused({...focused, nmFocused:false})}
                  blurOnSubmit={false}
                  onSubmitEditing={()=>{onSubmit('user_nm', input.passwd);}}
                  keyboardType={'default'}
                  />
          </View>
        </View>


        <Spacer space={16}/>
        <View>
          <Caption flex={0} title={'패스워드'} astar={'*'} horizontal={false} />
          <Spacer space={5}/>

          <View style={{...styles.inputStyle, 
                            height:textHeight,
                            borderColor:focused.maFocused ? COLOR.GREEN_1: '#D9D9D9',
                          }}>
            <TextInput 
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  ref={ref_input[2]}
                  returnKeyType={"next"}
                  value={input.passwd}
                  onChangeText={(text:string)=>{
                    onChangeText('passwd', text); 
                    }}
                  placeholder={'영숫자,특수문자로 조합 8자리 또는 이상 입력하세요' }
                  style={{fontSize:16, color:COLOR.GREEN_1,flex:1,width:'100%'}}
                  onFocus={()=> setFocused({...focused, pwFocused:true})} 
                  onBlur={()=> setFocused({...focused, pwFocused:false})}
                  blurOnSubmit={false}
                  onSubmitEditing={()=>{onSubmit('passwd', input.passwd);}}
                  keyboardType={'default'}
                  />
          </View>

        </View>
        {
          /*
        <Spacer space={16}/>
        
        <View>
          <Caption flex={0} title={'메일'} astar={'*'} horizontal={false} />
          <Spacer space={5}/>
          <View style={{...styles.inputStyle, 
                          height:textHeight,
                          borderColor:focused.maFocused ? COLOR.GREEN_1: '#D9D9D9',
                      }}>
            <TextInput 
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  ref={ref_input[3]}
                  returnKeyType={"next"}
                  value={input.email}
                  onChangeText={(text:string)=>{
                    onChangeText('email', text); 
                    }}
                  placeholder={'메일을 입력하세요' }
                  style={{fontSize:16, color:COLOR.GREEN_1,flex:1,width:'100%'}}
                  onFocus={()=> setFocused({...focused, maFocused:true})} 
                  onBlur={()=> setFocused({...focused, maFocused:false})}
                  blurOnSubmit={false}
                  onSubmitEditing={()=>{onSubmit('email', input.email);}}
                  keyboardType={'email-address'}
                  />
          </View>
        </View>


      <Spacer space={16}/>
      <View>
          <Caption flex={0} title={'연락처'} astar={'*'} horizontal={false} />
          <Spacer space={5}/>

          <View style={{...styles.inputStyle, 
                        height:textHeight,
                        borderColor:focused.phFocused ? COLOR.GREEN_1: '#D9D9D9',
                        }}>
            <TextInput 
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  ref={ref_input[4]}
                  returnKeyType={"next"}
                  value={input.phone}
                  onChangeText={(text:string)=>{
                    onChangeText('phone', text); 
                    }}
                  placeholder={'연락처를 입력하세요' }
                  style={{fontSize:16, color:COLOR.GREEN_1,flex:1,width:'100%'}}
                  onFocus={()=> setFocused({...focused, phFocused:true})} 
                  onBlur={()=> setFocused({...focused, phFocused:false})}
                  blurOnSubmit={false}
                  onSubmitEditing={()=>{onSubmit('phone', input.phone);}}
                  keyboardType={'phone-pad'}
                  />
          </View>
        </View>

        <Spacer space={16}/>
        <View>
          <Caption flex={0} title={'주소'} astar={''} horizontal={false} />
          <Spacer space={5}/>

          <View style={{...styles.inputStyle, 
                        height:textHeight,
                        borderColor:focused.adFocused ? COLOR.GREEN_1: '#D9D9D9',
                        }}>
            <TextInput 
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  ref={ref_input[5]}
                  returnKeyType={"next"}
                  value={input.cmt}
                  onChangeText={(text:string)=>{
                    onChangeText('cmt', text); 
                    }}
                  placeholder={'주소를 입력하세요.' }
                  style={{fontSize:16, color:COLOR.GREEN_1,flex:1,width:'100%'}}
                  onFocus={()=> setFocused({...focused, adFocused:true})} 
                  onBlur={()=> setFocused({...focused, adFocused:false})}
                  blurOnSubmit={false}
                  onSubmitEditing={()=>{onSubmit('cmt', input.cmt);}}
                  keyboardType={'default'}
                  />
          </View>
        </View>
            */
        }

        <Spacer space={16}/>


      </ScrollView>
      <View style={{flexDirection:'row',  height: 60,
                   alignItems:'flex-end', justifyContent:'space-around', 
                   marginBottom:16, marginHorizontal:20, 
                    }}>
                    
        <View style={{flex:1, marginHorizontal:10}}>
              <Button onPress={onPressInit}>
                <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                    <NoflexTypography fontSize={menuFontSize} color='#ffffff' background='#979b9e'>초기화</NoflexTypography>
                </View>
              </Button>
        </View>              

        <View style={{flex:1, marginHorizontal:10}}>
              <Button onPress={onPressReg}>
                <View style={{borderRadius:10,backgroundColor:COLOR.GRAY_3, alignItems:'center', justifyContent:'center', height: 40}}>
                    <NoflexTypography fontSize={menuFontSize} color='#ffffff' background='#979b9e'>저장</NoflexTypography>
                </View>
              </Button>
        </View>              
      </View>
      <TrsMessageShow message={msgShow} 
                        onMessage={ (msgId:string)=>{ setMsgShow(DefaultMsg) }} />
      {
         sendData && <Sync apiName={apiname} 
                           sendData={sendData} 
                          onData={onReceived} />
      }

    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer:{
    flex:3, justifyContent:'flex-end'
  },
  inputStyle:{
    flexDirection:'row',
    alignSelf:'stretch', 
    alignItems:'center',
    paddingHorizontal:20, 
    paddingVertical:12, 
    borderRadius: 10,
    borderWidth:1, 
    borderColor:'#D9D9D9',
    justifyContent:'space-between'
  },

});
