import Checkbox from 'expo-checkbox';
import React, {useCallback,useState,useEffect, LegacyRef, forwardRef} from 'react';
import { 
  StyleSheet, 
  View,
  useWindowDimensions, 
  Text, 
  TextInput, 
  KeyboardTypeOptions,
  Platform,
  TouchableOpacity} from 'react-native';
import CheckBox from '@react-native-community/checkbox';
import { Button } from '../../components/Button';
import { NoflexTypography } from '../../components/custom/NoflexTypography';
import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import { IDDLSelectValue, ISelectCode, ISelectInfo } from '../../datas/TrsDataDetailType';
import { COLOR } from '../../utils/color';
import { DataDropDownList, DataSelectList } from '../components/DataSelectList';
import { useApiSync } from '../../hooks/use-ApiSync';
import { FindDefault, IFindInfo } from '../../datas/TDataFind';
import { SelectList } from '../../components/dropdown/CustomSelectList';
import { api_GetCode, default_url } from '../../datas/TrsApiType';
import { Ionicons } from '@expo/vector-icons';
import { IconName } from '../../components/Icons';
import { getIndex } from '../../utils/main';
const titleflex=1;
const dataflex=3;
const height=40;


export const Caption:React.FC <{
  title : string,
  horizontal?:boolean;
  astar?:string,
  flex?:number;
}>=(props)=>{

  const fontSize=(Platform.OS ==='web') ? 16:15;
  const height=(Platform.OS ==='web') ? 50:40;

  if (props.horizontal){
    return(
        <View style={{flex: props.flex ?? 1,
                      justifyContent:'center',
                       alignItems:'center',
                       flexDirection:'row',
                       height:height}}>
          <NoflexTypography color='#7F7F7F' fontSize={fontSize}>{props.title}</NoflexTypography>
          {
            props.astar&&<NoflexTypography color='red' fontSize={fontSize} >{" " + props.astar +" "}</NoflexTypography>
          }
        </View>
    )  
  }
  return (
    <View style={{ paddingLeft:10, 
                    flexDirection: "row", 
                    justifyContent:"flex-start"}}>
      <NoflexTypography color='#7F7F7F' fontSize={fontSize}>{props.title}</NoflexTypography>
  
      {
        props.astar&&<Typography color='red' fontSize={14} >{" " + props.astar +" "}</Typography>
      }
    </View>
  )
}

export const InputDropDownList:React.FC <{
  title : string,
  class_cd: string,
  name:string,
  action?:string,
  data? : string, 
  horizontal?:boolean;
  astar?:string,
  placeholder ?:string,
  onSelect:(key:IDDLSelectValue)=>void,
  isSync?:boolean,
  onSync?:()=>void,
}> =(props)=>{

  const [selected, setSelected] = React.useState<IDDLSelectValue>({name:props.name, value:'', title:'', sub_cd1:'', sub_cd2:'', checked:false});
  const [datas, setData] = useState<ISelectInfo>();
  const [run, setRun] = useState('start');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inx, setInx] = useState(0);

  

  const state = async ()=>{
    console.log('.........======>class_cd', props.class_cd, props.data);
    if ( loading === true) return;
    setLoading(true);
    const url =  default_url.concat(api_GetCode);
    const find = {
      action:props.action??'rcombo', 
      code_cd:'',
      class_cd:props.class_cd,
    }
    console.log('여기 InputDrop............', find);
    const apiResponse = await fetch(url,{
      method:'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(find),
    });

    const res = await apiResponse.json();
    const tmp:ISelectInfo = res;
    const inx = await getIndex(tmp, props.data??"");
    console.log('INDEX.................', inx);
    setData(tmp);
    setInx(inx);
    setLoading(false);
    setRun('stop');
    setError(false);

    props.onSync && props.onSync();
  }

  useEffect(() => {
    if ( props.class_cd.length > 0) state();
  }, [props.class_cd]);

  
  useEffect(()=>{
    if ( run === 'start') state();
  },[run]);
  
  const onSelected = useCallback(() => {
    console.log('combobox onSelected', selected);
    if (!datas) return;
    const sel =datas?.table.filter((value:ISelectCode) => value.key === selected.value)
                           .map((row)=>{ 
                            const r = { ...selected,
                                        title:row.value,
                                        sub_cd1: row.sub_cd1, 
                                        sub_cd2: row.sub_cd2, }
                            return r;
                           }) ;
    props.onSelect(sel[0]);
  },[selected] ); 

  const onSetSelected = useCallback((val:string) => {
    console.log('onSetSelected', selected);
    setSelected({...selected, value:val, });
  },[selected] ); 
  
  if (loading) return <View><Text>로딩중..</Text></View>
  if (error) return  <View><Text>Error Occured: </Text></View>
  if (!datas) return(
    <View style={{}}>
    </View>
  ) 

  if(!props.horizontal){
    return (
      <View>
        <Caption title={props.title} astar={props.astar} horizontal={props.horizontal} />
        <Spacer space={6}/>
        <SelectList
                placeholder={props.placeholder }
                onSelect={onSelected}
                setSelected={onSetSelected} 
                data={datas.table} 
                defaultOption={datas.table[inx]}
                save="key"
                search={false} 
                boxStyles={{borderColor : "#D9D9D9"}}
                dropdownStyles={{borderColor:"#D9D9D9",backgroundColor:'white'}}
                dropdownTextStyles={{}}
              />

      </View>
    )
  }
  return (
    <View>
      <View style={{ flexDirection:'row', 
                     alignItems:'center',
                     justifyContent:'center'   }}>
        <Caption title={props.title} astar={props.astar} horizontal={props.horizontal} />
        <View style={{flex:dataflex ?? 3, }}>
        <SelectList
                placeholder={props.placeholder }
                onSelect={onSelected}
                setSelected={onSetSelected} 
                data={datas.table} 
                defaultOption={datas.table[inx]}
                save="key"
                search={false} 
                boxStyles={{borderColor : "#D9D9D9"}}
                dropdownStyles={{borderColor:"#D9D9D9",backgroundColor:'white'}}
                dropdownTextStyles={{}}
              />
        </View>
      </View>
    </View>
  ) 
}


export const InputSelectList:React.FC <{
  title : string,
  class_cd: string,
  data? : string,
  horizontal?:boolean;
  astar?:string,
  placeholder ?:string,
  onSelect:(key:string)=>void,
}> =(props)=>{
  const onSelect = useCallback((key:string) => {
    props.onSelect(key);
},[] ); 

  const {width} = useWindowDimensions();
  const fontSize=(Platform.OS ==='web') ? 20:16;
  const height=(Platform.OS ==='web') ? 60:40;

  if(!props.horizontal){
    return (
      <View>
        <Caption title={props.title} astar={props.astar} horizontal={props.horizontal} />
        <Spacer space={6}/>
          <DataSelectList action={"rcombo"} class_cd={props.class_cd} onSelect={onSelect}  placeholder={props.placeholder}/>
      </View>
    )
  }
  return (
    <View style={{
          flexDirection:'row', 
          alignItems:'center',
      }}>
      <View style={{flex: 1, flexDirection:"row", height:height ?? 40, backgroundColor:'white'}}>
      <Caption title={props.title} astar={props.astar} horizontal={props.horizontal} />
        <View style={{flex:dataflex ?? 3, }}>
          <DataSelectList action={"rcombo"} class_cd={props.class_cd} onSelect={onSelect} placeholder={props.placeholder} />
        </View>
      </View>
    </View>
  ) 
}

export const InputText:React.FC <{
  title : string,
  value : string,
  name  : string,
  captionHidden?:boolean,
  placeholder?:string,
  astar?:string,
  keyboardType?:KeyboardTypeOptions|undefined,
  onChangeText:(text:string)=>void,
  horizontal?:boolean
  onSubmit?:(name:string, value:string)=>void,
  ref?:LegacyRef<TextInput>|undefined,
  returnKeyType?:any,
  autoFocus?:boolean|undefined,
  width?:string
  secureTextEntry?:boolean;
}> =(props)=>{
  const [focused, setFocused] = useState(false);
  const fontSize=(Platform.OS ==='web') ? 16:16;
  const height=(Platform.OS ==='web') ? 45:50;

  useEffect(() =>{
    console.log('inputText ..... props.ref..... ', props.ref, '] end.')
  }, [props.ref]);

  const onSubmit= useCallback(() => {
    console.log('InputText_onSubmit',props.value );
    props.onSubmit && props.onSubmit(props.name, props.value);
  }, [props.value]);
  useEffect

  if ( !props.horizontal ){
    return(
      <View>
        {
           !props.captionHidden&&<Caption flex={0} title={props.title} astar={props.astar} horizontal={props.horizontal} />
        }
        {
           !props.captionHidden&&<Spacer space={6}/>
        }
        <View style={{...styles.inputStyle, 
                      borderColor:focused ?COLOR.GREEN_1: '#D9D9D9',
                    }}>
          <TextInput 
            autoCorrect={false}
            ref={props.ref}
            secureTextEntry={props.secureTextEntry??false}
            returnKeyType={props.returnKeyType??"next"}
            autoCapitalize={'none'}
            value={props.value}
            onChangeText={props.onChangeText}
            placeholder={props.placeholder}
            selectionColor='#99C341'
            autoFocus={props.autoFocus}
            style={{fontSize:fontSize, 
                    color:COLOR.GREEN_1,
                    borderColor:'white',
                    padding:0,
                    height:20,
                    width:props.width ?? '100%'
                  }}
                  
            onFocus={()=> setFocused(true)} 
            onBlur={()=> setFocused(false)}
            blurOnSubmit={false}
            onSubmitEditing={onSubmit}
            keyboardType={props.keyboardType??'numeric'}
          />

        </View>
      </View>
    )
  }
  return (
    <View>
      <View style={{
                flexDirection:'row', 
                alignItems:'center',
                justifyContent:'center'
            }}>
       
        {
          !props.captionHidden&&<Caption title={props.title} 
                                         astar={props.astar} 
                                         horizontal={props.horizontal} />
        }
         <View style={styles.inputContainer}>
          <View style={{...styles.inputStyle, 
                        height:height,
                        borderColor:focused ?COLOR.GREEN_1: '#D9D9D9',
                        }}>
          <TextInput 
                autoCorrect={false}
                autoCapitalize={'none'}
                ref={props.ref}
                secureTextEntry={props.secureTextEntry??false}
                returnKeyType={props.returnKeyType??"next"}
                value={props.value}
                onChangeText={props.onChangeText}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                style={{fontSize:fontSize, color:COLOR.GREEN_1,flex:1,width:props.width ?? '100%'}}
                onFocus={()=> setFocused(true)} 
                onBlur={()=> setFocused(false)}
                blurOnSubmit={false}
                onSubmitEditing={onSubmit}
                keyboardType={props.keyboardType??'numeric'}
                />
            </View>
                          
        </View>
      
      </View>
    </View>
  ) 
}


export const InputView:React.FC <{
  value : string,
  title : string,
  isTitle?:boolean,
  horizontal?:boolean,
}> =(props)=>{
  const {width} = useWindowDimensions();
  const fontSize=(Platform.OS ==='web') ? 16:16;
  const height=(Platform.OS ==='web') ? 50:45;
  if ( !props.horizontal){
    return(
      <View>
        {
          props.isTitle&&
              <Caption title={props.title} horizontal={props.horizontal} />
        }
        {
          props.isTitle&&
              <Spacer space={6}/>
        }
        <View style={{  alignSelf:'stretch', 
                        paddingHorizontal:20, 
                        paddingVertical:8, 
                        borderRadius: 10,
                        borderWidth:1, 
                        borderColor:'#D9D9D9',
                        height:height,       
                        justifyContent:'center'  }}>
            <NoflexTypography color='#7F7F7F' 
                              fontSize={fontSize}>{props.value}</NoflexTypography>
        </View>
      </View>
    )
  }
  
  return (
    <View>
      <View style={{ flexDirection:'row' }}>
        <Caption title={props.title}  horizontal={props.horizontal} />
        <View style={styles.inputContainer}>
          <View style={{...styles.inputStyle, height:height}}>
            <NoflexTypography color='#7F7F7F' fontSize={fontSize}>{props.value}</NoflexTypography>
          </View>
        </View>
      </View>
    </View>
  ) 
}


export const InputButton:React.FC <{
  title : string,
  onPress:()=>void,
}> =({title, onPress})=>{
  const {width} = useWindowDimensions();

  const fontSize=(Platform.OS ==='web') ? 20:16;
  const height=(Platform.OS ==='web') ? 60:40;

  return (
    <View style={{flex:1,minWidth:'100%',}}>
        <Button onPress={onPress}>
            <View  style={{backgroundColor:'#A6A6A6',
                          borderRadius:10,
                          height: 50,
                          minWidth:'100%',
                          justifyContent:'center', 
                          alignItems:'center'}}>
              <NoflexTypography color='white' background='#A6A6A6' fontSize={16} >{title}</NoflexTypography>
            </View>
        </Button>
    </View>
  ) 
}

export const InputCheck : React.FC<{
  value:ISelectCode,
  key:number,
  subkey:number, 
  onCheckChange:(e:ISelectCode, key:number, subkey:number)=>void
}> = ({value, key, subkey, onCheckChange}) => {
  const [checked, setChecked] = useState(false); 
  const [item, setItem] = useState(value); 
  

  const onValueChange = useCallback(() => {
    setChecked(!checked);
    setItem({...item, checked:!checked})
    console.log(item);
    onCheckChange(item, key, subkey);
  },[checked] );

  return (
    <View style={{
      flexDirection: 'row', 
      alignItems: 'flex-start', 
      justifyContent: 'space-between'
    }}>
      <View style={{flex:4, justifyContent:'flex-start'}}>
        <Text style={{justifyContent:'flex-start', alignItems:'center', fontSize: 15,}}>{item.value}</Text>
      </View>
      <View style={{flex:1}}>
        <Checkbox
          style={{margin: 8,}}
          value={item.checked || false}
          onValueChange={onValueChange}
          color={checked ? COLOR.SELECT : undefined}
        />
      </View>
  </View>
  )
}

export const InputSearchText:React.FC <{
  title : string,
  value : string,
  name  : string,
  onChange:(text:string)=>void,
  onSubmit:(name:string, text:string)=>void,
  asta?  : string,
  ref?:LegacyRef<TextInput>|undefined
  returnKeyType?:any,
  fontSize?:number,
  placeholder?:string,
  horizontal?:boolean,
  keyboardType?:KeyboardTypeOptions|undefined,
  iconName?:IconName,
  iconColor?:string,
  autoFocus?:boolean|undefined
}> =({title, value, name, 
  placeholder, horizontal, 
  fontSize, keyboardType, 
  iconName, iconColor, onSubmit, 
  asta, ref, autoFocus, onChange,
  returnKeyType,
})=>{
  const [focused, setFocused] = useState(false);
  const onSubmitEditing= useCallback(() => {
    console.log('InputText_onSubmit',value );
    onSubmit(name, value);
  }, [value]);
  const height=(Platform.OS ==='web') ? 45:50;
  const fontSize1=(Platform.OS ==='web') ? 16:16;
  if ( !horizontal ){
    return(
      <View style={{}}>
        {
          (title.length > 0 ) && <Caption title={title} horizontal={horizontal} astar={asta} />
        }
        <Spacer space={6}/>

        <View style={{flexDirection:'row', 
                      borderColor:focused ?COLOR.GREEN_1: '#D9D9D9',
                      borderWidth:1, borderRadius:10, 
                      height:50,
                      alignItems:'center'
                    }}>
          <View style={{flex:1, marginLeft:10,
                              }}>
              <TextInput 
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  ref={ref}
                  returnKeyType={returnKeyType??"next"}
                  value={value}
                  onChangeText={onChange}
                  placeholder={placeholder}
                  keyboardType={keyboardType?? 'default'}
                  style={{fontSize:fontSize, 
                    color:COLOR.GREEN_1,
                    borderColor:'white',
                    padding:0,
                    height:20,
                    width: '100%',
                   
                  }}
                  onFocus={()=> setFocused(true)} 
                  onBlur={()=> setFocused(false)}
                  blurOnSubmit={false}
                  onSubmitEditing={onSubmitEditing}
                  autoFocus={autoFocus}

              />
          </View>
          <Spacer space={10} horizontal />

          <TouchableOpacity
            style={{justifyContent:'center', alignItems:'center'}}
            activeOpacity={0.5}
            onPress={onSubmitEditing}>
            <Ionicons name={iconName??"search"} size={24} color={iconColor??"black"} />
          </TouchableOpacity>
          <Spacer space={10} horizontal />
        </View>
      </View>
    )
  }
  return (
    <View style ={{}}>
      <View style={{ flexDirection:'row', alignItems:'center',justifyContent:'center'}}>
        {
          (title.length > 0 ) && <Caption title={title} horizontal={horizontal} />
        }
        <View style={{flex:3, justifyContent:'flex-end'}}>
          <View 
            style={{...styles.inputStyle, height:height, 
                    borderColor:focused ?COLOR.GREEN_1: '#D9D9D9',
                    
                    }}>
            <TextInput 
                  autoCorrect={false}
                  ref={ref}
                  returnKeyType="next"
                  autoCapitalize={'none'}
                  value={value}
                  onChangeText={onChange}
                  placeholder={placeholder}
                  style={{fontSize:fontSize1, 
                    color:COLOR.GREEN_1,flex:1,width: '100%'}}
                  keyboardType={keyboardType?? 'default'}
                  onFocus={()=> setFocused(true)} 
                  onBlur={()=> setFocused(false)}
                  blurOnSubmit={false}
                  onSubmitEditing={onSubmitEditing}
                  autoFocus={autoFocus}
              />
            <Spacer space={10} horizontal />
            <TouchableOpacity
              style={{justifyContent:'center', alignItems:'center'}}
              activeOpacity={0.5}
              onPress={onSubmitEditing}>
              <Ionicons name={iconName??"search"} size={24} color={iconColor??"black"} />
            </TouchableOpacity>
            <Spacer space={10} horizontal />
          </View>
          
        </View>
 
        </View>
    </View>
  ) 
}

const styles = StyleSheet.create({
  inputContainer:{
    flex:3, justifyContent:'flex-end'
  },
  inputStyle:{
    flexDirection:'row',
    alignSelf:'stretch', 
    alignItems:'center',
    paddingHorizontal:20, 
    paddingVertical:12, 
    borderRadius: 10,
    borderWidth:1, 
    borderColor:'#D9D9D9',
    justifyContent:'space-between'
  },

});
