import React, {useEffect, useCallback,useState,useRef} from "react";
import {View, Text, Pressable} from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { SplashView } from "./SplashView";
import { useLoginState } from "./contexts/LoginContext";
import { ReqStateContextProvider } from "./contexts/ReqContext";
import { IUserInfo } from "./datas/TrsDataType";
import { StackMainNavi } from "./navigations/StackMainNavi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AppState } from "react-native"
import dayjs from 'dayjs';

export const RootApp:React.FC<{token?:string}> = ({token}) =>{
  const loginState = useLoginState();
  const [isLogin, setLogin] = useState(false);
  const [user, setUser] = useState<IUserInfo>();
  const [time, setTime] = useState(0);
  const timerId = useRef(null);

  const [elapsed, setElapsed] = useState(0);
  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
 

  useEffect(() => {
    
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        console.log('App has come to the foreground!');
      }

      appState.current = nextAppState;
      setAppStateVisible(appState.current);
      console.log('AppState', appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);




  useEffect(() =>{
    setLogin(loginState.isLogin);
    setUser(loginState.user);
  }, [loginState]);

  useEffect(()=>{
    if ( loginState.isLogin == true){
      console.log("RootApp LoginState Start Time", ";;;;;;;;;;;;")
      /*
      const recordStartTime = async () => {
        try {
          const now = new Date();
          console.log("RootApp LoginState Start Time", now)
          await AsyncStorage.setItem("@start_time", now.toISOString());
        } catch (err) {
          // TODO: handle errors from setItem properly
          console.warn(err);
        }
      };
        */
     // recordStartTime();
    }
  },[loginState])


  if (isLogin && user){
    return (
        <NavigationContainer>
          <ReqStateContextProvider>
          <StackMainNavi />
          </ReqStateContextProvider>
        </NavigationContainer> 

    );
  }
  return(
    <SplashView token={token} />
  );
}