import { Moment } from 'moment';
import React, {useState,useEffect, useCallback} from 'react';
import {
  Alert, 
  Modal, 
  Text, 
  View,
  Platform,
  Pressable, 
  StyleSheet, 
  ActivityIndicator, 
  useWindowDimensions,
  FlatList, 
  ListRenderItem,
} from 'react-native';
import { MenuOption } from 'react-native-popup-menu';
import { Spacer } from '../../components/Spacer';
import { minWinWidth } from '../../datas/Const';
import { COLOR } from '../../utils/color';
import { Data2 } from './data';
import { CheckColumn } from './GridHeader';
import { TrsCalendar } from './TrsCalender';
import { Entypo  } from "@expo/vector-icons";
import { Button } from '../../components/Button';
import { TrackLine} from'./TrsComponent';
import { useLoginState } from '../../contexts/LoginContext';
//import CalendarPicker from 'react-native-calendar-picker';

export interface IMsg {
  msgId:string;
  message:string;
  message2:string;
}

export const DefaultMsg={
  msgId:'',
  message:'',
  message2:'',
}

export const TrsMessageShow:React.FC<{ message:IMsg, onMessage:(msgId:string)=>void}> = ({ message, onMessage}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=width - (marginHorizontal * 2)
  const innerHeight=height/5;
  const textHeight=innerHeight/2;
  const menuButtonHeight=innerHeight/2;
  useEffect(() =>{
      if (message&&message.msgId.length !== 0)  setModalVisible(true) ;
  }, [message]);

  if(modalVisible == true){
   setTimeout(() => {
    
    setModalVisible(false);
    onMessage(message.msgId);
  }, 2000);}

  return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
          onMessage(message.msgId);
        }}>
        <View style={{ paddingLeft:width< 1300 ? marginHorizontal : marginHorizontal,
          paddingRight:width< 1300 ? marginHorizontal : marginHorizontal/2,
          height:height,
          alignItems: 'center', 
          justifyContent:'center',
          flexDirection:'row',
          }}>
          <View style={{flex:1,height:height,alignItems: 'center', 
          justifyContent:'center',}}>
            <View style={styles.modalView}>
              <Text style={styles.modalText}>{message.message}</Text>
              {
                    message.message2 && message.message2.length > 0 &&
                    <Text style={styles.modalText}>{message.message2}</Text>
              }
            </View>
          </View>
          { width> 1300 ? 
            <View style={{width:500,height:height}}/>
            :<View/>
            }
        </View>
      </Modal>
  );
};


export const Waiting:React.FC<{visible:boolean, message:string}> = ({visible, message}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=width - (marginHorizontal * 2)
  const innerHeight=height/5;
  const textHeight=innerHeight/2;
  const menuButtonHeight=innerHeight/2;

  useEffect(() =>{
    console.log('Wating................', visible);
    setModalVisible(!visible) ;
  }, [visible]);

  return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);         
        }}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Pressable style={{}} onPress={() => {setModalVisible(false); }}>
              <ActivityIndicator size='large' color='#ABF200'/>
              <Spacer space={10}/>  
              <Text style={{color:'#666666', fontSize:14 , fontWeight:'bold'}}> {message} </Text>
              </Pressable>
            </View>
          </View>
      </Modal>
  );
};

export const TrsMessageBox:React.FC<{
  message:IMsg;
  onOK:(msgId:string)=>void, 
  onCancel:(msgId:string)=>void
}> = ({message, onOK, onCancel}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
 
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=Platform.OS ==='web'? (width - (marginHorizontal * 2))/2: width - (marginHorizontal * 2)

  const innerHeight= Platform.OS ==='web'? height/4 : height/5;
  const textHeight=innerHeight/2;
  const menuButtonHeight=innerHeight/2;
  const maxWinWidth = 393 * 2;
  useEffect(() =>{
      if (message && message.msgId.length !== 0)  setModalVisible(true) ;
  }, [message]);


  return (
      <View style={{alignItems:'center',justifyContent:'center',}}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalVisible(!modalVisible);
        }}>
        <View style={{ paddingLeft:width< 1300 ? marginHorizontal : marginHorizontal,paddingRight:width< 1300 ? marginHorizontal : marginHorizontal/2,height:height,
               alignItems: 'center', justifyContent:'center',
               flexDirection:'row'}}>
          <View style={{flex:1,alignItems:'center',justifyContent:'center',height:height,backgroundColor: 'rgba(0,0,0,0.35)',
         }}>
            <View style={{maxWidth: maxWinWidth  , width:innerWidth, height:innerHeight, backgroundColor:'white', borderRadius:10, }}>
                <View style={{ maxWidth:maxWinWidth, 
                               width:innerWidth, height:textHeight, 
                               alignItems: 'center', justifyContent: 'center', marginTop:10}}>
                  <Text style={{color:'#666666', fontSize:16}}>{message.message}</Text>
                  {
                    message.message2 && message.message2.length > 0 &&
                    <Text style={{color:'#666666', fontSize:16}}>{message.message2}</Text>
                  }

                </View>  
                <View style={{maxWidth:maxWinWidth ,width:innerWidth, height:menuButtonHeight/2, marginBottom:0, flexDirection:'row'}}>
                {/** 확인 버튼  */}
                  <View style={{flex:1, alignItems: 'center', justifyContent: 'center',marginLeft:15}}>  
                    <Pressable style={{}} onPress={()=>{setModalVisible(false); onOK(message.msgId); }}>
                      <View style={{maxWidth:maxWinWidth/3.5, height:innerHeight/3.5 ,width:innerWidth/3, alignItems: 'center', justifyContent: 'center',backgroundColor:'#99C341', borderRadius:100 }} >  
                        <Text style={{color:'white',fontSize:16}}>확인</Text>
                      </View>
                    </Pressable>
                  </View>
                  {/** 취소 버튼  */}
                  <View style={{flex:1,alignItems: 'center', justifyContent: 'center',marginRight:15}}>  
                    <Pressable style={{}} onPress={() => {setModalVisible(false); onCancel(message.msgId);}}>
                      <View style={{maxWidth:maxWinWidth/3.5, height:innerHeight/3.5 ,width:innerWidth/3, alignItems: 'center', justifyContent: 'center',backgroundColor:'#F2F2F2',borderRadius:100}} >  
                        <Text style={{color:'#666666', fontSize:16}}>취소</Text>
                      </View>
                    </Pressable>
                  </View>
              </View>
            </View>
          </View>
          { width> 1300 ? 
            <View style={{width:500,height:height}}/>
            :<View/>
            }
        </View>
      </Modal>
      </View>
  );
};



export const TrsMessageConfirm:React.FC<{
  message:IMsg,
  onOK:(msgId:string)=>void, 
}> = ({message, onOK}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
 
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=Platform.OS ==='web'? (width - (marginHorizontal * 2))/2: width - (marginHorizontal * 2)

  const innerHeight= Platform.OS ==='web'? height/4 : height/5;
  const textHeight=innerHeight/2;
  const menuButtonHeight=innerHeight/2;
  const maxWinWidth = 393 * 2;
  useEffect(() =>{
      if (message && message.msgId.length !== 0)  setModalVisible(true) ;
  }, [message]);


  return (
      <View style={{alignItems:'center',
                   justifyContent:'center', 
                   marginHorizontal:marginHorizontal}}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalVisible(!modalVisible);
        }}>
          <View style={{ paddingLeft:width< 1300 ? marginHorizontal : marginHorizontal,paddingRight:width< 1300 ? marginHorizontal : marginHorizontal/2,height:height,
               alignItems: 'center', justifyContent:'center',
               flexDirection:'row'}}>
          <View style={{flex:1,alignItems:'center',justifyContent:'center',height:height,backgroundColor: 'rgba(0,0,0,0.35)',}}>
      
                 <View style={{maxWidth: maxWinWidth  , width:innerWidth, height:innerHeight, backgroundColor:'white', borderRadius:10, }}>
                     <View style={{ maxWidth:maxWinWidth, 
                                    width:innerWidth, height:textHeight, 
                                    alignItems: 'center', justifyContent: 'center', 
                                    marginTop:10}}>
                       <Text style={{color:'#666666', fontSize:16}}>{message.message}</Text>
                       {
                        message.message2 && message.message2.length > 0 &&
                        <Text style={{color:'#666666', fontSize:16}}>{message.message2}</Text>
                      }
                     </View>  
                     <View style={{maxWidth:maxWinWidth ,width:innerWidth, height:menuButtonHeight/2, marginBottom:0, flexDirection:'row'}}>
                      {/** 확인 버튼  */}
                        <View style={{flex:1, alignItems: 'center', justifyContent: 'center',marginLeft:15}}>  
                         <Pressable style={{}} onPress={()=>{setModalVisible(false); onOK(message.msgId); }}>
                           <View style={{maxWidth:maxWinWidth/3.5, height:innerHeight/3.5 ,width:innerWidth/3, alignItems: 'center', justifyContent: 'center',backgroundColor:'#99C341', borderRadius:100 }} >  
                             <Text style={{color:'white',fontSize:16}}>확인</Text>
                           </View>
                         </Pressable>
                       </View>
                     </View>
                 </View>
               </View>
               { width> 1300 ? 
            <View style={{width:500,height:height}}/>
            :<View/>
            }
               </View>
      </Modal>
      </View>
  );
};




export const TrsCalendarBox:React.FC<{
  show:boolean;
  onOK:(startDate:string, endDate:string)=>void, 
  onCancel:()=>void
}> = ({show, onOK, onCancel}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedStartDate, setSelectedStartDate] =useState(''); 
  const [selectedEndDate, setSelectedEndDate] = useState(''); 

  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;

  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;
  const innerWidth=width - (marginHorizontal * 2)
  

  const innerHeight= Platform.OS ==='web'? height/1.5 : height/1.5;
  const textHeight=innerHeight/2;
  const menuButtonHeight=innerHeight/4;
  const maxWinWidth = 393 * 2;

  const modalHeight = innerHeight/1.2
  const calenderWebSize = ( innerHeight < 500 ) ? (( innerHeight < 400 ) ?  900 : 600  ): 300 
  const calendarSize= Platform.OS ==='web'?  350 : 400 ;
  
  const calendarWidth= Platform.OS ==='web'? 400  : innerWidth;

  useEffect(() =>{
      if (show === true)  setModalVisible(true) ;
  }, [show]);

  const onDateChange = (date : Moment , type: 'START_DATE' | 'END_DATE') => {
    //function to handle the date change
    //const asdf = date.toString()
    const sel_date = date.format('YYYY-MM-DD').toString();
    if (type === 'END_DATE') {
        setSelectedEndDate(sel_date);
    } else {
        setSelectedStartDate(sel_date);
    }
  };
  

  return (
      <View style={{alignItems:'center',justifyContent:'center', marginHorizontal:marginHorizontal}}>
        <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert('Modal has been closed.');
            setModalVisible(!modalVisible);
          }}>
          
          <View style={{ paddingLeft:width< 1300 ? marginHorizontal : marginHorizontal,paddingRight:width< 1300 ? marginHorizontal : marginHorizontal/2,height:height,
               alignItems: 'center', justifyContent:'center',
               flexDirection:'row'}}>
          <View style={{flex:1,alignItems:'center',justifyContent:'center',height:height,backgroundColor: 'rgba(0,0,0,0.35)',paddingHorizontal:Platform.OS==='web' ? (width < 1300 ? innerWidth/10 : 15) : 10}}>
            <View style={{ width:'100%', height:Platform.OS ==='web' ? innerHeight/1.1 :innerHeight/1.3, backgroundColor:'white', borderRadius:10,alignItems:'flex-start', justifyContent :'flex-start'  }}>
              <View style={{ width:'100%', height:Platform.OS ==='web' ?  modalHeight/1.2 -20 : modalHeight/1.4-20, justifyContent: 'center', alignItems:'center',marginTop:20}}>
               <TrsCalendar calendarSize={calendarSize} 
                            width = {calendarWidth-30}
                            onDateChange={onDateChange}
                            />
              </View>  
              <View style={{width:'100%', height:menuButtonHeight/2, marginBottom:0, flexDirection:'row'}}>
                      {/** 확인 버튼  */}
                        <View style={{flex:1, alignItems: 'center', justifyContent: 'center',marginLeft:15}}>  
                         <Pressable style={{width: '100%',}} 
                                    onPress={()=>{
                                    
                                      setModalVisible(false); 
                                      onOK(selectedStartDate, selectedEndDate); 
                                    }}>
                           <View style={{ height:innerHeight/10,width: '100%', alignItems: 'center', justifyContent: 'center',backgroundColor:'#99C341', borderRadius:100 }} >  
                             <Text style={{color:'white',fontSize:16}}>확인</Text>
                           </View>
                         </Pressable>
                       </View>
                       <Spacer space={30} horizontal/>
                       {/** 취소 버튼  */}
                       <View style={{flex:1,alignItems: 'center', justifyContent: 'center',marginRight:15}}>  
                         <Pressable style={{width: '100%',}} onPress={() => {
                                                          setModalVisible(false); 
                                                          onCancel();}}>
                           <View style={{ height:innerHeight/10, width: '100%', alignItems: 'center', justifyContent: 'center',backgroundColor:'#F2F2F2',borderRadius:100}} >  
                             <Text style={{color:'#666666', fontSize:16}}>취소</Text>
                           </View>
                         </Pressable>
                       </View>
              </View>
            </View>
            </View>
            { width> 1300 ? 
            <View style={{width:500,height:height}}/>
            :<View/>
            }
          </View>
        </Modal>
      </View>
  );
};


type IconName = keyof typeof Entypo.glyphMap;
interface IItem  {
  id: number,
  name: string,
  menuId:string,
  icon:any,
}

const MenuClientList :IItem[]= [
  {
    id: 1,
    name: '사용자 관리',
    menuId: 'User',
    icon: 'user',
  },
 
 ];
 const MenuServiceWebList = [
  {
    id: 4,
    name: '사용자 관리',
    menuId: 'User',
    icon: 'user',
  },
  {
    id: 5,
    name: "기준코드 관리",
    menuId: 'Code',
    icon: 'code',
  },
  {
    id: 6,
    name: '시리얼 정보 업로드',
    menuId: 'Serial',
    icon: 'add-to-list',
  },
 ];
 
 const MenuServiceList = [
  {
    id: 4,
    name: '사용자 관리',
    menuId: 'User',
    icon: 'user',
  },
  {
    id: 5,
    name: "기준코드 관리",
    menuId: 'Code',
    icon: 'code',
  },
  
 ];

export const TrsPop:React.FC<{
  show:boolean;
  onMenu:(menu:string)=>void, 
}> = ({show, onMenu}) => {
  const loginState = useLoginState();
  const [modalVisible, setModalVisible] = useState(false);
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
 
  const tmpMargin = ( width < minWinWidth)? 0 :( (width - minWinWidth) / 2);   // margin 2
  const marginHorizontal=Platform.OS ==='web'? tmpMargin:0;

  const menuType1 = (loginState.user.user_tp === 'U0100'||loginState.user.user_tp === 'U0101'  )
  const menuType2 = (loginState.user.user_tp === 'U0200' )
  const menuType3 = (loginState.user.user_tp === 'U0102'|| loginState.user.user_tp === 'U0201' )
  // 임시로 
  //const menuType1 = (loginState.user.user_tp === 'U0100'||loginState.user.user_tp === 'U0101'|| loginState.user.user_tp === 'U0200'  )
  //const menuType2 = (loginState.user.user_tp === 'U0200' )

  const userInfoHeight= Platform.OS ==='web'? 80:80;
  const menuHeight = Platform.OS ==='web'? 70:60;
  const logoutHeight=Platform.OS ==='web'? 70:60;
  const menuCount = (menuType1 === true) ? 1: (Platform.OS === 'web' && menuType2 === true)? 3: (Platform.OS !== 'web' && menuType2 === true)?2:0
  // 메뉴 타입 으로 높이 계산
  const innerWidth=Platform.OS ==='web'? width < 780 ? 410 : (width - (marginHorizontal * 2) )/2 : width - (marginHorizontal * 2)
  const popposition = Platform.OS ==='web'? marginHorizontal+10 : 10
  const menuContainerHeight = (menuCount * menuHeight);
  const innerHeight= userInfoHeight + menuContainerHeight + logoutHeight;
  const textHeight=innerHeight/2;
  const menuButtonHeight=innerHeight/2;
  const maxWinWidth = 393 * 2;

  const iconsize = 24;
  const iconcolor = COLOR.ICONCOLOR;

  const menuFontSize = Platform.OS ==='web'? 18:15;
  const menuMarginHorizontal = Platform.OS ==='web'? 10:10;
 
 
  useEffect(() =>{
      setModalVisible(show) ;
  }, [show]);
  console.log('[m1=',menuType1,'][m2=', menuType2,']')

  const [selectedId, setSelectedId] = useState<number>();
  const renderItem:ListRenderItem<IItem>=({item,index})=>{
    const backgroundColor = item.id === selectedId ? '#e2e2e2' : 'white';
    console.log('menuItem', item)
    return(
      <View style={{ height:menuHeight,
                     alignItems:'center',
                     width:innerWidth/2 - 20,
                     justifyContent:'flex-start'
                     }}>
        <Button onPress={() => onMenu(item.menuId)}>
          <View style={{height:menuHeight-1, 
                        width:innerWidth/2 -20,
                        justifyContent:'space-around',
                        flexDirection:"row", 
                        backgroundColor:backgroundColor, 
                        alignItems:'center',}}>
            <Text style={{fontSize:menuFontSize}}>{item.name}</Text>
            <Spacer space={10} horizontal/>
            <Entypo name={item.icon} size={iconsize} color={iconcolor} />
          </View>
          <TrackLine/>
        </Button>
      </View>
    );
  }
 
  return (
      <View style={{alignItems:'center',justifyContent:'center', marginHorizontal:marginHorizontal}}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalVisible(!modalVisible);
          onMenu('cancel');
        }}>
        <Pressable style={{}} onPress={() => {
          setModalVisible(false); 
          onMenu('cancel');
        }
        }>
        <View style={{ paddingLeft:width< 1300 ? marginHorizontal : marginHorizontal,paddingRight:width< 1300 ? marginHorizontal : marginHorizontal/2,height:height,
                      alignItems: 'flex-start', justifyContent:'flex-end',paddingTop:65,
                      flexDirection:'row'}}>
          
          <View style={{
                        flex:1,
                        borderRadius:10, 
                        justifyContent:'flex-start',
                        alignItems:'flex-end',
                        marginRight:10,
                       }}
                      >
            <View style={{width: innerWidth/2,
                          paddingLeft:10,
                          height:innerHeight, 
                          backgroundColor:'white', 
                          borderRadius:10, 
                          shadowColor:'black', 
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.25,
                          shadowRadius: 3.84,
                          elevation: 5,
                         }}>
            <View style={{flexDirection:'row',
                          maxWidth: maxWinWidth, 
                          width:innerWidth/2-20, 
                          height:userInfoHeight, 
                          justifyContent:'space-around',
                          paddingTop:5,
                          borderBottomColor:COLOR.GRAY_4,
                          borderBottomWidth:0.7,

                          }}>
              <Button onPress={() =>{ onMenu('UserInfo'); }}>
                <View style={{height:userInfoHeight-1, 
                              width:innerWidth/2,
                              justifyContent:'space-around', 
                              alignItems:'center',}}>
                  <Spacer space={5} />
                  <Text style={{fontSize:menuFontSize+1}}>{loginState.user.user_nm}</Text>
                  <Spacer space={5} />
                  <Text style={{fontSize:menuFontSize, color:COLOR.GREEN_1}}>{loginState.user.user_tpn}</Text>
                  <Spacer space={20} />
                </View>
              </Button>
            </View>
            {
              menuType1&&
              <View style={{maxWidth:maxWinWidth, 
                            width:innerWidth/2, 
                            height:menuContainerHeight, 
                            paddingLeft:10,
                            paddingRight:10,
                                alignItems: 'center', 
                            justifyContent: 'flex-start', }}>
                <FlatList
                  data={MenuClientList}
                  keyExtractor={(item) => item.id.toString()}
                  style={{width:innerWidth/2,height: menuContainerHeight }}
                  renderItem={renderItem}
                />
              </View> 
            }
            {
              Platform.OS === 'web' && menuType2&&
              <View style={{maxWidth:maxWinWidth, 
                            width:innerWidth/2, 
                            height:menuContainerHeight, 
                            paddingLeft:10,
                            paddingRight:10,
                                alignItems: 'center', 
                            justifyContent: 'flex-start', }}>
                <FlatList
                  data={MenuServiceWebList}
                  keyExtractor={(item) => item.id.toString()}
                  style={{width:innerWidth/2,height: menuContainerHeight }}
                  renderItem={renderItem}
                />
              </View> 
            }
            {
              Platform.OS !== 'web' && menuType2&&
              <View style={{maxWidth:maxWinWidth, 
                            width:innerWidth/2, 
                            height:menuContainerHeight, 
                            paddingLeft:10,
                            paddingRight:10,
                                alignItems: 'center', 
                            justifyContent: 'flex-start', }}>
                <FlatList
                  data={MenuServiceList}
                  keyExtractor={(item) => item.id.toString()}
                  style={{width:innerWidth/2,height: menuContainerHeight }}
                  renderItem={renderItem}
                />
              </View> 
            }


            <View style={{                
                          maxWidth: maxWinWidth, 
                          width:innerWidth/2-20, 
                          height:logoutHeight, 
                          justifyContent:'center',
                          alignItems:'center',
                          paddingTop:5,
                          paddingBottom:5,
                        }}>
              <Button onPress={() => onMenu('logout')}>
                <Spacer space={15}/>
                <View style={{height:logoutHeight-16, 
                              width:innerWidth/2-40,
                              justifyContent:'center',
                              flexDirection:"row",  
                              alignItems:'center',
                              backgroundColor:COLOR.GREEN_1,
                              borderRadius:30,
                              padding:10
                              }}>
                  <Text style={{fontSize:menuFontSize,
                                fontWeight:'bold', color:'white'}}>로그아웃</Text>
                </View>
                <Spacer space={15}/>
              </Button>
            </View>
          </View>
          </View>
          { width> 1300 ? 
            <View style={{width:500,height:height}}/>
            :<View/>
            }
        </View>
       
        </Pressable>
      </Modal>
      </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'rgba(0,0,0,0.35)',
    borderRadius: 20,
    padding: 15,
    alignItems: 'center',
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#99C341',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    textAlign: 'center',
    color:'white'
  },
});

