import React, {useCallback,useEffect, useState} from 'react';
import {View, Text,Platform} from 'react-native';
import { useTrsNavigation, useTrsRoute } from '../navigations/StackParamNavi';
import { Button } from '../components/Button';
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { DataGrid, IGridSelect } from './components/DataGrid';
import {Spacer} from '../components/Spacer';
import { useLoginState } from '../contexts/LoginContext';
import { HeaderTop, HeaderUserInfo } from './components/HeaderTop';
import * as ApiCmd from '../datas/TrsApiType';
import * as DataType from '../datas/TrsDataType';
import { IFindInfo, FindDefault  } from '../datas/TDataFind';
import { useSize } from '../hooks/use-Size';
import { InputSearchText } from './components/InputComponent';
import { COLOR } from '../utils/color';
import dayjs from 'dayjs';
import { useIsFocused } from '@react-navigation/native';
import { ExcelSync } from './components/ExcelSync';
import AsyncStorage from "@react-native-async-storage/async-storage";


interface ISelect {
  site:string,
  all:boolean, 
  rdy:boolean, 
  as:boolean, 
  tnt:boolean, 
  zeb:boolean, 
  end:boolean
}

const DefaultSelect={
  site:"",
  all:false, 
  rdy:false, 
  as:false, 
  tnt:false, 
  zeb:false, 
  end:false
}

export const TrsListScreen:React.FC = () =>{
  const now = new Date();

  const navigation = useTrsNavigation();
  const route = useTrsRoute<'TrsList'>();
  const loginState = useLoginState();
  const [selected, setSelected] =  useState("all");
  const [sel, setSel] = useState<ISelect>({...DefaultSelect, all:true, site:loginState.site_cd});
  const [site, setSite] =  useState(loginState.site_cd);
  const [winWidth, winHeight, isHorizontal] = useSize();
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(dayjs(now).set('day', -14).format('YYYY-MM-DD').toString())
  const [endDate, setEndDate] = useState(dayjs(now).format('YYYY-MM-DD'))
  const [isSync, setSync] = useState(true);
  const user_cd = (loginState.user.user_tp === 'U0102')? loginState.user.user_cd: '';
  const [item, setItem] = useState<IFindInfo>({
    ...FindDefault, 
    action:ApiCmd.action_trsalist,
    loc_sts:"00|01|02", 
    serial_no:'', 
    site_no:loginState.site_cd,
    user_cd : (loginState.user.user_tp === 'U0102' ) ? loginState.user.user_cd:"",
    user_tp: loginState.user_tp,
  });  

  const [sendData,   setSendData]     = useState<any>();
  const [command,    setCommand]       = useState('');
  const [apiname,    setApiName]       = useState('');

  const isFocused = useIsFocused();

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  useEffect(() =>{
    onSync();
  }, [isFocused]);

  const onSync=useCallback(()=>{
    setSync(true);
  }, [isSync])

  const onSite = useCallback((key:string) => {
    console.log('test....', selected);
    setSite (key);
    setSel({...DefaultSelect, all:true});
  },[site] ); 

  useEffect(()=>{
    console.log('sel.....................>', sel);
  },[sel])

  const onBtnSelect = useCallback((key:string) => {
    console.log('onBtnSelect [', key, ']');
    setSel({...DefaultSelect, [key]:true})
    switch(key){
      case 'all':
        setItem({...item, action:ApiCmd.action_trsalist,loc_sts:"00|01|02", serial_no:searchText, site_no:site});
        break;
      case 'rdy':
        setItem({...item, action:ApiCmd.action_trslist,loc_sts:'00', serial_no:searchText,site_no:site});
        break;
      case 'as':
        setItem({...item, action:ApiCmd.action_trslist,loc_sts:"01|02", serial_no:searchText,site_no:site});
        break;
      case 'tnt':
        setItem({...item, action:ApiCmd.action_trslist,loc_sts:'01', serial_no:searchText, site_no:site});
        break;
      case 'zeb':
        setItem({...item, action:ApiCmd.action_trslist,loc_sts:'02', serial_no:searchText, site_no:site});
        break;
      case 'end':
        const st = dayjs(now).set('day', -7).format('YYYYMMDD').toString();
        const ed = dayjs(now).format('YYYYMMDD').toString();
        setItem({...item, action:ApiCmd.action_reclist, fr_dat : st,  to_dat : ed, serial_no:searchText, site_no:site});
        break;
    }
    onSync();
  },[item, site, sel, searchText] ); 


  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  const onGridSelectData = useCallback((value:IGridSelect) => {
    console.log('onGridSelectData', value);
    // setItem({...item, track_no:value.track_no});
    if ( value.req_tp === '1'){
      const find1 = { 
        ...FindDefault,
        action: ApiCmd.action_track_no,
        site_no : loginState.user.site_cd,
        track_no: value.track_no,
        serial_no:value.serial,
        det_seq : value.det_seq,
      };
      navigation.navigate('TrsClientDetail', {find:find1,  menuId:"TrsList"});

    } // 세조회
    else if ( value.action === 'serial'){
      const find1 = { 
        ...FindDefault,
        action: ApiCmd.action_track_no,
        site_no : loginState.user.site_cd,
        track_no: value.track_no,
        serial_no:value.serial,
        det_seq : value.det_seq,
      };
      navigation.navigate('TrsClientDetail', {find:find1,  menuId:"TrsList"});

    } // 세조회
    else{
      console.log('리스트 셀렉트', value);
      const find1 = { 
        ...FindDefault,
        action: ApiCmd.action_trsblist,
        loc_sts:value.loc_sts,
        track_step:value.track_step,
        site_no : loginState.user.site_cd,
        track_no: value.track_no,
      };
  
      navigation.navigate('TrsListDetail', {find:find1,  menuId:"TrsList"});
    }  // 일괄
  },[selected] );

  const onChangeText = useCallback( (name: string,value:string) => {
    setSearchText(value);
  }, [searchText]);

  const onSubmit = useCallback((name:string, text:string)=>{
    //setItem({...item, action:ApiCmd.action_trsalist,loc_sts:'00|01|02', serial_no:searchText, site_no:site});
    onBtnSelect('all');
    //setSync(true);
  }, [item, searchText]);

  const onExcel = useCallback((find:IFindInfo)=>{
    console.log('Excel ........여기엑셀 검색=>>>>>', find);
    setSendData(find);
    setCommand('send');
    setApiName(ApiCmd.api_GetTrsViewExcel);
  }, [command, apiname])


  const onReceived= useCallback(( resultCode:boolean, cmd:string, rData:any)=>{ 
    setApiName("");
    setCommand("");
    setSendData("");
  },[apiname]);

  //useEffect(()=>{
   // setItem({...item, action:ApiCmd.action_trslist, loc_sts:selected, site_no:site});
  //},[site, selected]);

  // 전체, 대기, 티엔테크, Zebra 위치 버튼 
  const ButtonSelect:React.FC<{
    title:string,
    name:string, 
    value:boolean,
    onPress:(name:string)=>void,
    flex?:number,
  }>=(props)=>{
    
    const onPress= useCallback(()=>{ 
       props.onPress(props.name);
    },[props.name, props.value]);
  
    return (
      <View style={{flex:props.flex??1,borderRightColor:'#D9D9D9',borderRightWidth:1, }}>
        <Button onPress={onPress}>
          <View style={{justifyContent:'center', alignItems:'center',height:40, marginTop:3, marginHorizontal: 7}}>
              <Text style={{ color:(props.value === true)? '#99C341':'#7F7F7F'
                            ,fontSize:(props.value === true)?16:14
                            ,fontWeight:(props.value === true)?'bold':'normal'
                            ,backgroundColor:'white'
                            }}>{props.title}</Text>
          </View>   
        </Button>  
      </View>
    );
  }

  return (
    <View style={{flex:1,backgroundColor:"white"}}>
      <HeaderTop
          title="서비스 신청현황" 
          isMain={false}
          onPress={onPressBack}           
          onHome={()=>{navigation.popToTop()}} />

      <View style={{flex:1, marginHorizontal: 16, backgroundColor:"white"}}>
        <Spacer space={20}/>
          <HeaderUserInfo isUser={false} onSelect={onSite} class_cd='acombo' />
        <Spacer space={5}/>
        <InputSearchText title={''} 
                      placeholder={'Serial # '} 
                      name='user_cd' 
                      value={searchText} 
                      onChange={(text:string)=>{
                        onChangeText('user_cd', text); 
                      }}
                      onSubmit={onSubmit}  
                      horizontal={isHorizontal} 
                      />
        <Spacer space={5}/>
        <View style={{flexDirection: "row",height:30, alignItems:'center'}}>
          <Text style={{fontSize:12, color:COLOR.GREEN_1}}>7일이내의 완료만 조회됩니다. 이전 완료건은 이력현황에서 검색하세요.</Text>
        </View>
        <Spacer space={5}/>
        <View style={{flexDirection: "row",height:50,borderWidth:1,borderColor:'#D9D9D9',borderRadius: 10}}>
          <ButtonSelect title='전체' name='all' onPress={onBtnSelect} value={sel.all} />
          <ButtonSelect title='대기'  name='rdy' onPress={onBtnSelect} value={sel.rdy} />
          {(loginState.user_tp === 'U02') && <ButtonSelect title='티엔테크'  name='tnt' onPress={onBtnSelect} value={sel.tnt} flex={1.1}/>}
          {(loginState.user_tp === 'U02') && <ButtonSelect title='제브라'    name='zeb' onPress={onBtnSelect} value={sel.zeb} />}
          {(loginState.user_tp === 'U01') && <ButtonSelect title='A/S진행'   name='as' onPress={onBtnSelect} value={sel.as}/>}
          <ButtonSelect title='완료'  name='end' onPress={onBtnSelect} value={sel.end} />
        </View>
        <View style={{flex:1}}>
        <DataGrid 
          url={(sel.end === true)? ApiCmd.api_GetTrsViewRecord2: ApiCmd.api_GetTrsViewDetail2} 
          title={(selected.length === 0)? '완료현황' :'접수현황' }
          item={item}
          onSelectData={onGridSelectData}
          isSync={isSync}
          onEnd={()=>{setSync(false)}}
          onExcel={onExcel}
          />
        </View>
      </View>
      {   
      ( Platform.OS === 'web' && sendData ) && 
         <ExcelSync apiName={apiname} 
                    sendData={sendData} 
                     onData={onReceived} />
      }

    </View>
  )
}