import React, {useCallback, useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image, BackHandler } from "react-native";
import { useLoginState } from '../../contexts/LoginContext';
import * as ApiCmd from '../../datas/TrsApiType';
import { IFindInfo, FindDefault  } from '../../datas/TDataFind';
import { useTrsNavigation, useTrsRoute } from '../../navigations/StackParamNavi';
import { HeaderTop, HeaderUserInfo } from '../components/HeaderTop';
import { Spacer } from '../../components/Spacer';
import { IGridSelect } from '../components/DataGrid';
import { CodeGrid } from '../components/CodeGrid';
import { InputButton, InputSelectList } from '../components/InputComponent';
import { NoflexTypography } from '../../components/custom/NoflexTypography';
import { DataDropDownList, DataSelectList } from '../components/DataSelectList';
import { DefaultCode, ICode, IDDLSelectValue } from '../../datas/TrsDataDetailType';
import { DefaultDDLSelect } from '../../datas/TrsDataDetailType';
import { useIsFocused } from '@react-navigation/native';
import dayjs from 'dayjs';
import AsyncStorage from "@react-native-async-storage/async-storage";

export const CodeListScreen : React.FC<{}> = () =>{
  const navigation = useTrsNavigation();
  const loginState = useLoginState();
  const [title, setTitle] =  useState('코드관리');
  const [listSelected, setListSelected] =  useState<IDDLSelectValue>(DefaultDDLSelect);
  const [list1Selected, setList1Selected] =  useState<IDDLSelectValue>(DefaultDDLSelect);
  const [list2Selected, setList2Selected] =  useState<IDDLSelectValue>(DefaultDDLSelect);
  const [isSync, setSync] = useState(true);
  const [item, setItem] = useState<IFindInfo>({
    ...FindDefault, 
    
    action:ApiCmd.action_list,
    site_no:loginState.user.site_cd,
  });

  const isFocused = useIsFocused();

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };
  
  useEffect(() =>{
    onSync();
  }, [isFocused]);

  const onSync=useCallback(()=>{
    setSync(true);
  }, [isSync])

  const onPressBack = useCallback(() => {
    navigation.goBack();
  },[] );

  const onGridSelectData = useCallback((value:ICode) => {
    const code = { 
      ...value,
      action: ApiCmd.action_select,
      site_no : loginState.user.site_cd,
      class1  : (listSelected.title === "no selected") ? "":listSelected.title?? "",
      class2  : (list1Selected.title === "no selected") ? "":list1Selected.title??"",
      class3  : (list2Selected.title === "no selected") ? "":list2Selected.title??"",
      class_cd: value.class_cd,
      class_nm: ( list2Selected.value !== '') ? list2Selected.title
                 : ( list1Selected.value !== '') ? list1Selected.title
                 : listSelected.title ,           
    };
    console.log('CodeList onGridSelectData=====?', code);
    navigation.navigate('CodeDetail', {code:code, menuId:"CodeList"});
  },[listSelected, list1Selected, list2Selected]);

  const onNewData = useCallback(()=>{
    const code = { 
      ...DefaultCode,
      action  : "insert",
      site_no : loginState.user.site_cd,
      class1  : (listSelected.title === "no selected") ? "":listSelected.title?? "",
      class2  : (list1Selected.title === "no selected") ? "":list1Selected.title??"",
      class3  : (list2Selected.title === "no selected") ? "":list2Selected.title??"",
      class_cd : ( list2Selected.value !== '') ? list2Selected.value
                 : ( list1Selected.value !== '') ? list1Selected.value
                 : listSelected.value ,
      class_nm: ( list2Selected.value !== '') ? list2Selected.title
                 : ( list1Selected.value !== '') ? list1Selected.title
                 : listSelected.title ,           
      depth:    ( list2Selected.value !== '') ? "3"
                 : ( list1Selected.value !== '') ? "2"
                 : "1"
    };
    console.log('CodeList ....   .........DefaultCode....', code);

    navigation.navigate('CodeReg', {code:code, menuId:"CodeList"});
  },[listSelected, list1Selected, list2Selected]);

  const onListSelect = useCallback((value:IDDLSelectValue) => {
    console.log('onListSelect onSelect ', value);
    setListSelected(value);
    if ( value.value === 'TLIST' || value.value === 'DEVCLS')
    setItem({...item, class_cd:value.value, depth:'1' });
    setTitle(value.title);
    onSync();
  },[listSelected, item, title] ); 

  const onList1Select = useCallback((value:IDDLSelectValue) => {
    console.log('onList1Select onSelect ', value);
    setList1Selected(value);
    if ( value.value !== '' ){
        setItem({...item, class_cd:value.value, depth:'2' });
        setTitle(value.title);
        onSync();
    }
    else{
      setItem({...item, class_cd:listSelected.value, depth:'1' });
      onSync();
    }     
  },[list1Selected, listSelected, item] ); 

  const onList2Select = useCallback((value:IDDLSelectValue) => {
    console.log('onList2Select onSelect ', value);
    setList2Selected(value);
    if ( value.value !== '' ){
      setItem({...item, class_cd:value.value, depth:'3' });
      onSync();

    }
    else    {
      if ( list1Selected.value !== ''){
          setItem({...item, class_cd:list1Selected.value, depth:'2' });
          onSync();
      }
    } 
  },[list2Selected, list1Selected, item, ] ); 

  return (
    <View style={{flex:1, backgroundColor:'white'}}>
      <HeaderTop title="기준정보 관리" 
                 isMain={false} 
                 onPress={onPressBack} 
                 onHome={()=>{navigation.popToTop()}} />

      <View style={{flex:1 , marginHorizontal:16}}>
        <Spacer space={10}/>
        <View style={{ flexDirection: "row", 
                     justifyContent:"space-between", 
                     alignItems:"center", 
                     marginHorizontal: 10}}>
          <View style={{flex:1}}>
            <Text style={{color:'#5A5A5A'}} >대분류</Text>
          </View>
          <View style={{flex:3}}>
            <DataDropDownList action="group0" 
                            name="list0"
                            data={listSelected.value}
                            class_cd={"LIST"} 
                            onSelect={onListSelect}  />
          </View>
        </View>
        <Spacer space={10}/>
        <View style={{ flexDirection: "row", 
                      justifyContent:"space-between", 
                      alignItems:"center", 
                      marginHorizontal: 10}}>
            <View style={{flex:1}}>
              <Text style={{color:'#5A5A5A'}} >중분류</Text>
            </View>
            <View style={{flex:3}}>
              <DataDropDownList action="group1" 
                                name="list1"
                                class_cd={listSelected.value} 
                                onSelect={onList1Select}  />
            </View>
        </View>

        <Spacer space={10}/>
        <View style={{ flexDirection: "row", 
                     justifyContent:"space-between", 
                     alignItems:"center", 
                     marginHorizontal: 10}}>
          <View style={{flex:1}}>
            <Text style={{color:'#5A5A5A'}} >소분류</Text>
          </View>
          <View style={{flex:3}}>
            <DataDropDownList action="group2" 
                              name="list2"
                              class_cd={list1Selected.value} 
                              onSelect={onList2Select}  />
          </View>
        </View>

        <CodeGrid
            url={ApiCmd.api_GetCode} 
            title={title}
            item={item}
            isSync={isSync}
            onEnd={()=>{setSync(false)}}
            onSelectData={onGridSelectData}
          />
      </View>
      <View style={{height:50, alignItems:"flex-end", marginBottom:10, marginHorizontal:16}}>
          <InputButton title={'등록'} onPress={onNewData} />
      </View>
    </View>
  )
}


