import { useTrsNavigation } from '../navigations/StackParamNavi';
import React, {useCallback, useState,useEffect, useRef } from 'react';
import { View, Text, StyleSheet, Image, BackHandler, Platform } from "react-native";
import { Button } from '../components/Button';
import { Spacer } from '../components/Spacer';
import { useLoginStateDispatch, useLoginState } from '../contexts/LoginContext';

import requestIcon from '../img/requestIcon.png'
import statusIcon from '../img/statusIcon.png'
import serviceIcon from '../img/serviceIcon.png'
import selfIcon from '../img/selfIcon.png'
import list from '../img/list.png'
import Management from '../img/Management.png'
import movebox from '../img/movebox.png'
import { NoflexTypography } from '../components/custom/NoflexTypography';
import { HeaderTop, HeaderUserInfo } from './components/HeaderTop';
import { DataSectionGrid } from './components/DataSectionGrid';
import * as ApiCmd from '../datas/TrsApiType';
import * as DataType from '../datas/TrsDataType';
import { IFindInfo, FindDefault  } from '../datas/TDataFind';
import { COLOR } from '../utils/color';
import { DefaultMsg, IMsg, TrsMessageBox, TrsMessageShow, TrsPop } from './components/TrsModal';
import { Popup } from './components/Popup';
import { DataGrid } from './components/DataGrid';
import { useIsFocused } from '@react-navigation/native';
import { AppState } from "react-native"
import dayjs from 'dayjs';
//import utc from 'dayjs/plugin/utc';
//import timezone from 'dayjs/plugin/timezone';
//import { Moment  } from 'moment';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";

//export type IconName = keyof typeof Ionicons.glyphMap | keyof typeof FontAwesome.glyphMap;
export const TrsMainScreen : React.FC<{}> = () =>{
  const tmout = Constants.manifest?.timeout;
  const navigation = useTrsNavigation();
  const dispatch = useLoginStateDispatch();
  const loginState = useLoginState();
  const [selected, setSelected] =  useState("SITE");
  const user_cd = (loginState.user.user_tp === 'U0102')? loginState.user.user_cd: '';
  const isFocused = useIsFocused();
  const [isSync, setSync] = useState(false);
  const [item, setItem] = useState<IFindInfo>(
    (loginState.user.user_tp === 'U0102')?
    {
      ...FindDefault, 
     // action:ApiCmd.action_trsalist,
     action:ApiCmd.action_trstotal,
     site_no:loginState.site_cd,
      user_cd : (loginState.user.user_tp === 'U0102' ) ? loginState.user.user_cd:"",
      user_tp: loginState.user.user_tp,
    }
    :
    {
    ...FindDefault, 
    action:ApiCmd.action_trstotal,
    site_no:loginState.user.site_cd,
    user_cd : (loginState.user.user_tp === 'U0102' ) ? loginState.user.user_cd:"",
    user_tp: loginState.user.user_tp,
    });

  const fontSize=(Platform.OS ==='web') ? 15:12;
  const [menuVisible, setMenuVisible] = useState(false);
  const [msgInfo, setMsgInfo] = useState<IMsg>(DefaultMsg);
  const [message, setMessage] = useState<IMsg>(DefaultMsg);
  
  const menuHeight = (loginState.user.user_tp === 'U0102' || loginState.user.user_tp === 'U0201') ? 70:70;

  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const [startNow, setStartNow] = useState(dayjs());
  const [tmValue, setTmValue] = useState(1000*60);

  const recordStartTime = async () => {
    try {
      var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
      console.log("MainScreen Start Time.222......", format2)
      await AsyncStorage.setItem("@start_time", format2);
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };


  const getTm = async()=>{
    var format1 = await AsyncStorage.getItem("@start_time")
    var format2 = dayjs().format('YYYY-MM-DD HH:mm').toString();
    console.log("async storage time format1, format2....",format1, format2)
    var st_tm = dayjs(format1, "YYYY-MM-DD HH:mm:ss")
    var ct_tm = dayjs(format2, "YYYY-MM-DD HH:mm:ss")

    var min = ct_tm.diff(st_tm, "minute");
    if ( min > (tmout?? 30)){
      console.log("async storage time Min > 10.... onOk",ct_tm, st_tm,  min)
      onOK('logout');
    }
    else console.log("async storage time Compare1....",ct_tm, st_tm,  min)
  }


  useEffect(() => {
    const interval = setInterval(() => {
      if ( loginState.isLogin ) getTm();
    }, tmValue);

    return () => {
      console.log("remove timer interval........")
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        console.log('App has come to the foreground!');
      }

      appState.current = nextAppState;
      setAppStateVisible(appState.current);
      console.log('AppState', appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() =>{
    onSync();
  }, [isFocused]);

  const onSync=useCallback(()=>{
    setSync(true);
  }, [isSync])

  const onEnd = useCallback(() => {
    setSync(false);
  },[isSync]);

  const onSelect = useCallback((key:string) => {
    setSelected(key);
    setItem({...item, site_no:key});
    onSync();
  },[selected, item] ); 

  const onOK = useCallback((msgId:string)=>{
    if (msgId === 'logout')
        dispatch({ type: 'SET_LOGOUT', });
  }, [dispatch, loginState]);

  const onMenu = useCallback((menuId:string)=>{

    recordStartTime();

    switch(menuId){
      case 'menu':
        setMenuVisible(!menuVisible);
        break;
      case 'UserInfo'  :
        const find1 = { 
          ...FindDefault,
          action: ApiCmd.action_select,
          site_no : loginState.user.site_cd,
          user_tp: loginState.user.user_tp,  
          user_cd: loginState.user.user_cd,
        };
    
        navigation.navigate('UserDetail', {find:find1,  menuId:"TrsMain"});
        break;
      case 'logout':
        console.log('logout value', loginState.isLogin  )
        setMsgInfo({...DefaultMsg, msgId:'logout', message:'로그아웃 하시겠습니까?',});

        
        break;
      case 'MoveReg':
        navigation.navigate('MoveReg');
        break;
      case 'StackSelfTest':
        navigation.navigate('SelfTest'); 
        break;
      case 'StackClientReg':
        navigation.navigate('ClientReg', { menuId:"TrsMain"});
        break;
      case 'StackTrsList':
        const find2 = { 
          ...FindDefault,
          action: ApiCmd.action_trslist,
          user_tp: loginState.user.user_tp,  
          user_cd: loginState.user.user_cd,
        };
        navigation.navigate('TrsList', {find:find2, menuId:"TrsMain"});
        break;
      case 'StackTrsRecord':
        const find3 = { 
          ...FindDefault,
          action: ApiCmd.action_trslist,
          user_tp: loginState.user.user_tp,  
          user_cd: loginState.user.user_cd,
        };
        navigation.navigate('TrsRecord');
        break;
      case 'StackAdminList':
        navigation.navigate('TrsAdminList');
        break;
      case 'User':
        navigation.navigate('UserList');
        break;
      case 'Code':
        navigation.navigate('CodeList');
        break;
      case 'Serial':
        navigation.navigate('SerialList');
        break;
      default:
      break;
    }

  },[]);

    
  const MenuButton:React.FC<{
    title:string,
    source:HTMLImageElement,
    onButtonPress:()=>void 
  }>=(props)=>{
   
      return(
        <View style={{ justifyContent:'center', alignItems:'center' }}>
          <Button onPress={props.onButtonPress}>
            <View style={styles.circle}>
              <Image source={props.source} style={{width : 20, height: 20} }/>
            </View> 
            <Spacer space={7}  /> 
          </Button>
          <View style={{justifyContent:'center',alignItems:'center' , backgroundColor:'blue'}}>
              <NoflexTypography color='#5A5A5A' fontSize={fontSize}>{props.title}</NoflexTypography>
            </View>
        </View>
      );
  }

  const MainMenu:React.FC =()=>{
    switch(loginState.user.user_tp){
      case 'U0100': case 'U0101':
        return (
          <View style={{ flex : 1, justifyContent:"flex-start"}}>
            <View style={{ flexDirection: "row", justifyContent:"space-around"}}>
              <MenuButton onButtonPress={()=>onMenu('StackSelfTest')} source={selfIcon} title={'자가검진'}/>
              <MenuButton onButtonPress={()=>onMenu('StackClientReg')} source={serviceIcon} title={'서비스신청'}/>
              <MenuButton onButtonPress={()=>onMenu('StackTrsList')} source={Management} title={'진행관리'}/>
              <MenuButton onButtonPress={()=>onMenu('StackTrsRecord')} source={list} title={'이력조회'}/>
              <MenuButton onButtonPress={()=>onMenu('StackAdminList')} source={requestIcon} title={'장애분류현황'}/>
            </View> 
           <Spacer space={5}  />
          </View>
        )

      case 'U0102':
        return (
          <View style={{ flex : 1, justifyContent:"flex-start"}}>
            <View style={{ flexDirection: "row", justifyContent:"space-around"}}>
              <MenuButton onButtonPress={()=>onMenu('StackSelfTest')} source={selfIcon} title={'자가검진'}/>
              <MenuButton onButtonPress={()=>onMenu('StackClientReg')} source={serviceIcon} title={'서비스신청'}/>
              <MenuButton onButtonPress={()=>onMenu('StackTrsList')} source={Management} title={'진행관리'}/>
              <MenuButton onButtonPress={()=>onMenu('StackTrsRecord')} source={list} title={'이력조회'}/>
            </View> 
           <Spacer space={5}  />
          </View>
        )
      case 'U0200':
      case 'U0201':
          return (
          <View style={{ flex : 1, justifyContent:"flex-start", height:70}}>
            <View style={{ flexDirection: "row", justifyContent:"space-around"}}>
              <MenuButton onButtonPress={()=>onMenu('MoveReg')} source={movebox} title={'이동등록'}/>
              <MenuButton onButtonPress={()=>onMenu('StackTrsList')} source={Management} title={'진행관리'}/>
              <MenuButton onButtonPress={()=>onMenu('StackTrsRecord')} source={list} title={'이력조회'}/>
              <MenuButton onButtonPress={()=>onMenu('StackAdminList')} source={requestIcon} title={'장애분류현황'}/>
            </View>
            <Spacer space={5}  />
          </View>
        )
      default:
        return <View></View>
    }// end-of-switch
  }

  return (
    <View style={{flex:1, backgroundColor:'white'}}>
      <HeaderTop title="Tracking System" isMain={true} onPress={()=>onMenu('menu')} />
        
      <View style={{flex:1, marginHorizontal:16}}>
        <Spacer space={20}/>
          <HeaderUserInfo isUser={false} onSelect={onSelect} />
        <Spacer space={20}/>
        {/** 메인 메뉴 Tab */ }
        <View style={{height:menuHeight}}>
          <MainMenu />
        </View>
        <Spacer space={30}/>
        {/** 서비스 신청현황 */}
        <View style={{ flex:2, justifyContent:"flex-start", backgroundColor:"white" }}>
          {
            //(loginState.user.user_tp !== 'U0102')&&
                <DataSectionGrid 
                  apiname={ApiCmd.api_GetTrsViewTotal} 
                  title='서비스 신청 현황' 
                  item={item} 
                  onSelectData={(find:IFindInfo)=>{
                  }}
                  isSync={isSync}
                  onEnd={onEnd}
                  />
          }
        </View>
      </View>
      
      <TrsPop show={menuVisible} onMenu={(menuId:string)=>{
                                          setMenuVisible(false);
                                          onMenu(menuId);
                                         }}  />

      <TrsMessageBox message={msgInfo} 
                      onOK={onOK} 
                      onCancel={(msgId:string)=>{
                                console.log('Cancel......................'); 
                                setMessage({...DefaultMsg, msgId:'', message:''})}} />


      <TrsMessageShow message={message} 
                      onMessage={(msgId:string)=>{
                                  setMessage({...DefaultMsg, msgId:'', message:''});
                                  }} />

    </View>
  )
}
const styles = StyleSheet.create({
 
  circle:{
    
    width : 60,
    height: 60,
    borderRadius : 50,
    justifyContent:'center', 
    alignItems:'center',
    borderColor:COLOR.MAINMENUBORDER, // '#F2F2F2',
    borderWidth:1,
  }
});